import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchPaySchedule, PaySchedule as PayScheduleType } from 'lib/fetchPaySchedule';
import { sendErrorMessage } from 'lib/sendErrorMessage';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { SectionHeader } from 'components/SectionHeader/sectionHeader';
import { PayScheduleForm } from 'components/PayScheduleForm/payScheduleForm';
import { ActionButton } from 'components/ActionButton/actionButton';
import { Spinner } from 'components/Spinner/spinner';
import {
  PayScheduleItemContainer,
  PayScheduleItemLabel,
  PayScheduleItemValue,
  PayScheduleContent,
  PayScheduleMessage,
  PayScheduleContainer,
  ButtonContainer,
} from './paySchedule.styles';

export const PaySchedule = () => {
  const navigate = useNavigate();
  const [paySchedule, setPaySchedule] = useState<PayScheduleType | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchPaySchedule()
      .then((data) => {
        setPaySchedule(data);
        setIsLoading(false);
      })
      .catch((error) => {
        sendErrorMessage(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ContentContainer onboarding={true} contentAlign="center">
      <PayScheduleContainer>
        <SectionHeader
          title="Pay Schedule"
          message="Set up your pay schedule by selecting the pay frequency, first period end, and first payday."
          divider={true}
        />
        {paySchedule ? (
          <PayScheduleContent>
            <PayScheduleMessage>
              <strong>(1)</strong> Pay schedule has already been created for this company. Only 1
              pay schedule can be added per company.
            </PayScheduleMessage>
            <PayScheduleItemContainer>
              <PayScheduleItemLabel>Pay Frequency</PayScheduleItemLabel>
              <PayScheduleItemValue>{paySchedule.payFrequency}</PayScheduleItemValue>
            </PayScheduleItemContainer>
            <PayScheduleItemContainer>
              <PayScheduleItemLabel>Next Payday</PayScheduleItemLabel>
              <PayScheduleItemValue>{paySchedule.nextPayday.payday}</PayScheduleItemValue>
            </PayScheduleItemContainer>
            <ButtonContainer>
              <ActionButton
                onClick={() => navigate('/company-onboarding')}
                size="medium"
                title="Continue"
                hidden={false}
              />
            </ButtonContainer>
          </PayScheduleContent>
        ) : (
          <PayScheduleForm onboarding={true} />
        )}
      </PayScheduleContainer>
    </ContentContainer>
  );
};
