import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPasswordSchema } from './validator';
import { ResetPasswordData } from './types';
import { resetPassword } from 'lib/resetPassword';
import { FormInput, FormButton } from 'components/form';
import { toast } from 'react-toastify';
import {
  Form,
  AuthFormContainer,
  AuthForm,
  AuthFormHeader,
  AuthSubHeader,
  ButtonContainer,
  PasswordWrapper,
  ToggleButton,
  AuthAction,
} from '../authForm.styles';
import { ReactComponent as ViewIcon } from '@src/assets/icons/view.svg';
import { ReactComponent as HideIcon } from '@src/assets/icons/hide.svg';

export const ResetPasswordForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ResetPasswordData>({
    resolver: yupResolver(resetPasswordSchema),
  });
  const { resetToken } = useParams();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const onSubmit: SubmitHandler<ResetPasswordData> = async (data: ResetPasswordData) => {
    if (Object.keys(errors).length === 0 && resetToken) {
      const response = await resetPassword({
        reset_password_token: resetToken,
        password: data.password,
      });
      if (response?.message) {
        toast.success(response.message);
        navigate('/login');
      }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  const isButtonDisabled = (): boolean => {
    return !password || !confirmPassword || password !== confirmPassword;
  };

  return (
    <AuthForm>
      <AuthFormHeader>Change Password</AuthFormHeader>
      <AuthSubHeader>Enter a new password for your account.</AuthSubHeader>
      <AuthFormContainer>
        <Form>
          <PasswordWrapper>
            <FormInput
              id="password"
              placeholder="Password"
              type={passwordVisible ? 'text' : 'password'}
              autoComplete="on"
              required
              {...register('password')}
              error={errors.password?.message}
            />
            <ToggleButton
              type="button"
              icon={passwordVisible ? <HideIcon /> : <ViewIcon />}
              onClick={togglePasswordVisibility}
            />
          </PasswordWrapper>
          <PasswordWrapper>
            <FormInput
              id="confirmPassword"
              placeholder="Confirm Password"
              type={confirmPasswordVisible ? 'text' : 'password'}
              autoComplete="on"
              required
              {...register('confirmPassword')}
              error={errors.confirmPassword?.message}
            />
            <ToggleButton
              type="button"
              icon={confirmPasswordVisible ? <HideIcon /> : <ViewIcon />}
              onClick={toggleConfirmPasswordVisibility}
            />
          </PasswordWrapper>
          <AuthAction>
            Must be 8 or more characters and contain at least 1 number and 1 special character.
          </AuthAction>
          <ButtonContainer>
            <FormButton
              onSubmit={handleSubmit(onSubmit)}
              disabled={isButtonDisabled()}
              label="Continue"
            />
          </ButtonContainer>
        </Form>
      </AuthFormContainer>
    </AuthForm>
  );
};
