import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from './sendErrorMessage';

export enum PayrollTypes {
  REGULAR = 'regular',
  OFF_CYCLE = 'off_cycle',
}

type CreatePayrollInput = {
  startDate: string;
  endDate: string;
  payday: string;
  employeeIds?: string[];
  type?: PayrollTypes;
};

type PayrollItem = {
  id: string;
  providerIdentifier: string;
};

type Payroll = {
  id: string;
  providerIdentifier: string;
  payrollItems: PayrollItem[];
};

type PayrollError = {
  message: string;
  type: string;
  inputErrors: {
    message: string;
    field: string;
    fieldPath: string;
  }[];
};

type CreatePayrollPayload = {
  error: PayrollError;
  payroll: Payroll;
};

export const createPayroll = (payrollInput: CreatePayrollInput): Promise<Payroll | void> => {
  const mutation = `
    mutation CreatePayroll($payrollInput: CreatePayrollInput!) {
      createPayroll(payrollInput: $payrollInput) {
        error {
          message
          type
          inputErrors {
            message
            field
            fieldPath
          }
        }
        payroll {
          id
          providerIdentifier
          payrollItems {
            id
            providerIdentifier
          }
        }
      }
    }
  `;

  const variables = {
    payrollInput: {
      ...payrollInput,
      employeeIds: payrollInput.employeeIds || [],
      type: payrollInput.type || PayrollTypes.REGULAR,
    },
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { createPayroll: CreatePayrollPayload } }>>('graphql', {
      query: mutation,
      variables,
    })
    .then(
      (
        response: AxiosResponse<{
          data: { createPayroll: CreatePayrollPayload };
        }>
      ) => {
        const { error, payroll } = response.data.data.createPayroll;
        if (error && error.message) {
          sendErrorMessage(error);
          return;
        }
        return payroll;
      }
    )
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
