import { downloadEmployeePaystub } from 'lib/downloadEmployeePaystub';

export const downloadPaystub = async (payrollId: string) => {
  const paystubData = await downloadEmployeePaystub(payrollId);
  if (paystubData) {
    const url = window.URL.createObjectURL(new Blob([paystubData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `paystub_${payrollId}.pdf`);
    document.body.appendChild(link);
    link.click();
  }
};
