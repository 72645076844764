import { StyledTable, Thead, Tr, Th, Td, Tbody, EmptyMessage } from '../Table.styles';
import { DownloadLink } from 'components/DownloadLink/DownloadLink';
import { CompanyDocument, ProvidedDocument, DocumentType } from 'lib/fetchCompanyDocuments';
import { downloadDocument } from '../../../lib/utils/downloadDocument';
import { formatValue } from 'lib/utils/formatValue';

type TableProps = {
  documents: (CompanyDocument | ProvidedDocument)[];
  type: DocumentType;
};

export const Table = ({ documents, type }: TableProps) => {
  if (!documents || documents.length === 0) {
    return <EmptyMessage>No documents found.</EmptyMessage>;
  }

  const isProvidedDocument = (doc: CompanyDocument | ProvidedDocument): doc is ProvidedDocument => {
    return 'documentType' in doc;
  };

  return (
    <StyledTable>
      <Thead>
        <Tr>
          {type === DocumentType.PROVIDED ? (
            <>
              <Th>Name</Th>
              <Th>Document Type</Th>
              <Th>Upload Status</Th>
            </>
          ) : (
            <>
              <Th>Document</Th>
              <Th>Signed Date</Th>
              <Th>Year</Th>
            </>
          )}
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {documents.map((doc) => (
          <Tr key={doc.id}>
            {isProvidedDocument(doc) ? (
              <>
                <Td>{doc.providedFilename}</Td>
                <Td>{formatValue(doc.documentType, true)}</Td>
                <Td>{formatValue(doc.uploadStatus, true)}</Td>
              </>
            ) : (
              <>
                <Td>{doc.label}</Td>
                <Td>{doc.filedOn}</Td>
                <Td>{doc.year}</Td>
              </>
            )}
            <Td>
              {type !== DocumentType.PROVIDED && (
                <DownloadLink onClick={() => downloadDocument(type, doc.id)}>Download</DownloadLink>
              )}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </StyledTable>
  );
};
