import {
  AuthorizeSignatoryContainer,
  WelcomeContainer,
  WelcomeHeading,
  WelcomeMessage,
  SignatoryContainer,
  SignatoryHeading,
  SignatoryMessage,
} from './AuthorizeSignatory.styles';
import { SignatoryForm } from './SignatoryForm/SignatoryForm';

export const AuthorizeSignatory = () => {
  return (
    <AuthorizeSignatoryContainer>
      <WelcomeContainer>
        <WelcomeHeading>Welcome!</WelcomeHeading>
        <WelcomeMessage>
          Procare Software uses Check to set up your payroll in minutes.
        </WelcomeMessage>
      </WelcomeContainer>
      <SignatoryContainer>
        <SignatoryHeading>Signatory</SignatoryHeading>
        <SignatoryMessage>
          This is the company&apos;s authorized signatory for filing purposes.
        </SignatoryMessage>
        <SignatoryForm />
      </SignatoryContainer>
    </AuthorizeSignatoryContainer>
  );
};
