import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { SyncErrorsTable } from 'components/Table/SyncErrorsTable/SyncErrorsTable';
import { Instruction } from './Errors.styles';

export const Errors = () => {
  return (
    <ContentContainer>
      <Instruction>Use the table below to review sync errors and how to correct them. </Instruction>
      <SyncErrorsTable />
    </ContentContainer>
  );
};
