import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { passwordSchema } from './validators';
import { FormInput, FormGroup } from 'components/form';
import { ActionButton } from 'components/ActionButton/actionButton';
import { updateUserPassword } from 'lib/updateUserPassword';
import { toast } from 'react-toastify';
import { PasswordFormContainer, Form, ButtonContainer } from './ResetPasswordForm.styles';

type PasswordResetFormProps = {
  handleFormSubmit: () => void;
};

export const PasswordResetForm = ({ handleFormSubmit }: PasswordResetFormProps) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordSchema),
  });

  const newPassword = watch('newPassword');
  const confirmPassword = watch('confirmPassword');

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    if (Object.keys(errors).length === 0) {
      const response = await updateUserPassword(data.newPassword);
      if (response) {
        toast.success('Password has been successfully updated.');
        handleFormSubmit();
      }
    }
  };

  const isButtonDisabled = newPassword !== confirmPassword || !newPassword || !confirmPassword;

  return (
    <PasswordFormContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup label="New Password">
          <FormInput
            id="newPassword"
            placeholder="New Password"
            type="password"
            required
            {...register('newPassword')}
          />
        </FormGroup>
        <FormGroup label="Confirm Password">
          <FormInput
            id="confirmPassword"
            placeholder="Confirm Password"
            type="password"
            required
            {...register('confirmPassword')}
          />
        </FormGroup>
        <ButtonContainer>
          <ActionButton
            size="medium"
            title="Save"
            hidden={false}
            disabled={isButtonDisabled}
            shape="fullWidth"
            onClick={handleSubmit(onSubmit)}
          />
        </ButtonContainer>
      </Form>
    </PasswordFormContainer>
  );
};
