import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from './sendErrorMessage';
import { Company } from 'features/company/types';

type CompanyUpdateInput = {
  legalName: string;
  tradeName?: string;
  phone: string;
};

type UpdateCompanyPayload = {
  company: Company;
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

export const updateCompany = (companyParams: CompanyUpdateInput): Promise<Company | void> => {
  const mutation = `
    mutation($companyParams: UpdateCompanyInput!) {
      updateCompany(companyParams: $companyParams) {
        company {
          id
          name
          onboarded
          platformIdentifier
          providerAttributes {
            legalName
            tradeName
            phone
            providerIdentifier
          }
        }
        error {
          message
          type
          inputErrors {
            field
            fieldPath
            message
          }
        }
      }
    }
  `;

  const variables = {
    companyParams,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { updateCompany: UpdateCompanyPayload } }>>('graphql', {
      query: mutation,
      variables,
    })
    .then((response: AxiosResponse<{ data: { updateCompany: UpdateCompanyPayload } }>) => {
      if (response.data.data.updateCompany.error) {
        sendErrorMessage(response.data.data.updateCompany.error);
      }
      return response.data.data.updateCompany.company;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
