import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { CheckComponent } from 'components/CheckComponent/checkComponent';
import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { fetchCompanyOnboarding } from 'lib/Check/fetchCompanyOnboardingComponent';
import { Spinner } from 'components/Spinner/spinner';
import { CompanyOnboardingModal } from './companyOnboarding.styles';
import { selectCurrentUser, logout } from 'features/user/slice';
import { CompanyOnboardingNotification } from 'components/DashboardNotifications/CompanyOnboardingNotification.tsx/companyOnboardingNotification';
import { setCompanyOnboarded } from 'features/company/slice';
import { fetchCompanyOnboardingStatus } from 'lib/fetchCompanyOnboardingStatus';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const CompanyOnboarding = () => {
  const [componentURL, setComponentURL] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const currentUser = useAppSelector(selectCurrentUser);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const signatoryId = currentUser?.signatory?.providerIdentifier ?? '';
    if (!signatoryId) return;

    const fetchComponentURL = async () => {
      const url = await fetchCompanyOnboarding(signatoryId);
      if (url) {
        setComponentURL(url);
      }
      setIsLoading(false);
    };
    fetchComponentURL();
  }, [currentUser]);

  useEffect(() => {
    fetchCompanyOnboardingStatus().then((status) => {
      dispatch(setCompanyOnboarded(status));

      if (status) {
        dispatch(logout());
        toast.error(
          'This company has already been onboarded. Please log in using your credentials or contact your company admin.'
        );
        navigate('/login');
      }
    });
  }, [dispatch, navigate]);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const completeOnboarding = () => {
    closeModal();
    navigate('/onboarding-complete');
    toast.success('You have successfully completed company onboarding.');
  };

  return (
    <ContentContainer contentAlign="left" onboarding={true}>
      <CompanyOnboardingNotification openModal={openModal} />
      {isLoading ? (
        <Spinner />
      ) : (
        <CompanyOnboardingModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              position: 'fixed',
              inset: '0px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 11,
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              transform: 'translate(-50%, -50%)',
              borderRadius: '16px',
              overflow: 'visible',
              width: '65vw',
            },
          }}
        >
          <CheckComponent
            id="company-onboarding"
            componentURL={componentURL}
            onComplete={completeOnboarding}
            onClose={closeModal}
          />
        </CompanyOnboardingModal>
      )}
    </ContentContainer>
  );
};
