import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { PayrollsTable } from 'components/Table/PayrollsTable/PayrollsTable';
import { PayrollStatus } from 'lib/fetchCompanyPayrolls';

export const Submitted = () => {
  return (
    <ContentContainer>
      <PayrollsTable status={PayrollStatus.Paid} />
    </ContentContainer>
  );
};
