import styled from 'styled-components';
import { ActionButtonBase } from 'components/ActionButton/actionButton.styles';

export const Container = styled.div`
  max-width: 750px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20vh;
`;

export const Heading = styled.h1`
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.largeHeading};
  font-weight: 400;
  color: ${(props) => props.theme.colors.black0};
`;

export const Message = styled.div`
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.subHeading};
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;

  ${ActionButtonBase} {
    width: 180px;
  }
`;
