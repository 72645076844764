import {
  SyncLogContainer,
  Instructions,
  ButtonContainer,
  ReviewContainer,
  StyledLink,
} from './SyncLog.styles';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { ActionButton } from 'components/ActionButton/actionButton';
import { SyncsLogTable } from 'components/Table/SyncsLogTable/SyncsLogTable';

export const SyncLog = () => {
  return (
    <ContentContainer>
      <SyncLogContainer>
        <Instructions>
          Use the “Sync Data” button to update workplace and staff information. This action
          initiates a sync, keeping payroll records up-to-date.
        </Instructions>
        <ReviewContainer>
          <p>
            To review all staff members that have been synced navigate to{' '}
            <StyledLink to="/people">People</StyledLink>.
          </p>
          <p>
            To review all staff members that have been synced navigate to{' '}
            <StyledLink to="/company">Workplaces</StyledLink>.
          </p>
        </ReviewContainer>
        <ButtonContainer>
          <ActionButton onClick={() => {}} size="medium" title="Sync Data" hidden={false} />
        </ButtonContainer>
      </SyncLogContainer>
      <SyncsLogTable />
    </ContentContainer>
  );
};
