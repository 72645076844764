import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-top: 10px;
`;

export const FormContainer = styled.div``;

export const FormHeading = styled.h3`
  font-size: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
  margin: 0;
  padding-bottom: 10px;
  text-align: center;
`;

export const CancelButton = styled.div`
  display: flex;
  justify-content: center;
`;
