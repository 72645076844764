import { useEffect, useState } from 'react';
import { Table } from './Table';
import {
  fetchCompanyDocuments,
  CompanyDocument,
  ProvidedDocument,
  PageInfo,
  DocumentType,
} from 'lib/fetchCompanyDocuments';
import { Spinner } from 'components/Spinner/spinner';
import { Pagination } from 'components/Pagination/pagination';
import { DEFAULT_PAGE_SIZE } from '@src/constants/constants';

type CompanyDocumentsTableProps = {
  type: DocumentType;
  refresh?: boolean;
};

export const CompanyDocumentsTable = ({ type, refresh }: CompanyDocumentsTableProps) => {
  const [documents, setDocuments] = useState<(CompanyDocument | ProvidedDocument)[]>([]);
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);

  const loadMore = () => {
    if (pageInfo?.hasNextPage) {
      setEndCursor(pageInfo.endCursor);
    }
  };

  const onPrev = () => {
    if (pageInfo?.hasPreviousPage) {
      setEndCursor(pageInfo.startCursor);
    }
  };

  const onNext = () => {
    if (pageInfo?.hasNextPage) {
      loadMore();
    }
  };

  useEffect(() => {
    fetchCompanyDocuments(type, DEFAULT_PAGE_SIZE, endCursor).then(
      ({ documents: newDocuments, pageInfo: newPageInfo }) => {
        setDocuments(newDocuments);
        setPageInfo(newPageInfo);
        setLoading(false);
      }
    );
  }, [type, endCursor, refresh]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      {pageInfo && (pageInfo.hasPreviousPage || pageInfo.hasNextPage) && (
        <Pagination
          onPrev={onPrev}
          onNext={onNext}
          disablePrev={!pageInfo.hasPreviousPage}
          disableNext={!pageInfo.hasNextPage}
        />
      )}
      <Table documents={documents} type={type} />
    </>
  );
};
