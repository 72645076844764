import { useLocation, useNavigate, useParams } from 'react-router-dom';

type RouterProps = {
  location: ReturnType<typeof useLocation>;
  navigate: ReturnType<typeof useNavigate>;
  params: ReturnType<typeof useParams>;
};

export function withRouter<T extends Record<string, any>>(
  Component: React.ComponentType<T & { router: RouterProps }>
) {
  function ComponentWithRouterProp(props: T) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
