import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createSignatorySchema } from './validators';
import { SignatoryInput } from './types';
import {
  Form,
  SignatoryFormContainer,
  ButtonContainer,
  FormGroup,
} from './CreateSignatoryForm.styles';
import { FormInput, FormSelect } from 'components/form';
import { ActionButton } from 'components/ActionButton/actionButton';
import { toast } from 'react-toastify';
import { Employee, fetchProviderEmployeesList } from 'lib/fetchProviderEmployeesList';
import { createEmployeeSignatory } from 'lib/createEmployeeSignatory';

type CreateSignatoryFormProps = {
  onFormSubmitSuccess: () => void;
};

export const CreateSignatoryForm = ({ onFormSubmitSuccess }: CreateSignatoryFormProps) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<SignatoryInput>({
    resolver: yupResolver(createSignatorySchema),
  });
  const [employees, setEmployees] = useState<Employee[]>([]);

  useEffect(() => {
    async function loadEmployees() {
      const { employees: fetchedEmployees } = await fetchProviderEmployeesList(undefined, 50);
      if (fetchedEmployees && fetchedEmployees.length > 0) {
        setEmployees(fetchedEmployees);
        setValue('employeeProviderId', fetchedEmployees[0].providerIdentifier);
      }
    }
    loadEmployees();
  }, [setValue]);

  const selectedEmployeeProviderId = watch('employeeProviderId');

  useEffect(() => {
    const selectedEmployee = employees.find(
      (employee) => employee.providerIdentifier === selectedEmployeeProviderId
    );
    if (selectedEmployee) {
      setValue('firstName', selectedEmployee.firstName);
      setValue('lastName', selectedEmployee.lastName);
      setValue('email', selectedEmployee.email);
    }
  }, [selectedEmployeeProviderId, employees, setValue]);

  const handleEmployeeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setValue('employeeProviderId', value);

    const selectedEmployee = employees.find((employee) => employee.providerIdentifier === value);
    if (selectedEmployee) {
      setValue('firstName', selectedEmployee.firstName);
      setValue('lastName', selectedEmployee.lastName);
      setValue('email', selectedEmployee.email);
    }
  };

  const onSubmit: SubmitHandler<SignatoryInput> = async (data: SignatoryInput) => {
    if (Object.keys(errors).length == 0) {
      try {
        const signatory = await createEmployeeSignatory(data);
        if (signatory) {
          toast.success('Signatory successfully created');
          onFormSubmitSuccess(); // Call the callback function here
        }
      } catch (error) {
        toast.error('Error creating signatory');
      }
    }
  };

  const isButtonDisabled = (): boolean => {
    const email = watch('email');
    const title = watch('title');
    const firstName = watch('firstName');
    const lastName = watch('lastName');

    return !email || !title || !firstName || !lastName;
  };

  return (
    <SignatoryFormContainer>
      <Form>
        <FormSelect
          label="Employee"
          id="employeeProviderId"
          required
          options={employees.map((employee) => ({
            value: employee.providerIdentifier,
            label: `${employee.firstName} ${employee.lastName}`,
          }))}
          defaultValue={employees.length > 0 ? employees[0].providerIdentifier : ''}
          {...register('employeeProviderId')}
          onChange={handleEmployeeChange}
        />

        <FormGroup>
          <FormInput
            id="firstName"
            placeholder="First Name"
            type="text"
            label="First Name"
            required
            {...register('firstName')}
            error={errors.firstName?.message}
          />
          <FormInput
            id="lastName"
            placeholder="Last Name"
            type="text"
            label="Last Name"
            required
            {...register('lastName')}
            error={errors.lastName?.message}
          />
        </FormGroup>
        <FormGroup>
          <FormInput
            id="title"
            placeholder="Title"
            type="text"
            label="Title"
            required
            {...register('title')}
            error={errors.title?.message}
          />
          <FormInput
            id="email"
            placeholder="Email"
            type="email"
            label="Email"
            required
            {...register('email')}
            error={errors.email?.message}
            disabled={true}
          />
        </FormGroup>
      </Form>
      <ButtonContainer>
        <ActionButton
          onClick={handleSubmit(onSubmit)}
          size="medium"
          title="Add Signatory"
          shape="fullWidth"
          hidden={false}
          disabled={isButtonDisabled()}
        />
      </ButtonContainer>
    </SignatoryFormContainer>
  );
};
