import { AxiosResponse, AxiosError } from 'axios';
import { sendAuthErrorMessage } from './sendAuthErrorMessage';
import { procareApi, ErrorResponse } from './HttpClient/httpClient';

type ResetPasswordRequest = {
  reset_password_token: string;
  password: string;
};

type ResetPasswordResponse = {
  message: string;
};

export const resetPassword = (data: ResetPasswordRequest): Promise<ResetPasswordResponse | void> =>
  procareApi
    .post<ResetPasswordRequest, AxiosResponse<ResetPasswordResponse>>('/api/v1/auth/reset', data)
    .then((response: AxiosResponse<ResetPasswordResponse>) => response.data)
    .catch((error: AxiosError<ErrorResponse>) => {
      sendAuthErrorMessage(error);
    });
