import styled, { css } from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;
  max-width: 1300px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid ${(props) => props.theme.colors.borderTable};
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 8px;
`;

export const Thead = styled.thead`
  background-color: ${(props) => props.theme.colors.gray5};
`;

export const Tbody = styled.tbody`
  background-color: white;
`;

export const Th = styled.th`
  color: black;
  font-weight: normal;
  padding: 20px;
  text-align: left;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontSizes.tableHeading};
`;

export const Tr = styled.tr``;

export const Td = styled.td`
  padding: 20px;
  border-top: 1px solid #ddd;
`;

export const StyledLink = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.primaryDark};
  }
`;

export const TableLink = styled(StyledLink)`
  text-transform: uppercase;
`;

export const EmptyMessage = styled.p`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: 16px;
  width: 100%;
  padding: 16px;
`;

const Status = css`
  text-transform: capitalize;
  border-radius: 15px;
  padding: 8px 12px;
`;

export const BlockingStatus = styled.span`
  background-color: ${(props) => props.theme.colors.blockingStatus};
  ${Status}
`;

export const CompletedStatus = styled.span`
  background-color: ${(props) => props.theme.colors.completedStatus};
  ${Status}
`;

export const AttentionStatus = styled.span`
  background-color: #ffffcc;
  ${Status}
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const Button = styled.button`
  border: none;
  background-color: inherit;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: ${(props) => props.theme.colors.primaryDark};
  }
`;

export const ControlBar = styled.div`
  display: flex;
  justify-content: space-between;
`;
