import { css } from 'styled-components';

export const hexToRgba = (hex: string, alpha = 1) => {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const fullWidthRestricted = css`
  width: 100%;
  max-width: 1000px;
`;

export const sectionDivider = (divider?: boolean) =>
  divider
    ? css`
        padding-bottom: 20px;
        border-bottom: 2px solid ${(props) => hexToRgba(props.theme.colors.offWhite, 0.75)};
      `
    : '';

export const lightShadow = css`
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.03);
`;

export const label = css`
  color: #ccc;
  text-transform: uppercase;
  user-select: none;
`;

export const ellipsize = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const wordBreak = css`
  word-break: break-all; // others
  word-break: break-word; // webkit
`;
