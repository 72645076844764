import { ContentContainer, PageContainer } from 'components/SharedComponents/Containers.styles';
import { useAppSelector } from 'hooks/redux';
import { SectionHeader } from 'components/SectionHeader/sectionHeader';
import { PaystubsTable } from 'components/Table/PaystubsTable/PaystubsTable';

export const Paystubs = () => {
  const { currentUser } = useAppSelector((state) => state.user);
  const employeeId = currentUser?.employee.id;

  return (
    <PageContainer>
      <SectionHeader
        title="Paystubs"
        message="Here you will find all of your paystubs. Download them to view an in-depth report on your earnings."
      />
      <ContentContainer>
        {employeeId ? <PaystubsTable employeeId={employeeId} /> : 'No Employee Paysubs Available'}
      </ContentContainer>
    </PageContainer>
  );
};
