import moment from 'moment';
import { Timecard } from 'lib/fetchEmployeeTimecards';
import { StyledTable, Thead, Tr, Th, Td, Tbody } from '../Table.styles';
import { TotalItem } from './RunPayrollTables.styles';

type TimecardTableProps = {
  timecards: Timecard[];
};

const TimecardTable = ({ timecards }: TimecardTableProps) => {
  const formatTotalTime = (totalTime: string): string => {
    const parts = totalTime.split(':');
    if (parts.length === 2) {
      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      return `${hours} hr ${minutes} min`;
    }
    return totalTime;
  };

  const sumWorkHours = timecards
    .reduce((acc, card) => acc + parseFloat(card.workHours), 0)
    .toFixed(2);
  const sumTotalTime = timecards.reduce((acc, card) => {
    const parts = card.totalTime.split(':');
    if (parts.length === 2) {
      const minutes = parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
      return acc + minutes;
    }
    return acc;
  }, 0);

  const formatSumTotalTime = () => {
    const hours = Math.floor(sumTotalTime / 60);
    const minutes = sumTotalTime % 60;
    return `${hours} hr ${minutes} min`;
  };

  return (
    <StyledTable>
      <Thead>
        <Tr>
          <Th>Date</Th>
          <Th>Clock In</Th>
          <Th>Clock Out</Th>
          <Th>Work Hours</Th>
          <Th>Total Time</Th>
        </Tr>
      </Thead>
      <Tbody>
        {timecards.map((card, index) => (
          <Tr key={index}>
            <Td>{moment(card.signInAt).format('MMMM D, YYYY')}</Td>
            <Td>{moment(card.signInAt).format('h:mm A')}</Td>
            <Td>{moment(card.signOutAt).format('h:mm A')}</Td>
            <Td>{parseFloat(card.workHours).toFixed(2)}</Td>
            <Td>{formatTotalTime(card.totalTime)}</Td>
          </Tr>
        ))}

        <Tr>
          <TotalItem colSpan={3}>Total Worked Hours</TotalItem>
          <TotalItem>{sumWorkHours}</TotalItem>
          <TotalItem>{formatSumTotalTime()}</TotalItem>
        </Tr>
      </Tbody>
    </StyledTable>
  );
};

export default TimecardTable;
