import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/redux';
import { Table } from './Table';
import { fetchCompanySignatories, Signatory, PageInfo } from 'lib/fetchCompanySignatories';
import { CurrentUserContext } from 'features/user/types';
import { Spinner } from 'components/Spinner/spinner';
import { Pagination } from 'components/Pagination/pagination';

type UserState = {
  user: {
    currentUser: CurrentUserContext | null;
  };
};

type SignatoriesTableProps = {
  refresh: boolean;
};

export const SignatoriesTable = ({ refresh }: SignatoriesTableProps) => {
  const { currentUser } = useAppSelector((state: UserState) => state.user);
  const [signatories, setSignatories] = useState<Signatory[]>([]);
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);

  const loadMore = () => {
    if (pageInfo && pageInfo.hasNextPage) {
      setEndCursor(pageInfo.endCursor);
    }
  };

  const onPrev = () => {
    if (pageInfo && pageInfo.hasPreviousPage) {
      setEndCursor(pageInfo.startCursor);
    }
  };

  const onNext = () => {
    if (pageInfo && pageInfo.hasNextPage) {
      loadMore();
    }
  };

  useEffect(() => {
    fetchCompanySignatories(endCursor).then(
      ({ signatories: newSignatories, pageInfo: newPageInfo }) => {
        setSignatories(newSignatories);
        setPageInfo(newPageInfo);
        setLoading(false);
      }
    );
  }, [currentUser, endCursor, refresh]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      {pageInfo && (pageInfo.hasPreviousPage || pageInfo.hasNextPage) && (
        <Pagination
          onPrev={onPrev}
          onNext={onNext}
          disablePrev={!pageInfo.hasPreviousPage}
          disableNext={!pageInfo.hasNextPage}
        />
      )}
      <Table signatories={signatories} />
    </>
  );
};
