import moment from 'moment';
import { formatDate } from 'lib/utils/formatDate';
import { Section } from 'components/SharedComponents/Containers.styles';
import { SectionHeading, CardsRow, Instruction, StyledArrowIcon } from './Timeline.styles';
import { CalendarCard } from './CalendarCard';
import { Payday } from 'lib/fetchCompanyNextPayday';

type CalendarCardsRowProps = {
  payday: Payday | null;
};

const CalendarCardsRow = ({ payday }: CalendarCardsRowProps) => {
  if (!payday) return null;

  const periodStart = moment(payday.periodStart).toDate();
  const periodEnd = moment(payday.periodEnd).toDate();
  const approvalDeadline = moment(payday.approvalDeadline).toDate();
  const payDate = moment(payday.payday).toDate();

  return (
    <CardsRow>
      <CalendarCard title="Start" date={periodStart} id="startDate" />
      <StyledArrowIcon />
      <CalendarCard title="End" date={periodEnd} id="endDate" />
      <CalendarCard title="Deadline" date={approvalDeadline} bgColor="#D64626" id="deadlineDate" />
      <CalendarCard title="Pay" date={payDate} bgColor="#58BA50" id="payDate" />
    </CardsRow>
  );
};

type TimelineProps = {
  payday: Payday | null;
};

export const Timeline = ({ payday }: TimelineProps) => {
  const instructionText = payday
    ? `In order to pay your employees on ${formatDate(
        payday.payday
      )}, you must submit payroll by 5pm on ${formatDate(payday.approvalDeadline)}.`
    : 'Loading payroll information...';

  return (
    <Section halfWidth>
      <SectionHeading>Timeline</SectionHeading>
      <CalendarCardsRow payday={payday} />
      <Instruction>{instructionText}</Instruction>
    </Section>
  );
};
