import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { CreateWorkplace } from 'components/CreateWorkplace/CreateWorkplace';

export const Workplace = () => {
  return (
    <ContentContainer onboarding={true} contentAlign="center">
      <CreateWorkplace />
    </ContentContainer>
  );
};
