import styled from 'styled-components';
import { RadioInputContainer, Label } from 'components/form/RadioInput/RadioInput.styles';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1300px;
`;

export const SectionHeading = styled.h3`
  font-size: 20px;
  color: ${(props) => props.theme.colors.black};
  margin: 0 0 10px;
`;

export const RunPayrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100px;
  margin: 16px 0;
`;

export const RadioContainer = styled.div`
  ${RadioInputContainer} {
    display: flex;
  }

  ${Label} {
    margin-right: 40px;
    text-transform: uppercase;
  }
`;
