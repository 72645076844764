import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 16px;
  margin: 0 12px;
`;

export const Heading = styled.h4`
  font-size: 16px;
  color: ${(props) => props.theme.colors.black};
  margin: 8px 0;
`;

export const Description = styled.p`
  line-height: 1.5;
  font-size: 13px;
`;

export const Content = styled.div``;

export const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${(props) => props.theme.colors.active};
`;
