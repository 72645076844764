import { useAppSelector } from 'hooks/redux';
import { PageContainer } from 'components/SharedComponents/Containers.styles';
import { SectionHeader } from 'components/SectionHeader/sectionHeader';
import { TabMenu } from 'components/TabMenu/tabMenu';
import { BasicInformation } from 'components/TabMenu/Tabs/CompanyDetailsMenu/BasicInformation/basicInformation';
import { PaySchedule } from 'components/TabMenu/Tabs/CompanyDetailsMenu/PaySchedule/paySchedule';
import { Workplaces } from 'components/TabMenu/Tabs/CompanyDetailsMenu/Workplace/workplace';
import { Financials } from 'components/TabMenu/Tabs/CompanyDetailsMenu/Financials/financials';
import { Signatories } from 'components/TabMenu/Tabs/CompanyDetailsMenu/Signatories/signatories';
import { Roles } from 'features/user/types';

export const CompanyDetails = () => {
  const { currentUser } = useAppSelector((state) => state.user);
  const isNotEmployer = currentUser?.role.name !== Roles.Employer;

  const MENU_ITEMS = {
    'Basic Information': <BasicInformation />,
    'Pay Schedule': <PaySchedule />,
    Workplace: <Workplaces />,
    ...(isNotEmployer ? { Financials: <Financials /> } : {}),
    ...(isNotEmployer ? { Signatories: <Signatories /> } : {}),
  };

  return (
    <PageContainer>
      <SectionHeader title="Company Details" />
      <TabMenu menuItems={MENU_ITEMS} defaultActiveItem="Basic Information" />
    </PageContainer>
  );
};
