import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SignupFormData } from 'features/user/types';
import { signupSchema } from '../validator';
import { FormInput, FormButton } from 'components/form';
import {
  Form,
  AuthAction,
  AuthFormContainer,
  AuthFormHeader,
  AuthSubHeader,
  AuthForm,
  ButtonContainer,
  Wrapper,
  PasswordWrapper,
  ToggleButton,
} from '../authForm.styles';
import { createUser } from 'features/user/service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as ViewIcon } from '@src/assets/icons/view.svg';
import { ReactComponent as HideIcon } from '@src/assets/icons/hide.svg';
import { createFormKeyPressHandler } from 'lib/utils/formUtils';

export const SignUpForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SignupFormData>({
    resolver: yupResolver(signupSchema),
  });

  const searchParams = useSearchParams()[0];
  const companyId = searchParams ? searchParams.get('company_id') : null;
  const companyType = searchParams ? searchParams.get('company_type') : null;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<SignupFormData> = async (data: SignupFormData) => {
    if (Object.keys(errors).length == 0) {
      if (companyId && companyType) {
        const userData = { user: { company_id: companyId, company_type: companyType, ...data } };
        const response = await createUser(userData);
        if (response) {
          navigate('/created');
        }
      } else {
        toast.error(
          'Must have Company ID and Company Type from Procare Online in order to create an account.'
        );
      }
    }
  };
  const formKeyPressHandler = createFormKeyPressHandler(handleSubmit, onSubmit);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const isButtonDisabled = (): boolean => !watch('email') || !watch('name');

  return (
    <AuthForm>
      <AuthFormHeader>Welcome to Procare Payroll!</AuthFormHeader>
      <AuthSubHeader>Create your Account</AuthSubHeader>
      <AuthFormContainer>
        <Form onKeyPress={formKeyPressHandler}>
          <FormInput
            id="name"
            placeholder="Full Name"
            type="text"
            required
            {...register('name')}
            error={errors.name?.message}
          />
          <FormInput
            id="email"
            placeholder="Email"
            type="email"
            required
            {...register('email')}
            error={errors.email?.message}
          />
          <PasswordWrapper>
            <FormInput
              id="password"
              placeholder="Password"
              type={passwordVisible ? 'text' : 'password'}
              autoComplete="on"
              required
              {...register('password')}
              error={errors.password?.message}
            />
            <ToggleButton
              type="button"
              icon={passwordVisible ? <HideIcon /> : <ViewIcon />}
              onClick={togglePasswordVisibility}
            />
          </PasswordWrapper>

          <AuthAction>
            Must be 8 or more characters and contain at least 1 number and 1 special character.
          </AuthAction>

          <ButtonContainer>
            <Wrapper>
              <FormButton
                onSubmit={handleSubmit(onSubmit)}
                disabled={isButtonDisabled()}
                label="Sign Up"
              />
            </Wrapper>
          </ButtonContainer>
        </Form>
      </AuthFormContainer>
    </AuthForm>
  );
};
