import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendAuthErrorMessage } from './sendAuthErrorMessage';
import { DocumentType } from './fetchCompanyDocuments';

export const downloadCompanyDocument = (
  docType: DocumentType,
  docId: string
): Promise<Blob | void> => {
  return procareApi
    .get<Blob>(`/api/v1/companies/documents/${docType}/download/${docId}`, { responseType: 'blob' })
    .then((response: AxiosResponse<Blob>) => {
      return response.data;
    })
    .catch((error: AxiosError<ErrorResponse>) => {
      sendAuthErrorMessage(error);
    });
};
