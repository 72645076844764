import { ContentContainer, PageContainer } from 'components/SharedComponents/Containers.styles';
import { SectionHeader } from 'components/SectionHeader/sectionHeader';
import { ViewPaystubsNotification } from 'components/DashboardNotifications/ViewPaystubsNotification/viewPaystubsNotification';
import { NavCardList } from 'components/NavCardsList/NavCardList';
import { employeeNavData } from '@src/constants/navData';

export const EmployeeDashboard = () => {
  return (
    <PageContainer>
      <SectionHeader title="Dashboard" />
      <ContentContainer noHeight={true}>
        <ViewPaystubsNotification />
      </ContentContainer>
      <ContentContainer noHeight={true}>
        <NavCardList navData={employeeNavData} />
      </ContentContainer>
    </PageContainer>
  );
};
