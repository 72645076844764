import styled from 'styled-components';

export const AuthorizeSignatoryContainer = styled.div`
  min-width: 800px;
  padding: 25px 0;
`;

export const WelcomeContainer = styled.div`
  padding: 16px 0;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray3};
  width: 800px;
`;

export const WelcomeHeading = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.fontSizes.heading};
  font-weight: 500;
`;

export const WelcomeMessage = styled.div`
  font-size: ${(props) => props.theme.fontSizes.normal};
`;

export const SignatoryContainer = styled.div`
  padding: 16px 0;
`;

export const SignatoryHeading = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.fontSizes.subHeading};
  font-weight: 500;
  margin: 10px 0 15px;
`;

export const SignatoryMessage = styled.div`
  font-size: ${(props) => props.theme.fontSizes.normal};
`;
