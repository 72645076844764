import { useEffect, useState } from 'react';
import { fetchRunPayrollComponent } from 'lib/Check/fetchRunPayrollComponent';
import { CheckComponent } from 'components/CheckComponent/checkComponent';
import { ActionButton } from 'components/ActionButton/actionButton';
import { CloseButton } from './runPayroll.styles';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';

type RunPayrollProps = {
  closeModal: () => void;
};

export const RunPayroll = ({ closeModal }: RunPayrollProps) => {
  const [componentURL, setComponentURL] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchComponentURL = async () => {
      const url = await fetchRunPayrollComponent();
      if (url) {
        setComponentURL(url);
      }
      setIsLoading(false);
    };

    fetchComponentURL();
  }, []);

  if (isLoading) return null;

  return (
    <>
      <CheckComponent id="run-payroll" componentURL={componentURL} />
      <CloseButton icon={<CloseIcon />} onClick={closeModal} />
    </>
  );
};
