/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Heading, Container, RolesSelectContainer } from './RoleSelector.styles';
import { Checkbox, CheckboxProps } from 'components/Checkbox/Checkbox';
import { Role } from 'lib/fetchEmployeeRoles';
import { assignRole } from 'lib/assignRole';
import { removeRole } from 'lib/removeRole';
import { toast } from 'react-toastify';

export type RoleSelectorProps = {
  roles: Role[];
  employeeId: string;
  fetchRoles: () => void;
  disabled: boolean;
};

export const RoleSelector = ({
  roles,
  employeeId,
  fetchRoles,
  disabled = false,
}: RoleSelectorProps) => {
  const [employeeChecked, setEmployeeChecked] = useState(false);
  const [employerChecked, setEmployerChecked] = useState(false);
  const [payrollAdminChecked, setPayrollAdminChecked] = useState(false);

  useEffect(() => {
    const roleNames = roles.map((role) => role.name);
    setEmployeeChecked(roleNames.includes('employee'));
    setEmployerChecked(roleNames.includes('employer'));
    setPayrollAdminChecked(roleNames.includes('payroll_admin'));
  }, [roles]);

  const selectedRolesCount = [employeeChecked, employerChecked, payrollAdminChecked].filter(
    Boolean
  ).length;

  const handleToggle =
    (setter: React.Dispatch<React.SetStateAction<boolean>>, roleName: string) => () => {
      setter((prev) => {
        if (prev) {
          if (selectedRolesCount === 1) {
            toast.warn('At least one role must be selected.');
            return prev;
          }
          removeRole({ employeeId, roleName })
            .then(() => {
              fetchRoles();
            })
            .catch((error) => {
              toast.error('Error removing role:', error);
            });
        } else {
          assignRole({ employeeId, roleName })
            .then(() => {
              fetchRoles();
            })
            .catch((error) => {
              toast.error('Error assigning role:', error);
            });
        }
        return !prev;
      });
    };

  const checkboxProps: { [key: string]: CheckboxProps } = {
    employee: {
      id: 'employee',
      isChecked: employeeChecked,
      toggleCheckbox: handleToggle(setEmployeeChecked, 'employee'),
      label: 'Employee',
      disabled: selectedRolesCount === 1 && employeeChecked,
    },
    employer: {
      id: 'employer',
      isChecked: employerChecked,
      toggleCheckbox: handleToggle(setEmployerChecked, 'employer'),
      label: 'Employer',
      disabled: (selectedRolesCount === 1 && employerChecked) || payrollAdminChecked,
    },
    payrollAdmin: {
      id: 'payroll_admin',
      isChecked: payrollAdminChecked,
      toggleCheckbox: handleToggle(setPayrollAdminChecked, 'payroll_admin'),
      label: 'Payroll Admin',
      disabled: (selectedRolesCount === 1 && payrollAdminChecked) || employerChecked,
    },
  };

  return (
    <Container>
      <Heading>Roles</Heading>
      <RolesSelectContainer>
        {Object.values(checkboxProps).map((props) => (
          <Checkbox key={props.id} {...props} disabled={disabled || props.disabled} />
        ))}
      </RolesSelectContainer>
    </Container>
  );
};
