import { AccessItem, PermissionIndicator } from './PermissionsListTable.styles';

type PermissionAccessItemProps = {
  hasPermission: boolean;
  children: React.ReactNode;
};

export const PermissionAccessItem = ({ hasPermission, children }: PermissionAccessItemProps) => {
  return (
    <AccessItem>
      <PermissionIndicator hasPermission={hasPermission}></PermissionIndicator>
      {children}
    </AccessItem>
  );
};
