import styled from 'styled-components';
import { hexToRgba } from 'styles/mixins';

export const PayScheduleContent = styled.div`
  width: 900px;
  padding: 8px;
`;

export const PayScheduleMessage = styled.div`
  margin: 20px 0 30px;
`;

export const PayScheduleItemContainer = styled.p``;

export const PayScheduleItemLabel = styled.span`
  font-weight: 500;
  color: ${(props) => props.theme.colors.black};
  display: inline-block;
  width: 200px;
  vertical-align: top;
  text-transform: uppercase;
`;

export const PayScheduleItemValue = styled.span`
  display: inline-block;
  text-transform: capitalize;
`;

export const ButtonContainer = styled.div`
  border-top: 2px solid ${(props) => hexToRgba(props.theme.colors.offWhite, 0.5)};
  display: flex;
  justify-content: center;
  padding: 16px;
  margin-top: 30px;
`;

export const PayScheduleContainer = styled.div`
  max-width: 900px;
`;
