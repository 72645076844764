import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';
import { DEFAULT_PAGE_SIZE } from '@src/constants/constants';

export type Workplace = {
  id: string;
  name: string;
};

export type Import = {
  completedAt: string;
  id: string;
  startedAt: string;
  type: string;
};

export type ImportLog = {
  id: string;
  importedIds: string[];
  resources: Workplace[];
  import: Import;
};

export type ImportLogEdge = {
  cursor: string;
  node: ImportLog;
};

export type PageInfo = {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
};

export type ImportLogsResponse = {
  data: {
    importLogsList: {
      edges: ImportLogEdge[];
      pageInfo: PageInfo;
    };
  };
};

export enum ImportType {
  Workplace = 'Workplace',
  Staff = 'Staff',
}

export const fetchImportLogs = (
  endCursor?: string,
  importType?: ImportType
): Promise<{ importLogs: ImportLog[]; pageInfo: PageInfo | null }> => {
  const after = endCursor ? `, after: "${endCursor}"` : '';
  let importTypeFilter = '';

  if (importType) {
    importTypeFilter = `, importInput: { type: ${ImportType[importType]} }`;
  }

  const query = `
    query ImportLogsList {
      importLogsList(first: ${DEFAULT_PAGE_SIZE}${after}${importTypeFilter}) {
        edges {
          cursor
          node {
            id
            importedIds
            resources {
              ... on Workplace {
                id
                name
              }
            }
            import {
              completedAt
              id
              startedAt
              type
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<ImportLogsResponse>>('graphql', { query })
    .then((response: AxiosResponse<ImportLogsResponse>) => {
      const importLogs = response.data.data.importLogsList.edges.map((edge) => edge.node);
      const pageInfo = response.data.data.importLogsList.pageInfo;
      return { importLogs, pageInfo };
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return { importLogs: [], pageInfo: null };
    });
};
