import { StyledTable, Thead, Tr, Th, Td, Tbody, EmptyMessage } from '../Table.styles';
import { Signatory } from 'lib/fetchCompanySignatories';

export type TableProps = {
  signatories: Signatory[];
};

export const Table = ({ signatories }: TableProps) => {
  if (signatories.length === 0) {
    return <EmptyMessage>No signatories found. Please check back later.</EmptyMessage>;
  }

  return (
    <StyledTable>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Title</Th>
          <Th>Email</Th>
        </Tr>
      </Thead>
      <Tbody>
        {signatories.map((signatory) => (
          <Tr key={signatory.id}>
            <Td>{`${signatory.firstName} ${signatory.lastName}`}</Td>
            <Td>{signatory.title}</Td>
            <Td>{signatory.email}</Td>
          </Tr>
        ))}
      </Tbody>
    </StyledTable>
  );
};
