import React, { useState, useEffect } from 'react';
import { Input, InputWrapper } from './TableInput.styles';

type TableInputProps = {
  value: number;
  onChange: (value: string) => void;
  onBlur?: () => void;
  type?: 'text' | 'number';
  placeholder?: string;
  min?: number;
  disabled?: boolean;
  format?: 'currency';
};

export const TableInput = ({
  value,
  onChange,
  onBlur,
  type = 'number',
  placeholder = '',
  min = 0,
  disabled = false,
  format,
}: TableInputProps) => {
  const [inputValue, setInputValue] = useState<string>(value.toString());

  useEffect(() => {
    setInputValue(value.toString());
  }, [value]);

  const formatCurrency = (num: number): string => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
  };

  const handleInputBlur = () => {
    const numericValue = parseFloat(inputValue.replace(/[^0-9.-]/g, ''));
    if (!isNaN(numericValue) && numericValue >= min) {
      const formattedValue =
        format === 'currency' ? formatCurrency(numericValue) : numericValue.toString();
      setInputValue(formattedValue);
      onChange(numericValue.toString());
    }
    onBlur?.();
  };

  return (
    <InputWrapper>
      <Input
        type={type}
        value={inputValue}
        placeholder={placeholder}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        min={type === 'number' ? min : undefined}
        disabled={disabled}
      />
    </InputWrapper>
  );
};
