import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from 'lib/sendErrorMessage';
import { DEFAULT_PAGE_SIZE } from '@src/constants/constants';

type Onboard = {
  blockingSteps: string[];
  remainingSteps: string[];
  status: string;
};

export type Employee = {
  email: string;
  firstName: string;
  lastName: string;
  providerIdentifier: string;
  onboard: Onboard;
};

type PageInfo = {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
};

type EmployeeEdge = {
  node: Employee;
};

type EmployeeListResponse = {
  data: {
    providerEmployeesList: {
      edges: EmployeeEdge[];
      pageInfo: PageInfo;
    };
  };
};

export const fetchProviderEmployeesList = (
  endCursor?: string,
  pageSize: number = DEFAULT_PAGE_SIZE
): Promise<{ employees: Employee[]; pageInfo: PageInfo | null }> => {
  const after = endCursor ? `, after: "${endCursor}"` : '';

  const query = `
    query {
      providerEmployeesList(first: ${pageSize}${after}) {
        edges {
          node {
            email
            firstName
            lastName
            providerIdentifier
            onboard {
              blockingSteps
              remainingSteps
              status
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<EmployeeListResponse>>('graphql', { query })
    .then((response: AxiosResponse<EmployeeListResponse>) => {
      const employees = response.data.data.providerEmployeesList.edges.map((edge) => edge.node);
      const pageInfo = response.data.data.providerEmployeesList.pageInfo;
      return { employees, pageInfo };
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return { employees: [], pageInfo: null };
    });
};
