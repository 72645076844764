import styled from 'styled-components';
import { FormInputContainer } from 'components/form/Input/input.styles';
import { FormSelectContainer } from 'components/form/Select/select.styles';

export const SignatoryFormContainer = styled.div`
  max-width: 520px;

  ${FormInputContainer} {
    min-width: 230px;
  }

  ${FormSelectContainer} {
    padding-bottom: 10px;
    border-bottom: 2px solid ${(props) => props.theme.colors.offWhite};
  }
`;

export const Form = styled.form`
  width: 480px;
  margin: 20px 20px 0;
`;

export const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 20px;
`;
