import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/redux';
import { selectCurrentUser } from 'features/user/slice';
import { fetchUserEmployeeProfile } from 'lib/fetchUserEmployeeProfile';
import { Employee } from 'features/user/types';
import { ActionButton } from 'components/ActionButton/actionButton';
import { CheckComponent } from 'components/CheckComponent/checkComponent';
import { fetchEmployeePaymentSetup } from 'lib/Check/fetchEmployeePAymentSetup';
import { EmployeeAddressForm } from 'components/EmployeeAddressForm/EmployeeAddressForm';
import { PasswordResetForm } from 'components/ResetPasswordForm/ResetPasswordForm';
import { Modal } from 'components/Modal/Modal';
import { toast } from 'react-toastify';
import { Spinner } from 'components/Spinner/spinner';
import { formatValue } from 'lib/utils/formatValue';
import { CompensationType } from '@src/constants/compensationType';
import { SsnModal } from 'components/TabMenu/Tabs/EmployeeProfileMenu/EmployeeInfo/EmployeeInfo.styles';
import { fetchEmployeeSsnComponent } from 'lib/Check/fetchEmployeeSsnComponent';
import { updateProfile } from 'lib/updateProfile';
import { AddressFormData } from 'components/EmployeeAddressForm/types';
import {
  ProfileContainer,
  ProfileHeader,
  ProfileContent,
  ProfileHeading,
  ItemLabel,
  ItemValue,
  ItemContainer,
  EditButton,
  FormHeading,
  BankAccountModal,
  BankAccountContainer,
  CloseButton,
} from './UserProfile.styles';
import { ReactComponent as EditIcon } from '@src/assets/icons/edit.svg';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';

export const UserProfile = () => {
  const currentUser = useAppSelector(selectCurrentUser);
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false);
  const [componentURL, setComponentURL] = useState('');
  const [isSSNModalOpen, setSSNModalOpen] = useState(false);
  const [ssnComponentURL, setSsnComponentURL] = useState('');

  const fetchAndUpdateEmployeeProfile = () => {
    fetchUserEmployeeProfile()
      .then((userProfileData) => {
        if (userProfileData && userProfileData.employee) {
          setEmployee(userProfileData.employee);
        }
      })
      .catch((error) => {
        toast.error('Error fetching employee.');
      });
  };

  const isSalary = (type: string) => {
    return type === CompensationType.SALARIED;
  };

  useEffect(() => {
    if (currentUser && currentUser.employee) {
      fetchAndUpdateEmployeeProfile();
    }
  }, [currentUser]);

  const handleOpenModal = async () => {
    if (employee?.id) {
      const url = await fetchEmployeePaymentSetup(employee.id);
      if (url) {
        setComponentURL(url);
      }
    }
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleOpenAddressModal = () => {
    setAddressModalIsOpen(true);
  };

  const handleCloseAddressModal = () => {
    setAddressModalIsOpen(false);
  };

  const handleFormSubmit = async (address: AddressFormData) => {
    const profileUpdateData = {
      address: {
        line1: address.line1,
        line2: address.line2,
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
        country: 'US',
      },
    };

    const response = await updateProfile(profileUpdateData);
    if (response) {
      toast.success('The address has been successfully updated.');
      fetchAndUpdateEmployeeProfile();
    } else {
      toast.error('Failed to update the address.');
    }

    setAddressModalIsOpen(false);
  };

  const handleOpenPasswordModal = () => {
    setPasswordModalIsOpen(true);
  };

  const handleClosePasswordModal = () => {
    setPasswordModalIsOpen(false);
  };

  const handlePasswordFormSubmit = () => {
    setPasswordModalIsOpen(false);
  };

  const handleOpenSSNModal = async () => {
    if (employee && employee.id) {
      const url = await fetchEmployeeSsnComponent(employee.id);
      if (url) {
        setSsnComponentURL(url);
      }
      setSSNModalOpen(true);
    }
  };

  const handleCompleteSSNModal = () => {
    fetchAndUpdateEmployeeProfile();
    setSSNModalOpen(false);
  };

  const handleCloseSSNModal = () => {
    setSSNModalOpen(false);
  };

  if (!employee) {
    return <Spinner />;
  }

  const {
    name,
    providerAttributes: { email, paymentMethodPreference, ssnLastFour, address, dateOfBirth },
    compensation,
    workplaces,
  } = employee;

  return (
    <>
      <ProfileContainer divider>
        <ProfileHeader>
          <ProfileHeading>General Information</ProfileHeading>
        </ProfileHeader>
        <ProfileContent>
          <ItemContainer>
            <ItemLabel>Full Name</ItemLabel>
            <ItemValue>{name}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>Email</ItemLabel>
            <ItemValue>{email}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>Password</ItemLabel>
            <ItemValue>**************************</ItemValue>
            <EditButton icon={<EditIcon />} onClick={handleOpenPasswordModal} />
            <Modal isOpen={passwordModalIsOpen} onRequestClose={handleClosePasswordModal}>
              <FormHeading>Edit Password</FormHeading>
              <PasswordResetForm handleFormSubmit={handlePasswordFormSubmit} />
              <CloseButton icon={<CloseIcon />} onClick={handleClosePasswordModal} />
            </Modal>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>Date of Birth</ItemLabel>
            <ItemValue>{dateOfBirth}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>Home Address</ItemLabel>
            <ItemValue>
              {address ? (
                <>
                  {address.line1}
                  <br />
                  {address.line2 ? (
                    <>
                      {address.line2}
                      <br />
                    </>
                  ) : null}
                  {address.city}, {address.state}
                  <br />
                  {address.postalCode}
                </>
              ) : (
                'Not Available'
              )}
            </ItemValue>
            <EditButton icon={<EditIcon />} onClick={handleOpenAddressModal} />

            <Modal isOpen={addressModalIsOpen} onRequestClose={handleCloseAddressModal}>
              <FormHeading>Edit Address</FormHeading>
              {employee && (
                <EmployeeAddressForm address={address} handleFormSubmit={handleFormSubmit} />
              )}
              <CloseButton icon={<CloseIcon />} onClick={handleCloseAddressModal} />
            </Modal>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>SSN</ItemLabel>
            <ItemValue>XXX-XX-{ssnLastFour}</ItemValue>
            <EditButton icon={<EditIcon />} onClick={handleOpenSSNModal} />
            <SsnModal
              isOpen={isSSNModalOpen}
              onRequestClose={handleCloseSSNModal}
              style={{
                overlay: {
                  position: 'fixed',
                  inset: '0px',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 11,
                },
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  transform: 'translate(-50%, -50%)',
                  borderRadius: '16px',
                  overflow: 'visible',
                  width: '70vw',
                  height: '90vh',
                },
              }}
            >
              {isSSNModalOpen && (
                <CheckComponent
                  id="ssn-setup"
                  componentURL={ssnComponentURL}
                  onComplete={handleCompleteSSNModal}
                  onClose={handleCloseSSNModal}
                />
              )}
            </SsnModal>
          </ItemContainer>
        </ProfileContent>
      </ProfileContainer>
      <ProfileContainer divider>
        <ProfileHeader>
          <ProfileHeading>Finanical Information</ProfileHeading>
          <ActionButton
            onClick={handleOpenModal}
            size="medium"
            title="Update Payment Method"
            hidden={false}
            variant="text"
          />
        </ProfileHeader>
        <BankAccountModal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          style={{
            overlay: {
              position: 'fixed',
              inset: '0px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 11,
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              transform: 'translate(-30%, -50%)',
              borderRadius: '16px',
              overflow: 'visible',
              width: '440px',
            },
          }}
        >
          <BankAccountContainer>
            {modalIsOpen && (
              <CheckComponent id="employee-payment-setup" componentURL={componentURL} />
            )}
            <CloseButton icon={<CloseIcon />} onClick={handleCloseModal} />
          </BankAccountContainer>
        </BankAccountModal>
        <ProfileContent>
          <ItemContainer>
            <ItemLabel>Payment Preference</ItemLabel>
            <ItemValue>
              {paymentMethodPreference && formatValue(paymentMethodPreference, true)}
            </ItemValue>
          </ItemContainer>
          {compensation && (
            <>
              <ItemContainer>
                <ItemLabel>Compensation Type</ItemLabel>
                <ItemValue>{compensation.type}</ItemValue>
              </ItemContainer>
              <ItemContainer>
                <ItemLabel>Compensation Rate</ItemLabel>
                <ItemValue>
                  {isSalary(compensation.type)
                    ? `${compensation.amount || 0} / year`
                    : `${compensation.amount || 0} / hour`}
                </ItemValue>
              </ItemContainer>
            </>
          )}
        </ProfileContent>
      </ProfileContainer>
      <ProfileContainer>
        <ProfileHeader>
          <ProfileHeading>Workplaces</ProfileHeading>
        </ProfileHeader>
        <ProfileContent>
          {workplaces.map((workplace, index) => (
            <div key={index}>
              <ItemContainer>
                <ItemLabel>Name</ItemLabel>
                <ItemValue>{workplace.name}</ItemValue>
              </ItemContainer>
              <ItemContainer>
                <ItemLabel>Work Address</ItemLabel>
                <ItemValue>
                  {workplace.providerAttributes.address ? (
                    <>
                      {workplace.providerAttributes.address.line1}
                      <br />
                      {workplace.providerAttributes.address.line2 ? (
                        <>
                          {workplace.providerAttributes.address.line2}
                          <br />
                        </>
                      ) : null}
                      {workplace.providerAttributes.address.city},{' '}
                      {workplace.providerAttributes.address.state}
                      <br />
                      {workplace.providerAttributes.address.postalCode}
                    </>
                  ) : (
                    'Not Available'
                  )}
                </ItemValue>
              </ItemContainer>
            </div>
          ))}
        </ProfileContent>
      </ProfileContainer>
    </>
  );
};
