import styled from 'styled-components';
import { CheckboxContainer } from 'components/Checkbox/Checkbox.styles';

export const Container = styled.div``;

export const Heading = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.black0};
  text-transform: uppercase;
  text-decoration: underline;
  margin: 0 0 12px;
`;

export const RolesSelectContainer = styled.div`
  display: flex;

  ${CheckboxContainer} {
    margin-right: 30px;
  }
`;
