import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { miscellaneousDeductionSchema } from '../validator';
import { MiscellaneousDeductionFormData } from '../types';
import moment from 'moment';
import { ActionButton } from 'components/ActionButton/actionButton';
import { FormGroup, FormInput } from 'components/form';
import { DateInput } from 'components/form/DateInput/dateInput';
import { RadioInput } from 'components/form/RadioInput/RadioInput';
import { DeductionFormContainer, Form, ButtonContainer } from '../PostTaxDeductionForm.styles';
import { toast } from 'react-toastify';
import { createPostTaxDeduction, PostTaxDeductionInput } from 'lib/createPostTaxDeduction';
import { PostTaxDeductionTypes } from 'lib/fetchEmployeePostTaxDeductions';
import { Employee } from 'features/user/types';

type MiscellaneousDeductionFormProps = {
  employee: Employee;
  onFormSubmit: () => void;
};

export const MiscellaneousDeductionForm = ({
  employee,
  onFormSubmit,
}: MiscellaneousDeductionFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<MiscellaneousDeductionFormData>({
    resolver: yupResolver(miscellaneousDeductionSchema),
  });

  const [amountOrPercent, setAmountOrPercent] = useState<'amount' | 'percent'>('amount');
  const [deductionType, setDeductionType] = useState<'recurring' | 'oneTime'>('recurring');

  const onSubmit: SubmitHandler<MiscellaneousDeductionFormData> = async (
    data: MiscellaneousDeductionFormData
  ) => {
    const { amount, annualLimit, percent, totalAmount } = data;

    const miscellaneous = {
      ...(amount ? { amount } : {}),
      ...(annualLimit ? { annualLimit } : {}),
      ...(percent ? { percent: parseFloat(percent) } : {}),
      ...(totalAmount ? { totalAmount } : {}),
    };

    const postTaxDeductionData: PostTaxDeductionInput = {
      employeeId: employee.id,
      type: 'miscellaneous' as PostTaxDeductionTypes,
      description: data.description,
      effectiveStart: moment(data.effectiveStart).format('YYYY-MM-DD'),
      effectiveEnd: data.effectiveEnd ? moment(data.effectiveEnd).format('YYYY-MM-DD') : undefined,
      configurationObject: { miscellaneous },
    };

    try {
      const result = await createPostTaxDeduction(postTaxDeductionData);
      if (result) {
        toast.success('Employee deduction has been successfully added.');
        onFormSubmit();
      }
    } catch (error) {
      toast.error('Failed to create deduction.');
    }
  };

  const isButtonDisabled = (): boolean => !watch('description') || !watch('effectiveStart');

  const amountOrPercentOptions = [
    { value: 'amount', label: 'Fixed Amount' },
    { value: 'percent', label: 'Percentage of Pay' },
  ];

  const deductionTypeOptions = [
    { value: 'recurring', label: 'Recurring Deduction' },
    { value: 'oneTime', label: 'One-time Deduction' },
  ];

  const onChangeAmountOrPercent = (value: 'amount' | 'percent') => {
    if (value === 'percent') {
      setValue('amount', '');
    } else if (value === 'amount') {
      setValue('percent', '');
    }
    setAmountOrPercent(value);
  };

  return (
    <DeductionFormContainer>
      <Form>
        <FormGroup label="General Information">
          <FormInput
            id="description"
            placeholder="Description"
            type="text"
            required
            {...register('description')}
          />
        </FormGroup>
        <FormGroup label="Frequency">
          <RadioInput
            name="deductionType"
            options={deductionTypeOptions}
            selectedValue={deductionType}
            onChange={(value) => setDeductionType(value as 'recurring' | 'oneTime')}
          />
          <DateInput control={control} name="effectiveStart" placeholder="Start Date" />
          {deductionType === 'recurring' && (
            <DateInput control={control} name="effectiveEnd" placeholder="End Date" />
          )}
        </FormGroup>

        <FormGroup label="Amount">
          <RadioInput
            name="amountOrPercent"
            options={amountOrPercentOptions}
            selectedValue={amountOrPercent}
            onChange={(value) => onChangeAmountOrPercent(value as 'amount' | 'percent')}
          />
          {amountOrPercent === 'amount' && (
            <FormInput
              id="amount"
              placeholder="Amount Per Pay Period (Required)"
              type="text"
              {...register('amount')}
            />
          )}
          {amountOrPercent === 'percent' && (
            <FormInput
              id="percent"
              placeholder="Percent (Required)"
              type="text"
              {...register('percent')}
            />
          )}
          <FormInput
            id="annualLimit"
            placeholder="Annual Limit"
            type="text"
            {...register('annualLimit')}
          />
          <FormInput
            id="totalAmount"
            placeholder="Total Amount"
            type="text"
            {...register('totalAmount')}
          />
        </FormGroup>
      </Form>
      <ButtonContainer>
        <ActionButton
          onClick={handleSubmit(onSubmit)}
          title="Add Deduction"
          size="medium"
          disabled={isButtonDisabled()}
        />
      </ButtonContainer>
    </DeductionFormContainer>
  );
};
