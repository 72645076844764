import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const NavBlockHighlightWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 5px;
`;

export const NavBlockTitle = styled.span`
  margin-top: 2px;
  font-weight: 500;
  padding-left: ${(props) => props.theme.padding.md};
`;

export const LinkStyles = css`
  padding: 0;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.text};
`;

export const ExternalLink = styled.a`
  ${LinkStyles}
`;

export const StyledNavLink = styled(NavLink)`
  ${LinkStyles}
`;

export const NavBlockContainer = styled.div`
  cursor: pointer;
  margin-bottom: ${(props) => props.theme.margin.sm};

  &:first-child {
    padding-top: ${(props) => props.theme.padding.md};
  }

  &:last-child {
    padding-bottom: ${(props) => props.theme.padding.md};
  }

  &.active {
    ${NavBlockTitle} {
      font-weight: 500;
    }

    ${NavBlockHighlightWrapper} {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.1);
      width: 100%;
    }
  }
`;
