import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from './sendErrorMessage';

type RoleInput = {
  employeeId: string;
  roleName: string;
};

type RolePayload = {
  role: {
    id: string;
    name: string;
  };
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

export const assignRole = (roleAttributes: RoleInput): Promise<RolePayload['role'] | void> => {
  const mutation = `
      mutation($roleParams: EmployeeRoleInput!) {
        assignRole(employeeRoleInput: $roleParams) {
          role {
            id
            name
          }
          error {
            message
            type
            inputErrors {
              field
              fieldPath
              message
            }
          }
        }
      }
    `;

  const variables = {
    roleParams: roleAttributes,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { assignRole: RolePayload } }>>('graphql', {
      query: mutation,
      variables,
    })
    .then((response: AxiosResponse<{ data: { assignRole: RolePayload } }>) => {
      return response.data.data.assignRole.role;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
