import styled from 'styled-components';

export const PageContainer = styled.div`
  margin-top: 72px;
  padding: 1px
  padding-bottom: 100px;
  margin-left: 214px;
  overflow-y: auto;
  background: ${(props) => props.theme.colors.gray5};
  height: 100%;

  &.no-sidebar {
    margin-left: 0;
  }
`;
