import { useState, useEffect } from 'react';
import { useAppSelector } from 'hooks/redux';
import { useParams } from 'react-router-dom';
import { selectCurrentUser } from 'features/user/slice';
import { TabContainer } from '../../tabMenu.styles';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { RoleSelector } from 'components/RoleSelector/RoleSelector';
import { PermissionsListTable } from '../../../Table/PermissionsTable/PermissionsListTable';
import { fetchEmployeeRoles, EmployeePayload } from 'lib/fetchEmployeeRoles';
import { toast } from 'react-toastify';

export const RolesPermissions = () => {
  const [employeeData, setEmployeeData] = useState<EmployeePayload | null>(null);
  const currentUser = useAppSelector(selectCurrentUser);

  const { id: providerIdentifier } = useParams<{ id: string }>();
  const disableRoleSelector = currentUser?.employee.providerIdentifier === providerIdentifier;

  const fetchRoles = () => {
    if (providerIdentifier) {
      fetchEmployeeRoles(providerIdentifier)
        .then((data) => {
          if (data) setEmployeeData(data);
        })
        .catch((error) => {
          toast.error("Error fetching employee's roles:", error);
        });
    } else {
      toast.error('No employee ID provided');
    }
  };

  useEffect(() => {
    fetchRoles();
  }, [providerIdentifier]);

  return (
    <ContentContainer>
      <TabContainer>
        <RoleSelector
          roles={employeeData?.roles || []}
          employeeId={employeeData?.id || ''}
          fetchRoles={fetchRoles}
          disabled={disableRoleSelector}
        />
        <PermissionsListTable roles={employeeData?.roles || []} />
      </TabContainer>
    </ContentContainer>
  );
};
