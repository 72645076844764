import { Button, PaginationContainer, PaginateIcon } from './pagination.styles';

type PaginationProps = {
  onPrev: () => void;
  onNext: () => void;
  disablePrev: boolean;
  disableNext: boolean;
};

export const Pagination = ({ onPrev, onNext, disablePrev, disableNext }: PaginationProps) => {
  return (
    <PaginationContainer>
      <Button onClick={onPrev} disabled={disablePrev}>
        <PaginateIcon direction="left" />
      </Button>
      <Button onClick={onNext} disabled={disableNext}>
        <PaginateIcon direction="right" />
      </Button>
    </PaginationContainer>
  );
};
