import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from './sendErrorMessage';

type IncomingEmployeeInput = {
  id: string;
};

type DeleteIncomingEmployeePayload = {
  success: boolean;
  error: {
    message: string;
    type: string;
    inputErrors: {
      field: string;
      fieldPath: string;
      message: string;
    }[];
  };
};

export const deleteIncomingEmployee = (
  incomingEmployeeInput: IncomingEmployeeInput
): Promise<boolean | void> => {
  const mutation = `
        mutation($incomingEmployeeInput: IncomingEmployeeInput!) {
          deleteIncomingEmployee(incomingEmployeeInput: $incomingEmployeeInput) {
            success
            error {
              message
              type
              inputErrors {
                field
                fieldPath
                message
              }
            }
          }
        }
      `;

  const variables = {
    incomingEmployeeInput,
  };

  return procareApi
    .post<
      unknown,
      AxiosResponse<{ data: { deleteIncomingEmployee: DeleteIncomingEmployeePayload } }>
    >('graphql', {
      query: mutation,
      variables,
    })
    .then(
      (
        response: AxiosResponse<{ data: { deleteIncomingEmployee: DeleteIncomingEmployeePayload } }>
      ) => {
        if (response.data.data.deleteIncomingEmployee.error) {
          throw new Error(response.data.data.deleteIncomingEmployee.error.message);
        }
        return response.data.data.deleteIncomingEmployee.success;
      }
    )
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
