import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { selectCurrentUser, selectEmployeeOnboarded, logout } from 'features/user/slice';
import { Topbar } from 'components/Topbar/topbar';
import { Sidebar } from 'components/Sidebar/sidebar';
import { selectCompanyOnboardedStatus } from 'features/company/slice';
import { Roles } from 'features/user/types';
import { eventEmitter } from 'lib/utils/eventEmitter';
import { PageContainer } from './protected.styles';
import { toast } from 'react-toastify';

export const ProtectedLayout = () => {
  const user = useAppSelector(selectCurrentUser);
  const isCompanyOnboarded = useAppSelector(selectCompanyOnboardedStatus);
  const isEmployeeOnboarded = useAppSelector(selectEmployeeOnboarded);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleRefreshTokenExpired = () => {
      dispatch(logout());
      toast.info('Your session has expired. Please login.');
    };

    eventEmitter.on('REFRESH_TOKEN_EXPIRED', handleRefreshTokenExpired);

    return () => {
      eventEmitter.removeListener('REFRESH_TOKEN_EXPIRED', handleRefreshTokenExpired);
    };
  }, [dispatch]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  let shouldRenderSidebar = isCompanyOnboarded && location.pathname !== '/select-company';
  if (user.role.name === Roles.Employee) {
    shouldRenderSidebar = isEmployeeOnboarded;
  }

  const className = !shouldRenderSidebar ? 'no-sidebar' : '';

  return (
    <div style={{ height: 'inherit' }}>
      <Topbar />
      {shouldRenderSidebar && <Sidebar />}
      <PageContainer className={className}>
        <Outlet />
      </PageContainer>
    </div>
  );
};
