import { useState, ChangeEvent } from 'react';
import { FormSelect } from 'components/form';
import { PayrollStatus } from 'lib/fetchCompanyPayrolls';
import { ActionButton } from 'components/ActionButton/actionButton';
import { FilterForm, ButtonContainer } from 'components/Filter/Filter.styles';
import { formatName } from 'lib/utils/formatName';

type PayrollStatusFiltersProps = {
  onApplyFilter: (status: PayrollStatus | undefined) => void;
  initialStatus?: PayrollStatus;
};

export const PayrollStatusFilters = ({
  onApplyFilter,
  initialStatus,
}: PayrollStatusFiltersProps) => {
  const [localPayrollStatus, setLocalPayrollStatus] = useState<PayrollStatus | undefined>(
    initialStatus
  );

  const handleApply = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onApplyFilter(localPayrollStatus);
  };

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value === '' ? undefined : (e.target.value as PayrollStatus);
    setLocalPayrollStatus(value);
  };

  const statusOptions = Object.values(PayrollStatus)
    .filter((status) => status !== PayrollStatus.Draft)
    .map((status) => ({
      value: status,
      label: formatName(status),
    }));

  return (
    <FilterForm>
      <FormSelect
        id="payrollStatus"
        label="Payroll Status"
        options={[{ value: '', label: 'All Statuses' }, ...statusOptions]}
        value={localPayrollStatus ?? ''}
        onChange={handleChange}
      />
      <ButtonContainer>
        <ActionButton onClick={handleApply} size="medium" title="Apply" hidden={false} />
      </ButtonContainer>
    </FilterForm>
  );
};
