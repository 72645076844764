import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SectionHeader } from 'components/SectionHeader/sectionHeader';
import { Spinner } from 'components/Spinner/spinner';
import { TabMenu } from 'components/TabMenu/tabMenu';
import { EmployeeInfo } from 'components/TabMenu/Tabs/EmployeeProfileMenu/EmployeeInfo/EmployeeInfo';
import { Paystubs } from 'components/TabMenu/Tabs/EmployeeProfileMenu/Paystubs';
import { GarnishmentsDeductions } from 'components/TabMenu/Tabs/EmployeeProfileMenu/GarnishmentsDeductions/GarnishmentsDeductions';
import { Documents } from 'components/TabMenu/Tabs/EmployeeProfileMenu/Documents';
import { RolesPermissions } from 'components/TabMenu/Tabs/EmployeeProfileMenu/RolesPermissions';
import { fetchEmployee, Employee } from 'lib/fetchEmployee';
import { toast } from 'react-toastify';
import { PageContainer } from 'components/SharedComponents/Containers.styles';

export const EmployeeProfile = () => {
  const [employee, setEmployee] = useState<Employee | null>(null);
  const { id: providerIdentifier } = useParams<{ id: string }>();

  useEffect(() => {
    if (providerIdentifier) {
      fetchEmployee(providerIdentifier)
        .then((employeeData) => setEmployee(employeeData))
        .catch((error) => toast.error('Error fetching employee data:', error));
    } else {
      toast.error('Missing provider identifier.');
    }
  }, [providerIdentifier]);

  const updateEmployeeState = async () => {
    try {
      if (providerIdentifier) {
        const updatedEmployee = await fetchEmployee(providerIdentifier);
        setEmployee(updatedEmployee);
      }
    } catch (error) {
      toast.error('Error updating employee data');
    }
  };

  if (!employee) {
    return <Spinner />;
  }

  const employeeName = `${employee.providerAttributes.firstName} ${employee.providerAttributes.lastName[0]}.`;

  return (
    <PageContainer>
      <SectionHeader title="People" detailItem={employeeName} />
      <TabMenu
        menuItems={{
          'Employee Info': (
            <EmployeeInfo employee={employee} updateEmployeeState={updateEmployeeState} />
          ),
          Paystubs: <Paystubs employee={employee} />,
          'Garnishments & Deductions': <GarnishmentsDeductions employee={employee} />,
          Documents: <Documents employee={employee} />,
          'Roles & Permissions': <RolesPermissions />,
        }}
        defaultActiveItem="Employee Info"
      />
    </PageContainer>
  );
};
