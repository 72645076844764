import styled, { css } from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 20px 0px;
  border: 1px solid ${(props) => props.theme.colors.borderTable};
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;
`;

export const Th = styled.th`
  color: black;
  font-weight: 700;
  padding: 12px;
  text-align: left;
  text-transform: capitalize;
  font-size: ${(props) => props.theme.fontSizes.tableHeading};
`;

export const Td = styled.td`
  padding: 12px;
  border-top: 1px solid ${(props) => props.theme.colors.offWhite};
`;

export const Access = styled.div`
  display: flex;
`;

export const AccessItem = styled.div`
  position: relative;
  padding-left: 25px;
  width: 150px;
`;

export const PermissionIndicator = styled.span<{ hasPermission: boolean }>`
  position: absolute;
  left: 0;
  top: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.offWhite};
  border: 1px solid ${(props) => props.theme.colors.border2};
  transform: translateY(-50%);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border-bottom: 2px solid transparent;
    border-right: 2px solid transparent;
  }

  ${(props) =>
    props.hasPermission &&
    css`
      border: 1px solid ${props.theme.colors.checkbox};
      background-color: ${props.theme.colors.checkbox};

      &:before {
        width: 4px;
        height: 9px;
        margin: -1px 0;
        border-bottom: 2px solid #000;
        border-right: 2px solid #000;
      }
    `}
`;
