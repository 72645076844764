import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { SignatoryInput } from 'components/AuthorizeSignatory/SignatoryForm/types';
import { Signatory } from 'features/user/types';
import { APIError, sendErrorMessage } from './sendErrorMessage';

type AssignSignatoryPayload = {
  signatory: Signatory;
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

export const authorizeSignatory = (
  signatoryAttributes: SignatoryInput
): Promise<Signatory | void> => {
  const mutation = `
      mutation($signatoryParams: SignatoryInput!) {
        assignSignatory(signatoryParams: $signatoryParams) {
          signatory {
            id
            firstName
            lastName
            email
            title
            providerIdentifier
          }
          error {
            message
            type
            inputErrors {
              field
              fieldPath
              message
            }
          }
        }
      }
    `;

  const variables = {
    signatoryParams: signatoryAttributes,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { assignSignatory: AssignSignatoryPayload } }>>(
      'graphql',
      { query: mutation, variables }
    )
    .then((response: AxiosResponse<{ data: { assignSignatory: AssignSignatoryPayload } }>) => {
      if (response.data.data.assignSignatory.error) {
        sendErrorMessage(response.data.data.assignSignatory.error);
      }
      return response.data.data.assignSignatory.signatory;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
