import { Outlet, Navigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { selectCurrentUser } from 'features/user/slice';
import { AuthLayoutContainer } from './auth.styles';

const ROOT_PAGE = '/';

export const AuthLayout = () => {
  const user = useAppSelector(selectCurrentUser);

  if (user) return <Navigate to={ROOT_PAGE} />;

  return (
    <AuthLayoutContainer>
      <Outlet />
    </AuthLayoutContainer>
  );
};
