import { useState } from 'react';
import { StyledTable, Thead, Tr, Th, Td, Tbody } from '../Table.styles';
import { TableInput } from '../TableInput/TableInput';
import {
  DateRange,
  ModalHeader,
  TableContainer,
  ButtonContainer,
  Heading,
  CloseButton,
  RemoveButton,
  AddButton,
  ReimbursementContainer,
  ReimbursementsHeading,
  InputContainer,
  AddContainer,
  HorizontalRule,
  Input,
  InputLabel,
  TableBar,
  SaveStatusContainer,
  SavingMessage,
  SavedMessage,
  StyledSavingIcon,
  StyledSaveIcon,
} from './RunPayrollTables.styles';
import { PayrollItem, EarningType, PayrollStatus } from 'lib/fetchProviderPayroll';
import ReactModal from 'react-modal';
import { updatePayrollItem, UpdatePayrollItemInput } from 'lib/updatePayrollItem';
import { toast } from 'react-toastify';
import { ActionButton } from 'components/ActionButton/actionButton';
import { currencyFormatter } from 'lib/utils/currencyFormatter';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';
import { ReactComponent as RemoveIcon } from '@src/assets/icons/close-circle.svg';
import { ReactComponent as AddIcon } from '@src/assets/icons/add-circle.svg';

type BonusCommissionFields = {
  bonus: number;
  commission: number;
};

type AdditionalEarningsTableProps = {
  items: PayrollItem[];
  periodStart: string;
  periodEnd: string;
  status: PayrollStatus | null;
  refreshPayrollData: () => void;
};

type ReimbursementState = {
  amount: number;
  description: string;
  code: string;
};

export const AdditionalEarningsTable = ({
  items: initialItems,
  periodStart,
  periodEnd,
  status,
}: AdditionalEarningsTableProps) => {
  const [items, setItems] = useState<PayrollItem[]>(initialItems);
  const [inputFields, setInputFields] = useState<Map<string, BonusCommissionFields>>(
    new Map(
      initialItems.map((item) => [
        item.employee.id,
        {
          bonus: item.earnings.find((earning) => earning.type === EarningType.Bonus)?.amount || 0,
          commission:
            item.earnings.find((earning) => earning.type === EarningType.Commission)?.amount || 0,
        },
      ])
    )
  );
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<PayrollItem['employee'] | null>(null);
  const [description, setDescription] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [currentReimbursements, setCurrentReimbursements] = useState<ReimbursementState[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const utcDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return utcDate.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const calculateGrossPay = (item: PayrollItem): string => {
    const fields = inputFields.get(item.employee.id) || { bonus: 0, commission: 0 };
    const bonus = parseFloat(fields.bonus.toString()) || 0;
    const commission = parseFloat(fields.commission.toString()) || 0;

    let regularPay = 0,
      overtimePay = 0,
      ptoPay = 0,
      sickPay = 0;
    let hourlyRate = item.employee.compensation.amount;

    if (item.employee.compensation.type === 'SALARIED') {
      hourlyRate = item.employee.compensation.amount / (40 * 52);
    }

    item.earnings.forEach((earning) => {
      const hours = earning.hours !== null ? parseFloat(earning.hours.toString()) : 0;
      switch (earning.type) {
        case EarningType.Hourly:
        case EarningType.Salaried:
          regularPay += hours * hourlyRate;
          break;
        case EarningType.Overtime:
          overtimePay += hours * hourlyRate * 1.5;
          break;
        case EarningType.PTO:
          ptoPay += hours * hourlyRate;
          break;
        case EarningType.Sick:
          sickPay += hours * hourlyRate;
          break;
        case EarningType.Bonus:
        case EarningType.Commission:
          break;
        default:
          break;
      }
    });

    const reimbursementTotal = item.reimbursements.reduce(
      (acc, reimbursement) => acc + parseFloat(reimbursement.amount.toString()),
      0
    );

    const totalEarnings =
      regularPay + overtimePay + ptoPay + sickPay + bonus + commission + reimbursementTotal;
    return currencyFormatter(totalEarnings);
  };

  const updateEarnings = async (employeeId: string) => {
    setIsSaving(true);
    setIsSaved(false);

    const fields = inputFields.get(employeeId);
    if (!fields) return;

    const payrollItem = items.find((item) => item.employee.id === employeeId);
    if (payrollItem) {
      const existingEarnings = payrollItem.earnings
        .filter(
          (earning) => earning.type !== EarningType.Bonus && earning.type !== EarningType.Commission
        )
        .map((earning) => ({
          type: earning.type,
          amount: earning.amount.toString(),
          description: earning.description,
          hours: earning.hours ? earning.hours.toString() : null,
        }));

      const bonusEarning =
        fields.bonus > 0
          ? [
              {
                type: EarningType.Bonus,
                amount: fields.bonus.toString(),
                description: 'Bonus',
                hours: null,
              },
            ]
          : [];

      const commissionEarning =
        fields.commission > 0
          ? [
              {
                type: EarningType.Commission,
                amount: fields.commission.toString(),
                description: 'Commission',
                hours: null,
              },
            ]
          : [];

      const earningsUpdate = [...existingEarnings, ...bonusEarning, ...commissionEarning];

      try {
        await updatePayrollItem({
          id: payrollItem.payrollItemId,
          earnings: earningsUpdate,
        });
        setIsSaving(false);
        setIsSaved(true);
      } catch (error) {
        setIsSaving(false);
        toast.error(`Failed to update payroll: ${error}`);
      }
    } else {
      toast.error('Payroll Item ID not found');
    }
  };

  const handleInputChange = (
    employeeId: string,
    field: keyof BonusCommissionFields,
    value: string
  ): void => {
    const numericValue = parseFloat(value) || 0;
    setInputFields((prev) => {
      const currentFields = prev.get(employeeId) || { bonus: 0, commission: 0 };
      const updatedFields = { ...currentFields, [field]: numericValue };
      return new Map(prev).set(employeeId, updatedFields);
    });
  };

  const handleOpenModal = (employee: PayrollItem['employee']): void => {
    setSelectedEmployee(employee);
    setCurrentReimbursements(
      items
        .find((item) => item.employee.id === employee.id)
        ?.reimbursements.map((r) => ({
          description: r.description,
          amount: parseFloat(r.amount.toString()),
          code: r.code,
        })) || []
    );
    setModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setModalOpen(false);
    setSelectedEmployee(null);
    setDescription('');
    setAmount('');
    setCurrentReimbursements([]);
  };

  const handleAddReimbursement = (): void => {
    if (description && amount) {
      setCurrentReimbursements((prev) => [
        ...prev,
        { description, amount: parseFloat(amount), code: 'new' },
      ]);
      setDescription('');
      setAmount('');
    }
  };

  const handleRemoveReimbursement = (index: number): void => {
    setCurrentReimbursements((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSave = (): void => {
    if (selectedEmployee) {
      const payrollItemId = items.find(
        (item) => item.employee.id === selectedEmployee.id
      )?.payrollItemId;
      if (payrollItemId) {
        const updatePayload: UpdatePayrollItemInput = {
          id: payrollItemId,
          reimbursements: currentReimbursements.map((r) => ({
            description: r.description,
            amount: r.amount.toString(),
            code: r.code,
          })),
        };

        updatePayrollItem(updatePayload)
          .then(() => {
            toast.success('Reimbursements updated successfully');
            setItems((prevItems) =>
              prevItems.map((item) =>
                item.employee.id === selectedEmployee.id
                  ? { ...item, reimbursements: currentReimbursements }
                  : item
              )
            );
            handleCloseModal();
          })
          .catch((error) => {
            toast.error(`Failed to update reimbursements: ${error}`);
          });
      } else {
        toast.error('Payroll Item ID not found');
      }
    }
  };

  return (
    <>
      <TableBar>
        <DateRange>{`Pay Period: ${formatDate(periodStart)} - ${formatDate(periodEnd)}`}</DateRange>
        {isSaving && (
          <SaveStatusContainer>
            <SavingMessage>
              <StyledSavingIcon />
              Saving...
            </SavingMessage>
          </SaveStatusContainer>
        )}
        {isSaved && (
          <SaveStatusContainer>
            <SavedMessage>
              <StyledSaveIcon />
              Saved
            </SavedMessage>
          </SaveStatusContainer>
        )}
      </TableBar>
      <StyledTable>
        <Thead>
          <Tr>
            <Th>Employee Name</Th>
            <Th>Bonus</Th>
            <Th>Commission</Th>
            <Th style={{ textAlign: 'center' }}>Reimbursements</Th>
            <Th>Gross Pay</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map((item) => {
            const fields = inputFields.get(item.employee.id) || {
              bonus: 0,
              commission: 0,
            };
            const grossPay = calculateGrossPay(item);
            const isDisabled = status !== PayrollStatus.Draft;

            return (
              <Tr key={item.employee.id}>
                <Td>{item.employee.name}</Td>
                <Td>
                  <TableInput
                    type="text"
                    format="currency"
                    value={fields.bonus}
                    onChange={(newValue) => handleInputChange(item.employee.id, 'bonus', newValue)}
                    onBlur={() => updateEarnings(item.employee.id)}
                    disabled={isDisabled}
                  />
                </Td>
                <Td>
                  <TableInput
                    type="text"
                    format="currency"
                    value={fields.commission}
                    onChange={(newValue) =>
                      handleInputChange(item.employee.id, 'commission', newValue)
                    }
                    onBlur={() => updateEarnings(item.employee.id)}
                    disabled={isDisabled}
                  />
                </Td>
                <Td style={{ textAlign: 'center' }}>
                  {item.reimbursements.length > 0 ? (
                    <>
                      {item.reimbursements.map((reimbursement, index) => (
                        <div key={index}>
                          <span>{currencyFormatter(reimbursement.amount)}</span>
                        </div>
                      ))}
                      <HorizontalRule />
                      {status === PayrollStatus.Draft && (
                        <ActionButton
                          onClick={(e) => {
                            e.preventDefault();
                            handleOpenModal(item.employee);
                          }}
                          size="medium"
                          variant="text"
                          title="View/Edit"
                          hidden={false}
                        />
                      )}
                    </>
                  ) : (
                    status === PayrollStatus.Draft && (
                      <ActionButton
                        onClick={(e) => {
                          e.preventDefault();
                          handleOpenModal(item.employee);
                        }}
                        size="medium"
                        variant="text"
                        title="Add"
                        hidden={false}
                      />
                    )
                  )}
                </Td>
                <Td>{grossPay}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </StyledTable>

      {selectedEmployee && (
        <ReactModal
          style={{
            overlay: {
              position: 'fixed',
              inset: '0px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 11,
            },
            content: {
              top: '45%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              transform: 'translate(-50%, -50%)',
              borderRadius: '16px',
              overflow: 'visible',
              padding: '0px',
            },
          }}
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
        >
          <ModalHeader>
            <Heading>Reimbursements</Heading>
          </ModalHeader>
          <TableContainer>
            <AddContainer>
              <InputContainer>
                <InputLabel>Description</InputLabel>
                <Input
                  type="text"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </InputContainer>
              <InputContainer>
                <InputLabel>Amount</InputLabel>
                <Input
                  type="number"
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </InputContainer>
              <AddButton icon={<AddIcon />} onClick={handleAddReimbursement} />
            </AddContainer>
            <hr />
            <ReimbursementsHeading>Added Reimbursements</ReimbursementsHeading>
            {currentReimbursements.map((reimbursement, index) => (
              <ReimbursementContainer key={index}>
                <InputContainer>
                  <InputLabel>Description</InputLabel>
                  <Input
                    type="text"
                    value={reimbursement.description}
                    onChange={(e) =>
                      setCurrentReimbursements((prev) =>
                        prev.map((item, i) =>
                          i === index ? { ...item, description: e.target.value } : item
                        )
                      )
                    }
                  />
                </InputContainer>
                <InputContainer>
                  <InputLabel>Amount</InputLabel>
                  <Input
                    type="number"
                    value={reimbursement.amount.toString()}
                    onChange={(e) =>
                      setCurrentReimbursements((prev) =>
                        prev.map((item, i) =>
                          i === index ? { ...item, amount: parseFloat(e.target.value) || 0 } : item
                        )
                      )
                    }
                  />
                </InputContainer>
                <RemoveButton
                  icon={<RemoveIcon />}
                  onClick={() => handleRemoveReimbursement(index)}
                />
              </ReimbursementContainer>
            ))}
          </TableContainer>
          <ButtonContainer>
            <ActionButton onClick={handleSave} size="medium" title="Save" hidden={false} />
          </ButtonContainer>
          <CloseButton icon={<CloseIcon />} onClick={handleCloseModal} />
        </ReactModal>
      )}
    </>
  );
};
