import { StatusContainer, StatusDot } from './EmployeeInfo.styles';

type EmployeeStatusProps = {
  active: boolean;
  terminationDate?: string;
};

export const EmployeeStatus = ({ active, terminationDate }: EmployeeStatusProps) => {
  let statusText = '';
  let color = '';

  if (terminationDate) {
    statusText = 'Terminated';
    color = '#cc0000';
  } else if (active) {
    statusText = 'Active';
    color = '#58BA50';
  } else {
    statusText = 'Inactive';
    color = '#cc0000';
  }

  return (
    <StatusContainer color={color}>
      <StatusDot color={color} />
      {statusText}
    </StatusContainer>
  );
};
