import { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { PageContainer, ContentContainer } from 'components/SharedComponents/Containers.styles';
import { SectionHeader } from 'components/SectionHeader/sectionHeader';
import { StepsComponent, StepDetail } from 'components/Steps/StepsComponent';
import { fetchProviderPayroll, PayrollDetails, PayrollStatus } from 'lib/fetchProviderPayroll';
import { runTimecardsImport } from 'lib/runTimecardsImport';
import { createPayroll, PayrollTypes } from 'lib/createPayroll';
import { toast } from 'react-toastify';
import { PayrollItemsTable } from 'components/Table/RunPayrollTables/PayrollITemsTable';
import { AdditionalEarningsTable } from 'components/Table/RunPayrollTables/AdditionalEarningsTable';
import { LoadingSync } from 'components/LoadingSync/LoadingSync';
import { Spinner } from 'components/Spinner/spinner';
import { ReviewStep } from 'components/Table/RunPayrollTables/ReviewStep/ReviewStep';
import { Payday } from 'lib/fetchCompanyNextPayday';

export const RunPayroll = () => {
  const { stepLabel } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [payroll, setPayroll] = useState<PayrollDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [periodDates, setPeriodDates] = useState({ start: '', end: '' });
  const [payrollProviderId, setPayrollProviderId] = useState<string | null>(null);
  const [payrollStatus, setPayrollStatus] = useState<PayrollStatus | null>(null);
  const handleSubmitRef = useRef<() => void>(() => {});

  useEffect(() => {
    async function processPayroll() {
      try {
        setIsLoading(true);

        const state = location.state as {
          PayrollProviderId?: string;
          payday?: Payday;
          startDate?: string;
          endDate?: string;
          type?: PayrollTypes;
          employeeIds?: string[];
        };

        if (state && state.PayrollProviderId) {
          setPayrollProviderId(state.PayrollProviderId);
          const payrollData = await fetchProviderPayroll(state.PayrollProviderId);
          if (payrollData) {
            setPayroll(payrollData);
            setPeriodDates({ start: payrollData.periodStart, end: payrollData.periodEnd });
            setPayrollStatus(payrollData.status);
          } else {
            toast.error('Failed to fetch payroll data');
          }
        } else {
          setIsCreating(true);
          const { payday, type, employeeIds } = state;
          if (!payday) {
            toast.error('Failed to fetch next payday details');
            setIsLoading(false);
            return;
          }

          const { periodStart, periodEnd } = payday;
          const completed = await runTimecardsImport({
            startDate: periodStart,
            endDate: periodEnd,
            employeeIds: employeeIds || [],
          });
          if (!completed) {
            toast.error('Timecards sync failed to complete.');
            setIsLoading(false);
            return;
          }

          toast.success('Timecards sync completed successfully!');
          const payrollCreated = await createPayroll({
            startDate: periodStart,
            endDate: periodEnd,
            payday: payday.payday,
            type: type || PayrollTypes.REGULAR,
            employeeIds: employeeIds || [],
          });
          if (!payrollCreated) {
            toast.error('Failed to create payroll');
            setIsLoading(false);
            return;
          }

          const payrollData = await fetchProviderPayroll(payrollCreated.providerIdentifier);
          if (payrollData) {
            setPayroll(payrollData);
            setPeriodDates({ start: periodStart, end: periodEnd });
            setPayrollProviderId(payrollCreated.providerIdentifier);
            setPayrollStatus(payrollData.status);
          } else {
            toast.error('Failed to fetch payroll data');
          }
        }
      } catch (error) {
        toast.error('An error occurred during the payroll process');
      } finally {
        setIsLoading(false);
        setIsCreating(false);
      }
    }

    processPayroll();
  }, [stepLabel, location.state]);

  const refreshPayrollData = async () => {
    if (payrollProviderId) {
      const payrollData = await fetchProviderPayroll(payrollProviderId);
      if (payrollData) {
        setPayroll(payrollData);
        setPayrollStatus(payrollData.status);
      } else {
        toast.error('Failed to fetch payroll data');
      }
    }
  };

  const StepContentOne = () => {
    return (
      <div>
        {payroll ? (
          <PayrollItemsTable
            items={payroll.items}
            periodStart={periodDates.start}
            periodEnd={periodDates.end}
            refreshPayrollData={refreshPayrollData}
            status={payrollStatus}
          />
        ) : (
          <Spinner />
        )}
      </div>
    );
  };

  const StepContentTwo = () => {
    return (
      <div>
        {payroll ? (
          <AdditionalEarningsTable
            items={payroll.items}
            periodStart={periodDates.start}
            periodEnd={periodDates.end}
            refreshPayrollData={refreshPayrollData}
            status={payrollStatus}
          />
        ) : (
          <Spinner />
        )}
      </div>
    );
  };

  const StepContentThree = () => {
    return (
      <div>
        {payrollProviderId ? (
          <ReviewStep
            payrollProviderId={payrollProviderId}
            onSubmit={(fn) => (handleSubmitRef.current = fn)}
            status={payrollStatus}
          />
        ) : (
          <Spinner />
        )}
      </div>
    );
  };

  const steps: StepDetail[] = [
    { label: 'Hours', content: <StepContentOne /> },
    { label: 'Additional Earnings', content: <StepContentTwo /> },
    { label: 'Review', content: <StepContentThree /> },
  ];

  const currentStepIndex = steps.findIndex(
    (step) => step.label.toLowerCase().replace(/\s+/g, '-') === stepLabel
  );

  const handleStepChange = (stepIndex: number) => {
    navigate(`/payroll/run-payroll/${steps[stepIndex].label.toLowerCase().replace(/\s+/g, '-')}`, {
      state: {
        PayrollProviderId: payrollProviderId,
        nextPayday: location.state.nextPayday,
        type: location.state.type,
        employeeIds: location.state.employeeIds,
      },
    });
  };

  const handleSubmit = () => {
    handleSubmitRef.current();
  };

  return (
    <PageContainer>
      <SectionHeader title="Run Payroll" />
      <ContentContainer>
        {isLoading ? (
          isCreating ? (
            <LoadingSync message="Just a moment while we sync staff hours." />
          ) : (
            <Spinner />
          )
        ) : (
          <StepsComponent
            initialActiveStep={currentStepIndex >= 0 ? currentStepIndex : 0}
            steps={steps}
            onStepChange={handleStepChange}
            onSubmit={handleSubmit}
            payrollStatus={payrollStatus}
          />
        )}
      </ContentContainer>
    </PageContainer>
  );
};
