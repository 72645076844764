import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export type APIError = {
  message: string;
  type: string;
  inputErrors?: {
    message: string;
    field: string;
    fieldPath: string;
  }[];
};

export const sendErrorMessage = (error: APIError | AxiosError<APIError>) => {
  let errorMessage: string | undefined;
  let inputErrors: APIError['inputErrors'] | undefined;

  if ('response' in error) {
    if (error.response?.status === 401) {
      return;
    }

    const apiError = error.response?.data as APIError | undefined;
    errorMessage = apiError?.message;
    inputErrors = apiError?.inputErrors;
  } else {
    const apiError = error as APIError;
    errorMessage = apiError.message;
    inputErrors = apiError.inputErrors;
  }

  if (inputErrors) {
    inputErrors.forEach((inputError) => {
      toast.error(`${inputError.field}: ${inputError.message}`);
    });
  } else if (errorMessage) {
    toast.error(errorMessage);
  }
};
