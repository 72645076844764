import { object, string } from 'yup';

export const workplaceSchema = object({
  name: string().required(),
  line1: string().required(),
  line2: string(),
  city: string().required(),
  state: string().required(),
  postalCode: string().required(),
  platformId: string(),
}).required();
