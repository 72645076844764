import { LoginForm } from 'components/AuthForm/LoginForm/loginForm';
import { AuthHeader } from 'components/AuthHeader/authHeader';

export const Login = () => {
  return (
    <div>
      <AuthHeader />
      <LoginForm />
    </div>
  );
};
