import { procareApi } from 'lib/HttpClient/httpClient';
import {
  UserState,
  FormData,
  Auth,
  ErrorResponse,
  RefreshToken,
  UserData,
  OnboardParams,
  CurrentUserContext,
  CurrentUserContextResponse,
} from './types';
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { setAccessToken, setRefreshToken, getRefreshToken } from 'lib/token';
import { sendAuthErrorMessage } from 'lib/sendAuthErrorMessage';

export const fetchUserContext = (): Promise<CurrentUserContext> => {
  const query = `
  query CurrentUserContext {
    currentUserContext {
        employee {
            id
            name
            providerIdentifier
            roles {
                id
                name
            }
            compensation {
                amount
                type
            }
            workplaces {
                id
                name
                providerIdentifier
            }
        }
        role {
            id
            name
            permissions {
                allowedFields
                id
                operation
                resource
            }
        }
        signatory {
            email
            firstName
            id
            lastName
            providerIdentifier
            title
        }
        user {
            email
            id
            name
            companies {
                id
                name
                onboarded
                providerIdentifier
            }
        }
    }
}

  `;

  return procareApi
    .post<unknown, AxiosResponse<CurrentUserContextResponse>>('graphql', { query })
    .then((response) => {
      return response.data.data.currentUserContext;
    });
};

export const loginUser = (loginParams: FormData): Promise<Auth | void> =>
  procareApi
    .post<FormData, AxiosResponse<Auth>>('/api/v1/auth/login', loginParams)
    .then((response) => {
      const auth = response.data;

      setAccessToken(auth.access_token);
      setRefreshToken(auth.refresh_token);

      return auth;
    })
    .catch((error: AxiosError<ErrorResponse>) => {
      sendAuthErrorMessage(error);
    });

export const createUser = (signupParams: UserData): Promise<AxiosResponse<UserData> | void> =>
  procareApi
    .post<UserData>('/api/v1/users', signupParams)
    .catch((error: AxiosError<ErrorResponse>) => {
      sendAuthErrorMessage(error);
    });

export const confirmUser = (token: string): Promise<Auth | void> =>
  procareApi
    .post<string, AxiosResponse<Auth>>(`/api/v1/auth/confirm/${token}`)
    .then((response) => {
      const auth = response.data;

      setAccessToken(auth.access_token);
      setRefreshToken(auth.refresh_token);

      return auth;
    })
    .catch((error: AxiosError<ErrorResponse>) => {
      sendAuthErrorMessage(error);
    });

// Employee User Action
export const onboardUser = ({ password, confirmToken }: OnboardParams): Promise<Auth | void> =>
  procareApi
    .post<{ password: string }, AxiosResponse<Auth>>(
      `/api/v1/auth/employees/confirm/${confirmToken}`,
      { password }
    )
    .then((response) => {
      const auth = response.data;

      setAccessToken(auth.access_token);
      setRefreshToken(auth.refresh_token);

      return auth;
    })
    .catch((error: AxiosError<ErrorResponse>) => {
      sendAuthErrorMessage(error);
    });

export const logoutUser = (): Promise<UserState | void> =>
  procareApi
    .delete('/api/v1/auth/logout')
    .then(() => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');

      return { currentUser: null, loading: false };
    })
    .catch((error: AxiosError<ErrorResponse>) => {
      sendAuthErrorMessage(error);
    });

export const refreshTokens = async (error: AxiosError<ErrorResponse>) => {
  const refreshToken = getRefreshToken();
  const response = await procareApi.post<RefreshToken, AxiosResponse<Auth>>(
    '/api/v1/auth/refresh',
    {
      refresh_token: refreshToken,
    }
  );

  if (response.status === 200) {
    setAccessToken(response.data.access_token);
    setRefreshToken(response.data.refresh_token);
    const { config } = error;
    if (config) {
      config.headers.Authorization = `Bearer ${response.data.access_token}`;
    }
    return axios(error.config as AxiosRequestConfig<Auth>);
  }
};
