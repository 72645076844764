import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 0 20px;
  width: 100%;
`;

export const NavCardsContainer = styled.div`
  display: flex;
`;

export const Heading = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: 20px;
  margin: 8px 0;
`;
