// Step.styles.ts
import styled, { css } from 'styled-components';

interface StepNumberProps {
  isActive: boolean;
  isCompleted: boolean;
}

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StepNumber = styled.div<StepNumberProps>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 10px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  background-color: ${({ theme, isActive, isCompleted }) =>
    isCompleted ? theme.colors.success : isActive ? theme.colors.primary : theme.colors.gray3};
`;

interface StepLabelProps {
  isActive: boolean;
}

export const StepLabel = styled.div<StepLabelProps>`
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  padding-top: 10px;
`;
