import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

export type PlatformWorkplace = {
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  email: string | null;
  name: string;
  phone: string;
  platformId: string;
  state: string;
  zip: string;
};

type PlatformWorkplaceResponse = {
  data: {
    platformWorkplaces: PlatformWorkplace[];
  };
};

export const fetchPlatformWorkplaces = (): Promise<PlatformWorkplace[] | null> => {
  const query = `
    query PlatformWorkplaces {
      platformWorkplaces {
        addressLine1
        addressLine2
        city
        email
        name
        phone
        platformId
        state
        zip
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<PlatformWorkplaceResponse>>('graphql', {
      query,
    })
    .then((response: AxiosResponse<PlatformWorkplaceResponse>) => {
      return response.data.data.platformWorkplaces;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return null;
    });
};
