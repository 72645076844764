import { useAppSelector } from 'hooks/redux';
import { selectCurrentUser } from 'features/user/slice';
import { Navigate, useLocation } from 'react-router-dom';
import { Roles } from 'features/user/types';

type PrivateRouteProps = {
  children: React.ReactElement;
  roles: Roles[];
};

export const NullComponent = () => null;

export const PrivateRoute = ({ roles, children }: PrivateRouteProps) => {
  const user = useAppSelector(selectCurrentUser);
  const location = useLocation();

  // If user doesn't have the required role, navigate to dashboard
  if (!user || !roles.includes(user.role.name)) {
    return <Navigate to="/dashboard" replace state={{ from: location }} />;
  }

  return children;
};
