import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';
import { DEFAULT_PAGE_SIZE } from '@src/constants/constants';

export enum PayrollStatus {
  Draft = 'draft',
  Pending = 'pending',
  Processing = 'processing',
  Failed = 'failed',
  PartiallyPaid = 'partially_paid',
  Paid = 'paid',
}

export enum PayrollTypes {
  Regular = 'regular',
  OffCycle = 'off_cycle',
}

export type Payroll = {
  approvalDeadline: string;
  approvedAt: string;
  fundingPaymentMethod: string;
  isVoid: boolean;
  managed: boolean;
  offCycleOptions: any;
  payFrequency: string;
  payday: string;
  periodEnd: string;
  periodStart: string;
  processingPeriod: string;
  providerId: string;
  status: PayrollStatus;
  type: PayrollTypes;
};

export type PageInfo = {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
};

type PayrollEdge = {
  node: Payroll;
};

type CompanyPayrollsResponse = {
  data: {
    companyPayrolls: {
      edges: PayrollEdge[];
      pageInfo: PageInfo;
    };
  };
};

type PayrollInput = {
  status?: PayrollStatus | null;
  type?: PayrollTypes | null;
  paydayBefore?: string | null;
  paydayAfter?: string | null;
};

export const fetchCompanyPayrolls = (
  endCursor?: string,
  payrollInput?: PayrollInput,
  pageSize: number = DEFAULT_PAGE_SIZE
): Promise<{ payrolls: Payroll[]; pageInfo: PageInfo | null }> => {
  const after = endCursor ? `, after: "${endCursor}"` : '';
  const statusFilter = payrollInput?.status ? `status: ${payrollInput.status}` : '';
  const typeFilter = payrollInput?.type ? `type: ${payrollInput.type}` : '';
  const paydayBeforeFilter = payrollInput?.paydayBefore
    ? `paydayBefore: "${payrollInput.paydayBefore}"`
    : '';
  const paydayAfterFilter = payrollInput?.paydayAfter
    ? `paydayAfter: "${payrollInput.paydayAfter}"`
    : '';

  const payrollInputFilter = [statusFilter, typeFilter, paydayBeforeFilter, paydayAfterFilter]
    .filter(Boolean)
    .join(', ');

  const payrollInputQuery = payrollInputFilter ? `, payrollInput: {${payrollInputFilter}}` : '';

  const query = `
    query CompanyPayrolls {
      companyPayrolls(first: ${pageSize}${after}${payrollInputQuery}) {
        edges {
          node {
            approvalDeadline
            approvedAt
            fundingPaymentMethod
            isVoid
            managed
            offCycleOptions
            payFrequency
            payday
            periodEnd
            periodStart
            processingPeriod
            providerId
            status
            type
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<CompanyPayrollsResponse>>('graphql', { query })
    .then((response: AxiosResponse<CompanyPayrollsResponse>) => {
      const payrolls = response.data.data.companyPayrolls.edges.map((edge) => edge.node);
      const pageInfo = response.data.data.companyPayrolls.pageInfo;
      return { payrolls, pageInfo };
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return { payrolls: [], pageInfo: null };
    });
};
