import styled, { css } from 'styled-components';

export const StatusContainer = styled.div`
  display: flex;
`;

export const StatusLabel = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: 20px;
  font-weight: 400;
  margin: 8px 0;
  color: ${(props) => props.theme.colors.black};
`;

const Status = css`
  text-transform: capitalize;
  border-radius: 15px;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 400;
  margin: 2px 20px;
`;

export const StatusValue = styled.span`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: 16px;
  font-weight: 400;
  margin: 2px 20px;
  background-color: ${(props) => props.theme.colors.completedStatus};

  ${Status}
`;

export const BlockingStatus = styled.span`
  background-color: ${(props) => props.theme.colors.blockingStatus};
  ${Status}
`;

export const CompletedStatus = styled.span`
  background-color: ${(props) => props.theme.colors.completedStatus};
  ${Status}
`;

export const AttentionStatus = styled.span`
  background-color: #ffffcc;
  ${Status}
`;
