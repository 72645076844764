import { TabContainer } from '../../tabMenu.styles';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { PaystubsTable } from 'components/Table/PaystubsTable/PaystubsTable';
import { Employee } from 'features/user/types';

type PaystubsProps = {
  employee: Employee;
};

export const Paystubs = ({ employee }: PaystubsProps) => {
  const { id: employeeId } = employee;

  return (
    <ContentContainer>
      <TabContainer>
        {employeeId ? <PaystubsTable employeeId={employeeId} /> : 'No Employee Paysubs Available'}
      </TabContainer>
    </ContentContainer>
  );
};
