import { useState, ReactNode, MouseEvent } from 'react';
import { Wrapper, Tip } from './tooltip.styles';

export type TooltipProps = {
  direction?: 'top' | 'right' | 'bottom' | 'left';
  delay?: number;
  content: ReactNode;
  children: ReactNode;
};

const Tooltip = ({ direction, delay, content, children }: TooltipProps) => {
  let timeout: any;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <Wrapper onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && <Tip direction={direction}>{content}</Tip>}
    </Wrapper>
  );
};

export default Tooltip;
