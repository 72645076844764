import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/redux';
import { selectCurrentUser } from 'features/user/slice';
import { getCurrentCompany } from 'features/company/slice';
import { setCurrentCompany } from 'lib/setCurrentCompany';
import { toast } from 'react-toastify';
import {
  CompanySelectionContainer,
  HeadingContainer,
  Heading,
  CompanyList,
  CompanyItem,
  Instruction,
  StyledSchoolIcon,
  CompanyText,
} from './CompanySelection.styles';

export const CompanySelection = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);

  const handleCompanySelection = async (companyId: string) => {
    try {
      await setCurrentCompany(companyId);
      await dispatch(getCurrentCompany());
      navigate('/');
    } catch (error) {
      toast.error('Unable to select company');
    }
  };

  useEffect(() => {
    if (currentUser?.user?.companies && currentUser.user.companies.length === 1) {
      handleCompanySelection(currentUser.user.companies[0].id);
    }
  }, [currentUser]);

  if (currentUser?.user?.companies && currentUser?.user?.companies.length === 1) {
    return null;
  }

  return (
    <CompanySelectionContainer>
      <HeadingContainer>
        <Heading>Select Company</Heading>
        <Instruction>Make sure you have the following information:</Instruction>
      </HeadingContainer>
      <CompanyList>
        {currentUser && currentUser.user.companies ? (
          currentUser.user.companies.map((company) => (
            <CompanyItem key={company.id} onClick={() => handleCompanySelection(company.id)}>
              <StyledSchoolIcon />
              <CompanyText>{company.name}</CompanyText>
            </CompanyItem>
          ))
        ) : (
          <p>No companies available.</p>
        )}
      </CompanyList>
    </CompanySelectionContainer>
  );
};
