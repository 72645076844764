import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from './sendErrorMessage';
import { Role } from 'features/user/types';

export type SetCurrentRolePayload = {
  role: Role;
};

export const setCurrentRole = (roleId: string): Promise<Role | void> => {
  const mutation = `
        mutation($roleId: ID!) {
          setCurrentRole(roleId: $roleId) {
            role {
              id
              name
              permissions {
                allowedFields
                id
                operation
                resource
              }
            }
          }
        }
      `;

  const variables = {
    roleId,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { setCurrentRole: SetCurrentRolePayload } }>>('graphql', {
      query: mutation,
      variables,
    })
    .then((response: AxiosResponse<{ data: { setCurrentRole: SetCurrentRolePayload } }>) => {
      return response.data.data.setCurrentRole.role;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
