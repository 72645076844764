import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { CompanyDashboard } from 'components/CompanyDashboard/CompanyDashboard';
import { CompanyOnboarding } from 'components/CompanyOnboarding/CompanyOnboarding';
import { fetchCompanyOnboardingStatus } from '../../../lib/fetchCompanyOnboardingStatus';
import { setCompanyOnboarded, selectCompanyOnboarded } from 'features/company/slice';
import { selectCurrentUser } from 'features/user/slice';

export const CompanyView = () => {
  const dispatch = useAppDispatch();
  const isOnboarded = useAppSelector(selectCompanyOnboarded);
  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    if (!currentUser) return;

    fetchCompanyOnboardingStatus()
      .then((status) => {
        dispatch(setCompanyOnboarded(status));
      })
      .catch(() => {
        dispatch(setCompanyOnboarded(false));
      });
  }, [dispatch, currentUser]);

  return isOnboarded ? <CompanyDashboard /> : <CompanyOnboarding />;
};
