import { StepContainer, StepNumber, StepLabel } from './Step.styles';

type StepProps = {
  stepNumber: number;
  label: string;
  isActive: boolean;
  isCompleted: boolean;
  onClick?: () => void;
};

export const Step = ({ stepNumber, label, isActive, isCompleted, onClick }: StepProps) => {
  return (
    <div onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'default' }}>
      <StepContainer>
        <StepNumber isActive={isActive} isCompleted={isCompleted}>
          {stepNumber}
        </StepNumber>
        <StepLabel isActive={isActive}>{label}</StepLabel>
      </StepContainer>
    </div>
  );
};
