import { ContentContainer, PageContainer } from 'components/SharedComponents/Containers.styles';
import { SectionHeader } from 'components/SectionHeader/sectionHeader';
import { EmployeeForm } from 'components/EmployeeForm/employeeForm';
import { SubHeading, HeadingMessage } from './addEmployee.styles';

export const AddEmployee = () => {
  return (
    <PageContainer>
      <SectionHeader title="People" />
      <ContentContainer>
        <SubHeading>Create Employee Profile</SubHeading>
        <HeadingMessage>
          Please provide some basic info about your employee. Separate instructions will be sent to
          the employee to set up payout options.
        </HeadingMessage>
        <EmployeeForm />
      </ContentContainer>
    </PageContainer>
  );
};
