import styled from 'styled-components';
import { Modal } from 'components/Modal/Modal';

export const EmployeeOnboardingModal = styled(Modal)`
  iframe {
    height: 90vh !important;
    width: 64vw !important;
    max-height: 750px;
    max-width: 1000px;
    border-radius: 16px;
  }
`;
