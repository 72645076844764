import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from './sendErrorMessage';

type PostTaxDeductionInput = {
  providerId: string;
};

type PostTaxDeductionPayload = {
  result: boolean;
  error: {
    message: string;
    type: string;
    inputErrors: {
      field: string;
      fieldPath: string;
      message: string;
    }[];
  };
};

export const deletePostTaxDeduction = (
  deductionAttributes: PostTaxDeductionInput
): Promise<boolean | void> => {
  const mutation = `
        mutation($deductionParams: PostTaxDeductionInput!) {
          deletePostTaxDeduction(postTaxDeductionInput: $deductionParams) {
            result
            error {
              message
              type
              inputErrors {
                field
                fieldPath
                message
              }
            }
          }
        }
      `;

  const variables = {
    deductionParams: deductionAttributes,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { deletePostTaxDeduction: PostTaxDeductionPayload } }>>(
      'graphql',
      {
        query: mutation,
        variables,
      }
    )
    .then(
      (response: AxiosResponse<{ data: { deletePostTaxDeduction: PostTaxDeductionPayload } }>) => {
        if (response.data.data.deletePostTaxDeduction.error) {
          throw new Error(response.data.data.deletePostTaxDeduction.error.message);
        }
        return response.data.data.deletePostTaxDeduction.result;
      }
    )
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
