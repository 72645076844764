import styled from 'styled-components';
import { sectionDivider } from 'styles/mixins';

export const HeadingContainer = styled.div<{ divider?: boolean }>`
  width: 100%;
  ${(props) => sectionDivider(props.divider)}
`;

export const Heading = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: 18px;
  color: ${(props) => props.theme.colors.black};
  font-weight: 500;
  margin-bottom: 5px;
`;

export const SubHeading = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: 16px;
  margin: 8px 0;
`;

export const HeadingMessage = styled.div`
  font-size: ${(props) => props.theme.fontSizes.normal};
`;
