import styled from 'styled-components';
import { ReactComponent as ArrowDownIcon } from '@src/assets/icons/arrow-down.svg';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 1300px;
  margin-top: 5px;
`;

export const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.normal};

  &:hover {
    color: ${(props) => props.theme.colors.primaryDark};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

type Direction = 'left' | 'right';

export const PaginateIcon = styled(ArrowDownIcon)<{ direction: Direction }>`
  transform: ${(props) => (props.direction === 'left' ? 'rotate(90deg)' : 'rotate(-90deg)')};
  fill: ${(props) => props.theme.colors.primary};
  width: 18px;

  ${Button}:hover & {
    fill: ${(props) => props.theme.colors.primaryDark};
  }

  ${Button}:disabled & {
    opacity: 0.5;
  }
`;
