import styled from 'styled-components';
import { FormSelectContainer } from 'components/form/Select/select.styles';

export const FilterDiv = styled.div`
  margin: 10px 0;
  cursor: pointer;
`;

export const FilterIconContainer = styled.div``;

export const TooltipDiv = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.gray0};
  box-shadow: 0 7px 40px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  color: ${(props) => props.theme.colors.black0};
  margin-top: 20px;
  z-index: 1000;
  width: 260px;

  // Tooltip arrow
  &::before {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    display: block;
    left: 12px;
    top: -7px;
    border-top: 1px solid ${(props) => props.theme.colors.gray0};
    border-left: 1px solid ${(props) => props.theme.colors.gray0};
    background-color: white;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.gray5};
  padding: 16px;
`;

export const FilterForm = styled.form`
  ${FormSelectContainer} {
    min-width: unset;
    padding: 12px;
    margin-bottom: 0;
  }
`;
