import styled from 'styled-components';

export const SignatoryFormContainer = styled.div`
  width: 800px;
`;

export const Form = styled.form`
  width: 800px;
  margin: 30px auto 5px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgb(200, 198, 196);
`;

export const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
