type Address = {
  line1: string;
  city: string;
  state: string;
  postalCode: string;
};

export const getFullAddress = (address: Address): string[] => {
  return [address.line1, `${address.city}, ${address.state} ${address.postalCode}`];
};
