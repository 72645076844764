import { selectCurrentUser } from 'features/user/slice';
import { useAppSelector } from 'hooks/redux';
import { DownloadLink } from 'components/DownloadLink/DownloadLink';
import { Paystub } from 'lib/fetchEmployeePaystubs';
import { downloadPaystub } from 'lib/utils/downloadPaystub';
import { Thead, Tr, Th, Td, Tbody, EmptyMessage } from '../Table.styles';
import { StyledTable } from './PaystubsTable.styles';

type PaystubTableProps = {
  paystubs: Paystub[];
};

export const Table = ({ paystubs }: PaystubTableProps) => {
  const currentUser = useAppSelector(selectCurrentUser);

  if (!paystubs || paystubs.length === 0) {
    return <EmptyMessage>No paystubs found.</EmptyMessage>;
  }

  return (
    <StyledTable>
      <Thead>
        <Tr>
          <Th>Date</Th>
          <Th>Total Amount</Th>
          <Th>Total Hours</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {paystubs.map((stub) => (
          <Tr key={stub.payday}>
            <Td>{stub.payday}</Td>
            <Td>{stub.totalAmount}</Td>
            <Td>{stub.totalHours}</Td>
            <Td>
              {currentUser?.employee.providerIdentifier && (
                <DownloadLink onClick={() => downloadPaystub(stub.payroll)}>Download</DownloadLink>
              )}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </StyledTable>
  );
};
