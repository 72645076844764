import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';
import { Company } from 'features/company/types';
import { Compensation, ProviderEmployee, Workplace, Role } from 'features/user/types';

type EmployeeResponse = {
  data: {
    getEmployeeByIdentifier: Employee;
  };
};

export type Employee = {
  company: Company;
  compensation: Compensation;
  id: string;
  name: string;
  active: boolean;
  terminationDate?: string;
  platformIdentifier?: string;
  providerAttributes: ProviderEmployee;
  providerIdentifier: string;
  workplaces: Workplace[];
  roles?: Role[];
};

export const fetchEmployee = (providerIdentifier: string): Promise<Employee> => {
  const query = `
        query GetEmployeeByIdentifier($employeeInput: ProviderEmployeeInput!) {
          getEmployeeByIdentifier(employeeInput: $employeeInput) {
            id
            name
            active
            terminationDate
            roles {
              id
              name
            }
            platformIdentifier
            providerIdentifier
            providerAttributes {
              email
              firstName
              lastName
              providerIdentifier
              ssnLastFour
              paymentMethodPreference
              address {
                city
                country
                line1
                line2
                postalCode
                state
              }
              dateOfBirth
              startDate
              onboard {
                blockingSteps
                remainingSteps
                status
              }
            }
            compensation {
              amount
              type
            }
            workplaces {
              id
              name
              platformIdentifier
              providerIdentifier
              providerAttributes {
                active
                providerIdentifier
                address {
                  city
                  country
                  line1
                  line2
                  postalCode
                  state
                }
              }
            }
          }
        }
      `;
  return procareApi
    .post<unknown, AxiosResponse<EmployeeResponse>>('graphql', {
      query,
      variables: { employeeInput: { providerIdentifier } },
    })
    .then((response: AxiosResponse<EmployeeResponse>) => {
      return response.data.data.getEmployeeByIdentifier;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      throw error;
    });
};
