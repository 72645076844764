import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from './sendErrorMessage';

export type Permission = {
  allowedFields: string[];
  id: string;
  operation: string;
  resource: string;
};

export type Role = {
  id: string;
  name: string;
  permissions: Permission[];
};

export type EmployeePayload = {
  id: string;
  name: string;
  providerIdentifier: string;
  roles: Role[];
};

export const fetchEmployeeRoles = (providerIdentifier: string): Promise<EmployeePayload | void> => {
  const query = `
      query GetEmployeeByIdentifier($employeeInput: ProviderEmployeeInput!) {
        getEmployeeByIdentifier(employeeInput: $employeeInput) {
          id
          name
          providerIdentifier
          roles {
            id
            name
            permissions {
              allowedFields
              id
              operation
              resource
            }
          }
        }
      }
    `;

  const variables = {
    employeeInput: { providerIdentifier },
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { getEmployeeByIdentifier: EmployeePayload } }>>(
      'graphql',
      { query, variables }
    )
    .then((response: AxiosResponse<{ data: { getEmployeeByIdentifier: EmployeePayload } }>) => {
      return response.data.data.getEmployeeByIdentifier;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
