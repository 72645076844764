import { selectCurrentUser } from 'features/user/slice';
import { Roles } from 'features/user/types';
import { useAppSelector } from 'hooks/redux';
import { EmployeeView } from './EmployeeView/employeeView';
import { CompanyView } from './CompanyView/CompanyView';

const Dashboard = () => {
  const currentUser = useAppSelector(selectCurrentUser);

  if (currentUser && currentUser.role.name === Roles.Employee) {
    return <EmployeeView />;
  }

  return <CompanyView />;
};

export default Dashboard;
