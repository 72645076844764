import { Modal } from 'components/Modal/Modal';
import { ActionButton } from 'components/ActionButton/actionButton';
import { ModalHeader, ButtonContainer, Heading, Instruction } from './ConfirmDeleteModal.styles';

type ConfirmDeleteModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
};

export const ConfirmDeleteModal = ({
  isOpen,
  onRequestClose,
  onConfirm,
}: ConfirmDeleteModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          position: 'fixed',
          inset: '0px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 11,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          borderRadius: '16px',
          overflow: 'visible',
          maxWidth: '500px',
          overflowY: 'auto',
          padding: '0px',
        },
      }}
    >
      <ModalHeader>
        <Heading>Delete Payroll Draft</Heading>
        <Instruction>
          Are you sure you want to delete this payroll? All associated information with this payroll
          will be deleted.
        </Instruction>
      </ModalHeader>
      <ButtonContainer>
        <ActionButton onClick={onRequestClose} size="medium" variant="outline" title="Close" />
        <ActionButton onClick={onConfirm} size="medium" title="Delete" />
      </ButtonContainer>
    </Modal>
  );
};
