import { createSlice, createAsyncThunk, createSelector, isFulfilled } from '@reduxjs/toolkit';
import { CompanyState, Company } from './types';
import { RootState } from '@src/store';
import { fetchCompany } from './service';
import { logout } from '../user/slice';

const initialState: CompanyState = {
  company: null,
  loading: false,
  isOnboarded: false,
};

export const companySelector = (state: RootState): CompanyState => state.company;
export const selectCompanyOnboarded = createSelector(
  companySelector,
  (companyState) => companyState.isOnboarded
);
export const selectCurrentCompany = createSelector(
  companySelector,
  (companyState) => companyState.company
);

export const selectCompanyOnboardedStatus = createSelector(
  companySelector,
  (companyState) => companyState.company?.onboarded
);

export const getCurrentCompany = createAsyncThunk<Company>('company/getCompany', () => {
  return fetchCompany();
});

export const setCompanyOnboarded = createAsyncThunk<boolean, boolean>(
  'company/setCompanyOnboarded',
  async (status: boolean) => {
    return status;
  }
);

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentCompany.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.company = { ...payload };
      })
      .addCase(getCurrentCompany.rejected, () => initialState)
      // Handle logout action from userSlice
      .addCase(setCompanyOnboarded.fulfilled, (state, { payload }) => {
        state.isOnboarded = payload;
      })
      .addMatcher(isFulfilled(logout), () => {
        return initialState;
      });
  },
});

export default companySlice.reducer;
