import { Company, CompanyResponse } from './types';
import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse } from 'axios';

export const fetchCompany = (): Promise<Company> => {
  const query = `
    query {
      currentCompany {
        id
        name
        incomingEmployeesCount
        onboarded
        approvedForPaymentProcessing
        platformIdentifier
        providerIdentifier
        providerAttributes {
            legalName
            tradeName
            phone
            providerIdentifier
            address {
                city
                country
                line1
                line2
                postalCode
                state
            }
        }
        workplaces {
          id
          name
          providerAttributes {
            active
            address {
              city
              country
              line1
              line2
              postalCode
              state
            }
            providerIdentifier
          }
        }
        signatories {
          id
          lastName
          title
          email
          firstName
          providerIdentifier
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<CompanyResponse>>('graphql', { query })
    .then((response) => {
      return response.data.data.currentCompany;
    });
};
