import { ReactComponent as DashboardIcon } from './dashboard.svg';
import { ReactComponent as PeopleIcon } from './people.svg';
import { ReactComponent as PayrollIcon } from './payroll.svg';
import { ReactComponent as ReportsIcon } from './reports.svg';
import { ReactComponent as DocumentsIcon } from './documents.svg';
import { ReactComponent as CompanyIcon } from './company.svg';
import { ReactComponent as ProfileIcon } from './profile.svg';
import { ReactComponent as SyncsIcon } from './syncs.svg';
import { ReactComponent as FilterIcon } from './filter.svg';
import { ReactComponent as StaffIcon } from './staff.svg';
import { ReactComponent as ProcessingIcon } from './processing.svg';
import { ReactComponent as TimeIcon } from './time.svg';
import { ReactComponent as ManageIcon } from './more-info.svg';

export const iconsMap = {
  dashboard: DashboardIcon,
  people: PeopleIcon,
  payroll: PayrollIcon,
  reports: ReportsIcon,
  documents: DocumentsIcon,
  company: CompanyIcon,
  paystubs: ReportsIcon,
  info: CompanyIcon,
  profile: ProfileIcon,
  syncs: SyncsIcon,
  filter: FilterIcon,
  staff: StaffIcon,
  processing: ProcessingIcon,
  time: TimeIcon,
  manage: ManageIcon,
};

export type IconType = keyof typeof iconsMap;
