import styled from 'styled-components';

export const EmailInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 15%;
  transform: translate(-50%);
`;
