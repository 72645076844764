import { useEffect } from 'react';
import { loadCheckComponent } from 'lib/Check/loadCheckComponent';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';
import { CloseButton } from './checkComponent.styles';

type CheckComponentProps = {
  id: string;
  componentURL: string;
  onComplete?: () => void;
  onClose?: () => void;
};

export const CheckComponent = ({ id, componentURL, onComplete, onClose }: CheckComponentProps) => {
  useEffect(() => {
    loadCheckComponent(componentURL, id, onComplete);
  }, [id, componentURL]);

  return <div id={id}>{onClose && <CloseButton icon={<CloseIcon />} onClick={onClose} />}</div>;
};
