import styled from 'styled-components';

export const ModalHeader = styled.div`
  padding: 16px;
`;

export const Heading = styled.h2`
  margin: 0;
  font-size: 1.5em;
  color: ${(props) => props.theme.colors.black0};
`;

export const Instruction = styled.p`
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 400;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.black0};
  margin: 16px 0;
`;

export const ButtonContainer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.buttonContainer};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-top: 16px;
`;
