import { object, string } from 'yup';

export const miscellaneousDeductionSchema = object({
  description: string().required(),
  effectiveStart: string().required(),
  effectiveEnd: string().nullable(),
  amount: string().nullable(),
  annualLimit: string().nullable(),
  percent: string().nullable(),
  totalAmount: string().nullable(),
}).required();

export const childSupportDeductionSchema = object({
  description: string().required(),
  effectiveStart: string().required(),
  effectiveEnd: string(),
  agency: string().required(),
  amount: string().required(),
  externalId: string().required(),
  issueDate: string().required(),
  maxPercent: string().required(),
}).required();
