import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';
import { DEFAULT_PAGE_SIZE } from '@src/constants/constants';

export type Signatory = {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  providerIdentifier: string;
  title: string;
  employee: {
    name: string;
    providerIdentifier: string;
    id: string;
  };
};

export type PageInfo = {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
};

type SignatoryEdge = {
  node: Signatory;
};

type CompanySignatoriesResponse = {
  data: {
    companySignatories: {
      edges: SignatoryEdge[];
      pageInfo: PageInfo;
    };
  };
};

export const fetchCompanySignatories = (
  endCursor?: string
): Promise<{ signatories: Signatory[]; pageInfo: PageInfo | null }> => {
  const after = endCursor ? `, after: "${endCursor}"` : '';

  const query = `
        query CompanySignatories {
            companySignatories(first: ${DEFAULT_PAGE_SIZE}${after}) {
                edges {
                    node {
                        email
                        firstName
                        id
                        lastName
                        providerIdentifier
                        title
                        employee {
                            name
                            providerIdentifier
                            id
                        }
                    }
                }
                pageInfo {
                    endCursor
                    hasNextPage
                    hasPreviousPage
                    startCursor
                }
            }
        }
    `;

  return procareApi
    .post<unknown, AxiosResponse<CompanySignatoriesResponse>>('graphql', { query })
    .then((response: AxiosResponse<CompanySignatoriesResponse>) => {
      const signatories = response.data.data.companySignatories.edges.map((edge) => edge.node);
      const pageInfo = response.data.data.companySignatories.pageInfo;
      return { signatories, pageInfo };
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return { signatories: [], pageInfo: null };
    });
};
