import styled from 'styled-components';
import { IconButton } from 'components/IconButton/IconButton';
import { ReactComponent as UploadIcon } from '@src/assets/icons/upload.svg';

export const StyledDropzone = styled.div`
  border: 2px dashed ${(props) => props.theme.colors.gray0};
  border-radius: 4px;
  padding: 20px;
  text-align: center;
`;

export const ChooseFileLink = styled.span`
  color: ${(props) => props.theme.colors.primary};
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }
`;

export const FileName = styled.div`
  padding: 8px;
`;

export const ClearFileButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const FileWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const FileInfo = styled.p`
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.75;
  color: ${(props) => props.theme.colors.border2};
`;

export const StyledUploadIcon = styled(UploadIcon)`
  fill: ${(props) => props.theme.colors.accent};
  width: 60px;
`;
