import { AuthHeader } from 'components/AuthHeader/authHeader';
import { ForgotPasswordForm } from 'components/AuthForm/ForgotPasswordForm/ForgotPasswordForm';

export const ForgotPassword = () => {
  return (
    <div>
      <AuthHeader />
      <ForgotPasswordForm />
    </div>
  );
};
