const EVENTS_WITH_CALLBACKS = [
  'check-component-employee-ssn-setup-complete',
  'check-onboard-app-completed',
];

export const loadCheckComponent = (
  componentLink: string,
  componentDivId: string,
  onCompleteCallback?: () => void
) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const handler = window.CheckComponent.create({
      link: componentLink,
      appearance: {
        includeCloseButton: false,
      },
      onEvent: (event: any) => {
        if (EVENTS_WITH_CALLBACKS.includes(event)) {
          onCompleteCallback?.();
          handler.close();
        }
      },
    });
    handler.open();
    const componentElement = document.getElementById('check-component-embedded-div');
    if (componentElement) {
      const componentHolder = document.getElementById(componentDivId);
      componentHolder?.appendChild(componentElement);
    }
  } catch (err) {
    throw new Error(`Unable to create Check Component. Error: ${err}`);
  }
};
