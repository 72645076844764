import { ReactNode } from 'react';
import { GroupContainer, GroupLabel } from './formGroup.styles';

type FormGroupProps = {
  label?: string;
  children: ReactNode;
};

export const FormGroup = ({ label, children }: FormGroupProps) => (
  <GroupContainer>
    <GroupLabel>{label}</GroupLabel>
    {children}
  </GroupContainer>
);
