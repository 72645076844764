import { useState } from 'react';
import { MiscellaneousDeductionForm } from './MiscDeductionForm/MiscDeductionForm';
import { ChildSupportDeductionForm } from './ChildSupportDeductionForm/ChildSupportForm';
import { RadioInput } from 'components/form/RadioInput/RadioInput';
import { PostTaxDeductionFormContainer } from './PostTaxDeductionForm.styles';
import { Employee } from 'features/user/types';
import { PostTaxDeductionTypes } from 'lib/fetchEmployeePostTaxDeductions';

type PostTaxDeductionProps = {
  employee: Employee;
  onFormSubmit: () => void;
};

export const PostTaxDeductionForm = ({ employee, onFormSubmit }: PostTaxDeductionProps) => {
  const [selectedDeduction, setSelectedDeduction] = useState<PostTaxDeductionTypes>(
    PostTaxDeductionTypes.Miscellaneous
  );

  const radioOptions = [
    { value: PostTaxDeductionTypes.Miscellaneous, label: 'Miscellaneous Deduction' },
    { value: PostTaxDeductionTypes.ChildSupport, label: 'Child Support Garnishment' },
  ];

  return (
    <PostTaxDeductionFormContainer>
      <RadioInput
        name="deductionType"
        options={radioOptions}
        selectedValue={selectedDeduction}
        onChange={(value) => setSelectedDeduction(value as PostTaxDeductionTypes)}
      />

      {selectedDeduction === PostTaxDeductionTypes.Miscellaneous && (
        <MiscellaneousDeductionForm employee={employee} onFormSubmit={onFormSubmit} />
      )}
      {selectedDeduction === PostTaxDeductionTypes.ChildSupport && (
        <ChildSupportDeductionForm employee={employee} onFormSubmit={onFormSubmit} />
      )}
    </PostTaxDeductionFormContainer>
  );
};
