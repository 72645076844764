import { useState, useEffect, MouseEvent } from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { logout, setRole, selectEmployeeOnboarded, getCurrentUser } from 'features/user/slice';
import { selectCompanyOnboarded } from 'features/company/slice';
import { formatValue } from 'lib/utils/formatValue';
import {
  SignOutItem,
  TopBarMenu,
  MenuItemLink,
  NavItem,
  Name,
  Email,
  RoleSelector,
  NameContainer,
  Role,
  HelpLink,
  HelpItem,
  CreateButton,
  FormHeading,
  Instruction,
  CloseButton,
  FormHeader,
} from './userMenu.styles';
import { Roles } from 'features/user/types';
import { toast } from 'react-toastify';
import ReactModal from 'react-modal';
import { EmployeeForm } from 'components/EmployeeForm/employeeForm';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';

export type PrepopulatedUserData = {
  firstName: string;
  lastName: string;
  email: string;
};

export const UserMenu = () => {
  const { currentUser } = useAppSelector((state) => state.user);
  const employeeOnboarded = useAppSelector(selectEmployeeOnboarded);
  const companyOnboarded = useAppSelector(selectCompanyOnboarded);
  const dispatch = useAppDispatch();
  const signOut = () => dispatch(logout());
  const [isEmployeeModalOpen, setEmployeeModalOpen] = useState(false);
  const [prepopulatedData, setPrepopulatedData] = useState<PrepopulatedUserData | null>(null);
  const [shouldSwitchRole, setShouldSwitchRole] = useState(false);

  const handleRoleSwitch = () => {
    if (currentUser && currentUser.employee && currentUser.employee.roles) {
      const alternateRole = currentUser.employee.roles.filter(
        (r) => r.name !== currentUser.role.name
      )[0];
      if (alternateRole) {
        dispatch(setRole(alternateRole.id));
      }
    }
  };

  useEffect(() => {
    if (shouldSwitchRole && currentUser && currentUser.employee && currentUser.employee.roles) {
      handleRoleSwitch();
      setShouldSwitchRole(false);
    }
  }, [shouldSwitchRole]);

  const handleOpenEmployeeModal = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (currentUser) {
      const [firstName, lastName] = currentUser.user.name.split(' ');
      const userInfo: PrepopulatedUserData = {
        firstName: firstName || '',
        lastName: lastName || '',
        email: currentUser.user.email || '',
      };

      setPrepopulatedData(userInfo);
      setEmployeeModalOpen(true);
    }
  };

  const handleCloseEmployeeModal = () => {
    setEmployeeModalOpen(false);
  };

  const handleEmployeeFormSuccess = async () => {
    handleCloseEmployeeModal();

    try {
      await dispatch(getCurrentUser()).unwrap();
      setShouldSwitchRole(true);
      toast.success(
        'Employee profile created successfully. Navigating you to employee onboarding.'
      );
    } catch (error) {
      toast.error('Failed to update current user.');
    }
  };

  const getRoleSwitchText = () => {
    if (currentUser && currentUser.employee && currentUser.employee.roles) {
      const alternateRole = currentUser.employee.roles.filter(
        (r) => r.name !== currentUser.role.name
      )[0];
      if (alternateRole) {
        return `Switch to ${formatValue(alternateRole.name)}`;
      }
    }
    return 'Switch Role';
  };

  const getUserRoute = () => {
    if (currentUser?.role.name === Roles.Employee && employeeOnboarded) {
      return '/profile';
    } else if (companyOnboarded) {
      return '/company';
    }
  };

  const showCreateEmployeeProfile = () => {
    return (
      currentUser?.role.name === Roles.PayrollAdmin && !currentUser.employee.providerIdentifier
    );
  };

  const renderLinkContent = () => {
    if (!currentUser) return null;

    return (
      <NameContainer>
        <Name>{currentUser.user.name}</Name>
        <Role>{formatValue(currentUser.role.name)}</Role>
      </NameContainer>
    );
  };

  return currentUser ? (
    <TopBarMenu>
      <NavItem>
        {getUserRoute() ? (
          <MenuItemLink to={getUserRoute() || ''}>{renderLinkContent()}</MenuItemLink>
        ) : (
          <div>{renderLinkContent()}</div>
        )}
        <Email>{currentUser.user.email}</Email>
      </NavItem>
      {currentUser.employee &&
        currentUser.employee.roles &&
        currentUser.employee.roles.length > 1 && (
          <NavItem>
            <MenuItemLink to="/" onClick={handleRoleSwitch}>
              <RoleSelector>{getRoleSwitchText()}</RoleSelector>
            </MenuItemLink>
          </NavItem>
        )}
      {showCreateEmployeeProfile() && (
        <NavItem>
          <CreateButton onClick={handleOpenEmployeeModal}>Create Employee Profile</CreateButton>
        </NavItem>
      )}
      <HelpItem>
        <HelpLink href="https://help.procareconnect.com/en/" target="_blank">
          Help Center
        </HelpLink>
      </HelpItem>
      <SignOutItem>
        <MenuItemLink to="/" onClick={signOut}>
          LOG OUT
        </MenuItemLink>
      </SignOutItem>
      <ReactModal
        id="createEmployee"
        isOpen={isEmployeeModalOpen}
        onRequestClose={handleCloseEmployeeModal}
        style={{
          overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 11,
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            borderRadius: '16px',
            overflow: 'auto',
            height: '90vh',
            width: '60vw',
          },
        }}
      >
        <FormHeader>
          <FormHeading>
            Create Employee Profile
            <Instruction>
              Please provide some basic info about yourself to complete your employee onboarding.
            </Instruction>
          </FormHeading>
          <CloseButton icon={<CloseIcon />} onClick={handleCloseEmployeeModal} />
        </FormHeader>

        <EmployeeForm
          prepopulatedData={prepopulatedData}
          onSuccessfulSubmit={handleEmployeeFormSuccess}
          admin={true}
        />
      </ReactModal>
    </TopBarMenu>
  ) : (
    <></>
  );
};
