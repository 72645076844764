import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { useNavigate } from 'react-router-dom';
import { ContentContainer, PageContainer } from 'components/SharedComponents/Containers.styles';
import { AddEmployeeNotification } from 'components/DashboardNotifications/AddEmployeeNotification/addEmployeeNotification';
import { NavCardList } from 'components/NavCardsList/NavCardList';
import { ActionButton } from 'components/ActionButton/actionButton';
import { adminNavData, employerNavData } from '@src/constants/navData';
import { selectCurrentCompany, getCurrentCompany } from 'features/company/slice';
import { Roles } from 'features/user/types';
import { Company } from 'features/company/types';
import { ActionBar } from './CompanyDashboard.styles';
import { ActionItemsNotification } from 'components/DashboardNotifications/ActionItemsNotification/ActionItemsNotification';

export const CompanyDashboard = () => {
  const { currentUser } = useAppSelector((state) => state.user);
  const company: Company | null = useAppSelector(selectCurrentCompany);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentUser?.role.name !== Roles.Employee) {
      dispatch(getCurrentCompany());
    }
  }, [dispatch, currentUser]);

  const selectedNavData =
    currentUser?.role.name === Roles.Employer ? employerNavData : adminNavData;

  const handleClick = () => {
    navigate('/people', { state: { triggerImport: true } });
  };

  return (
    <PageContainer>
      {company && company.incomingEmployeesCount > 0 ? (
        <ContentContainer noHeight={true}>
          <ActionItemsNotification count={company.incomingEmployeesCount} />
        </ContentContainer>
      ) : (
        <>
          <ActionBar>
            <ActionButton
              onClick={handleClick}
              size="medium"
              title="Add Employees"
              hidden={false}
            />
          </ActionBar>
          <ContentContainer noHeight={true}>
            <AddEmployeeNotification />
          </ContentContainer>
        </>
      )}
      <ContentContainer noHeight={true}>
        <NavCardList navData={selectedNavData} />
      </ContentContainer>
    </PageContainer>
  );
};
