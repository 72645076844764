import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from './sendErrorMessage';

type EmployeesWithTimecardsInput = {
  startDate: string;
  endDate: string;
  employeeIds: string[];
};

type Error = {
  message: string;
  type: string;
};

type RunTimecardsImportPayload = {
  completed: boolean;
  error?: Error;
  importErrorsCount?: number;
};

export const runTimecardsImport = async (
  importInput: EmployeesWithTimecardsInput
): Promise<boolean> => {
  const mutation = `
    mutation RunTimecardsImport($importInput: EmployeesWithTimecardsInput!) {
      runTimecardsImport(importInput: $importInput) {
        completed
        error {
          message
          type
        }
        importErrorsCount
      }
    }
  `;

  const variables = {
    importInput,
  };

  try {
    const response = await procareApi.post<
      unknown,
      AxiosResponse<{ data: { runTimecardsImport: RunTimecardsImportPayload } }>
    >('graphql', {
      query: mutation,
      variables,
    });

    const { completed, error, importErrorsCount } = response.data.data.runTimecardsImport;
    if (error && error.message) {
      sendErrorMessage(error);
      throw new Error(
        `Timecards import failed: ${error.message} with ${importErrorsCount} errors.`
      );
    }
    if (!completed) {
      throw new Error('Timecards import reported incomplete without specific errors.');
    }
    return completed;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      sendErrorMessage(error.response.data as APIError);
    }
    throw error;
  }
};
