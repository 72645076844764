import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IconButton } from 'components/IconButton/IconButton';

export const TopBarMenu = styled.ul`
  width: 260px;
  margin-left: -18px;
  margin-right: -18px;
  margin-bottom: -16px;
`;

const MenuItem = styled.li`
  cursor: default;
  a {
    color: ${(props) => props.theme.colors.primary};
    font-family: ${(props) => props.theme.fontFamily.default};
    text-decoration: none;
  }

  &:hover {
    a {
      color: ${(props) => props.theme.colors.primaryDark};
    }
  }
`;

export const MenuItemLink = styled(Link)`
  display: block;
  cursor: pointer;
`;

export const HelpLink = styled.a`
  display: block;
  text-transform: uppercase;
`;

export const NavItem = styled(MenuItem)`
  padding: 8px 0 14px;
  margin-left: 24px;
  margin-right: 24px;
`;

export const HelpItem = styled(NavItem)`
  padding-top: 0;
`;

export const RoleSelector = styled.span`
  margin-bottom: 5px;
  display: block;
  text-transform: uppercase;
`;

export const NameContainer = styled.span`
  display: flex;
  justify-content: space-between;
`;

export const Role = styled.span`
  font-size: 0.65em;
  color: ${(props) => props.theme.colors.black};
  font-weight: 500;
  text-transform: capitalize;
`;

export const Name = styled.span`
  font-size: 1.1em;
  margin-bottom: 5px;
  display: inline-block;
  color: ${(props) => props.theme.colors.primary};
`;

export const Email = styled.span`
  display: block;
  font-size: 0.65em;
  color: ${(props) => props.theme.colors.primaryDark};
  word-break: break-word;
`;

export const ProfileLink = styled.p`
  margin: 0;
  text-transform: uppercase;
`;

export const SignOutItem = styled(MenuItem)`
  margin-left: 2px;
  background: ${(props) => props.theme.colors.gray6};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 1em;

  a {
    display: block;
    padding: 19px 0 18px 24px;
  }
`;

export const CreateButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: 1em;
  text-transform: uppercase;
  padding: 0;

  &:hover {
    color: ${(props) => props.theme.colors.primaryDark};
  }
`;

export const FormHeading = styled.h3`
  font-size: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
  margin: 10px 0 0;
  padding-bottom: 20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray6};
`;

export const Instruction = styled.div`
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 400;
  color: ${(props) => props.theme.colors.black0};
  line-height: 1.5;
  padding-top: 16px;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  z-index: 10;
  top: 8px;
  right: 8px;
`;

export const FormHeader = styled.div`
  display: flex;
`;
