import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { PayScheduleFormData as PayScheduleInput } from 'components/PayScheduleForm/types';
import { APIError, sendErrorMessage } from './sendErrorMessage';

type PaySchedule = {
  name: string;
  providerIdentifier: string;
  id: string;
};

type CreatePaySchedulePayload = {
  paySchedule: PaySchedule;
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

export const createPaySchedule = (
  payScheduleParams: PayScheduleInput
): Promise<PaySchedule | void> => {
  const mutation = `
        mutation($payScheduleParams: CreatePayScheduleInput!) {
          createPaySchedule(payScheduleInput: $payScheduleParams) {
            paySchedule {
              name
              providerIdentifier
              id
            }
            error {
              message
              type
              inputErrors {
                field
                fieldPath
                message
              }
            }
          }
        }
      `;

  const variables = {
    payScheduleParams,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { createPaySchedule: CreatePaySchedulePayload } }>>(
      'graphql',
      {
        query: mutation,
        variables,
      }
    )
    .then((response: AxiosResponse<{ data: { createPaySchedule: CreatePaySchedulePayload } }>) => {
      if (response.data.data.createPaySchedule.error) {
        sendErrorMessage(response.data.data.createPaySchedule.error);
      }
      return response.data.data.createPaySchedule.paySchedule;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
