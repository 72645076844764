import styled from 'styled-components';
import { IconButton } from 'components/IconButton/IconButton';

export const CloseButton = styled(IconButton)`
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
`;

export const FormContainer = styled.div``;

export const FormHeading = styled.h3`
  font-size: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
  margin: 0;
  padding-bottom: 10px;
  text-align: center;
`;

export const FormNote = styled.p`
  font-size: 12px;
  text-align: center;
  margin: 0 0 12px;
`;
