import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendAuthErrorMessage } from './sendAuthErrorMessage';

export const updateUserPassword = (newPassword: string): Promise<AxiosResponse<any> | void> => {
  const payload = {
    password: newPassword,
  };

  return procareApi
    .put(`/api/v1/users`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .catch((error: AxiosError<ErrorResponse>) => {
      sendAuthErrorMessage(error);
    });
};
