/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from 'axios';
import { getAccessToken } from '../token';
import { procareApiUrl } from '@src/config';
import { refreshTokens } from 'features/user/service';
import { eventEmitter } from 'lib/utils/eventEmitter';

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

export type ErrorResponse = {
  errors: string[];
  message: string;
};

export type Interceptor = {
  onRejected?: ((error: any) => any) | null;
  onFulfilled?: ((value: any) => any | Promise<any>) | null;
};

export type AxiosInterceptors = {
  request?: Interceptor[];
  response?: Interceptor[];
};

export type ClientConfig = {
  options?: CreateAxiosDefaults;
  interceptors?: AxiosInterceptors;
};

const defaultConfig: CreateAxiosDefaults = {
  baseURL: procareApiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export class HttpClient {
  private instance: AxiosInstance | null = null;

  private config: CreateAxiosDefaults | undefined;

  private interceptors: AxiosInterceptors = { request: [], response: [] };

  constructor(config?: ClientConfig) {
    if (config) {
      this.config = { ...defaultConfig, ...config.options };
      this.interceptors = config.interceptors || { request: [], response: [] };
    }
  }

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  assignInterceptorsFor(http: AxiosInstance) {
    if (this.interceptors.request) {
      this.interceptors.request.forEach((reqInterceptor) => {
        http.interceptors.request.use(reqInterceptor.onFulfilled, reqInterceptor.onRejected);
      });
    }

    if (this.interceptors.response) {
      this.interceptors.response.forEach((respInterceptor) => {
        http.interceptors.response.use(respInterceptor.onFulfilled, respInterceptor.onRejected);
      });
    }
  }

  initHttp() {
    const http = axios.create(this.config);

    http.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError<ErrorResponse>) => {
        if (error.response?.status === 401 && error.response?.data.message === 'Token is expired') {
          return refreshTokens(error);
        }

        if (
          error.response?.status === 401 &&
          error.response?.data.message === 'Refresh Token Expired'
        ) {
          eventEmitter.emit('REFRESH_TOKEN_EXPIRED');
          return;
        }

        return this.handleError(error);
      }
    );

    this.instance = http;

    this.assignInterceptorsFor(http);

    return http;
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  // TODO: Handle global errors
  private handleError(error: AxiosError<ErrorResponse>) {
    const { response } = error;

    switch (response?.status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCode.Forbidden: {
        // Handle Forbidden
        break;
      }
      case StatusCode.Unauthorized: {
        // Handle Unauthorized
        break;
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
    }

    return Promise.reject(error);
  }
}

// We will have different API clients later with their own auth system
export const procareApi = new HttpClient({
  interceptors: {
    request: [
      {
        onFulfilled: (
          request: InternalAxiosRequestConfig<any>
        ): InternalAxiosRequestConfig<any> => {
          const token = getAccessToken();

          if (token != null) {
            request.headers.set('Authorization', `Bearer ${token}`);
          }

          return request;
        },
        onRejected: (error) => Promise.reject(error),
      },
    ],
  },
});
