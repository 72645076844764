import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { EmployeeOnboarding } from 'components/EmployeeOnboarding/employeeOnboarding';
import { EmployeeDashboard } from 'components/EmployeeDashboard/EmployeeDashboard';
import { fetchEmployeeOnboardingStatus } from 'lib/fetchEmployeeOnboardingStatus';
import {
  selectEmployeeOnboarded,
  setEmployeeOnboarded,
  selectCurrentUser,
} from 'features/user/slice';

export const EmployeeView = () => {
  const dispatch = useAppDispatch();
  const isOnboarded = useAppSelector(selectEmployeeOnboarded);
  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    if (!currentUser) return;

    fetchEmployeeOnboardingStatus()
      .then((status) => {
        dispatch(setEmployeeOnboarded(status));
      })
      .catch(() => {
        dispatch(setEmployeeOnboarded(false));
      });
  }, [dispatch, currentUser]);

  return isOnboarded ? <EmployeeDashboard /> : <EmployeeOnboarding />;
};
