import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

type CompanyOnboardResponse = {
  data: {
    companyOnboardComponent: {
      url: string;
    };
  };
};

export const fetchCompanyOnboarding = (signatoryId: string) => {
  const query = `
    query {
      companyOnboardComponent(input: { signatory: "${signatoryId}" }) {
        url
      }
    }
  `;
  return procareApi
    .post<unknown, AxiosResponse<CompanyOnboardResponse>>('graphql', { query })
    .then((response: AxiosResponse<CompanyOnboardResponse>) => {
      return response.data.data.companyOnboardComponent.url;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
