import { ComponentType, ElementType } from 'react';
import { StyledNavLink } from './navBlock.styles';

type ItemLinkProps<P = {}> = {
  as?: ComponentType<P> | ElementType;
} & P;

export const ItemLink = <P = {},>({
  as: Component = StyledNavLink,
  ...props
}: ItemLinkProps<P>) => {
  return <Component {...props} />;
};
