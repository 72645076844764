import styled from 'styled-components';

export const SubHeading = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: 16px;
  margin: 8px 0;
`;

export const HeadingMessage = styled.div`
  font-size: ${(props) => props.theme.fontSizes.normal};
  margin-bottom: 16px;
`;
