import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { ellipsize, lightShadow } from 'styles/mixins';
import { Link } from 'react-router-dom';
import { ReactComponent as ProcareLogo } from '../../assets/images/logo.svg';

export const TopbarHeader = styled.header`
  display: flex;
  width: 100%;
  top: 0;
  height: ${(props) => props.theme.dimensions.headerHeight};
  padding: 0 ${(props) => props.theme.padding.lg};
  padding-left: 0;
  position: fixed;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  ${lightShadow}
`;

export const TopbarLeft = styled.div`
  display: flex;
  flex-grow: 4;
  align-items: center;
`;

export const TopbarRight = styled.div``;

export const TopbarLogoLink = styled(Link)``;

export const TopbarLogo = styled(ProcareLogo)`
  width: 160px;
  min-width: 160px;
  height: 72px;
  padding: 0px 15px 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${down('desktop')} {
    width: ${(props) => props.theme.dimensions.asideWidthTablet} - 2 * 10px;
    background-size: 100px;
    background-position: left center;
    padding: 0;
    margin: 0 10px;
    min-width: 0;
  }
`;

export const TopbarWelcome = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-top: ${(props) => props.theme.padding.sm};
  margin-bottom: 6px;
  margin-right: 20px;
  font-size: 1.4em;
  color: #666;

  ${down('tablet')} {
    display: block;
    font-size: 1.2em;
  }
`;

export const TopbarWelcomeSchool = styled.div`
  color: ${(props) => props.theme.colors.black0};
  flex-shrink: 0;
`;

export const TopbarWelcomeSchoolInner = styled.div`
  display: flex;
  align-items: center;
`;

export const TopbarWelcomeSchoolText = styled.div`
  ${ellipsize}
`;

export const TopbarWelcomeMorePower = styled.div`
  min-height: 20px;
  font-weight: 400;
  white-space: nowrap;
  flex-shrink: 1;
  flex-grow: 1;

  ${down('tablet')} {
    display: inline-block;
    margin-top: 4px;
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }
`;
