import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import { IncomingEmployeesTable } from 'components/Table/IncomingEmployeesTable/IncomingEmployeesTable';
import { Pagination } from 'components/Pagination/pagination';
import { EmployeeNode, PageInfo } from 'lib/fetchIncomingEmployees';
import { Spinner } from 'components/Spinner/spinner';
import { ActionButton } from 'components/ActionButton/actionButton';
import {
  ModalHeader,
  TableContainer,
  ButtonContainer,
  Heading,
  Instruction,
  ActionContainer,
  SearchInput,
} from './incomingEmployeesModal.styles';

type IncomingEmployeesModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  incomingEmployees: EmployeeNode[];
  onSearchChange: (searchName: string) => void;
  onSortChange: (sortDirection: 'asc' | 'desc') => void;
  sortDirection: 'asc' | 'desc';
  onEmployeeDeleted: (updatedEmployees: EmployeeNode[]) => void;
};

export const IncomingEmployeesModal = ({
  isOpen,
  onRequestClose,
  incomingEmployees,
  onSearchChange,
  onSortChange,
  sortDirection,
  onEmployeeDeleted,
}: IncomingEmployeesModalProps) => {
  const [employees, setEmployeesLocal] = useState<EmployeeNode[]>([]);
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);
  const [searchName, setSearchName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setEmployeesLocal(incomingEmployees);
    setLoading(false);
  }, [incomingEmployees]);

  const loadMore = () => {
    if (pageInfo && pageInfo.hasNextPage) {
      setEndCursor(pageInfo.endCursor);
    }
  };

  const onPrev = () => {
    if (pageInfo && pageInfo.hasPreviousPage) {
      setEndCursor(pageInfo.startCursor);
    }
  };

  const onNext = () => {
    if (pageInfo && pageInfo.hasNextPage) {
      loadMore();
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchName(value);
    onSearchChange(value);
  };

  return (
    <ReactModal
      style={{
        overlay: {
          position: 'fixed',
          inset: '0px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 11,
        },
        content: {
          top: '45%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          borderRadius: '16px',
          overflow: 'visible',
          padding: '0px',
          width: '1080px',
        },
      }}
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose();
      }}
    >
      <ModalHeader>
        <Heading>Correct Missing Information</Heading>
        <Instruction>
          Fill in your employee&apos;s outstanding information. Once you save your updates, an
          onboarding email will be generated and sent to your employee&apos;s email.
          <br />
          <br />
          Employees without an account (e.g., custodians) can be manually added in a few short
          steps.
        </Instruction>
      </ModalHeader>
      <TableContainer>
        <ActionContainer>
          <SearchInput
            type="text"
            value={searchName}
            onChange={handleSearchChange}
            placeholder="Search by name"
          />
          <ActionButton
            onClick={() => navigate('/people/add')}
            size="medium"
            variant="outline"
            title="Add Manually"
            hidden={false}
          />
        </ActionContainer>

        {loading ? (
          <Spinner />
        ) : (
          <IncomingEmployeesTable
            employees={employees}
            onSortChange={onSortChange}
            sortDirection={sortDirection}
            setEmployees={setEmployeesLocal}
            onEmployeeDeleted={onEmployeeDeleted}
          />
        )}
      </TableContainer>
      {pageInfo && (pageInfo.hasPreviousPage || pageInfo.hasNextPage) && (
        <Pagination
          onPrev={onPrev}
          onNext={onNext}
          disablePrev={!pageInfo.hasPreviousPage}
          disableNext={!pageInfo.hasNextPage}
        />
      )}
      <ButtonContainer>
        <ActionButton
          onClick={() => {
            onRequestClose();
          }}
          size="medium"
          title="Done"
          hidden={false}
        />
      </ButtonContainer>
    </ReactModal>
  );
};
