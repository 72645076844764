import { useEffect, useState } from 'react';
import { Table } from './Table';
import { fetchImportErrors, ImportErrorLog, PageInfo } from 'lib/fetchImportErrors';
import { Spinner } from 'components/Spinner/spinner';
import { Pagination } from 'components/Pagination/pagination';

export const SyncErrorsTable = () => {
  const [importErrors, setImportErrors] = useState<ImportErrorLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);

  const loadMore = () => {
    if (pageInfo && pageInfo.hasNextPage) {
      setEndCursor(pageInfo.endCursor);
    }
  };

  const onPrev = () => {
    if (pageInfo && pageInfo.hasPreviousPage) {
      setEndCursor(pageInfo.startCursor);
    }
  };

  const onNext = () => {
    if (pageInfo && pageInfo.hasNextPage) {
      loadMore();
    }
  };

  useEffect(() => {
    fetchImportErrors(endCursor).then(
      ({ importErrors: newImportErrors, pageInfo: newPageInfo }) => {
        setImportErrors(newImportErrors);
        setPageInfo(newPageInfo);
        setLoading(false);
      }
    );
  }, [endCursor]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      {pageInfo && (pageInfo.hasPreviousPage || pageInfo.hasNextPage) && (
        <Pagination
          onPrev={onPrev}
          onNext={onNext}
          disablePrev={!pageInfo.hasPreviousPage}
          disableNext={!pageInfo.hasNextPage}
        />
      )}
      <Table importErrors={importErrors} />
    </>
  );
};
