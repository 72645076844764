import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { signatorySchema } from './validators';
import { SignatoryInput } from './types';
import { Form, FormGroup, SignatoryFormContainer, ButtonContainer } from './SignatoryForm.styles';
import { FormInput } from 'components/form';
import { AuthorizeCheck } from '../AuthorizeCheck/AuthorizeCheck';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { signatory } from 'features/user/slice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const SignatoryForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SignatoryInput>({
    resolver: yupResolver(signatorySchema),
  });
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = () => {
    setIsChecked((prevState) => !prevState);
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<SignatoryInput> = async (data: SignatoryInput) => {
    if (Object.keys(errors).length == 0) {
      const resultAction = await dispatch(signatory(data));
      const signatoryResult = unwrapResult(resultAction);

      if (signatoryResult) {
        toast.success('Signatory has been successfully created.');
        navigate('/onboarding');
      }
    }
  };

  const isButtonDisabled = (): boolean => {
    const email = watch('email');
    const title = watch('title');
    const firstName = watch('firstName');
    const lastName = watch('lastName');
    const checkboxValue = isChecked;

    return !email || !title || !firstName || !lastName || !checkboxValue;
  };

  return (
    <SignatoryFormContainer>
      <Form>
        <FormGroup>
          <FormInput
            id="firstName"
            placeholder="First Name"
            type="text"
            label="First Name"
            required
            {...register('firstName')}
            error={errors.firstName?.message}
          />
          <FormInput
            id="lastName"
            placeholder="Last Name"
            type="text"
            label="Last Name"
            required
            {...register('lastName')}
            error={errors.lastName?.message}
          />
        </FormGroup>
        <FormGroup>
          <FormInput
            id="title"
            placeholder="Title"
            type="text"
            label="Title"
            required
            {...register('title')}
            error={errors.title?.message}
          />
          <FormInput
            id="email"
            placeholder="Email"
            type="email"
            label="Email"
            required
            {...register('email')}
            error={errors.email?.message}
          />
        </FormGroup>
      </Form>
      <AuthorizeCheck id="signatory" isChecked={isChecked} toggleCheckbox={toggleCheckbox} />
      <ButtonContainer>
        <ActionButton
          onClick={handleSubmit(onSubmit)}
          size="medium"
          title="Continue"
          hidden={false}
          disabled={isButtonDisabled()}
        />
      </ButtonContainer>
    </SignatoryFormContainer>
  );
};
