import { StyledTable, Thead, Tr, Th, Td, Tbody, EmptyMessage } from '../../Table.styles';
import { PayrollItem } from 'lib/fetchProviderPayroll';
import { AggregateContainer, Aggregate, Total, SubHeading } from './ReviewStep.styles';

type EmployeeHours = {
  name: string;
  regularHours: number;
  overtimeHours: number;
  ptoHours: number;
  sickHours: number;
  totalWorkHours: number;
};

const calculateEmployeeHours = (items: PayrollItem[]): EmployeeHours[] => {
  return items.map((item) => {
    const regularHours = item.earnings
      .filter((earning) => earning.type === 'hourly')
      .reduce((acc, curr) => acc + curr.hours, 0);
    const overtimeHours = item.earnings
      .filter((earning) => earning.type === 'overtime')
      .reduce((acc, curr) => acc + curr.hours, 0);
    const ptoHours = item.earnings
      .filter((earning) => earning.type === 'pto')
      .reduce((acc, curr) => acc + curr.hours, 0);
    const sickHours = item.earnings
      .filter((earning) => earning.type === 'sick')
      .reduce((acc, curr) => acc + curr.hours, 0);
    const totalWorkHours = regularHours + overtimeHours + ptoHours + sickHours;

    return {
      name: item.employee.name,
      regularHours,
      overtimeHours,
      ptoHours,
      sickHours,
      totalWorkHours,
    };
  });
};

const calculateAggregateHours = (employeeHours: EmployeeHours[]) => {
  return employeeHours.reduce(
    (acc, curr) => {
      acc.regularHours += curr.regularHours;
      acc.overtimeHours += curr.overtimeHours;
      acc.ptoHours += curr.ptoHours;
      acc.sickHours += curr.sickHours;
      acc.totalWorkHours += curr.totalWorkHours;
      return acc;
    },
    {
      regularHours: 0,
      overtimeHours: 0,
      ptoHours: 0,
      sickHours: 0,
      totalWorkHours: 0,
    }
  );
};

export const EmployeeHourlyBreakdown = ({ items }: { items: PayrollItem[] }) => {
  const employeeHours = calculateEmployeeHours(items);
  const aggregateHours = calculateAggregateHours(employeeHours);

  if (employeeHours.length === 0) {
    return (
      <EmptyMessage>No employees found. Please add employees in order to run payroll.</EmptyMessage>
    );
  }

  return (
    <>
      <SubHeading>Employee Hourly Breakdown</SubHeading>
      <AggregateContainer>
        <Aggregate>
          <div>Total Regular Hours</div>
          <Total>{aggregateHours.regularHours}</Total>
        </Aggregate>
        <Aggregate>
          <div>Total Overtime Hours</div>
          <Total>{aggregateHours.overtimeHours}</Total>
        </Aggregate>
        <Aggregate>
          <div>Total PTO Hours</div>
          <Total>{aggregateHours.ptoHours}</Total>
        </Aggregate>
        <Aggregate>
          <div>Total Sick Hours</div>
          <Total>{aggregateHours.sickHours}</Total>
        </Aggregate>
        <Aggregate>
          <div>Total Work Hours</div>
          <Total>{aggregateHours.totalWorkHours}</Total>
        </Aggregate>
      </AggregateContainer>
      <StyledTable>
        <Thead>
          <Tr>
            <Th>Employee</Th>
            <Th>Regular Hours</Th>
            <Th>OverTime</Th>
            <Th>PTO</Th>
            <Th>Sick</Th>
            <Th>Total Work Hours</Th>
          </Tr>
        </Thead>
        <Tbody>
          {employeeHours.map((employee, index) => (
            <Tr key={index}>
              <Td>{employee.name}</Td>
              <Td>{employee.regularHours}</Td>
              <Td>{employee.overtimeHours}</Td>
              <Td>{employee.ptoHours}</Td>
              <Td>{employee.sickHours}</Td>
              <Td>{employee.totalWorkHours}</Td>
            </Tr>
          ))}
        </Tbody>
      </StyledTable>
    </>
  );
};
