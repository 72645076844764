import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddressFormData } from './types';
import { addressSchema } from './validators';
import { FormInput, FormGroup } from 'components/form';
import { ActionButton } from 'components/ActionButton/actionButton';
import { Address } from 'features/user/types';
import { IncomingAddress } from 'lib/fetchIncomingEmployees';
import { AddressFormContainer, Form, ButtonContainer } from './EmployeeAddressForm.styles';

type EmployeeAddressFormProps = {
  address?: Address | IncomingAddress;
  handleFormSubmit: (address: AddressFormData) => void;
};

export const EmployeeAddressForm = ({ address, handleFormSubmit }: EmployeeAddressFormProps) => {
  const defaultValues: AddressFormData = {
    line1: address?.line1 || '',
    line2: address?.line2 || '',
    city: address?.city || '',
    state: address?.state || '',
    postalCode: address?.postalCode || '',
  };

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<AddressFormData>({
    resolver: yupResolver(addressSchema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<AddressFormData> = (data: AddressFormData) => {
    handleFormSubmit(data);
  };

  const isButtonDisabled = (): boolean =>
    !watch('line1') || !watch('city') || !watch('state') || !watch('postalCode');

  return (
    <AddressFormContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup label="Address">
          <FormInput
            id="line1"
            placeholder="Street Address Line 1"
            type="text"
            required
            {...register('line1')}
          />
          <FormInput
            id="line2"
            placeholder="Street Address Line 2"
            type="text"
            {...register('line2')}
          />
          <FormInput id="city" placeholder="City" type="text" required {...register('city')} />
          <FormInput id="state" placeholder="State" type="text" required {...register('state')} />
          <FormInput
            id="postalCode"
            placeholder="Postal Code"
            type="text"
            required
            {...register('postalCode')}
          />
        </FormGroup>
        <ButtonContainer>
          <ActionButton
            size="medium"
            title="Save"
            hidden={false}
            disabled={isButtonDisabled()}
            shape="fullWidth"
            onClick={handleSubmit(onSubmit)}
          />
        </ButtonContainer>
      </Form>
    </AddressFormContainer>
  );
};
