import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from './sendErrorMessage';
import { Employee } from 'features/user/types';

export type ProviderEmployeeInput = {
  providerIdentifier: string;
};

export type OnboardingEmailResponse = {
  employee: Employee;
};

export const sendOnboardingEmail = (
  onboardingEmailParams: ProviderEmployeeInput
): Promise<Employee | void> => {
  const mutation = `
            mutation($employeeInput: ProviderEmployeeInput!) {
              sendOnboardingEmail(employeeInput: $employeeInput) {
                employee {
                  id
                  name
                  platformIdentifier
                  providerIdentifier
                }
              }
            }
          `;

  const variables = {
    employeeInput: onboardingEmailParams,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { sendOnboardingEmail: OnboardingEmailResponse } }>>(
      'graphql',
      {
        query: mutation,
        variables,
      }
    )
    .then((response: AxiosResponse<{ data: { sendOnboardingEmail: OnboardingEmailResponse } }>) => {
      return response.data.data.sendOnboardingEmail.employee;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
