import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

type InputError = {
  message: string;
  field: string;
};

type ErrorResponse = {
  message: string;
  input_errors?: InputError[];
};

export const sendAuthErrorMessage = (error: AxiosError<ErrorResponse>) => {
  const { response, message } = error;
  if (response?.data?.input_errors) {
    const inputErrors = response.data.input_errors;

    inputErrors.forEach((inputError) => {
      toast.error(`${inputError.field}: ${inputError.message}`);
    });
  } else {
    const errorMessage = response ? response.data.message : message;
    toast.error(errorMessage);
  }
};
