import styled from 'styled-components';
import { GroupContainer, GroupLabel } from 'components/form/FormGroup/formGroup.styles';
import { Select } from 'components/form/Select/select.styles';

export const UpdateEmployeeFormContainer = styled.div`
  padding: 0px 20px 10px;

  ${GroupContainer} {
    padding: 0;
  }

  ${GroupLabel} {
    font-size: ${(props) => props.theme.fontSizes.normal};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
  }

  ${Select} {
    margin: 0;
  }
`;

export const UpdateForm = styled.form`
  margin: 10px auto 0;
  min-width: 400px;
`;

export const FormInputGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UpdateButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
