import { OnboardingInfo } from 'components/OnboardingInfo/OnboardingInfo';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';

export const Onboarding = () => {
  return (
    <ContentContainer onboarding={true} contentAlign="center">
      <OnboardingInfo />
    </ContentContainer>
  );
};
