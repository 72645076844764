import { useEffect, useState } from 'react';
import { ActionButton } from 'components/ActionButton/actionButton';
import { fetchPaySchedule, PaySchedule as PayScheduleType } from 'lib/fetchPaySchedule';
import { Spinner } from 'components/Spinner/spinner';
import { sendErrorMessage } from 'lib/sendErrorMessage';
import { Modal } from 'components/Modal/Modal';
import { UpdatePayScheduleForm } from 'components/PayScheduleForm/UpdatePayScheduleForm/updatePayScheduleForm';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import {
  TabContainer,
  TabContent,
  TabHeader,
  TabHeading,
  ItemContainer,
  ItemLabel,
  ItemValue,
} from '../../../tabMenu.styles';
import { EditFormContainer, FormHeading, CloseButton } from './paySchedule.styles';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';

export const PaySchedule = () => {
  const [paySchedule, setPaySchedule] = useState<PayScheduleType | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    fetchPaySchedule()
      .then((data) => setPaySchedule(data))
      .catch((error) => {
        sendErrorMessage(error);
      });
  }, []);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFormSubmit = (updatedPaySchedule: PayScheduleType) => {
    setPaySchedule(updatedPaySchedule);
    handleCloseModal();
  };

  return (
    <TabContainer>
      <TabHeader type="company">
        <ActionButton onClick={handleOpenModal} size="medium" title="Edit" hidden={false} />
      </TabHeader>
      {paySchedule && (
        <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <EditFormContainer>
            <FormHeading>Edit Pay Schedule</FormHeading>
            <UpdatePayScheduleForm
              id={paySchedule.id}
              paySchedule={paySchedule}
              handleFormSubmit={handleFormSubmit}
            />
            <CloseButton icon={<CloseIcon />} onClick={handleCloseModal} />
          </EditFormContainer>
        </Modal>
      )}
      <ContentContainer contentAlign="left">
        <TabContent>
          {paySchedule ? (
            <>
              <ItemContainer>
                <ItemLabel>Pay Frequency</ItemLabel>
                <ItemValue>{paySchedule.payFrequency}</ItemValue>
              </ItemContainer>
              <ItemContainer>
                <ItemLabel>Next Payday</ItemLabel>
                <ItemValue>{paySchedule.nextPayday.payday}</ItemValue>
              </ItemContainer>
            </>
          ) : (
            <Spinner />
          )}
        </TabContent>
      </ContentContainer>
    </TabContainer>
  );
};
