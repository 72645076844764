import { NotificationContainer } from '../notification.styles';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useNavigate } from 'react-router-dom';
import EmptyStatePlaceholder from 'components/Placeholder/EmptyStatePlaceholder';

export const AddEmployeeNotification = () => {
  const navigate = useNavigate();
  return (
    <NotificationContainer>
      <EmptyStatePlaceholder
        title="Add Your Employees"
        description="Let's onboard your employees so you can start running payroll."
        steps={[
          {
            iconName: 'staff',
            text: 'Seamlessly import current staff profiles ensuring a smooth and quick onboarding process for all employees.',
          },
          {
            iconName: 'processing',
            text: 'Simplify payroll processing and reduce your workload by managing all employee pay information in one place.',
          },
          {
            iconName: 'time',
            text: 'Automate calculations to save time on complex payroll tasks like taxes and deductions, guaranteeing accuracy and efficiency.',
          },
        ]}
      />
    </NotificationContainer>
  );
};
