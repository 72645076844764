import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from './sendErrorMessage';

type RoleInput = {
  employeeId: string;
  roleName: string;
};

type RolePayload = {
  role: {
    id: string;
    name: string;
  };
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

export const removeRole = (roleAttributes: RoleInput): Promise<RolePayload['role'] | void> => {
  const mutation = `
        mutation($roleParams: EmployeeRoleInput!) {
          removeRole(employeeRoleInput: $roleParams) {
            role {
              id
              name
            }
            error {
              message
              type
              inputErrors {
                field
                fieldPath
                message
              }
            }
          }
        }
      `;

  const variables = {
    roleParams: roleAttributes,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { removeRole: RolePayload } }>>('graphql', {
      query: mutation,
      variables,
    })
    .then((response: AxiosResponse<{ data: { removeRole: RolePayload } }>) => {
      return response.data.data.removeRole.role;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
