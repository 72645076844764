import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from 'lib/sendErrorMessage';
import { DEFAULT_PAGE_SIZE } from '@src/constants/constants';

export type IncomingAddress = {
  city: string | null;
  country: string | null;
  line1: string | null;
  line2: string | null;
  postalCode: string | null;
  state: string | null;
};

export type EmployeeNode = {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  active: boolean;
  dateOfBirth: string | null;
  address: IncomingAddress | null;
  employee: {
    id: string | null;
  } | null;
  workplace: {
    id: string | null;
  } | null;
  company: {
    id: string | null;
  } | null;
  platformIdentifier: string | null;
};

type EmployeeEdge = {
  node: EmployeeNode;
};

export type PageInfo = {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
};

type IncomingEmployeesResponse = {
  data: {
    incomingEmployees: {
      edges: EmployeeEdge[];
      pageInfo: PageInfo;
    };
  };
};

export const fetchIncomingEmployees = (
  pageSize: number = DEFAULT_PAGE_SIZE,
  endCursor?: string,
  searchName = '',
  sortField = 'last_name',
  sortDirection: 'asc' | 'desc' = 'asc'
): Promise<{ employees: EmployeeNode[]; pageInfo: PageInfo | null }> => {
  const after = endCursor ? `, after: "${endCursor}"` : '';

  const query = `
    query IncomingEmployees {
      incomingEmployees(
        first: ${pageSize}${after},
        sortingInput: { direction: ${sortDirection}, field: "${sortField}" },
        incomingEmployeeInput: { name: "${searchName}" }
      ) {
        edges {
          node {
            email
            firstName
            id
            lastName
            dateOfBirth
            address {
              city
              country
              line1
              line2
              postalCode
              state
            }
            employee {
              id
            }
            workplace {
              id
            }
            company {
              id
            }
            platformIdentifier
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<IncomingEmployeesResponse>>('graphql', { query })
    .then((response: AxiosResponse<IncomingEmployeesResponse>) => {
      const employees = response.data.data.incomingEmployees.edges.map((edge) => edge.node);
      const pageInfo = response.data.data.incomingEmployees.pageInfo;
      return { employees, pageInfo };
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return { employees: [], pageInfo: null };
    });
};
