import { AccountCreationMessage, InstructionMessage } from './accountCreated.styles';
import { AuthHeader } from 'components/AuthHeader/authHeader';

export const AccountCreated = () => {
  return (
    <>
      <AuthHeader />
      <AccountCreationMessage>Account Created!</AccountCreationMessage>
      <InstructionMessage>Please check your email for further instructions.</InstructionMessage>
    </>
  );
};
