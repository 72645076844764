import React, { useState, useEffect, useRef } from 'react';
import { Icon } from 'components/Icon/icon';
import {
  TopBarUser,
  UserDiv,
  UserInitials,
  UserPlaceholder,
  TooltipDiv,
} from './userNavItem.styles';
import { checkValidUrl } from 'lib/utils/checkValidUrl';

type UserProps = {
  url?: string;
  name?: string;
  size?: 'small' | 'medium' | 'large';
  tooltipContent?: React.ReactNode;
};

export const UserNavItem = ({ url, size = 'small', tooltipContent }: UserProps) => {
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const modal = document.getElementById('createEmployee');

    if (
      ref.current &&
      !ref.current.contains(event.target as Node) &&
      (!modal || !modal.contains(event.target as Node))
    ) {
      setIsTooltipVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (url) {
      checkValidUrl(url).then((isValid) => {
        setIsValidUrl(isValid);
      });
    }
  }, [url]);

  return (
    <TopBarUser>
      <UserDiv
        className={`user--${size}`}
        ref={ref}
        onClick={() => setIsTooltipVisible(!isTooltipVisible)}
        size={size}
        id="userNav"
      >
        <UserInitials>
          {url && isValidUrl ? (
            <div className="user__picture" style={{ backgroundImage: `url('${url}')` }} />
          ) : (
            <UserPlaceholder>
              <Icon name="profile" />
            </UserPlaceholder>
          )}
        </UserInitials>

        {tooltipContent && isTooltipVisible && (
          <TooltipDiv>
            <div className="user__tooltip-title">{tooltipContent}</div>
          </TooltipDiv>
        )}
      </UserDiv>
    </TopBarUser>
  );
};
