import styled from 'styled-components';
import { GroupLabel } from 'components/form/FormGroup/formGroup.styles';

export const UpdateCompanyInfoFormContainer = styled.div`
  padding: 0px 20px 5px;

  ${GroupLabel} {
    font-size: ${(props) => props.theme.fontSizes.normal};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
  }
`;

export const UpdateForm = styled.form`
  margin: 0 auto;
  min-width: 400px;
`;

export const UpdateButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FormSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormAddress = styled.div`
  line-height: 1.5;
`;
