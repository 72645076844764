import './spinner.scss';

export interface SpinnerProps {
  centered?: boolean;
}

export const Spinner = (props: SpinnerProps) => (
  <div
    aria-label="Loading"
    role="progressbar"
    className={props.centered ? 'loader loader__centered' : 'loader'}
  ></div>
);
