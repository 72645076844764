import { OnboardAccountForm } from 'components/AuthForm/OnboardAccountForm/onboardAccountForm';
import { AuthHeader } from 'components/AuthHeader/authHeader';

export const Onboard = () => {
  return (
    <>
      <AuthHeader />
      <OnboardAccountForm />
    </>
  );
};
