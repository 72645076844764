import { downloadCompanyDocument } from 'lib/downloadCompanyDocuments';
import { DocumentType } from 'lib/fetchCompanyDocuments';

export const downloadDocument = async (docType: DocumentType, docId: string) => {
  const documentData = await downloadCompanyDocument(docType, docId);
  if (documentData) {
    const url = window.URL.createObjectURL(new Blob([documentData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `document_${docId}.pdf`);
    document.body.appendChild(link);
    link.click();
  }
};
