import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/redux';

import { NavBlock } from './NavBlock/navBlock';
import { NavItem, adminNavData, employeeNavData, employerNavData } from '@src/constants/navData';
import { selectCompanyOnboardedStatus } from 'features/company/slice';
import { selectEmployeeOnboarded } from 'features/user/slice';
import { Roles } from 'features/user/types';

import { SidebarBackground, SidebarContainer, SidebarNav } from './sidebar.styles';

export const Sidebar = () => {
  const { currentUser } = useAppSelector((state) => state.user);
  const isCompanyOnboarded = useAppSelector(selectCompanyOnboardedStatus);
  const isEmployeeOnboarded = useAppSelector(selectEmployeeOnboarded);
  const [navItems, setNav] = useState<NavItem[]>([]);

  useEffect(() => {
    let currentNavData;
    switch (currentUser?.role.name) {
      case Roles.Employer:
        currentNavData = employerNavData;
        break;
      case Roles.Employee:
        currentNavData = employeeNavData;
        break;
      case Roles.PayrollAdmin:
      default:
        currentNavData = adminNavData;
        break;
    }

    setNav([...currentNavData]);
  }, [currentUser]);

  if (
    (currentUser?.role.name === Roles.Employer || currentUser?.role.name === Roles.PayrollAdmin) &&
    !isCompanyOnboarded
  )
    return null;

  if (currentUser?.role.name === Roles.Employee && !isEmployeeOnboarded) return null;

  return (
    <SidebarContainer>
      <SidebarBackground />
      <SidebarNav>
        {navItems.map((item, index) => (
          <NavBlock key={`block-${item.title + item.url || index}`} item={item} />
        ))}
      </SidebarNav>
    </SidebarContainer>
  );
};
