import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

export type Payday = {
  approvalDeadline: string;
  impactedByWeekendOrHoliday: boolean;
  payday: string;
  periodEnd: string;
  periodStart: string;
};

type CompanyNextPaydayResponse = {
  data: {
    getPaySchedule: {
      nextPayday: Payday;
    };
  };
};

export const fetchCompanyNextPayday = (): Promise<Payday | null> => {
  const query = `
    query {
      getPaySchedule {
        nextPayday {
          approvalDeadline
          impactedByWeekendOrHoliday
          payday
          periodEnd
          periodStart
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<CompanyNextPaydayResponse>>('graphql', {
      query,
    })
    .then((response: AxiosResponse<CompanyNextPaydayResponse>) => {
      return response.data.data.getPaySchedule.nextPayday;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return null;
    });
};
