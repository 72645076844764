import {
  StyledTable,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  StyledLink,
  EmptyMessage,
  BlockingStatus,
  CompletedStatus,
  AttentionStatus,
} from '../Table.styles';
import { Employee } from 'lib/fetchProviderEmployeesList';
import { OnboardingStatus } from '@src/constants/onboardingStatus';

export type TableProps = {
  employees: Employee[];
};

export const Table = ({ employees }: TableProps) => {
  if (employees.length === 0) {
    return (
      <EmptyMessage>No employees found. Please add employees in order to run payroll.</EmptyMessage>
    );
  }

  return (
    <StyledTable>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Email</Th>
          <Th>Onboarding Status</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {employees.map((employee) => (
          <Tr key={employee.providerIdentifier}>
            <Td>{`${employee.firstName} ${employee.lastName}`}</Td>
            <Td>{employee.email}</Td>
            <Td>
              {employee.onboard.status === OnboardingStatus.Blocking && (
                <BlockingStatus>{employee.onboard.status}</BlockingStatus>
              )}
              {employee.onboard.status === OnboardingStatus.Completed && (
                <CompletedStatus>{employee.onboard.status}</CompletedStatus>
              )}
              {employee.onboard.status === OnboardingStatus.NeedsAttention && (
                <AttentionStatus>{employee.onboard.status.replace('_', ' ')}</AttentionStatus>
              )}
            </Td>
            <Td>
              <StyledLink href={`/people/${employee.providerIdentifier}`}>View</StyledLink>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </StyledTable>
  );
};
