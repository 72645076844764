import { AuthHeader } from 'components/AuthHeader/authHeader';
import { ResetPasswordForm } from 'components/AuthForm/ResetPasswordForm/ResetPasswordForm';

export const ResetPassword = () => {
  return (
    <div>
      <AuthHeader />
      <ResetPasswordForm />
    </div>
  );
};
