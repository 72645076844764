import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { UserNavItem } from 'components/UserNavItem/userNavItem';
import { UserMenu } from 'components/UserMenu/userMenu';
import { selectCurrentCompany, getCurrentCompany } from 'features/company/slice';
import { Roles } from 'features/user/types';

import {
  TopbarHeader,
  TopbarLeft,
  TopbarLogo,
  TopbarLogoLink,
  TopbarRight,
  TopbarWelcome,
  TopbarWelcomeMorePower,
  TopbarWelcomeSchool,
  TopbarWelcomeSchoolInner,
  TopbarWelcomeSchoolText,
} from './topbar.styles';

export const Topbar = () => {
  const { currentUser } = useAppSelector((state) => state.user);
  const company = useAppSelector(selectCurrentCompany);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentUser?.role.name !== Roles.Employee) {
      dispatch(getCurrentCompany());
    }
  }, [dispatch, currentUser]);

  const companyName =
    company?.providerAttributes?.tradeName || company?.providerAttributes?.legalName || '';

  return (
    <TopbarHeader>
      <TopbarLeft>
        <TopbarLogoLink to="/">
          <TopbarLogo />
        </TopbarLogoLink>

        <TopbarWelcome>
          <TopbarWelcomeMorePower>Payroll</TopbarWelcomeMorePower>
          <TopbarWelcomeSchool>
            <TopbarWelcomeSchoolInner>
              <TopbarWelcomeSchoolText>{companyName}</TopbarWelcomeSchoolText>
            </TopbarWelcomeSchoolInner>
          </TopbarWelcomeSchool>
        </TopbarWelcome>
      </TopbarLeft>
      <TopbarRight>{currentUser && <UserNavItem tooltipContent={<UserMenu />} />}</TopbarRight>
    </TopbarHeader>
  );
};
