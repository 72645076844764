import { useEffect, useState, MouseEvent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { employeeSchema } from './validator';
import { EmployeeInput, EmployeeFormData } from './types';
import { CompensationType } from '@src/constants/compensationType';
import { Form, EmployeeFormContainer, ButtonContainer } from './employeeForm.styles';
import { FormInput, FormSelect, FormGroup } from 'components/form';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useNavigate } from 'react-router-dom';
import { createEmployee } from 'lib/createEmployee';
import { createAdminEmployee } from 'lib/createAdminEmployee';
import { fetchCompanyWorkplaces, Workplace } from 'lib/fetchCompanyWorkplaces';
import { DateInput } from 'components/form/DateInput/dateInput';
import moment from 'moment';
import { toast } from 'react-toastify';
import { PrepopulatedUserData } from 'components/UserMenu/userMenu';

type EmployeeFormProps = {
  prepopulatedData?: PrepopulatedUserData | null;
  onSuccessfulSubmit?: () => void;
  admin?: boolean;
};

export const EmployeeForm = ({
  prepopulatedData,
  onSuccessfulSubmit,
  admin,
}: EmployeeFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<EmployeeFormData>({
    resolver: yupResolver(employeeSchema),
  });

  const navigate = useNavigate();
  const [workplaces, setWorkplaces] = useState<Workplace[]>([]);

  useEffect(() => {
    if (prepopulatedData) {
      setValue('firstName', prepopulatedData.firstName);
      setValue('lastName', prepopulatedData.lastName);
      setValue('email', prepopulatedData.email);
    }

    async function loadWorkplaces() {
      const fetchedWorkplaces = await fetchCompanyWorkplaces();
      if (fetchedWorkplaces && fetchedWorkplaces.length > 0) {
        setWorkplaces(fetchedWorkplaces);
        setValue('workplaceId', fetchedWorkplaces[0].id);
      }
    }
    loadWorkplaces();
  }, [prepopulatedData, setValue]);

  const onSubmit: SubmitHandler<EmployeeFormData> = async (data: EmployeeFormData) => {
    if (Object.keys(errors).length === 0) {
      const employeeData: EmployeeInput = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        dateOfBirth: moment(data.dateOfBirth).format('YYYY-MM-DD'),
        startDate: moment(data.startDate).format('YYYY-MM-DD'),
        compensation: {
          amount: data.amount,
          type: data.type as CompensationType,
        },
        address: {
          line1: data.line1,
          city: data.city,
          state: data.state,
          postalCode: data.postalCode,
          country: 'US',
        },
        workplaceId: data.workplaceId,
      };

      let response;
      if (admin) {
        response = await createAdminEmployee(employeeData);
      } else {
        response = await createEmployee(employeeData);
        if (response) {
          toast.success('The employee has been successfully added.');
        }
      }

      if (response) {
        if (onSuccessfulSubmit) {
          onSuccessfulSubmit();
        } else {
          navigate('/people');
        }
      }
    }
  };

  const handleFormClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const isButtonDisabled = (): boolean =>
    !watch('firstName') ||
    !watch('lastName') ||
    !watch('email') ||
    !watch('dateOfBirth') ||
    !watch('startDate') ||
    !watch('amount') ||
    !watch('type') ||
    !watch('workplaceId') ||
    !watch('line1') ||
    !watch('city') ||
    !watch('state') ||
    !watch('postalCode');

  return (
    <EmployeeFormContainer onClick={handleFormClick}>
      <Form>
        <FormGroup label="General Information">
          <FormInput
            id="firstName"
            placeholder="First Name"
            label="First Name"
            type="text"
            required
            {...register('firstName')}
            error={errors.firstName?.message}
          />

          <FormInput
            id="lastName"
            placeholder="Last Name"
            label="Last Name"
            type="text"
            required
            {...register('lastName')}
            error={errors.lastName?.message}
          />

          <DateInput
            control={control}
            name="dateOfBirth"
            placeholder="Date of Birth"
            label="Date of Birth"
            dateRestriction="past"
          />
        </FormGroup>

        <FormGroup label="Employee Address">
          <FormInput
            id="line1"
            placeholder="Street Address"
            label="Street Address"
            type="text"
            required
            {...register('line1')}
            error={errors.line1?.message}
          />

          <FormInput
            id="city"
            placeholder="City"
            label="City"
            type="text"
            required
            {...register('city')}
            error={errors.city?.message}
          />

          <FormInput
            id="state"
            placeholder="State"
            label="State"
            type="text"
            required
            {...register('state')}
            error={errors.state?.message}
          />

          <FormInput
            id="postalCode"
            placeholder="Postal Code"
            label="Postal Code"
            type="text"
            required
            {...register('postalCode')}
            error={errors.postalCode?.message}
          />
        </FormGroup>

        <FormGroup label="Set Workplace">
          <FormSelect
            id="workplaceId"
            required
            options={workplaces.map((workplace) => ({
              value: workplace.id,
              label: workplace.name,
            }))}
            defaultValue={workplaces.length > 0 ? workplaces[0].id : ''}
            {...register('workplaceId')}
          />
        </FormGroup>

        <FormGroup label="Compensation">
          <DateInput
            control={control}
            name="startDate"
            placeholder="Start Date"
            label="Start Date"
          />

          <FormSelect
            id="type"
            label="Compensation Type"
            required
            options={Object.values(CompensationType).map((type) => ({
              value: type,
              label: type,
            }))}
            {...register('type')}
          />

          <FormInput
            id="amount"
            placeholder="Amount"
            label="Amount"
            type="text"
            required
            {...register('amount')}
            error={errors.amount?.message}
          />
        </FormGroup>
        <FormGroup label="Employee Contact">
          <FormInput
            id="email"
            placeholder="Email"
            label="Email"
            type="email"
            required
            {...register('email')}
          />
        </FormGroup>
      </Form>
      <ButtonContainer>
        <ActionButton
          onClick={handleSubmit(onSubmit)}
          size="medium"
          title="Create Employee"
          hidden={false}
          disabled={isButtonDisabled()}
          shape="fullWidth"
        />
      </ButtonContainer>
    </EmployeeFormContainer>
  );
};
