import styled from 'styled-components';

export const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;

  &:hover {
    opacity: 0.8;
  }

  svg {
    fill: ${(props) => props.theme.colors.gray7};
    width: 20px;
    height: 20px;
  }
`;
