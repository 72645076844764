import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { EmployeeInput } from 'components/EmployeeForm/types';
import { APIError, sendErrorMessage } from './sendErrorMessage';

type AdminEmployee = {
  id: string;
  name: string;
  onboarded: boolean;
  platformIdentifier: string;
  providerIdentifier: string;
};

type CreateAdminEmployeePayload = {
  employee: AdminEmployee;
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

export const createAdminEmployee = (
  employeeParams: EmployeeInput
): Promise<AdminEmployee | void> => {
  const mutation = `
        mutation($employeeParams: CreateEmployeeInput!) {
          createAdminEmployee(employeeParams: $employeeParams) {
            employee {
              id
              name
              onboarded
              platformIdentifier
              providerIdentifier
            }
            error {
              message
              type
              inputErrors {
                field
                fieldPath
                message
              }
            }
          }
        }
      `;

  const variables = {
    employeeParams,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { createAdminEmployee: CreateAdminEmployeePayload } }>>(
      'graphql',
      {
        query: mutation,
        variables,
      }
    )
    .then(
      (response: AxiosResponse<{ data: { createAdminEmployee: CreateAdminEmployeePayload } }>) => {
        if (response.data.data.createAdminEmployee.error) {
          sendErrorMessage(response.data.data.createAdminEmployee.error);
        }
        return response.data.data.createAdminEmployee.employee;
      }
    )
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
