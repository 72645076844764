import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '@src/assets/icons/arrow-down.svg';

export const SectionHeading = styled.h3`
  font-size: 20px;
  color: ${(props) => props.theme.colors.black};
  margin: 0 0 10px;
`;

export const CardsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Instruction = styled.p`
  font-size: 12px;
  padding: 0 8px;
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  fill: ${(props) => props.theme.colors.gray7};
  width: 30px;
  transform: rotate(-90deg);
`;
