import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from './sendErrorMessage';
import { PostTaxDeductionTypes } from './fetchEmployeePostTaxDeductions';

type ChildSupportDeduction = {
  agency: string;
  amount: string;
  externalId: string;
  fipsCode: string;
  issueDate: string;
  maxPercent: number;
};

type MiscellaneousDeduction = {
  amount: string;
  annualLimit: string;
  percent: number;
  totalAmount?: string;
};

type ConfigurationObject = ChildSupportDeduction | MiscellaneousDeduction;

export type UpdatePostTaxDeductionInput = {
  providerId: string;
  description?: string;
  effectiveStart?: string;
  effectiveEnd?: string | null;
  configurationObject: {
    miscellaneous?: {
      amount?: string;
      annualLimit?: string;
      percent?: number;
      totalAmount?: string;
    };
    childSupport?: {
      agency: string;
      amount: string;
      externalId: string;
      issueDate: string;
      maxPercent: number;
    };
  };
};

type UpdatePostTaxDeductionPayload = {
  postTaxDeduction: {
    configurationObject: ConfigurationObject;
    description: string;
    effectiveEnd?: string;
    effectiveStart: string;
    employeeProviderId: string;
    providerId: string;
    type: PostTaxDeductionTypes;
  };
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

export type PostTaxDeduction = {
  configurationObject: ConfigurationObject;
  description: string;
  effectiveEnd?: string;
  effectiveStart: string;
  employeeProviderId: string;
  providerId: string;
  type: string;
};

export const updatePostTaxDeduction = (
  postTaxDeductionParams: UpdatePostTaxDeductionInput
): Promise<PostTaxDeduction | void> => {
  const mutation = `
      mutation UpdatePostTaxDeduction($postTaxDeductionInput: UpdatePostTaxDeductionInput!) {
        updatePostTaxDeduction(postTaxDeductionInput: $postTaxDeductionInput) {
          postTaxDeduction {
            description
            effectiveEnd
            effectiveStart
            employeeProviderId
            providerId
            type
            configurationObject {
              ... on MiscellaneousDeduction {
                amount
                annualLimit
                percent
                totalAmount
              }
              ... on ChildSupportDeduction {
                agency
                amount
                externalId
                issueDate
                maxPercent
              }
            }
          }
          error {
            message
            type
            inputErrors {
              field
              fieldPath
              message
            }
          }
        }
      }
    `;

  const variables = {
    postTaxDeductionInput: postTaxDeductionParams,
  };

  return procareApi
    .post<
      unknown,
      AxiosResponse<{ data: { updatePostTaxDeduction: UpdatePostTaxDeductionPayload } }>
    >('graphql', {
      query: mutation,
      variables,
    })
    .then(
      (
        response: AxiosResponse<{ data: { updatePostTaxDeduction: UpdatePostTaxDeductionPayload } }>
      ) => {
        if (response.data.data.updatePostTaxDeduction.error) {
          sendErrorMessage(response.data.data.updatePostTaxDeduction.error);
        }
        return response.data.data.updatePostTaxDeduction.postTaxDeduction;
      }
    )
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
