import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'components/Modal/Modal';
import { OffCyclePayrollFormData } from './types';
import {
  OffCyclePayrollModalContainer,
  CloseButton,
  TitleContainer,
  ModalTitle,
  Instruction,
  EmployeeContainer,
  EmployeeItem,
  HeaderContainer,
  NoStaffMessage,
  InitialCircle,
  EmployeeLabel,
  SearchContainer,
  SearchInput,
  ButtonContainer,
  Period,
  PeriodContainer,
} from './OffCyclePayrollModal.styles';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { fetchEmployeeList, EmployeeBasic as Employee } from 'lib/fetchEmployeeList';
import { ActionButton } from 'components/ActionButton/actionButton';
import moment from 'moment';
import { PayrollTypes } from 'lib/createPayroll';
import { Payday } from 'lib/fetchCompanyNextPayday';

type OffCyclePayrollModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  formData: OffCyclePayrollFormData | null;
};

export const OffCyclePayrollModal = ({
  isOpen,
  onRequestClose,
  formData,
}: OffCyclePayrollModalProps) => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<{ [key: string]: boolean }>({});
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [searchName, setSearchName] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      fetchEmployeeList(undefined, undefined, searchName).then((response) => {
        setEmployees(response.employees.filter((employee) => employee.onboarded));
      });
    }
  }, [isOpen, searchName]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
  };

  const toggleCheckbox = (id: string) => {
    setSelectedEmployees((prev) => {
      const newSelected = { ...prev, [id]: !prev[id] };
      setIsAllSelected(
        Object.keys(newSelected).length === employees.length &&
          Object.values(newSelected).every((isSelected) => isSelected)
      );
      return newSelected;
    });
  };

  const toggleSelectAll = () => {
    const allSelected = !isAllSelected;
    const newSelectedEmployees = employees.reduce((acc, employee) => {
      acc[employee.id] = allSelected;
      return acc;
    }, {} as { [key: string]: boolean });
    setSelectedEmployees(newSelectedEmployees);
    setIsAllSelected(allSelected);
  };

  const getInitials = (name: string) => {
    const [firstName, lastName] = name.split(' ');
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };

  const noStaffSelected =
    Object.keys(selectedEmployees).length === 0 ||
    Object.values(selectedEmployees).every((isSelected) => !isSelected);

  const formatPayPeriod = (startDate: string, endDate: string) => {
    return `Pay Period: ${moment(startDate).format('MMMM D, YYYY')} - ${moment(endDate).format(
      'MMMM D, YYYY'
    )}`;
  };

  const handleContinue = () => {
    if (formData) {
      const selectedEmployeeIds = Object.keys(selectedEmployees).filter(
        (key) => selectedEmployees[key]
      );

      const payday: Payday = {
        approvalDeadline: '',
        impactedByWeekendOrHoliday: false,
        payday: formData.payday,
        periodEnd: formData.endDate,
        periodStart: formData.startDate,
      };

      navigate('/payroll/run-payroll/hours', {
        state: {
          payday,
          type: PayrollTypes.OFF_CYCLE,
          employeeIds: selectedEmployeeIds,
        },
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          position: 'fixed',
          inset: '0px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 11,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-30%, -50%)',
          borderRadius: '16px',
          overflow: 'visible',
          width: '760px',
          padding: '0px',
        },
      }}
    >
      <OffCyclePayrollModalContainer>
        <TitleContainer>
          <ModalTitle>Select Employees</ModalTitle>
          <Instruction>
            Select which employees you would like to run an off-cycle payroll for.
          </Instruction>
        </TitleContainer>

        {formData && (
          <PeriodContainer>
            <Period>{formatPayPeriod(formData.startDate, formData.endDate)}</Period>
          </PeriodContainer>
        )}
        <SearchContainer>
          <SearchInput
            type="text"
            value={searchName}
            onChange={handleSearchChange}
            placeholder="Search by name"
          />
        </SearchContainer>

        <HeaderContainer>
          <Checkbox
            id="selectAll"
            isChecked={isAllSelected}
            toggleCheckbox={toggleSelectAll}
            label="Select All"
          />
          {noStaffSelected && <NoStaffMessage>No Staff Selected</NoStaffMessage>}
        </HeaderContainer>
        <EmployeeContainer>
          {employees.map((employee, index) => (
            <EmployeeItem key={employee.id} isLastItem={index === employees.length - 1}>
              <Checkbox
                id={employee.id}
                isChecked={selectedEmployees[employee.id] || false}
                toggleCheckbox={() => toggleCheckbox(employee.id)}
                label={
                  <EmployeeLabel>
                    <InitialCircle>{getInitials(employee.name)}</InitialCircle>
                    {employee.name}
                  </EmployeeLabel>
                }
              />
            </EmployeeItem>
          ))}
        </EmployeeContainer>
        <ButtonContainer>
          <ActionButton
            size="large"
            title="Continue"
            hidden={false}
            disabled={noStaffSelected}
            onClick={handleContinue}
          />
        </ButtonContainer>
        <CloseButton icon={<CloseIcon />} onClick={onRequestClose} />
      </OffCyclePayrollModalContainer>
    </Modal>
  );
};
