import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
import { signup } from 'features/user/slice';
import { Spinner } from 'components/Spinner/spinner';

export const Verify = () => {
  const { token } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token) {
      dispatch(signup(token));
    }
  }, []);

  return <Spinner />;
};
