import { createGlobalStyle } from 'styled-components';

import GothamRoundedBook from '../assets/fonts/gotham-rounded-book.woff';
import GothamRoundedBookItalic from '../assets/fonts/gotham-rounded-book-italic.woff';
import GothamRoundedBold from '../assets/fonts/gotham-rounded-bold.woff';
import GothamRoundedLight from '../assets/fonts/gotham-rounded-light.woff';
import GothamRoundedLightItalic from '../assets/fonts/gotham-rounded-light-italic.woff';
import GothamRoundedMedium from '../assets/fonts/gotham-rounded-medium.woff';
import Cervanttis from '../assets/fonts/cervanttis.woff';

export const FontStyles = createGlobalStyle`

$font-path: '../assets/fonts/gotham-rounded-';

@font-face {
  font-family: GothamRounded;
  src: url(${GothamRoundedBook}) format('woff');
  font-weight: normal;
}

@font-face {
  font-family: GothamRounded;
  src: url(${GothamRoundedBookItalic}) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: GothamRounded;
  src: url(${GothamRoundedBold}) format('woff');
  font-weight: bold;
}

@font-face {
  font-family: GothamRounded;
  src: url(${GothamRoundedLight}) format('woff');
  font-weight: 300;
}

@font-face {
  font-family: GothamRounded;
  src: url(${GothamRoundedLightItalic}) format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: GothamRounded;
  src: url(${GothamRoundedMedium}) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: GothamRounded;
  src: url(${GothamRoundedMedium}) format('woff');
  font-weight: 700;
}

@font-face {
  font-family: GothamRounded;
  src: url(${GothamRoundedMedium}) format('woff');
  font-weight: bold;
}

@font-face {
  font-family: Cervanttis;
  src: url(${Cervanttis}) format('woff');
  font-weight: normal;
}
`;
