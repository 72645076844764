import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

export const SidebarContainer = styled.aside`
  display: flex;
  width: ${(props) => props.theme.dimensions.asideWidth};
  position: fixed;
  top: ${(props) => props.theme.dimensions.headerHeight};
  bottom: 0;
  z-index: 9;
  flex-direction: column;

  background: url('../../assets/images/ring-bluegreen-nav.svg') no-repeat left bottom / 160px;
  background-color: ${(props) => props.theme.colors.gray5};
`;

export const SidebarBackground = styled.div`
  position: absolute;
  width: 8px;
  background: linear-gradient(
    112deg,
    ${(props) => props.theme.colors.homeEnd} 0%,
    ${(props) => props.theme.colors.homeStart} 100%
  );
  left: 0;
  top: 0;
  bottom: 0;
`;

export const SidebarNav = styled.div`
  line-height: 1.1em;

  ${up('desktop')} {
    overflow-y: auto;
  }

  ${down('desktop')} {
    width: ${(props) => props.theme.dimensions.asideWidthTablet};
  }
`;
