import { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { selectCurrentCompany } from 'features/company/slice';
import {
  Container,
  Heading,
  Message,
  ButtonContainer,
  ReportsContainer,
  DateSelectionContainer,
  DateContainer,
} from '../reportsContainer.styles';
import { ActionButton } from 'components/ActionButton/actionButton';
import { DateSelector } from '../../DateSelector/dateSelector';
import { downloadPayrollSummary } from 'lib/downloadPayrollSummary';

export const PayrollSummary = () => {
  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);

  const company = useSelector(selectCurrentCompany);

  const handleDownloadClick = async () => {
    if (!company || !company.id) {
      return;
    }

    const formattedStartDate = startDate ? startDate.format('YYYY-MM-DD') : undefined;
    const formattedEndDate = endDate ? endDate.format('YYYY-MM-DD') : undefined;

    const data = await downloadPayrollSummary(formattedStartDate, formattedEndDate);

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'payroll_summary.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <ReportsContainer>
      <Container>
        <Heading>Payroll Summary</Heading>
        <Message>
          View employees&apos; past earnings, deductions, and taxes aggregated over a period for
          your company.
        </Message>
      </Container>
      <DateSelectionContainer>
        <DateContainer>
          <DateSelector placeholder="Start Date" onDateChange={setStartDate} />
        </DateContainer>
        <DateContainer>
          <DateSelector placeholder="End Date" onDateChange={setEndDate} />
        </DateContainer>
      </DateSelectionContainer>
      <ButtonContainer>
        <ActionButton
          onClick={handleDownloadClick}
          size="medium"
          title="Download Report"
          hidden={false}
        />
      </ButtonContainer>
    </ReportsContainer>
  );
};
