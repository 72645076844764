import { LoadingContainer, Loading } from './LoadingSync.styles';
import loading from '../../assets/images/loading.gif';

type LoadingSyncProps = {
  message: string;
};

export const LoadingSync = ({ message }: LoadingSyncProps) => (
  <LoadingContainer>
    <p>{message}</p>
    <Loading src={loading} alt="Loading..." />
  </LoadingContainer>
);
