import styled from 'styled-components';

export const EmployeeFormContainer = styled.div``;

export const Form = styled.form`
  margin: 0 auto 5px;
  padding-bottom: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray3};
  min-width: 800px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;
