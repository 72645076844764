import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from 'lib/sendErrorMessage';
import { DEFAULT_PAGE_SIZE } from '@src/constants/constants';

export type Timecard = {
  id: string;
  signInAt: string;
  signOutAt: string;
  totalTime: string;
  workHours: string;
  employee: {
    id: string;
    name: string;
  };
};

type TimecardEdge = {
  cursor: string;
  node: Timecard;
};

type TimecardPageInfo = {
  endCursor: string;
  hasNextPage: boolean;
};

type TimecardsResponse = {
  data: {
    employeeTimecards: {
      edges: TimecardEdge[];
      pageInfo: TimecardPageInfo;
    };
  };
};

export const fetchEmployeeTimecards = (
  startDate: string,
  endDate: string,
  employeeId: string,
  pageSize: number = DEFAULT_PAGE_SIZE
): Promise<{ timecards: Timecard[]; pageInfo: TimecardPageInfo | null }> => {
  const query = `
    query EmployeeTimecards($input: EmployeeTimecardsInput!) {
      employeeTimecards(
        employeeTimecardsInput: $input
        first: ${pageSize}
      ) {
        edges {
          cursor
          node {
            id
            signInAt
            signOutAt
            totalTime
            workHours
            employee {
              id
              name
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `;

  const variables = {
    input: {
      startDate,
      endDate,
      employeeId,
    },
  };

  return procareApi
    .post<unknown, AxiosResponse<TimecardsResponse>>('graphql', { query, variables })
    .then((response: AxiosResponse<TimecardsResponse>) => {
      const timecards = response.data.data.employeeTimecards.edges.map((edge) => edge.node);
      const pageInfo = response.data.data.employeeTimecards.pageInfo;
      return { timecards, pageInfo };
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return { timecards: [], pageInfo: null };
    });
};
