import { StyledTable, Thead, Tr, Th, Td, Tbody, EmptyMessage } from '../../Table.styles';
import { PayerType, PayrollItem } from 'lib/fetchProviderPayroll';
import { AggregateContainer, Aggregate, Total, SubHeading } from './ReviewStep.styles';
import { currencyFormatter } from 'lib/utils/currencyFormatter';

type TaxSummary = {
  description: string;
  employeeTotal: number;
  companyTotal: number;
};

const calculateTaxBreakdown = (items: PayrollItem[]): TaxSummary[] => {
  const taxMap: { [key: string]: TaxSummary } = {};

  items.forEach((item) => {
    item.taxes.forEach((tax) => {
      if (!taxMap[tax.description]) {
        taxMap[tax.description] = {
          description: tax.description,
          employeeTotal: 0,
          companyTotal: 0,
        };
      }

      if (tax.payer === PayerType.Employee) {
        taxMap[tax.description].employeeTotal += Number(tax.amount);
      } else if (tax.payer === PayerType.Company) {
        taxMap[tax.description].companyTotal += Number(tax.amount);
      }
    });
  });

  return Object.values(taxMap);
};

const calculateAggregateTaxes = (taxSummary: TaxSummary[]) => {
  return taxSummary.reduce(
    (acc, curr) => {
      acc.employeeTotal += curr.employeeTotal;
      acc.companyTotal += curr.companyTotal;
      return acc;
    },
    {
      employeeTotal: 0,
      companyTotal: 0,
    }
  );
};

export const TaxBreakdown = ({ items }: { items: PayrollItem[] }) => {
  const taxSummary = calculateTaxBreakdown(items);
  const aggregateTaxes = calculateAggregateTaxes(taxSummary);

  if (taxSummary.length === 0) {
    return <EmptyMessage>No taxes found. Please check the payroll data.</EmptyMessage>;
  }

  return (
    <>
      <SubHeading>Tax Breakdown</SubHeading>
      <AggregateContainer>
        <Aggregate>
          <div>Total Employee Taxes</div>
          <Total>{currencyFormatter(aggregateTaxes.employeeTotal)}</Total>
        </Aggregate>
        <Aggregate>
          <div>Total Company Taxes</div>
          <Total>{currencyFormatter(aggregateTaxes.companyTotal)}</Total>
        </Aggregate>
        <Aggregate>
          <div>Total Taxes (Company & Employee)</div>
          <Total>
            {currencyFormatter(aggregateTaxes.employeeTotal + aggregateTaxes.companyTotal)}
          </Total>
        </Aggregate>
      </AggregateContainer>
      <StyledTable>
        <Thead>
          <Tr>
            <Th>Tax Description</Th>
            <Th>By Employees</Th>
            <Th>By Company</Th>
          </Tr>
        </Thead>
        <Tbody>
          {taxSummary.map((tax, index) => (
            <Tr key={index}>
              <Td>{tax.description}</Td>
              <Td>{currencyFormatter(tax.employeeTotal)}</Td>
              <Td>{currencyFormatter(tax.companyTotal)}</Td>
            </Tr>
          ))}
        </Tbody>
      </StyledTable>
    </>
  );
};
