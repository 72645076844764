import { useState } from 'react';
import { Modal } from 'components/Modal/Modal';
import { TabHeader, TabContainer } from '../../../tabMenu.styles';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { DeductionsTable } from 'components/Table/DeductionsTable/DeductionsTable';
import { Employee } from 'features/user/types';
import { ActionButton } from 'components/ActionButton/actionButton';
import { PostTaxDeductionForm } from 'components/PostTaxDeductionForm/PostTaxDeductionForm';
import { FormHeading, FormContainer, CloseButton, FormNote } from './GarnishmentsDeductions.styles';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';

type GarnishmentsDeductionsProps = {
  employee: Employee;
};

export const GarnishmentsDeductions = ({ employee }: GarnishmentsDeductionsProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFormSubmit = () => {
    handleCloseModal();

    setRefresh((prevState) => !prevState);
  };

  return (
    <>
      <TabHeader>
        <ActionButton
          onClick={handleOpenModal}
          size="medium"
          title="Add Post-Tax Deduction"
          hidden={false}
        />
      </TabHeader>
      <ContentContainer>
        <TabContainer>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            style={{
              overlay: {
                position: 'fixed',
                inset: '0px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 11,
              },
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                transform: 'translate(-50%, -50%)',
                borderRadius: '16px',
                overflow: 'visible',
                maxHeight: '99vh',
                overflowY: 'auto',
              },
            }}
          >
            <FormContainer>
              <FormHeading>Add Post-Tax Deduction</FormHeading>
              <FormNote>Note: All deductions will start with the next pay period.</FormNote>
              <PostTaxDeductionForm employee={employee} onFormSubmit={handleFormSubmit} />
              <CloseButton icon={<CloseIcon />} onClick={handleCloseModal} />
            </FormContainer>
          </Modal>

          <DeductionsTable employee={employee} refresh={refresh} />
        </TabContainer>
      </ContentContainer>
    </>
  );
};
