import React, { MouseEventHandler } from 'react';
import { ActionButtonSize } from 'components/ActionButton/actionButton';
import { IconContainer } from './icon.styles';
import { iconsMap, IconType } from '../../assets/icons/index';

type IconProps = {
  name: IconType;
  color?: string;
  size?: ActionButtonSize;
  customSize?: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  'data-cy'?: string;
};

export const Icon: React.FC<IconProps> = (props) => {
  const { name, color, size, customSize, onClick, 'data-cy': dataCY } = props;
  const IconSvg = iconsMap[name];

  return (
    <IconContainer
      size={size}
      color={color}
      customSize={customSize}
      onClick={onClick}
      data-cy={dataCY}
    >
      <IconSvg />
    </IconContainer>
  );
};

Icon.defaultProps = {
  size: 'small',
};
