import styled from 'styled-components';

export const UploadFormContainer = styled.div`
  padding: 0px 20px;
`;

export const Instructions = styled.p`
  font-size: 12px;
  line-height: 1.5;
`;

export const Form = styled.form`
  margin: 10px auto 0;
  max-width: 450px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;
