import styled from 'styled-components';
import { IconButton } from 'components/IconButton/IconButton';

export const CloseButton = styled(IconButton)`
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
`;

export const SignatoryFormContainer = styled.div``;

export const FormHeading = styled.h3`
  font-size: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
  margin: 0 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray6};
`;

export const Instruction = styled.div`
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 400;
  color: ${(props) => props.theme.colors.black0};
  margin: 15px 20px;
`;
