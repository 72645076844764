import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { offCyclePayrollSchema } from './validator';
import { OffCyclePayrollFormData } from './types';
import { Form, OffCyclePayrollFormContainer, ButtonContainer } from './OffCyclePayrollForm.styles';
import { FormGroup } from 'components/form';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useState } from 'react';
import { DateInput } from 'components/form/DateInput/dateInput';
import moment from 'moment';
import { OffCyclePayrollModal } from './OffCyclePayrollModal';

const defaultFormValues = {
  startDate: '',
  endDate: '',
  payday: '',
};

export const OffCyclePayrollForm = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState<OffCyclePayrollFormData | null>(null);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<OffCyclePayrollFormData>({
    resolver: yupResolver(offCyclePayrollSchema),
    defaultValues: defaultFormValues,
  });

  const onSubmit: SubmitHandler<OffCyclePayrollFormData> = async (
    data: OffCyclePayrollFormData
  ) => {
    if (Object.keys(errors).length === 0) {
      const offCyclePayrollData: OffCyclePayrollFormData = {
        startDate: moment(data.startDate).format('YYYY-MM-DD'),
        endDate: moment(data.endDate).format('YYYY-MM-DD'),
        payday: moment(data.payday).format('YYYY-MM-DD'),
      };

      setFormData(offCyclePayrollData);
      setModalIsOpen(true);
    }
  };

  const isButtonDisabled = (): boolean => {
    return !watch('startDate') || !watch('endDate') || !watch('payday');
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  return (
    <OffCyclePayrollFormContainer>
      <Form>
        <FormGroup label="START DATE">
          <DateInput
            control={control}
            name="startDate"
            placeholder="Start Date"
            dateRestriction="future"
          />
        </FormGroup>
        <FormGroup label="END DATE">
          <DateInput
            control={control}
            name="endDate"
            placeholder="End Date"
            dateRestriction="future"
          />
        </FormGroup>
        <FormGroup label="PAYDAY">
          <DateInput
            control={control}
            name="payday"
            placeholder="Payday"
            dateRestriction="future"
          />
        </FormGroup>
      </Form>
      <ButtonContainer>
        <ActionButton
          onClick={handleSubmit(onSubmit)}
          size="large"
          title="Run Payroll"
          hidden={false}
          disabled={isButtonDisabled()}
        />
      </ButtonContainer>
      <OffCyclePayrollModal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        formData={formData}
      />
    </OffCyclePayrollFormContainer>
  );
};
