import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from './sendErrorMessage';
import { PayrollStatus } from './fetchCompanyPayrolls';

type ReopenPayrollInput = {
  providerId: string;
};

type ReopenPayrollPayload = {
  payroll: {
    approvalDeadline: string;
    approvedAt: string;
    fundingPaymentMethod: string;
    isVoid: boolean;
    managed: boolean;
    offCycleOptions: any;
    payFrequency: string;
    payday: string;
    periodEnd: string;
    periodStart: string;
    processingPeriod: string;
    providerId: string;
    status: PayrollStatus;
    type: string;
  };
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

export const reopenPayroll = (
  payrollInput: ReopenPayrollInput
): Promise<ReopenPayrollPayload['payroll'] | void> => {
  const mutation = `
        mutation($payrollInput: PayrollInput!) {
          reopenPayroll(payrollInput: $payrollInput) {
            payroll {
              approvalDeadline
              approvedAt
              fundingPaymentMethod
              isVoid
              managed
              offCycleOptions
              payFrequency
              payday
              periodEnd
              periodStart
              processingPeriod
              providerId
              status
              type
            }
            error {
              message
              type
              inputErrors {
                field
                fieldPath
                message
              }
            }
          }
        }
      `;

  const variables = {
    payrollInput,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { reopenPayroll: ReopenPayrollPayload } }>>('graphql', {
      query: mutation,
      variables,
    })
    .then((response: AxiosResponse<{ data: { reopenPayroll: ReopenPayrollPayload } }>) => {
      if (response.data.data.reopenPayroll.error) {
        sendErrorMessage(response.data.data.reopenPayroll.error);
      }
      return response.data.data.reopenPayroll.payroll;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
