import styled from 'styled-components';

export const StyledLink = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.primaryDark};
  }
`;
