import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { forgotPasswordSchema } from './validator';
import { ForgotPasswordInput } from './types';
import { forgotPassword } from 'lib/forgotPassword';
import { FormInput, FormButton } from 'components/form';
import { toast } from 'react-toastify';

import {
  Form,
  AuthFormContainer,
  AuthForm,
  AuthFormHeader,
  AuthSubHeader,
  ButtonContainer,
} from '../authForm.styles';

export const ForgotPasswordForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ForgotPasswordInput>({
    resolver: yupResolver(forgotPasswordSchema),
  });
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<ForgotPasswordInput> = async (data: ForgotPasswordInput) => {
    if (Object.keys(errors).length === 0) {
      const response = await forgotPassword(data);
      if (response?.message) {
        toast.success(response.message);
        navigate('/login');
      }
    }
  };

  const isButtonDisabled = (): boolean => !watch('email');

  return (
    <AuthForm>
      <AuthFormHeader>Forgot Password</AuthFormHeader>
      <AuthSubHeader>Enter the email that is associated with your account.</AuthSubHeader>
      <AuthFormContainer>
        <Form>
          <FormInput
            id="email"
            placeholder="Email"
            type="email"
            required
            {...register('email')}
            error={errors.email?.message}
          />
          <ButtonContainer>
            <FormButton
              onSubmit={handleSubmit(onSubmit)}
              disabled={isButtonDisabled()}
              label="Continue"
            />
          </ButtonContainer>
        </Form>
      </AuthFormContainer>
    </AuthForm>
  );
};
