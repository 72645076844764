import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

type PlaidBankAccount = {
  institutionName: string;
  mask: string;
  microdepositVerificationStatus: string;
  name: string;
  plaidPublicToken: string;
  subtype: string;
  type: string;
  verified: boolean;
};

type RawBankAccount = {
  accountNumberLastFour: string;
  institutionName: string;
  routingNumber: string;
  subtype: string;
  type: string;
};

type BankAccount = PlaidBankAccount | RawBankAccount;

export type Bank = {
  account: BankAccount;
  employeeId: string;
  id: string;
  status: string;
  companyId: string;
};

type BankAccountsResponse = {
  data: {
    companyBankAccounts: Bank[];
  };
};

export const fetchCompanyBankAccounts = (): Promise<Bank[]> => {
  const query = `
    query GetBankAccounts {
      companyBankAccounts {
        account {
          ... on PlaidBankAccount {
            institutionName
            mask
            microdepositVerificationStatus
            name
            plaidPublicToken
            subtype
            type
            verified
          }
          ... on RawBankAccount {
            accountNumberLastFour
            institutionName
            routingNumber
            subtype
            type
          }
        }
        employeeId
        id
        status
        companyId
      }
    }
  `;
  return procareApi
    .post<unknown, AxiosResponse<BankAccountsResponse>>('graphql', {
      query,
    })
    .then((response: AxiosResponse<BankAccountsResponse>) => {
      return response.data.data.companyBankAccounts;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return [];
    });
};
