import styled from 'styled-components';

import { IconContainer } from 'components/Icon/icon.styles';

export const TopBarUser = styled.div`
  cursor: pointer;
  display: flex;
  padding: 3px;
  border-radius: 50%;
  background-image: linear-gradient(to top, #f4e495 0%, #0ac6c6 80%, #30bdef 100%);
`;

export const UserDiv = styled.div<{ size: string }>`
  width: 64px;
  min-width: 64px;
  min-height: 64px;
  height: 64px;
  user-select: none;

  &.user--large {
    min-width: 160px;
    min-height: 160px;
    width: 160px;
    height: 160px;
    font-size: 2.2em;
  }

  &.user--medium {
    min-width: 120px;
    min-height: 120px;
    width: 120px;
    height: 120px;
    font-size: 1.8em;
  }

  &.user--small {
    min-width: 42px;
    min-height: 42px;
    width: 42px;
    height: 42px;
  }
`;

export const UserInitials = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
`;

export const UserPlaceholder = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 6px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: ${(props) => props.theme.colors.gray1};
  border-radius: 50%;

  ${IconContainer} {
    width: 75%;
    height: 75%;

    svg {
      width: 18px;
      min-width: 18px;
      height: 18px;
    }
  }
`;

export const TooltipDiv = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.gray0};
  box-shadow: 0 7px 40px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  color: ${(props) => props.theme.colors.black0};
  padding: 16px;
  margin-top: 55px;
  z-index: 1000;
  width: 260px;
  right: -11px;

  // Tooltip arrow
  &::before {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    display: block;
    right: 24px;
    top: -7px;
    border-top: 1px solid ${(props) => props.theme.colors.gray0};
    border-left: 1px solid ${(props) => props.theme.colors.gray0};
    background-color: white;
  }
`;
