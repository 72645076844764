import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

export type Workplace = {
  id: string;
  name: string;
  providerIdentifier: string;
  providerAttributes: {
    active: boolean;
    providerIdentifier: string;
    address: {
      city: string;
      country: string;
      line1: string;
      line2: string;
      postalCode: string;
      state: string;
    };
  };
};

type WorkplaceResponse = {
  data: {
    companyWorkplaces: Workplace[];
  };
};

export const fetchCompanyWorkplaces = (): Promise<Workplace[] | null> => {
  const query = `
    query {
      companyWorkplaces {
        id
        name
        providerIdentifier
        providerAttributes {
            active
            providerIdentifier
            address {
                city
                country
                line1
                line2
                postalCode
                state
            }
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<WorkplaceResponse>>('graphql', {
      query,
    })
    .then((response: AxiosResponse<WorkplaceResponse>) => {
      return response.data.data.companyWorkplaces;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return null;
    });
};
