import styled from 'styled-components';
import { ReactComponent as SchoolIcon } from '@src/assets/icons/school.svg';
import { hexToRgba } from 'styles/mixins';

export const StyledSchoolIcon = styled(SchoolIcon)`
  fill: ${(props) => props.theme.colors.text};
  width: 30px;
  background-color: ${(props) => props.theme.colors.gray0};
  padding: 8px;
`;

export const CompanyText = styled.span`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.black0};
  padding: 8px 12px;
`;

export const CompanySelectionContainer = styled.div`
  min-width: 800px;
  padding: 25px 0;
`;

export const HeadingContainer = styled.div`
  padding: 16px 0 0;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray3};
`;

export const Heading = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.fontSizes.heading};
  font-weight: 500;
  color: ${(props) => props.theme.colors.black0};
`;

export const CompanyList = styled.div`
  padding: 16px 0;
`;

export const CompanyItem = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 12px 0;
  padding-bottom: 16px;
  border-bottom: 2px solid ${(props) => hexToRgba(props.theme.colors.offWhite, 0.25)};
`;

export const Instruction = styled.div`
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 400;
  color: ${(props) => props.theme.colors.black0};
  margin-bottom: 20px;
`;
