import { PageContainer } from 'components/SharedComponents/Containers.styles';
import { SectionHeader } from 'components/SectionHeader/sectionHeader';
import { Dashboard } from 'components/TabMenu/Tabs/RunPayrollMenu/Dashboard/Dashboard';
import { Drafts } from 'components/TabMenu/Tabs/RunPayrollMenu/Drafts/Drafts';
import { Submitted } from 'components/TabMenu/Tabs/RunPayrollMenu/Submitted/Submitted';
import { TabMenu } from 'components/TabMenu/tabMenu';

export const Payroll = () => {
  const MENU_ITEMS = {
    Dashboard: <Dashboard />,
    Drafts: <Drafts />,
    Submitted: <Submitted />,
  };

  return (
    <PageContainer>
      <SectionHeader title="Payroll" />
      <TabMenu menuItems={MENU_ITEMS} defaultActiveItem="Dashboard" />
    </PageContainer>
  );
};
