import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendAuthErrorMessage } from './sendAuthErrorMessage';

export const downloadEmployeeDocument = (docId: string): Promise<Blob | void> => {
  return procareApi
    .get<Blob>(`/api/v1/documents/${docId}/download`, { responseType: 'blob' })
    .then((response: AxiosResponse<Blob>) => {
      return response.data;
    })
    .catch((error: AxiosError<ErrorResponse>) => {
      sendAuthErrorMessage(error);
    });
};
