import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

type TaxSetupResponse = {
  data: {
    companyTaxSetupComponent: {
      url: string;
    };
  };
};

export const fetchCompanyTaxSetup = (signatoryId: string) => {
  const query = `
    query CompanyTaxSetupComponent($companyInput: CompanyOnboardInput!) { 
      companyTaxSetupComponent(companyInput: $companyInput) { 
        url 
      } 
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<TaxSetupResponse>>('graphql', {
      query,
      variables: {
        companyInput: {
          signatory: signatoryId,
        },
      },
    })
    .then((response: AxiosResponse<TaxSetupResponse>) => {
      return response.data.data.companyTaxSetupComponent.url;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
