import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from 'lib/sendErrorMessage';
import { DEFAULT_PAGE_SIZE } from '@src/constants/constants';

export type EmployeeBasic = {
  id: string;
  name: string;
  onboarded: boolean;
  providerIdentifier: string;
};

type EmployeeEdgeBasic = {
  node: EmployeeBasic;
};

type PageInfo = {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
};

type EmployeeListBasicResponse = {
  data: {
    employeesList: {
      edges: EmployeeEdgeBasic[];
      pageInfo: PageInfo;
    };
  };
};

export const fetchEmployeeList = (
  pageSize: number = DEFAULT_PAGE_SIZE,
  endCursor?: string,
  name?: string
): Promise<{ employees: EmployeeBasic[]; pageInfo: PageInfo | null }> => {
  const after = endCursor ? `, after: "${endCursor}"` : '';
  const nameFilter = name ? `, employeesInput: { name: "${name}" }` : '';

  const query = `
    query EmployeeList {
      employeesList(first: ${pageSize}${nameFilter}${after}) {
        edges {
          node {
            id
            name
            onboarded
            providerIdentifier
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<EmployeeListBasicResponse>>('graphql', { query })
    .then((response: AxiosResponse<EmployeeListBasicResponse>) => {
      const employees = response.data.data.employeesList.edges.map((edge) => edge.node);
      const pageInfo = response.data.data.employeesList.pageInfo;
      return { employees, pageInfo };
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return { employees: [], pageInfo: null };
    });
};
