import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { workplaceSchema } from './validators';
import { WorkplaceFormData } from './types';
import {
  Form,
  FormGroup,
  WorkplaceFormContainer,
  ButtonContainer,
  StateInput,
} from './WorkplaceForm.styles';
import { FormInput } from 'components/form';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useNavigate } from 'react-router-dom';
import { createWorkplace } from 'lib/createWorkplace';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

export type WorkplaceInput = {
  address: AddressInput;
  name: string;
  platformId?: string;
};

export type AddressInput = {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

export type WorkplaceFormProps = {
  btnText?: string;
  onSubmitSuccess?: () => void;
  navigateOnSuccess?: boolean;
  defaultValues?: WorkplaceInput;
};

export const WorkplaceForm = ({
  btnText = 'Continue',
  onSubmitSuccess,
  navigateOnSuccess = true,
  defaultValues,
}: WorkplaceFormProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<WorkplaceFormData>({
    resolver: yupResolver(workplaceSchema),
    defaultValues,
  });

  useEffect(() => {
    if (defaultValues) {
      setValue('name', defaultValues.name);
      setValue('line1', defaultValues.address.line1);
      setValue('line2', defaultValues.address.line2 || '');
      setValue('city', defaultValues.address.city);
      setValue('state', defaultValues.address.state);
      setValue('postalCode', defaultValues.address.postalCode);
      if (defaultValues.platformId) {
        setValue('platformId', defaultValues.platformId);
      }
    }
  }, [defaultValues, setValue]);

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<WorkplaceFormData> = async (data: WorkplaceFormData) => {
    if (Object.keys(errors).length == 0) {
      const workplaceData: WorkplaceInput = {
        name: data.name,
        address: {
          line1: data.line1,
          line2: data.line2,
          city: data.city,
          state: data.state,
          postalCode: data.postalCode,
          country: 'US',
        },
        platformId: data.platformId,
      };
      const response = await createWorkplace(workplaceData);
      if (response) {
        toast.success('Workplace has been successfully created.');
        if (navigateOnSuccess) {
          navigate('/pay-schedule');
        }

        if (onSubmitSuccess) {
          onSubmitSuccess();
        }
      }
    }
  };

  const isButtonDisabled = (): boolean =>
    !watch('name') || !watch('line1') || !watch('city') || !watch('state') || !watch('postalCode');

  return (
    <WorkplaceFormContainer>
      <Form>
        <FormInput
          id="name"
          placeholder="Workplace Name"
          type="text"
          label="Workplace Name"
          required
          {...register('name')}
        />
        <FormInput
          id="line1"
          placeholder="Address Line 1"
          type="text"
          label="Address Line 1"
          required
          {...register('line1')}
        />

        <FormInput
          id="line2"
          placeholder="Address Line 2"
          type="text"
          label="Address Line 2"
          {...register('line2')}
        />

        <FormGroup>
          <FormInput
            id="city"
            placeholder="City"
            type="text"
            label="City"
            className="city"
            required
            {...register('city')}
          />
          <StateInput
            id="state"
            placeholder="State"
            type="text"
            label="State"
            className="state"
            required
            {...register('state')}
          />
          <FormInput
            id="postalCode"
            placeholder="Postal Code"
            type="text"
            label="Postal Code"
            className="postal"
            required
            {...register('postalCode')}
          />
        </FormGroup>
      </Form>
      <ButtonContainer>
        <ActionButton
          onClick={handleSubmit(onSubmit)}
          size="medium"
          title={btnText}
          hidden={false}
          disabled={isButtonDisabled()}
        />
      </ButtonContainer>
    </WorkplaceFormContainer>
  );
};
