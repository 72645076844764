import { useState } from 'react';
import moment from 'moment';
import { StyledTable, Thead, Tr, Th, Td, Tbody, EmptyMessage } from '../Table.styles';
import { EmployeeNode } from 'lib/fetchIncomingEmployees';
import {
  EditButton,
  EditCell,
  CloseButton,
  SaveButton,
  FormHeading,
  CompensationTypeCell,
  DateInputWrapper,
  SortButton,
} from './incomingEmployeesModal.styles';
import { ActionButton } from 'components/ActionButton/actionButton';
import { ReactComponent as EditIcon } from '@src/assets/icons/edit.svg';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close-circle.svg';
import { ReactComponent as SaveIcon } from '@src/assets/icons/check.svg';
import { ReactComponent as ArrowIcon } from '@src/assets/icons/arrow.svg';
import { DateInput } from 'components/form/DateInput/dateInput';
import { FormSelect } from 'components/form';
import { TableInput } from '../TableInput/TableInput';
import { useForm, Control } from 'react-hook-form';
import { Modal } from 'components/Modal/Modal';
import { EmployeeAddressForm } from 'components/EmployeeAddressForm/EmployeeAddressForm';
import { AddressFormData } from 'components/EmployeeAddressForm/types';
import { updateEmployee } from 'lib/updateEmployee';
import { createEmployee } from 'lib/createEmployee';
import { deleteIncomingEmployee } from 'lib/deleteIncomingEmployee';
import { toast } from 'react-toastify';
import { CompensationType } from '@src/constants/compensationType';

export type IncomingEmployeesTableProps = {
  employees: EmployeeNode[];
  onSortChange: (sortDirection: 'asc' | 'desc') => void;
  sortDirection: 'asc' | 'desc';
  setEmployees: React.Dispatch<React.SetStateAction<EmployeeNode[]>>;
  onEmployeeDeleted: (updatedEmployees: EmployeeNode[]) => void;
};

export const IncomingEmployeesTable = ({
  employees,
  onSortChange,
  sortDirection,
  setEmployees,
  onEmployeeDeleted,
}: IncomingEmployeesTableProps) => {
  const { control } = useForm();
  const [editState, setEditState] = useState<{ [key: string]: boolean }>({});
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [addressModalIsOpen, setAddressModalIsOpen] = useState<{ [key: string]: boolean }>({});

  const toggleEditMode = (employeeId: string) => {
    setEditState((prevState) => ({
      ...prevState,
      [employeeId]: !prevState[employeeId],
    }));
  };

  const handleInputChange = (employeeId: string, field: string, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [employeeId]: {
        ...prevState[employeeId],
        [field]: value,
      },
    }));
  };

  const handleSave = async (employeeId: string) => {
    const employeeData = employees.find((emp) => emp.id === employeeId);
    const formValues = formData[employeeId];
    const {
      address,
      startDate,
      dateOfBirth,
      compensationType = CompensationType.HOURLY,
      rate,
    } = formValues;

    if (!employeeData) {
      toast.error('Employee data not found');
      return;
    }

    const formattedDateOfBirth = dateOfBirth ? moment(dateOfBirth).format('YYYY-MM-DD') : null;
    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : null;

    if (!formattedDateOfBirth) {
      toast.error('Invalid date of birth');
      return;
    }

    if (!formattedStartDate) {
      toast.error('Invalid start date');
      return;
    }

    const employeeInput = {
      firstName: employeeData.firstName,
      lastName: employeeData.lastName,
      email: employeeData.email,
      dateOfBirth: formattedDateOfBirth,
      startDate: formattedStartDate,
      compensation: {
        amount: rate,
        type: compensationType as CompensationType,
      },
      address: {
        line1: address.line1,
        line2: address.line2,
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
        country: 'US',
      },
      workplaceId: employeeData.workplace?.id || '',
      active: employeeData.active,
    };

    try {
      if (employeeData.employee?.id) {
        await updateEmployee({
          ...employeeInput,
          providerIdentifier: employeeData.platformIdentifier || '',
          workplaceId: employeeData.workplace?.id || '',
        });
        toast.success('Employee information updated and onboarding email has been sent.');
      } else {
        const employeeResponse = await createEmployee(employeeInput, { id: employeeId });
        if (employeeResponse) {
          toast.success('Employee information updated and onboarding email has been sent.');
        } else {
          toast.error('Failed to save employee due to missing or invalid info.');
          // Return to stop deleteIncomingEmployee from running if error
          return;
        }
      }

      await deleteIncomingEmployee({ id: employeeId });

      const updatedEmployees = employees.filter((employee) => employee.id !== employeeId);
      setEmployees(updatedEmployees);
      onEmployeeDeleted(updatedEmployees);

      toggleEditMode(employeeId);
    } catch (error) {
      toast.error('Failed to save employee');
    }
  };

  const handleCancel = (employeeId: string) => {
    toggleEditMode(employeeId);
  };

  const handleOpenAddressModal = (employeeId: string) => {
    setAddressModalIsOpen((prevState) => ({
      ...prevState,
      [employeeId]: true,
    }));
  };

  const handleCloseAddressModal = (employeeId: string) => {
    setAddressModalIsOpen((prevState) => ({
      ...prevState,
      [employeeId]: false,
    }));
  };

  const handleFormSubmit = (employeeId: string, newAddress: AddressFormData) => {
    handleInputChange(employeeId, 'address', newAddress);
    handleCloseAddressModal(employeeId);
  };

  const toggleSortDirection = () => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    onSortChange(newDirection);
  };

  if (employees.length === 0) {
    return <EmptyMessage>No incoming employees found.</EmptyMessage>;
  }

  return (
    <StyledTable>
      <Thead>
        <Tr>
          <Th>
            Name{' '}
            <SortButton
              icon={<ArrowIcon />}
              onClick={toggleSortDirection}
              sortDirection={sortDirection}
            />
          </Th>
          <Th>Address</Th>
          <Th>Date of Birth</Th>
          <Th>Start Date</Th>
          <Th>Compensation Type</Th>
          <Th>Rate</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {employees.map((employee) => {
          const isEditing = editState[employee.id] || false;
          const address = formData[employee.id]?.address || employee.address;

          return (
            <Tr key={employee.id} className={isEditing ? 'edit-mode' : ''}>
              <Td>{`${employee.firstName} ${employee.lastName}`}</Td>
              <Td>
                {isEditing ? (
                  <ActionButton
                    onClick={() => handleOpenAddressModal(employee.id)}
                    size="medium"
                    title={address ? 'Edit Address' : 'Add Address'}
                    hidden={false}
                    variant="text"
                  />
                ) : address ? (
                  <>
                    {address.line1 && <div>{address.line1}</div>}
                    {address.line2 && <div>{address.line2}</div>}
                    <div>
                      {address.city ?? 'N/A'}, {address.state ?? 'N/A'}{' '}
                      {address.postalCode ?? 'N/A'}, {address.country ?? 'US'}
                    </div>
                  </>
                ) : (
                  'No Address'
                )}
              </Td>
              <Td>
                {isEditing ? (
                  <DateInputWrapper>
                    <DateInput
                      control={control as unknown as Control}
                      name={`dateOfBirth_${employee.id}`}
                      placeholder="Date of Birth"
                      defaultValue={
                        formData[employee.id]?.dateOfBirth || employee.dateOfBirth || ''
                      }
                      dateRestriction="past"
                      onChange={(date) => handleInputChange(employee.id, 'dateOfBirth', date)}
                    />
                  </DateInputWrapper>
                ) : (
                  formData[employee.id]?.dateOfBirth || employee.dateOfBirth || 'No Date of Birth'
                )}
              </Td>
              <Td>
                {isEditing ? (
                  <DateInputWrapper>
                    <DateInput
                      control={control as unknown as Control}
                      name={`startDate_${employee.id}`}
                      placeholder="Start Date"
                      defaultValue={formData[employee.id]?.startDate || ''}
                      onChange={(date) => handleInputChange(employee.id, 'startDate', date)}
                    />
                  </DateInputWrapper>
                ) : (
                  formData[employee.id]?.startDate || 'No Start Date'
                )}
              </Td>
              <CompensationTypeCell>
                {isEditing ? (
                  <FormSelect
                    id={`compensationType_${employee.id}`}
                    options={[
                      { value: CompensationType.SALARIED, label: 'Salary' },
                      { value: CompensationType.HOURLY, label: 'Hourly' },
                    ]}
                    defaultValue={
                      formData[employee.id]?.compensationType || CompensationType.HOURLY
                    }
                    onChange={(e) =>
                      handleInputChange(employee.id, 'compensationType', e.target.value)
                    }
                  />
                ) : (
                  formData[employee.id]?.compensationType || 'No Compensation Type'
                )}
              </CompensationTypeCell>
              <Td>
                {isEditing ? (
                  <TableInput
                    type="text"
                    value={parseFloat(formData[employee.id]?.rate) || 0}
                    onChange={(value) => handleInputChange(employee.id, 'rate', value)}
                    format="currency"
                  />
                ) : (
                  formData[employee.id]?.rate || 'No Rate'
                )}
              </Td>
              <Td>
                <EditCell className={isEditing ? 'edit-mode' : ''}>
                  {isEditing ? (
                    <>
                      <SaveButton icon={<SaveIcon />} onClick={() => handleSave(employee.id)} />
                      <CloseButton icon={<CloseIcon />} onClick={() => handleCancel(employee.id)} />
                    </>
                  ) : (
                    <EditButton icon={<EditIcon />} onClick={() => toggleEditMode(employee.id)} />
                  )}
                </EditCell>
              </Td>
              {addressModalIsOpen[employee.id] && (
                <Modal
                  isOpen={addressModalIsOpen[employee.id]}
                  onRequestClose={() => handleCloseAddressModal(employee.id)}
                >
                  <FormHeading>{address ? 'Edit Address' : 'Add Address'}</FormHeading>
                  {employee && (
                    <EmployeeAddressForm
                      address={address}
                      handleFormSubmit={(newAddress) => handleFormSubmit(employee.id, newAddress)}
                    />
                  )}
                  <CloseButton
                    icon={<CloseIcon />}
                    onClick={() => handleCloseAddressModal(employee.id)}
                  />
                </Modal>
              )}
            </Tr>
          );
        })}
      </Tbody>
    </StyledTable>
  );
};
