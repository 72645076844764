import styled from 'styled-components';

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 6px 24px;
  min-width: 150px;

  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: 16px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;

  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 16px;
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: default;

    &:active {
      opacity: 0.6;
    }
  }

  &:active {
    opacity: 0.9;
  }
`;
