import styled from 'styled-components';

type FormInputContainerProps = {
  column?: 2 | 3;
};

export const FormInputContainer = styled.div<FormInputContainerProps>`
  min-width: ${({ column }) => (column ? 360 / column : 360)}px;
  margin-bottom: 16px;
`;

export const FormLabel = styled.label`
  font-size: ${(props) => props.theme.fontSizes.normal};
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  position: relative;
  margin: 0;
  width: 100%;
  min-width: 140px;
  padding: 3px 10px 0;
  height: 40px;

  border: 1px solid ${(props) => props.theme.colors.gray0};
  border-radius: 6px;
  outline: none;

  font-size: ${(props) => props.theme.fontSizes.normal};
  font-family: ${(props) => props.theme.fontFamily.default};

  &::placeholder {
    opacity: 0.5;
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.blue2} !important;
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.disabled};
    color: ${(props) => props.theme.colors.black2};
  }
`;

export const InputError = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.colors.error};
  padding: 5px;
`;
