import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';
import { PayrollDetails } from './fetchProviderPayroll';

type PreviewDraftPayrollResponse = {
  data: {
    previewDraftPayroll: PayrollDetails;
  };
};

export const previewDraftPayroll = (providerId: string): Promise<PayrollDetails | void> => {
  const query = `
    query PreviewDraftPayroll($payrollInput: PayrollInput!) {
      previewDraftPayroll(payrollInput: $payrollInput) {
        approvalDeadline
        approvedAt
        fundingPaymentMethod
        isVoid
        managed
        offCycleOptions
        payFrequency
        payday
        periodEnd
        periodStart
        processingPeriod
        providerId
        status
        type
        items {
          netPay
          paymentMethod
          payrollProviderId
          providerId
          status
          employee {
            id
            name
            onboarded
            compensation {
              amount
              type
            }
          }
          earnings {
            amount
            description
            earningCode
            earningRate
            hours
            type
            workplaceIdentifier
          }
          reimbursements {
            amount
            code
            description
          }
          taxes {
            amount
            description
            payer
            providerId
            remittable
          }
        }
        totals {
          cashRequirement
          companyBenefits
          companyTaxes
          contractorGross
          contractorNet
          contractorReimbursements
          employeeBenefits
          employeeGross
          employeeNet
          employeeReimbursements
          employeeTaxes
          liability
          postTaxDeductions
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<PreviewDraftPayrollResponse>>('graphql', {
      query,
      variables: { payrollInput: { providerId } },
    })
    .then((response: AxiosResponse<PreviewDraftPayrollResponse>) => {
      return response.data.data.previewDraftPayroll;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      throw error;
    });
};
