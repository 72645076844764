import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from './sendErrorMessage';
import { Employee, Address, Compensation } from 'features/user/types';

export type UpdateEmployeeInput = {
  address: Address;
  compensation: Compensation;
  dateOfBirth: string;
  email: string;
  firstName: string;
  lastName: string;
  providerIdentifier: string;
  startDate: string;
  workplaceId: string;
  active: boolean;
  terminationDate?: string | null;
};

export type UpdateEmployeePayload = {
  employee: Employee;
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

export const updateEmployee = (employeeParams: UpdateEmployeeInput): Promise<Employee | void> => {
  const mutation = `
          mutation($employeeParams: UpdateEmployeeInput!) {
            updateEmployee(employeeParams: $employeeParams) {
              employee {
                id
                name
                active
                terminationDate
                providerAttributes {
                  address {
                    city
                    country
                    line1
                    postalCode
                    state
                  }
                  firstName
                  lastName
                  dateOfBirth
                  email
                }
                workplaces {
                  id
                  name
                  providerIdentifier
                }
              }
              error {
                message
                type
                inputErrors {
                  field
                  fieldPath
                  message
                }
              }
            }
          }
        `;

  const variables = {
    employeeParams,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { updateEmployee: UpdateEmployeePayload } }>>('graphql', {
      query: mutation,
      variables,
    })
    .then((response: AxiosResponse<{ data: { updateEmployee: UpdateEmployeePayload } }>) => {
      if (response.data.data.updateEmployee.error) {
        sendErrorMessage(response.data.data.updateEmployee.error);
      }
      return response.data.data.updateEmployee.employee;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
