import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from './sendErrorMessage';

export type Company = {
  id: string;
  name: string;
  onboarded: boolean;
  platformIdentifier: string;
  providerIdentifier: string;
};

export type SetCompanyStartDatePayload = {
  company: Company;
  error: {
    message: string;
    type: string;
    inputErrors: {
      field: string;
      fieldPath: string;
      message: string;
    }[];
  };
};

export const setCompanyStartDate = (startDate: string): Promise<Company | void> => {
  const mutation = `
        mutation($startDate: ISO8601Date!) {
            setCompanyStartDate(companyParams: { startDate: $startDate }) {
                company {
                    id
                    name
                    onboarded
                    platformIdentifier
                    providerIdentifier
                }
                error {
                    message
                    type
                    inputErrors {
                        field
                        fieldPath
                        message
                    }
                }
            }
        }
    `;

  const variables = {
    startDate,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { setCompanyStartDate: SetCompanyStartDatePayload } }>>(
      'graphql',
      {
        query: mutation,
        variables,
      }
    )
    .then(
      (response: AxiosResponse<{ data: { setCompanyStartDate: SetCompanyStartDatePayload } }>) => {
        if (response.data.data.setCompanyStartDate.error) {
          throw new Error(response.data.data.setCompanyStartDate.error.message);
        }
        return response.data.data.setCompanyStartDate.company;
      }
    )
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
