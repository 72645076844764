import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { RootState } from '@src/store';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { AuthorizeSignatory } from 'components/AuthorizeSignatory/AuthorizeSignatory';

export const CompanyOnboarding = () => {
  const currentUser = useAppSelector((state: RootState) => state.user.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.signatory) {
      navigate('/onboarding');
    }
  }, [currentUser, navigate]);

  return (
    <ContentContainer onboarding={true} contentAlign="center">
      {currentUser?.signatory ? null : <AuthorizeSignatory />}
    </ContentContainer>
  );
};
