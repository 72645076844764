import { useState } from 'react';
import { CompanyDocumentsTable } from 'components/Table/CompanyDocumentsTable/CompanyDocumentsTable';
import { ActionButton } from 'components/ActionButton/actionButton';
import { DocumentType } from 'lib/fetchCompanyDocuments';
import { UploadForm } from 'components/UploadForm/UploadForm';
import { TabContainer, TabInstruction, ButtonContainer } from '../../tabMenu.styles';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { Modal } from 'components/Modal/Modal';
import { UploadContainer, FormHeading, CloseButton } from './companyDocuments.styles';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';

export const CompanyProvidedDocuments = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFileUpload = () => {
    handleCloseModal();

    setRefresh((prevState) => !prevState);
  };

  return (
    <>
      <ButtonContainer>
        <ActionButton onClick={handleOpenModal} size="medium" title="Upload" hidden={false} />
      </ButtonContainer>
      <ContentContainer>
        <TabContainer>
          <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
            <UploadContainer>
              <FormHeading>Upload a file</FormHeading>
              <UploadForm onSuccess={handleFileUpload} />
              <CloseButton icon={<CloseIcon />} onClick={handleCloseModal} />
            </UploadContainer>
          </Modal>
          <CompanyDocumentsTable type={DocumentType.PROVIDED} refresh={refresh} />
        </TabContainer>
      </ContentContainer>
    </>
  );
};
