import { SignUpForm } from 'components/AuthForm/SignUpForm/signUpForm';
import { AuthHeader } from 'components/AuthHeader/authHeader';

export const SignUp = () => {
  return (
    <>
      <AuthHeader />
      <SignUpForm />
    </>
  );
};
