import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from './sendErrorMessage';
import { PayrollItem, EarningType } from './fetchProviderPayroll';

export type Earning = {
  amount: string;
  description: string | null;
  hours: string | null;
  type: EarningType;
};

type Reimbursement = {
  amount: string;
  description: string;
};

export type UpdatePayrollItemInput = {
  id: string;
  earnings?: Earning[];
  reimbursements?: Reimbursement[];
};

export type UpdatePayrollItemPayload = {
  payrollItem: PayrollItem;
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

export const updatePayrollItem = (
  payrollItemParams: UpdatePayrollItemInput
): Promise<PayrollItem | void> => {
  const mutation = `
          mutation UpdatePayrollItem($payrollItemParams: UpdatePayrollItemInput!) {
            updatePayrollItem(payrollItemInput: $payrollItemParams) {
              payrollItem {
                id
                providerIdentifier
                payroll {
                  providerAttributes {
                    items {
                      netPay
                      paymentMethod
                      payrollItemId
                      payrollProviderId
                      providerId
                      status
                      earnings {
                        amount
                        description
                        hours
                        type
                      }
                      reimbursements {
                        amount
                        description
                      }
                    }
                  }
                }
              }
              error {
                message
                type
                inputErrors {
                  field
                  fieldPath
                  message
                }
              }
            }
          }
        `;

  const variables = {
    payrollItemParams,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { updatePayrollItem: UpdatePayrollItemPayload } }>>(
      'graphql',
      {
        query: mutation,
        variables,
      }
    )
    .then((response: AxiosResponse<{ data: { updatePayrollItem: UpdatePayrollItemPayload } }>) => {
      if (response.data.data.updatePayrollItem.error) {
        sendErrorMessage(response.data.data.updatePayrollItem.error);
      }
      return response.data.data.updatePayrollItem.payrollItem;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
