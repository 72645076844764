import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

export type Paystub = {
  payday: string;
  payroll: string;
  periodEnd: string;
  periodStart: string;
  totalAmount: string;
  totalHours: string;
};

export type PageInfo = {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
};

type PaystubsListResponse = {
  data: {
    employeePaystubs: {
      nodes: Paystub[];
      pageInfo: PageInfo;
    };
  };
};

export const fetchEmployeePaystubs = (
  employeeId: string,
  firstCount: number,
  endCursor?: string,
  startDate?: string,
  endDate?: string
): Promise<{ paystubs: Paystub[]; pageInfo: PageInfo | null }> => {
  const after = endCursor ? `, after: "${endCursor}"` : '';

  const query = `
    query EmployeePaystubs($employeeInputVar: EmployeePaystubsInput!, $firstValue: Int!) {
      employeePaystubs(employeeInput: $employeeInputVar, first: $firstValue${after}) {
        nodes {
          payday
          payroll
          periodEnd
          periodStart
          totalAmount
          totalHours
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<PaystubsListResponse>>('graphql', {
      query,
      variables: {
        employeeInputVar: {
          id: employeeId,
          startDate,
          endDate,
        },
        firstValue: firstCount,
      },
    })
    .then((response: AxiosResponse<PaystubsListResponse>) => {
      const paystubs = response.data.data.employeePaystubs.nodes;
      const pageInfo = response.data.data.employeePaystubs.pageInfo;
      return { paystubs, pageInfo };
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return { paystubs: [], pageInfo: null };
    });
};
