import styled from 'styled-components';

export const PayScheduleFormContainer = styled.div``;

export const Form = styled.form`
  margin: 10px auto 5px;
  min-width: 890px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

export const Instruction = styled.p`
  font-size: 12px;
  margin: 5px 0;
`;
