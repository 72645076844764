import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from './sendErrorMessage';

type SignatoryInput = {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  employeeProviderId: string;
};

type Signatory = {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  providerIdentifier: string;
  title: string;
};

type CreateEmployeeSignatoryPayload = {
  signatory: Signatory;
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

// Create the function for the mutation
export const createEmployeeSignatory = (
  signatoryParams: SignatoryInput
): Promise<Signatory | void> => {
  const mutation = `
    mutation CreateEmployeeSignatory($signatoryParams: CreateEmployeeSignatoryInput!) {
      createEmployeeSignatory(signatoryParams: $signatoryParams) {
        signatory {
          email
          firstName
          id
          lastName
          providerIdentifier
          title
        }
        error {
          message
          type
          inputErrors {
            message
            field
            fieldPath
          }
        }
      }
    }
  `;

  const variables = {
    signatoryParams,
  };

  return procareApi
    .post<
      unknown,
      AxiosResponse<{ data: { createEmployeeSignatory: CreateEmployeeSignatoryPayload } }>
    >('graphql', {
      query: mutation,
      variables,
    })
    .then(
      (
        response: AxiosResponse<{
          data: { createEmployeeSignatory: CreateEmployeeSignatoryPayload };
        }>
      ) => {
        if (response.data.data.createEmployeeSignatory.error) {
          sendErrorMessage(response.data.data.createEmployeeSignatory.error);
        }
        return response.data.data.createEmployeeSignatory.signatory;
      }
    )
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
