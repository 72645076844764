import styled from 'styled-components';
import { IconButton } from 'components/IconButton/IconButton';
import { FormSelectContainer } from 'components/form/Select/select.styles';

export const SortButton = styled(IconButton)<{ sortDirection: 'asc' | 'desc' }>`
  display: inline-block;
  svg {
    width: 14px;
    height: 14px;
    fill: ${(props) => props.theme.colors.primary};
    vertical-align: middle;
    transform: ${(props) => (props.sortDirection === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.2s ease;
  }
`;

export const EditButton = styled(IconButton)`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  svg {
    width: 20px;
    height: 20px;
    fill: ${(props) => props.theme.colors.primary};
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  svg {
    width: 24px;
    height: 24px;
    fill: ${(props) => props.theme.colors.border2};
  }
`;

export const SaveButton = styled(IconButton)`
  position: absolute;
  right: 48px;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  svg {
    width: 24px;
    height: 24px;
    fill: ${(props) => props.theme.colors.primary};
  }
`;

export const EditCell = styled.div`
  position: relative;
  min-width: 60px;

  tr:hover & {
    ${EditButton} {
      display: inline-block;
    }
  }

  tr.edit-mode & {
    ${CloseButton}, ${SaveButton} {
      display: inline-block;
    }
  }
`;

export const CompensationTypeCell = styled.td`
  padding: 20px;
  border-top: 1px solid #ddd;

  ${FormSelectContainer} {
    min-width: 150px;
    margin-bottom: 0;
  }
`;

export const DateInputWrapper = styled.div`
  .DateInput_input {
    margin-bottom: 0;
  }
`;

export const ModalHeader = styled.div`
  padding: 16px;
`;

export const Heading = styled.h2`
  margin: 0;
  font-size: 1.5em;
  color: ${(props) => props.theme.colors.black0};
`;

export const Instruction = styled.div`
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 400;
  color: ${(props) => props.theme.colors.black0};
  margin: 16px 0;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableContainer = styled.div`
  padding: 16px;
`;

export const ButtonContainer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.buttonContainer};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-top: 16px;
`;

export const FormHeading = styled.h3`
  font-size: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
  margin: 0 10px;
  padding-bottom: 15px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray6};
`;

export const SearchInput = styled.input`
  padding: 8px;
  border: 1px solid ${(props) => props.theme.colors.gray0};
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
`;
