import { StyledLink } from './DownloadLink.styles';

type DownloadLinkProps = {
  onClick: () => void;
  children: React.ReactNode;
};

export const DownloadLink = ({ onClick, children }: DownloadLinkProps) => {
  return <StyledLink onClick={onClick}>{children}</StyledLink>;
};
