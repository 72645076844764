import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch } from 'hooks/redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { companyInfoSchema } from './validator'; // Make sure to update this schema as well
import {
  UpdateCompanyInfoFormContainer,
  UpdateForm,
  UpdateButtonContainer,
  FormSection,
  FormAddress,
} from './UpdateCompanyInfo.styles';
import { FormInput, FormGroup } from 'components/form';
import { ActionButton } from 'components/ActionButton/actionButton';
import { updateCompany } from 'lib/updateCompany';
import { Company } from 'features/company/types';
import { getCurrentCompany } from 'features/company/slice';
import Tooltip from 'components/Tooltip/tooltip';

export type CompanyInfoFormData = {
  legalName: string;
  tradeName?: string;
  phone: string;
};

export const UpdateCompanyInfoForm = ({
  companyDetails,
  handleFormSubmit,
}: {
  id: string;
  companyDetails: Company;
  handleFormSubmit: (updatedCompany: Company) => void;
}) => {
  const defaultValues: CompanyInfoFormData = {
    legalName: companyDetails.providerAttributes.legalName,
    tradeName: companyDetails.providerAttributes.tradeName,
    phone: companyDetails.providerAttributes.phone,
  };

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<CompanyInfoFormData>({
    resolver: yupResolver(companyInfoSchema),
    defaultValues,
  });

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<CompanyInfoFormData> = async (data) => {
    const response = await updateCompany(data);

    if (response) {
      handleFormSubmit(response);
      dispatch(getCurrentCompany());
    }
  };

  const isButtonDisabled = (): boolean => {
    const watchedFields = watch();

    return !(watchedFields.legalName && watchedFields.phone);
  };

  return (
    <UpdateCompanyInfoFormContainer>
      <UpdateForm>
        <FormGroup label="Company Name">
          <FormSection>
            <FormInput
              id="legalName"
              placeholder="Legal Name"
              column={2}
              type="text"
              required
              {...register('legalName')}
            />
            <FormInput
              id="tradeName"
              placeholder="Trade Name"
              column={2}
              type="text"
              {...register('tradeName')}
            />
          </FormSection>
        </FormGroup>

        <FormGroup label="Legal Address">
          <Tooltip
            content="Please contact support to edit your company’s legal address."
            direction="right"
          >
            <FormAddress>
              {companyDetails.providerAttributes.address?.line1}
              <br />
              {companyDetails.providerAttributes.address?.line2 && (
                <>
                  {companyDetails.providerAttributes.address?.line2}
                  <br />
                </>
              )}
              {companyDetails.providerAttributes.address?.city},&nbsp;
              {companyDetails.providerAttributes.address?.state}
              <br />
              {companyDetails.providerAttributes.address?.postalCode}
            </FormAddress>
          </Tooltip>
        </FormGroup>

        <FormGroup label="Contact Number">
          <FormInput
            id="phone"
            placeholder="Phone Number"
            type="tel"
            required
            {...register('phone')}
          />
        </FormGroup>
      </UpdateForm>
      <UpdateButtonContainer>
        <ActionButton
          onClick={handleSubmit(onSubmit)}
          size="medium"
          shape="fullWidth"
          title="Save"
          hidden={false}
          disabled={isButtonDisabled()}
        />
      </UpdateButtonContainer>
    </UpdateCompanyInfoFormContainer>
  );
};
