import { StyledTable, StyledLink, Thead, Tr, Th, Td, Tbody, EmptyMessage } from '../Table.styles';
import { ImportLog } from 'lib/fetchImportLogs';
import { formatTimestamp } from 'lib/utils/formatTimestamp';

export type TableProps = {
  syncLogs: ImportLog[];
};

export const Table = ({ syncLogs }: TableProps) => {
  if (syncLogs.length === 0) {
    return <EmptyMessage>No sync logs found. Please check back later.</EmptyMessage>;
  }

  return (
    <StyledTable>
      <Thead>
        <Tr>
          <Th>Sync ID</Th>
          <Th>Sync Type</Th>
          <Th>Last Synced</Th>
        </Tr>
      </Thead>
      <Tbody>
        {syncLogs.map((log) => (
          <Tr key={log.id}>
            <Td>
              <StyledLink href="">{log.id}</StyledLink>
            </Td>
            <Td>{log.import.type}</Td>
            <Td>{formatTimestamp(log.import.completedAt)}</Td>
          </Tr>
        ))}
      </Tbody>
    </StyledTable>
  );
};
