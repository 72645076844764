import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { childSupportDeductionSchema } from '../validator';
import { ChildSupportDeductionFormData } from '../types';
import moment from 'moment';
import { ActionButton } from 'components/ActionButton/actionButton';
import { FormGroup, FormInput } from 'components/form';
import { DateInput } from 'components/form/DateInput/dateInput';
import { DeductionFormContainer, Form, ButtonContainer } from '../PostTaxDeductionForm.styles';
import { UpdatePostTaxDeductionInput, updatePostTaxDeduction } from 'lib/updatePostTaxDeduction';
import {
  DeductionNode,
  ConfigurationObject,
  ChildSupportDeduction,
} from 'lib/fetchEmployeePostTaxDeductions';
import { toast } from 'react-toastify';

type UpdateChildSupportProps = {
  deduction: DeductionNode | null;
  onFormSubmit: () => void;
};

const isChildSupportDeduction = (
  obj: ConfigurationObject | undefined
): obj is ChildSupportDeduction => (obj ? 'agency' in obj : false);

export const UpdateChildSupportDeductionForm = ({
  deduction,
  onFormSubmit,
}: UpdateChildSupportProps) => {
  const defaultValues = {
    description: deduction?.description,
    agency: isChildSupportDeduction(deduction?.configurationObject)
      ? deduction?.configurationObject?.agency
      : undefined,
    externalId: isChildSupportDeduction(deduction?.configurationObject)
      ? deduction?.configurationObject?.externalId
      : undefined,
    issueDate: isChildSupportDeduction(deduction?.configurationObject)
      ? deduction?.configurationObject?.issueDate
      : undefined,
    maxPercent: isChildSupportDeduction(deduction?.configurationObject)
      ? deduction?.configurationObject?.maxPercent?.toString()
      : undefined,
    effectiveStart: deduction?.effectiveStart,
    effectiveEnd: deduction?.effectiveEnd,
    amount: isChildSupportDeduction(deduction?.configurationObject)
      ? deduction?.configurationObject?.amount
      : undefined,
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ChildSupportDeductionFormData>({
    resolver: yupResolver(childSupportDeductionSchema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<ChildSupportDeductionFormData> = async (
    data: ChildSupportDeductionFormData
  ) => {
    const postTaxDeductionData: UpdatePostTaxDeductionInput = {
      providerId: deduction?.providerId || '',
      description: data.description,
      effectiveStart: moment(data.effectiveStart).format('YYYY-MM-DD'),
      effectiveEnd: data.effectiveEnd ? moment(data.effectiveEnd).format('YYYY-MM-DD') : undefined,
      configurationObject: {
        childSupport: {
          agency: data.agency,
          amount: data.amount,
          externalId: data.externalId,
          issueDate: moment(data.issueDate).format('YYYY-MM-DD'),
          maxPercent: parseFloat(data.maxPercent),
        },
      },
    };

    try {
      const result = await updatePostTaxDeduction(postTaxDeductionData);
      if (result) {
        toast.success('Employee deduction has been successfully updated.');
        onFormSubmit();
      }
    } catch (error) {
      toast.error('Failed to update deduction.');
    }
  };

  const isButtonDisabled = (): boolean =>
    !watch('description') || !watch('effectiveStart') || !watch('agency') || !watch('externalId');

  return (
    <DeductionFormContainer>
      <Form>
        <FormGroup label="General Information">
          <FormInput
            id="description"
            placeholder="Description"
            type="text"
            required
            {...register('description')}
          />
          <FormInput
            id="agency"
            placeholder="Agency"
            type="text"
            required
            {...register('agency')}
          />
          <FormInput
            id="externalId"
            placeholder="External ID"
            type="text"
            required
            {...register('externalId')}
          />
          <DateInput
            control={control}
            name="issueDate"
            placeholder="Issue Date"
            defaultValue={moment(defaultValues.issueDate).format('YYYY-MM-DD')}
          />
        </FormGroup>
        <FormGroup label="Effective Dates">
          <DateInput
            control={control}
            name="effectiveStart"
            placeholder="Start Date"
            defaultValue={moment(defaultValues.effectiveStart).format('YYYY-MM-DD')}
          />
          <DateInput
            control={control}
            name="effectiveEnd"
            placeholder="End Date"
            defaultValue={moment(defaultValues.effectiveEnd).format('YYYY-MM-DD')}
          />
        </FormGroup>
        <FormGroup label="Amount">
          <FormInput
            id="amount"
            placeholder="Amount Per Pay Period (Required)"
            type="text"
            required
            {...register('amount')}
          />
          <FormInput
            id="maxPercent"
            placeholder="Max Percent (Required)"
            type="text"
            required
            {...register('maxPercent')}
          />
        </FormGroup>
      </Form>
      <ButtonContainer>
        <ActionButton
          onClick={handleSubmit(onSubmit)}
          title="Save Deduction"
          size="medium"
          disabled={isButtonDisabled()}
        />
      </ButtonContainer>
    </DeductionFormContainer>
  );
};
