import { KeyboardEventHandler } from 'react';
import { SubmitHandler, UseFormHandleSubmit, FieldValues } from 'react-hook-form';

export const createFormKeyPressHandler = <T extends FieldValues>(
  handleSubmit: UseFormHandleSubmit<T>,
  onSubmit: SubmitHandler<T>
): KeyboardEventHandler<HTMLFormElement> => {
  const submitForm = handleSubmit(onSubmit);

  return (e) => {
    if (e.key === 'Enter' && !e.defaultPrevented) {
      e.preventDefault();
      submitForm(e as unknown as React.BaseSyntheticEvent);
    }
  };
};
