import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/redux';
import { Table } from './Table';
import { Employee, fetchProviderEmployeesList } from 'lib/fetchProviderEmployeesList';
import { CurrentUserContext } from 'features/user/types';
import { Spinner } from 'components/Spinner/spinner';
import { Pagination } from 'components/Pagination/pagination';
import EmptyStatePlaceholder from 'components/Placeholder/EmptyStatePlaceholder';
import { toast } from 'react-toastify';

type UserState = {
  user: {
    currentUser: CurrentUserContext | null;
  };
};

type PageInfo = {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
};

type EmployeesTableProps = {
  setEmployees: React.Dispatch<React.SetStateAction<Employee[]>>;
  refreshTrigger: boolean;
};

export const EmployeesTable = ({ setEmployees, refreshTrigger }: EmployeesTableProps) => {
  const { currentUser } = useAppSelector((state: UserState) => state.user);
  const [loading, setLoading] = useState(true);
  const [employees, setLocalEmployees] = useState<Employee[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);

  const loadEmployees = async (cursor?: string) => {
    setLoading(true);
    try {
      const { employees: fetchedEmployees, pageInfo: fetchedPageInfo } =
        await fetchProviderEmployeesList(cursor);
      setLocalEmployees(fetchedEmployees);
      setEmployees(fetchedEmployees);
      setPageInfo(fetchedPageInfo);
    } catch (error) {
      toast.error('Unable to fetch employees');
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (pageInfo?.hasNextPage) {
      loadEmployees(pageInfo.endCursor);
    }
  };

  const onPrev = () => {
    if (pageInfo?.hasPreviousPage) {
      loadEmployees(pageInfo.startCursor);
    }
  };

  const onNext = () => {
    loadMore();
  };

  useEffect(() => {
    loadEmployees();
  }, [currentUser, endCursor, refreshTrigger]);

  return loading ? (
    <Spinner />
  ) : employees.length === 0 ? (
    <EmptyStatePlaceholder
      title="Add Your Employees"
      description="Let's onboard your employees so you can start running payroll."
      steps={[
        {
          iconName: 'staff',
          text: 'Seamlessly import current staff profiles ensuring a smooth and quick onboarding process for all employees.',
        },
        {
          iconName: 'processing',
          text: 'Simplify payroll processing and reduce your workload by managing all employee pay information in one place.',
        },
        {
          iconName: 'time',
          text: 'Automate calculations to save time on complex payroll tasks like taxes and deductions, guaranteeing accuracy and efficiency.',
        },
      ]}
    />
  ) : (
    <>
      {pageInfo && (pageInfo.hasPreviousPage || pageInfo.hasNextPage) && (
        <Pagination
          onPrev={onPrev}
          onNext={onNext}
          disablePrev={!pageInfo.hasPreviousPage}
          disableNext={!pageInfo.hasNextPage}
        />
      )}
      <Table employees={employees} />
    </>
  );
};
