import { useState } from 'react';
import { TabMenuContainer, TabMenuItem, StyledTabMenu } from './tabMenu.styles';

type TabMenuProps = {
  menuItems: { [key: string]: JSX.Element };
  defaultActiveItem: string;
};

export const TabMenu = ({ menuItems, defaultActiveItem }: TabMenuProps) => {
  const [activeItem, setActiveItem] = useState(defaultActiveItem);

  const handleClick = (item: string) => {
    setActiveItem(item);
  };

  return (
    <TabMenuContainer>
      <StyledTabMenu>
        {Object.keys(menuItems).map((item, index) => (
          <TabMenuItem key={index} onClick={() => handleClick(item)} active={activeItem === item}>
            {item}
          </TabMenuItem>
        ))}
      </StyledTabMenu>
      {menuItems[activeItem]}
    </TabMenuContainer>
  );
};
