import styled from 'styled-components';

export const HeadingContainer = styled.div``;

export const Heading = styled.h2`
  font-size: 28px;
  margin-bottom: 0;
`;

export const Instruction = styled.p`
  color: ${(props) => props.theme.colors.black};
`;

export const SubHeading = styled.h3`
  font-size: 24px;
  margin: 40px 0 10px;
`;

export const ModalHeading = styled.h3`
  font-size: 24px;
  color: ${(props) => props.theme.colors.black};
`;

export const ModalMessage = styled.p`
  color: ${(props) => props.theme.colors.black};
`;

export const ModalImage = styled.img`
  padding: 10px;
`;

export const AggregateContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const Aggregate = styled.div`
  text-transform: uppercase;
  margin-right: 20px;
`;

export const Total = styled.div`
  color: ${(props) => props.theme.colors.black};
  font-weight: bold;
`;
