import { useState } from 'react';
import { StyledTable, Thead, Tr, Th, Td, Tbody } from '../Table.styles';
import { TableInput } from '../TableInput/TableInput';
import {
  TimecardHeading,
  DateRange,
  TimecardDateRange,
  ButtonContainer,
  ModalHeader,
  TableContainer,
  ModalLink,
  TableBar,
  StyledSaveIcon,
  StyledSavingIcon,
  SaveStatusContainer,
  SavingMessage,
  SavedMessage,
} from './RunPayrollTables.styles';
import { PayrollItem, EarningType, PayrollStatus } from 'lib/fetchProviderPayroll';
import ReactModal from 'react-modal';
import TimecardTable from './TimecardTable';
import { fetchEmployeeTimecards, Timecard } from 'lib/fetchEmployeeTimecards';
import { updatePayrollItem } from 'lib/updatePayrollItem';
import { ActionButton } from 'components/ActionButton/actionButton';
import { Spinner } from 'components/Spinner/spinner';
import { toast } from 'react-toastify';
import moment from 'moment';

type InputFields = {
  hours: number;
  overtime: number;
  pto: number;
  sick: number;
};

type PayrollItemsTableProps = {
  items: PayrollItem[];
  periodStart: string;
  periodEnd: string;
  status: PayrollStatus | null;
  refreshPayrollData: () => void;
};

export const PayrollItemsTable = ({
  items,
  periodStart,
  periodEnd,
  status,
}: PayrollItemsTableProps) => {
  const [inputFields, setInputFields] = useState<Map<string, InputFields>>(
    new Map(
      items.map((item) => [
        item.employee.id,
        {
          hours: item.earnings.find((earning) => earning.type === EarningType.Hourly)?.hours || 0,
          overtime:
            item.earnings.find((earning) => earning.type === EarningType.Overtime)?.hours || 0,
          pto: item.earnings.find((earning) => earning.type === EarningType.PTO)?.hours || 0,
          sick: item.earnings.find((earning) => earning.type === EarningType.Sick)?.hours || 0,
        },
      ])
    )
  );

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<PayrollItem['employee'] | null>(null);
  const [timecards, setTimecards] = useState<Timecard[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const utcDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return utcDate.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const calculateGrossPay = (employee: PayrollItem['employee'], fields: InputFields): string => {
    if (!employee || !employee.compensation) return currencyFormatter.format(0);
    let hourlyRate: number;
    if (employee.compensation.type === 'HOURLY') {
      hourlyRate = employee.compensation.amount;
    } else if (employee.compensation.type === 'SALARIED') {
      hourlyRate = employee.compensation.amount / (40 * 52);
    } else {
      return currencyFormatter.format(0);
    }

    const regularPay = hourlyRate * fields.hours;
    const overtimePay = hourlyRate * 1.5 * fields.overtime;
    const ptoPay = hourlyRate * fields.pto;
    const sickPay = hourlyRate * fields.sick;

    const grossPay = regularPay + overtimePay + ptoPay + sickPay;

    return currencyFormatter.format(grossPay);
  };

  const handleInputChange = (employeeId: string, field: keyof InputFields, value: string): void => {
    const numericValue = parseFloat(value);
    setInputFields((prev) => {
      const currentFields = prev.get(employeeId) || { hours: 0, overtime: 0, pto: 0, sick: 0 };
      const updatedFields = { ...currentFields, [field]: numericValue || 0 };
      return new Map(prev).set(employeeId, updatedFields);
    });
  };

  const updateEarnings = async (employeeId: string, employeeCompensationAmount: number) => {
    setIsSaving(true);
    setIsSaved(false);

    const fields = inputFields.get(employeeId);
    if (!fields) return;

    const payrollItem = items.find((item) => item.employee.id === employeeId);
    if (payrollItem) {
      const existingEarnings = payrollItem.earnings.filter(
        (earning) =>
          earning.type !== EarningType.Hourly &&
          earning.type !== EarningType.Overtime &&
          earning.type !== EarningType.PTO &&
          earning.type !== EarningType.Sick
      );

      const newEarnings = [
        {
          type: EarningType.Hourly,
          amount: (fields.hours * employeeCompensationAmount).toFixed(2).toString(),
          hours: fields.hours.toString(),
          description: 'Regular hours',
        },
        {
          type: EarningType.Overtime,
          amount: (fields.overtime * employeeCompensationAmount).toFixed(2).toString(),
          hours: fields.overtime.toString(),
          description: 'Overtime hours',
        },
        {
          type: EarningType.PTO,
          amount: (fields.pto * employeeCompensationAmount).toFixed(2).toString(),
          hours: fields.pto.toString(),
          description: 'Paid time off',
        },
        {
          type: EarningType.Sick,
          amount: (fields.sick * employeeCompensationAmount).toFixed(2).toString(),
          hours: fields.sick.toString(),
          description: 'Sick leave',
        },
      ];

      const updatedExistingEarnings = existingEarnings.map((earning) => ({
        type: earning.type,
        amount: earning.amount.toString(),
        hours: earning.hours !== null ? earning.hours.toString() : null,
        description: earning.description,
      }));

      const earningsUpdate = [...updatedExistingEarnings, ...newEarnings];

      try {
        await updatePayrollItem({
          id: payrollItem.payrollItemId,
          earnings: earningsUpdate,
        });
        setIsSaving(false);
        setIsSaved(true);
      } catch (error) {
        setIsSaving(false);
        toast.error(`Failed to update payroll: ${error}`);
      }
    } else {
      toast.error('Payroll Item ID not found');
    }
  };

  const handleOpenModal = (employee: PayrollItem['employee']): void => {
    setSelectedEmployee(employee);
    setIsLoading(true);
    fetchEmployeeTimecards(periodStart, periodEnd, employee.id)
      .then(({ timecards: fetchedTimecards }) => {
        setTimecards(fetchedTimecards);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
    setModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setModalOpen(false);
    setSelectedEmployee(null);
    setTimecards([]);
  };

  return (
    <>
      <TableBar>
        <DateRange>{`Pay Period: ${moment(periodStart).format('MMMM D, YYYY')} - ${moment(
          periodEnd
        ).format('MMMM D, YYYY')}`}</DateRange>
        <SaveStatusContainer>
          {isSaving && (
            <SavingMessage>
              <StyledSavingIcon />
              Saving...
            </SavingMessage>
          )}
          {isSaved && (
            <SavedMessage>
              <StyledSaveIcon />
              Saved
            </SavedMessage>
          )}
        </SaveStatusContainer>
      </TableBar>
      <StyledTable>
        <Thead>
          <Tr>
            <Th>Employee Name</Th>
            <Th>Rate</Th>
            <Th>Hours</Th>
            <Th>Overtime</Th>
            <Th>PTO</Th>
            <Th>Sick</Th>
            <Th>Gross Pay</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map((item) => {
            const fields = inputFields.get(item.employee.id) || {
              hours: 0,
              overtime: 0,
              pto: 0,
              sick: 0,
            };
            const rateDisplay = item.employee.compensation
              ? `${currencyFormatter.format(item.employee.compensation.amount)} / ${
                  item.employee.compensation.type === 'HOURLY' ? 'hour' : 'year'
                }`
              : 'N/A';
            const grossPay = calculateGrossPay(item.employee, fields);
            const isDisabled =
              item.employee.compensation.type === 'SALARIED' || status !== PayrollStatus.Draft;

            return (
              <Tr key={item.employee.id}>
                <Td>
                  {item.employee.compensation.type === 'SALARIED' ? (
                    <span>{item.employee.name}</span>
                  ) : (
                    <ModalLink
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenModal(item.employee);
                      }}
                    >
                      {item.employee.name}
                    </ModalLink>
                  )}
                </Td>
                <Td>{rateDisplay}</Td>
                <Td>
                  <TableInput
                    value={fields.hours}
                    onChange={(newValue) => handleInputChange(item.employee.id, 'hours', newValue)}
                    onBlur={() =>
                      updateEarnings(item.employee.id, item.employee.compensation.amount)
                    }
                    disabled={isDisabled}
                  />
                </Td>
                <Td>
                  <TableInput
                    value={fields.overtime}
                    onChange={(newValue) =>
                      handleInputChange(item.employee.id, 'overtime', newValue)
                    }
                    onBlur={() =>
                      updateEarnings(item.employee.id, item.employee.compensation.amount)
                    }
                    disabled={isDisabled}
                  />
                </Td>
                <Td>
                  <TableInput
                    value={fields.pto}
                    onChange={(newValue) => handleInputChange(item.employee.id, 'pto', newValue)}
                    onBlur={() =>
                      updateEarnings(item.employee.id, item.employee.compensation.amount)
                    }
                    disabled={isDisabled}
                  />
                </Td>
                <Td>
                  <TableInput
                    value={fields.sick}
                    onChange={(newValue) => handleInputChange(item.employee.id, 'sick', newValue)}
                    onBlur={() =>
                      updateEarnings(item.employee.id, item.employee.compensation.amount)
                    }
                    disabled={isDisabled}
                  />
                </Td>
                <Td>{grossPay}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </StyledTable>
      {selectedEmployee && (
        <ReactModal
          style={{
            overlay: {
              position: 'fixed',
              inset: '0px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 11,
            },
            content: {
              top: '45%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              transform: 'translate(-50%, -50%)',
              borderRadius: '16px',
              overflow: 'visible',
              padding: '0px',
            },
          }}
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
        >
          <ModalHeader>
            <TimecardHeading>{`${selectedEmployee.name} Timesheet`}</TimecardHeading>
          </ModalHeader>
          <TimecardDateRange>{`Dates: ${formatDate(periodStart)} - ${formatDate(
            periodEnd
          )}`}</TimecardDateRange>
          <TableContainer>
            {isLoading ? <Spinner /> : <TimecardTable timecards={timecards} />}
          </TableContainer>
          <ButtonContainer>
            <ActionButton onClick={handleCloseModal} size="medium" title="Close" hidden={false} />
          </ButtonContainer>
        </ReactModal>
      )}
    </>
  );
};
