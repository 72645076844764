import { useState } from 'react';
import { Modal } from 'components/Modal/Modal';
import { ActionButton } from 'components/ActionButton/actionButton';
import { SignatoriesTable } from 'components/Table/SignatoriesTable/SignatoriesTable';
import { CreateSignatoryForm } from 'components/CreateSignatoryForm/CreateSignatoryForm';
import { TabContainer, TabHeader, TabContent } from '../../../tabMenu.styles';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import {
  SignatoryFormContainer,
  FormHeading,
  Instruction,
  CloseButton,
} from './signatories.styles';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';

export const Signatories = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [refreshSignatories, setRefreshSignatories] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFormSubmit = () => {
    handleCloseModal();
    setRefreshSignatories((prev) => !prev);
  };

  return (
    <TabContainer>
      <TabHeader type="company">
        <ActionButton
          onClick={handleOpenModal}
          size="medium"
          title="Create Signatory"
          hidden={false}
        />
      </TabHeader>
      <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
        <SignatoryFormContainer>
          <FormHeading>Create Signatory</FormHeading>
          <Instruction>
            Select from the employee list to populate the signatory information.
          </Instruction>
          <CreateSignatoryForm onFormSubmitSuccess={handleFormSubmit} />

          <CloseButton icon={<CloseIcon />} onClick={handleCloseModal} />
        </SignatoryFormContainer>
      </Modal>
      <ContentContainer contentAlign="left">
        <TabContent>
          <SignatoriesTable refresh={refreshSignatories} />
        </TabContent>
      </ContentContainer>
    </TabContainer>
  );
};
