import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendAuthErrorMessage } from './sendAuthErrorMessage';

export const downloadPayrollJournal = (
  startDate?: string,
  endDate?: string
): Promise<Blob | void> => {
  return procareApi
    .get<Blob>(`/api/v1/companies/reports/payroll_journal/download`, {
      params: { start_date: startDate, end_date: endDate },
      responseType: 'blob',
    })
    .then((response: AxiosResponse<Blob>) => {
      return response.data;
    })
    .catch((error: AxiosError<ErrorResponse>) => {
      sendAuthErrorMessage(error);
    });
};
