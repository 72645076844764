import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';
import { DEFAULT_PAGE_SIZE } from '@src/constants/constants';

export type InputError = {
  field: string;
  fieldPath: string;
  message: string;
};

export type ImportError = {
  message: string;
  type: string;
  inputErrors: InputError[];
};

export type ImportErrorLog = {
  id: string;
  errorsList: ImportError[];
  import: {
    completedAt: string;
    id: string;
    startedAt: string;
    type: string;
  };
  failedAt: string;
};

export type ImportErrorEdge = {
  node: ImportErrorLog;
  cursor: string;
};

export type PageInfo = {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
};

export type ImportErrorsResponse = {
  data: {
    importErrorsList: {
      edges: ImportErrorEdge[];
      pageInfo: PageInfo;
    };
  };
};

export const fetchImportErrors = (
  endCursor?: string
): Promise<{ importErrors: ImportErrorLog[]; pageInfo: PageInfo | null }> => {
  const after = endCursor ? `, after: "${endCursor}"` : '';

  const query = `
    query ImportErrorsList {
      importErrorsList(first: ${DEFAULT_PAGE_SIZE}${after}) {
        edges {
          node {
            id
            errorsList {
              message
              type
              inputErrors {
                field
                fieldPath
                message
              }
            }
            import {
              completedAt
              id
              startedAt
              type
            }
            failedAt
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<ImportErrorsResponse>>('graphql', { query })
    .then((response: AxiosResponse<ImportErrorsResponse>) => {
      const importErrors = response.data.data.importErrorsList.edges.map((edge) => edge.node);
      const pageInfo = response.data.data.importErrorsList.pageInfo;
      return { importErrors, pageInfo };
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return { importErrors: [], pageInfo: null };
    });
};
