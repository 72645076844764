import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  StyledDropzone,
  ChooseFileLink,
  FileWrapper,
  FileName,
  FileInfo,
  ClearFileButton,
  StyledUploadIcon,
} from './FileUpload.styles';
import { toast } from 'react-toastify';
import { ReactComponent as CloseCircleIcon } from '@src/assets/icons/close-circle.svg';

export const FileUpload: React.FC<{
  onFileUpload: (files: File[]) => void;
}> = ({ onFileUpload }) => {
  const [fileName, setFileName] = useState<string | null>(null);

  const MAX_FILE_SIZE = 25 * 1024 * 1024;

  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    onDrop: (files) => {
      onFileUpload(files);
      setFileName(files[0]?.name || null);
    },
    noClick: true,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf'],
    },
  });

  if (fileRejections.length > 0) {
    toast.error('Only pdf, jpeg, and png files are allowed with a maximum file size of 25MB.');
  }

  const clearFile = () => {
    setFileName(null);
    onFileUpload([]);
  };

  return (
    <div>
      <StyledDropzone {...getRootProps()}>
        <input {...getInputProps()} />
        <StyledUploadIcon />
        <p>
          <ChooseFileLink onClick={open}>Choose a file</ChooseFileLink> or drop a file here
        </p>
        <FileInfo>
          Max File Size: <strong>25MB</strong> <br />
          Supported File Types: <strong>PDF, JPG, PNG</strong>
        </FileInfo>
      </StyledDropzone>
      {fileName && (
        <FileWrapper>
          <FileName>{fileName}</FileName>
          <ClearFileButton icon={<CloseCircleIcon />} onClick={clearFile} />
        </FileWrapper>
      )}
    </div>
  );
};
