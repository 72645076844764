import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';

type RunStaffImportPayload = {
  completed: boolean;
};

export const runStaffImport = async (): Promise<boolean> => {
  const mutation = `
    mutation RunStaffImport {
      runStaffImport {
        completed
      }
    }
  `;

  try {
    const response = await procareApi.post<
      unknown,
      AxiosResponse<{ data: { runStaffImport: RunStaffImportPayload } }>
    >('graphql', {
      query: mutation,
    });

    const { completed } = response.data.data.runStaffImport;

    if (!completed) {
      throw new Error('Staff import reported incomplete.');
    }
    return completed;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(`Staff import failed: ${error.response.data}`);
    }
    throw error;
  }
};
