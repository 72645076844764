import { TabContainer } from '../../tabMenu.styles';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { CompanyDocumentsTable } from 'components/Table/CompanyDocumentsTable/CompanyDocumentsTable';
import { DocumentType } from 'lib/fetchCompanyDocuments';

export const CompanyAuthorizationDocuments = () => {
  return (
    <ContentContainer>
      <TabContainer>
        <CompanyDocumentsTable type={DocumentType.AUTHORIZATION} />
      </TabContainer>
    </ContentContainer>
  );
};
