import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

export enum DocumentType {
  TAX = 'tax',
  AUTHORIZATION = 'authorization',
  PROVIDED = 'provided',
}

export type CompanyDocument = {
  category: string;
  description: string;
  filedOn: string;
  id: string;
  jurisdiction: string;
  label: string;
  quarter: string;
  year: string;
};

export type ProvidedDocument = {
  company: string;
  documentType: string;
  fileContentType: string;
  id: string;
  providedFilename: string;
  uploadStatus: string;
};

export type PageInfo = {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
};

type CompanyDocumentListResponse = {
  data: {
    companyDocuments: {
      nodes: (CompanyDocument | ProvidedDocument)[];
      pageInfo: PageInfo;
    };
  };
};

const DEFAULT_DOC_SIZE = 5;

export const fetchCompanyDocuments = (
  documentType: DocumentType = DocumentType.AUTHORIZATION,
  firstCount: number = DEFAULT_DOC_SIZE,
  endCursor?: string
): Promise<{ documents: (CompanyDocument | ProvidedDocument)[]; pageInfo: PageInfo | null }> => {
  const after = endCursor ? `, after: "${endCursor}"` : '';

  const documentFields =
    documentType === DocumentType.PROVIDED
      ? `
    ... on ProvidedDocument {
      company
      documentType
      fileContentType
      id
      providedFilename
      uploadStatus
    }
  `
      : `
    ... on Document {
      category
      description
      filedOn
      id
      jurisdiction
      label
      quarter
      year
    }
  `;

  const query = `
    query CompanyDocuments {
      companyDocuments(companyDocument: { type: ${documentType} }, first: ${firstCount}${after})
      {
        nodes {
          ${documentFields}
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<CompanyDocumentListResponse>>('graphql', { query })
    .then((response: AxiosResponse<CompanyDocumentListResponse>) => {
      const documents = response.data.data.companyDocuments.nodes;
      const pageInfo = response.data.data.companyDocuments.pageInfo;
      return { documents, pageInfo };
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return { documents: [], pageInfo: null };
    });
};
