import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

type EmployeePaymentSetupResponse = {
  data: {
    employeePaymentSetup: {
      url: string;
    };
  };
};

export const fetchEmployeePaymentSetup = (employeeId: string) => {
  const query = `
    query EmployeePaymentSetup($employeeInput: EmployeeInput!) { 
      employeePaymentSetup(employeeInput: $employeeInput) { 
        url 
      } 
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<EmployeePaymentSetupResponse>>('graphql', {
      query,
      variables: {
        employeeInput: {
          id: employeeId,
        },
      },
    })
    .then((response: AxiosResponse<EmployeePaymentSetupResponse>) => {
      return response.data.data.employeePaymentSetup.url;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
