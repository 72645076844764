import { useState } from 'react';
import { DeductionNode, PostTaxDeductionTypes } from 'lib/fetchEmployeePostTaxDeductions';
import { UpdateChildSupportDeductionForm } from './ChildSupportDeductionForm/UpdateChildSupportDeductionForm';
import { UpdateMiscDeductionForm } from './MiscDeductionForm/UpdateMiscDeductionForm';
import { DeductionHeading, PostTaxDeductionFormContainer } from './PostTaxDeductionForm.styles';

type UpdatePostTaxDeductionProps = {
  deduction: DeductionNode | null;
  onClose: () => void;
};

export const UpdatePostTaxDeductionForm = ({ deduction, onClose }: UpdatePostTaxDeductionProps) => {
  const initialDeductionType =
    deduction && deduction.type === PostTaxDeductionTypes.ChildSupport
      ? PostTaxDeductionTypes.ChildSupport
      : PostTaxDeductionTypes.Miscellaneous;

  const [selectedDeduction] = useState<PostTaxDeductionTypes>(initialDeductionType);

  const onFormSubmit = () => {
    onClose();
  };

  // Determine the heading text based on the initial deduction type
  const headingText =
    initialDeductionType === PostTaxDeductionTypes.ChildSupport
      ? 'Child Support Garnishment'
      : 'Miscellaneous Deduction';

  return (
    <PostTaxDeductionFormContainer>
      <DeductionHeading>{headingText}</DeductionHeading>

      {selectedDeduction === PostTaxDeductionTypes.Miscellaneous && (
        <UpdateMiscDeductionForm deduction={deduction} onFormSubmit={onFormSubmit} />
      )}
      {selectedDeduction === PostTaxDeductionTypes.ChildSupport && (
        <UpdateChildSupportDeductionForm deduction={deduction} onFormSubmit={onFormSubmit} />
      )}
    </PostTaxDeductionFormContainer>
  );
};
