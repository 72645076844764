import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

export enum PayrollStatus {
  Draft = 'draft',
  Pending = 'pending',
  Processing = 'processing',
  Failed = 'failed',
  PartiallyPaid = 'partially_paid',
  Paid = 'paid',
}

export enum EarningType {
  Bonus = 'bonus',
  Commission = 'commission',
  Hourly = 'hourly',
  Overtime = 'overtime',
  PTO = 'pto',
  Sick = 'sick',
  Salaried = 'salaried',
}

export enum PayerType {
  Company = 'company',
  Employee = 'employee',
}

export type PayrollTax = {
  amount: number;
  description: string;
  payer: PayerType;
  providerId: string;
  remittable: boolean;
};

export type PayrollInput = {
  providerId: string;
};

export type PayrollEarning = {
  amount: number;
  description: string;
  earningCode: string;
  earningRate: number;
  hours: number;
  type: EarningType;
  workplaceIdentifier: string;
};

export type PayrollReimbursement = {
  amount: number;
  code: string;
  description: string;
};

type EmployeeCompensation = {
  amount: number;
  type: string;
};

type Employee = {
  id: string;
  name: string;
  onboarded: boolean;
  compensation: EmployeeCompensation;
};

export type PayrollItem = {
  payrollItemId: string;
  netPay: number;
  paymentMethod: string;
  payrollProviderId: string;
  providerId: string;
  status: PayrollStatus;
  employee: Employee;
  earnings: PayrollEarning[];
  reimbursements: PayrollReimbursement[];
  taxes: PayrollTax[];
};

type PayrollTotals = {
  cashRequirement: number;
  companyBenefits: number;
  companyTaxes: number;
  contractorGross: number;
  contractorNet: number;
  contractorReimbursements: number;
  employeeBenefits: number;
  employeeGross: number;
  employeeNet: number;
  employeeReimbursements: number;
  employeeTaxes: number;
  liability: number;
  postTaxDeductions: number;
};

export type PayrollDetails = {
  approvalDeadline: string;
  approvedAt: string;
  fundingPaymentMethod: string;
  isVoid: boolean;
  managed: boolean;
  offCycleOptions: string;
  payFrequency: string;
  payday: string;
  periodEnd: string;
  periodStart: string;
  processingPeriod: string;
  providerId: string;
  status: PayrollStatus;
  type: string;
  totals: PayrollTotals;
  items: PayrollItem[];
};

type ProviderPayrollResponse = {
  data: {
    providerPayroll: PayrollDetails;
  };
};

export const fetchProviderPayroll = (providerId: string): Promise<PayrollDetails | void> => {
  const query = `
    query ProviderPayroll($payrollInput: PayrollInput!) {
      providerPayroll(payrollInput: $payrollInput) {
        approvalDeadline
        approvedAt
        fundingPaymentMethod
        isVoid
        managed
        offCycleOptions
        payFrequency
        payday
        periodEnd
        periodStart
        processingPeriod
        providerId
        status
        type
        items {
          payrollItemId
          netPay
          paymentMethod
          payrollProviderId
          providerId
          status
          employee {
            id
            name
            onboarded
            compensation {
              amount
              type
            }
          }
          earnings {
            amount
            description
            earningCode
            earningRate
            hours
            type
            workplaceIdentifier
          }
          reimbursements {
            amount
            code
            description
          }
          taxes {
            amount
            description
            payer
            providerId
            remittable
          }
        }
        totals {
          cashRequirement
          companyBenefits
          companyTaxes
          contractorGross
          contractorNet
          contractorReimbursements
          employeeBenefits
          employeeGross
          employeeNet
          employeeReimbursements
          employeeTaxes
          liability
          postTaxDeductions
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<ProviderPayrollResponse>>('graphql', {
      query,
      variables: { payrollInput: { providerId } },
    })
    .then((response: AxiosResponse<ProviderPayrollResponse>) => {
      return response.data.data.providerPayroll;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      throw error;
    });
};
