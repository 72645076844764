import styled from 'styled-components';
import { IconButton } from 'components/IconButton/IconButton';

export const CloseButton = styled(IconButton)`
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
`;

export const WorkplaceListing = styled.div`
  border-top: 2px solid ${(props) => props.theme.colors.border};
  margin-top: 25px;
  padding: 10px 20px;
`;

export const WorkplaceListItem = styled.div`
  color: ${(props) => props.theme.colors.black};
  line-height: 1.5;
  margin: 25px 0;
`;

export const WorkplaceName = styled.div`
  font-weight: 500;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export const WorkplaceFormContainer = styled.div`
  padding: 0 16px;
  width: 800px;
`;

export const FormHeading = styled.h3`
  font-size: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
  margin: 0;
  padding-bottom: 15px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray6};
`;
