import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { payScheduleSchema } from '../validator';
import { PayFrequencyType } from '../types';
import {
  UpdatePayScheduleFormContainer,
  UpdateForm,
  UpdateButtonContainer,
} from './updatePayScheduleForm.styles';
import { FormSelect, FormGroup } from 'components/form';
import { ActionButton } from 'components/ActionButton/actionButton';
import { DateInput } from 'components/form/DateInput/dateInput';
import moment from 'moment';
import { updatePaySchedule } from 'lib/updatePaySchedule';
import { PaySchedule as PayScheduleType } from 'lib/fetchPaySchedule';
import { toast } from 'react-toastify';

export type UpdatePayScheduleFormData = {
  payFrequency: PayFrequencyType;
  firstPayday: string;
  firstPeriodEnd: string;
  id: string;
};

export const UpdatePayScheduleForm = ({
  id,
  paySchedule,
  handleFormSubmit,
}: {
  id: string;
  paySchedule: PayScheduleType;
  handleFormSubmit: (updatedPaySchedule: PayScheduleType) => void;
}) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UpdatePayScheduleFormData>({
    resolver: yupResolver(payScheduleSchema),
    defaultValues: {
      payFrequency: paySchedule.payFrequency as PayFrequencyType,
    },
  });

  const onSubmit: SubmitHandler<UpdatePayScheduleFormData> = async (
    data: UpdatePayScheduleFormData
  ) => {
    if (Object.keys(errors).length === 0) {
      const payScheduleData: UpdatePayScheduleFormData = {
        payFrequency: data.payFrequency,
        firstPayday: moment(data.firstPayday).format('YYYY-MM-DD'),
        firstPeriodEnd: moment(data.firstPeriodEnd).format('YYYY-MM-DD'),
        id,
      };

      const response = await updatePaySchedule(payScheduleData);

      if (response) {
        const updatedPaySchedule: PayScheduleType = {
          ...paySchedule,
          payFrequency: data.payFrequency,
          nextPayday: {
            ...paySchedule.nextPayday,
            payday: data.firstPayday,
            periodEnd: data.firstPeriodEnd,
          },
        };

        toast.success('Pay Schedule has been successfully updated.');
        handleFormSubmit(updatedPaySchedule);
      }
    }
  };

  const isButtonDisabled = (): boolean =>
    !watch('payFrequency') || !watch('firstPayday') || !watch('firstPeriodEnd');

  return (
    <UpdatePayScheduleFormContainer>
      <UpdateForm>
        <FormGroup label="Pay Frequency">
          <FormSelect
            data-testid="payFrequency"
            id="payFrequency"
            required
            options={Object.values(PayFrequencyType).map((type) => ({
              value: type,
              label: type,
            }))}
            {...register('payFrequency')}
          />
        </FormGroup>
        <FormGroup label="Period Details">
          <DateInput
            control={control}
            openDirection="up"
            name="firstPeriodEnd"
            placeholder="First Period End"
          />
          <DateInput
            control={control}
            openDirection="up"
            name="firstPayday"
            placeholder="First Pay Day"
          />
        </FormGroup>
      </UpdateForm>
      <UpdateButtonContainer>
        <ActionButton
          onClick={handleSubmit(onSubmit)}
          size="medium"
          shape="fullWidth"
          title="Save"
          hidden={false}
          disabled={isButtonDisabled()}
        />
      </UpdateButtonContainer>
    </UpdatePayScheduleFormContainer>
  );
};
