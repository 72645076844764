import { useAppDispatch } from '../../hooks/redux';
import { logout } from 'features/user/slice';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { Container, Heading, Message, ButtonContainer } from './OnboardingComplete.styles';
import { ActionButton } from 'components/ActionButton/actionButton';

export const OnboardingComplete = () => {
  const dispatch = useAppDispatch();
  const signOut = () => dispatch(logout());

  return (
    <ContentContainer onboarding={true} contentAlign="center">
      <Container>
        <Heading>Thank you!</Heading>
        <Message>
          Your application has been submitted for review. You will receive a confirmation email
          within 5 business days to access payroll.
        </Message>
        <ButtonContainer>
          <ActionButton onClick={signOut} size="large" title="Exit" hidden={false} />
        </ButtonContainer>
      </Container>
    </ContentContainer>
  );
};
