import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
}

html {
  background: ${(props) => props.theme.colors.gray2};
}

div,
input,
button,
textarea,
header,
footer,
section,
aside {
  display: block;
  box-sizing: border-box;
  position: relative;
}

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

label {
  cursor: pointer;
}

body {
  font-family: GothamRounded, Helvetica, Arial, sans-serif;
  font-size: ${(props) => props.theme.fontSizes.normal};
  color: ${(props) => props.theme.colors.text};
}

img {
  display: block;
  font-size: 10px;
  line-height: 1em;
  color: ${(props) => props.theme.colors.border};
  overflow: hidden;
  text-overflow: ellipsis;
}

a {
  color: ${(props) => props.theme.colors.black1};
  text-decoration: none;
}

strong {
  font-weight: 700;
}

.rrt-holder {
  margin-top: 0px !important;
}

.rrt-left-container {
  overflow: visible !important;
}

.bottom-right {
  bottom: 50px !important;
  right: 15px !important;
}

#check-component-embedded-div {
  height: 100%;
  padding: 16px;
}

#run-payroll {
  width: 65vw;
  height: 75vh;
}

.Toastify__toast--success {
  background: #58BA50;
  color: white;
}

.Toastify__toast--error {
  background: #F15C3B;
  color: white;
}

.Toastify__toast--warning {
  background: #FEBE10;
  color: white;
}

.Toastify__toast--info {
  background: #00C1F2;
  color: white;
}

.Toastify__toast-icon svg {
  fill: white;
}

.Toastify__close-button {
  color: white;
}

`;
