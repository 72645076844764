import { FormEventHandler, ReactNode } from 'react';
import { Button } from './button.styles';

export interface ButtonProps {
  onSubmit: FormEventHandler;
  disabled: boolean;
  label: ReactNode;
  isLoading?: boolean;
}

export const FormButton = (props: ButtonProps) => {
  return (
    <Button type="submit" onClick={props.onSubmit} disabled={props.disabled}>
      {props.isLoading ? 'Loading...' : props.label}
    </Button>
  );
};
