import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon } from 'components/Icon/icon';

import { NavItem } from '@src/constants/navData';
import { ItemLink } from './itemLink';
import {
  ExternalLink,
  NavBlockContainer,
  NavBlockHighlightWrapper,
  NavBlockTitle,
  StyledNavLink,
} from './navBlock.styles';

type BlockProps = {
  item: NavItem;
};

type LinkProps = {
  href?: string;
  target?: string;
  to?: string;
};

export const NavBlock: FC<BlockProps> = ({ item }) => {
  const location = useLocation();

  const isExternal = item.isExternal;
  const linkProps: LinkProps = isExternal
    ? {
        href: item.url,
        target: '_blank',
      }
    : {
        to: item.url,
      };

  return (
    <NavBlockContainer className={location.pathname.indexOf(item.url) === 0 ? 'active' : ''}>
      <ItemLink id={item.id} as={isExternal ? ExternalLink : StyledNavLink} {...linkProps}>
        <NavBlockHighlightWrapper>
          <Icon className="nav-block__header-icon" name={item.icon} size="large" />
          <NavBlockTitle>{item.title}</NavBlockTitle>
        </NavBlockHighlightWrapper>
      </ItemLink>
    </NavBlockContainer>
  );
};
