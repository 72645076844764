import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from 'lib/sendErrorMessage';
import { Employee, UserContext } from 'features/user/types';

type UserProfileResponse = {
  data: {
    currentUserContext: UserProfile;
  };
};

type UserProfile = {
  user: UserContext;
  employee: Employee;
};

export const fetchUserEmployeeProfile = (): Promise<UserProfile | null> => {
  const query = `
  query CurrentUserContext {
    currentUserContext {
      employee {
        id
        name
        providerIdentifier
        compensation {
          amount
          type
        }
        workplaces {
          id
          name
          providerIdentifier
          providerAttributes {
            active
            providerIdentifier
            address {
              city
              country
              line1
              line2
              postalCode
              state
            }
          }
        }
        providerAttributes {
          email
          dateOfBirth
          ssnLastFour
          paymentMethodPreference
          providerIdentifier
          address {
            line1
            line2
            postalCode
            city
            state
            country
          }
        }
      }
      user {
        email
        id
        name
      }
    }
  }
  `;

  return procareApi
    .post<unknown, AxiosResponse<UserProfileResponse>>('graphql', { query })
    .then((response) => {
      return response.data.data.currentUserContext;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return null;
    });
};
