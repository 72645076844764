import React from 'react';
import {
  InfoPlaceholderContainer,
  InfoPlaceholderTitle,
  InfoPlaceholderDesc,
  InfoPlaceholderImage,
} from './Placeholder.styles';

type InfoPlaceholderProps = {
  image?: string | false;
  title: string;
  children: React.ReactNode;
};

const InfoPlaceholder = ({ image, title, children }: InfoPlaceholderProps) => {
  return (
    <InfoPlaceholderContainer>
      <InfoPlaceholderTitle>{title}</InfoPlaceholderTitle>
      <InfoPlaceholderDesc>{children}</InfoPlaceholderDesc>
      <div>
        {image && (
          <InfoPlaceholderImage
            className="info-placeholder__img"
            src={`/assets/images/placeholders/info/${image}.png`}
            alt={image}
          />
        )}
      </div>
    </InfoPlaceholderContainer>
  );
};

export default InfoPlaceholder;
