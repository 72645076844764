import { useState, useEffect } from 'react';
import { ActionButton } from 'components/ActionButton/actionButton';
import { Modal } from 'components/Modal/Modal';
import { WorkplaceForm } from 'components/CreateWorkplace/WorkplaceForm/WorkplaceForm';
import { fetchCompanyWorkplaces, Workplace } from 'lib/fetchCompanyWorkplaces';
import { Spinner } from 'components/Spinner/spinner';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import {
  WorkplaceListing,
  WorkplaceListItem,
  WorkplaceName,
  WorkplaceFormContainer,
  FormHeading,
  CloseButton,
} from './workplace.styles';
import {
  TabHeading,
  TabContainer,
  TabInstruction,
  TabContent,
  TabHeader,
} from 'components/TabMenu/tabMenu.styles';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';

export const Workplaces = () => {
  const [workplaces, setWorkplaces] = useState<Workplace[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchWorkplaces = async () => {
      setIsLoading(true);
      const fetchedWorkplaces = await fetchCompanyWorkplaces();
      if (fetchedWorkplaces) setWorkplaces(fetchedWorkplaces);
      setIsLoading(false);
    };

    fetchWorkplaces();
  }, []);

  const refetchWorkplaces = async () => {
    setIsLoading(true);
    const fetchedWorkplaces = await fetchCompanyWorkplaces();
    if (fetchedWorkplaces) setWorkplaces(fetchedWorkplaces);
    setIsLoading(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleWorkplaceCreationSuccess = async () => {
    handleCloseModal();
    await refetchWorkplaces();
  };

  return (
    <TabContainer>
      <TabHeader>
        <ActionButton
          onClick={handleOpenModal}
          size="medium"
          title="Add Workplace"
          hidden={false}
        />
      </TabHeader>
      <ContentContainer contentAlign="left">
        <TabContent>
          <TabHeading>Your Workplaces</TabHeading>
          <TabInstruction>
            In order to accurately calculate tax for employees, we need to keep track of your
            company’s physical work locations.
          </TabInstruction>
          <WorkplaceListing>
            {isLoading ? (
              <Spinner />
            ) : (
              workplaces.map((workplace) => (
                <WorkplaceListItem key={workplace.id}>
                  <WorkplaceName>{workplace.name}</WorkplaceName>
                  <div>{workplace.providerAttributes.address.line1}</div>
                  <div>{workplace.providerAttributes.address.line2}</div>
                  <div>{`${workplace.providerAttributes.address.city}, ${workplace.providerAttributes.address.state} ${workplace.providerAttributes.address.postalCode}`}</div>
                </WorkplaceListItem>
              ))
            )}
          </WorkplaceListing>

          <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
            <WorkplaceFormContainer>
              <FormHeading>Add Workplace</FormHeading>
              <WorkplaceForm
                btnText="Add Workplace"
                onSubmitSuccess={handleWorkplaceCreationSuccess}
                navigateOnSuccess={false}
              />
              <CloseButton icon={<CloseIcon />} onClick={handleCloseModal} />
            </WorkplaceFormContainer>
          </Modal>
        </TabContent>
      </ContentContainer>
    </TabContainer>
  );
};
