import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UploadFormData, CheckDocumentType } from './types';
import { Form, UploadFormContainer, ButtonContainer, Instructions } from './UploadForm.styles';
import { FormSelect } from 'components/form';
import { ActionButton } from 'components/ActionButton/actionButton';
import { formatValue } from 'lib/utils/formatValue';
import { FileUpload } from 'components/FileUpload/FileUpload';
import { uploadCompanyDocument } from 'lib/uploadCompanyDocument';
import { toast } from 'react-toastify';

type UploadFormProps = {
  onSuccess: () => void;
};

export const UploadForm = ({ onSuccess }: UploadFormProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { register, watch, handleSubmit } = useForm<UploadFormData>();

  const onSubmit: SubmitHandler<UploadFormData> = async (data: UploadFormData) => {
    if (data.provided_document_type && selectedFile) {
      try {
        const response = await uploadCompanyDocument(data.provided_document_type, selectedFile);
        if (response) {
          toast.success('Your document has been submitted to the enrollment team');
          onSuccess();
        }
      } catch (error) {
        toast.error('File Submission failed. Please try to upload again or contact support.');
      }
    }
  };

  const handleFileUpload = (files: File[]) => {
    if (files.length > 0) {
      setSelectedFile(files[0]);
    } else {
      setSelectedFile(null);
    }
  };

  const documentTypeOptions = Object.entries(CheckDocumentType).map(([key, value]) => ({
    value,
    label: formatValue(key, true),
  }));
  const defaultDocumentTypeValue = documentTypeOptions.length ? documentTypeOptions[0].value : '';

  const isButtonDisabled = !watch('provided_document_type') || !selectedFile;

  return (
    <UploadFormContainer>
      <Form>
        <Instructions>
          Please ensure the document is titled in a way that is easily identifiable. The
          document&apos;s title cannot be edited after it has been uploaded.
        </Instructions>
        <FormSelect
          id="provided_document_type"
          label="Document Type"
          required
          options={documentTypeOptions}
          defaultValue={defaultDocumentTypeValue}
          {...register('provided_document_type')}
        />

        <FileUpload onFileUpload={handleFileUpload} />
      </Form>
      <ButtonContainer>
        <ActionButton
          onClick={handleSubmit(onSubmit)}
          size="medium"
          title="Submit"
          hidden={isButtonDisabled}
          disabled={isButtonDisabled}
        />
      </ButtonContainer>
    </UploadFormContainer>
  );
};
