import React, { useState, useEffect, useRef } from 'react';
import { Icon } from 'components/Icon/icon';
import { FilterDiv, FilterIconContainer, TooltipDiv } from './Filter.styles';
import { theme } from 'styles/theme';

type FilterProps = {
  tooltipContent?: React.ReactNode;
};

export const Filter = ({ tooltipContent }: FilterProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsTooltipVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <FilterDiv ref={ref}>
      <FilterIconContainer onClick={() => setIsTooltipVisible(!isTooltipVisible)}>
        <Icon name="filter" size="large" color={theme.colors.primary} />
      </FilterIconContainer>

      {tooltipContent && isTooltipVisible && <TooltipDiv>{tooltipContent}</TooltipDiv>}
    </FilterDiv>
  );
};
