import styled from 'styled-components';

export const ListingContainer = styled.div`
  padding: 0 8px;
  width: 100%;
  max-width: 1300px;
`;

export const ListingHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
`;

export const Heading = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.fontSizes.subHeading};
  font-weight: 400;
  margin: 5px 0;
`;
