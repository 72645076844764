declare global {
  interface Window {
    env: {
      REACT_APP_PROCARE_API_URL: string;
    };
  }
}

export const procareApiUrl: string =
  process.env.REACT_APP_BUILD_TIME === 'true' || process.env.NODE_ENV != 'production'
    ? (process.env.REACT_APP_PROCARE_API_URL as string)
    : (window.env.REACT_APP_PROCARE_API_URL as string);
