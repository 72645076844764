import { useEffect, useState } from 'react';
import { Table } from './Table';
import {
  fetchEmployeePostTaxDeductions,
  DeductionNode,
  PageInfo,
} from 'lib/fetchEmployeePostTaxDeductions';
import { Employee } from 'features/user/types';
import { Spinner } from 'components/Spinner/spinner';
import { Pagination } from 'components/Pagination/pagination';
import { deletePostTaxDeduction } from 'lib/deleteTaxDeductions';
import { toast } from 'react-toastify';
import { Modal } from 'components/Modal/Modal';
import { UpdatePostTaxDeductionForm } from '../../PostTaxDeductionForm/UpdatePostTaxDeductionForm';
import { FormHeading, FormContainer, CancelButton } from './DeductionsTable.styles';
import { ActionButton } from 'components/ActionButton/actionButton';
import { DEFAULT_PAGE_SIZE } from '@src/constants/constants';

type DeductionsTableProps = {
  employee?: Employee;
  refresh: boolean;
};

export const DeductionsTable = ({ employee, refresh }: DeductionsTableProps) => {
  const [deductions, setDeductions] = useState<DeductionNode[]>([]);
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [deductionToUpdate, setDeductionToUpdate] = useState<DeductionNode | null>(null);
  const [refreshDeductions, setRefresh] = useState(false);

  const handleDelete = async (providerId: string) => {
    try {
      const result = await deletePostTaxDeduction({ providerId });
      if (result) {
        const updatedDeductions = deductions.filter(
          (deduction) => deduction.providerId !== providerId
        );
        setDeductions(updatedDeductions);
      }
    } catch (error) {
      toast.error('Failed to delete deduction.');
    }
  };

  const handleUpdate = (deduction: DeductionNode) => {
    setDeductionToUpdate(deduction);
    setUpdateModalOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setDeductionToUpdate(null);
    setUpdateModalOpen(false);
    setRefresh((prevState) => !prevState);
  };

  const loadMore = () => {
    if (pageInfo?.hasNextPage) {
      setEndCursor(pageInfo.endCursor);
    }
  };

  const onPrev = () => {
    if (pageInfo?.hasPreviousPage) {
      setEndCursor(pageInfo.startCursor);
    }
  };

  const onNext = () => {
    if (pageInfo?.hasNextPage) {
      loadMore();
    }
  };

  useEffect(() => {
    if (employee) {
      fetchEmployeePostTaxDeductions(employee.id, DEFAULT_PAGE_SIZE, endCursor).then(
        ({ deductions: newDeductions, pageInfo: newPageInfo }) => {
          setDeductions(newDeductions);
          setPageInfo(newPageInfo);
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  }, [employee, endCursor, refresh, refreshDeductions]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      {pageInfo && (pageInfo.hasPreviousPage || pageInfo.hasNextPage) && (
        <Pagination
          onPrev={onPrev}
          onNext={onNext}
          disablePrev={!pageInfo.hasPreviousPage}
          disableNext={!pageInfo.hasNextPage}
        />
      )}
      <Table deductions={deductions} onDelete={handleDelete} onUpdate={handleUpdate} />
      <Modal
        isOpen={isUpdateModalOpen}
        onRequestClose={handleCloseUpdateModal}
        style={{
          overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 11,
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            borderRadius: '16px',
            overflow: 'visible',
            maxHeight: '99vh',
            overflowY: 'auto',
          },
        }}
      >
        <FormContainer>
          <FormHeading>Update Post-Tax Deduction</FormHeading>
          <UpdatePostTaxDeductionForm
            deduction={deductionToUpdate}
            onClose={handleCloseUpdateModal}
          />
          <CancelButton>
            <ActionButton
              onClick={handleCloseUpdateModal}
              size="medium"
              variant="text"
              title="Cancel"
              hidden={false}
            />
          </CancelButton>
        </FormContainer>
      </Modal>
    </>
  );
};
