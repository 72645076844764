import { useEffect, useState } from 'react';
import { Table } from './Table';
import { fetchEmployeePaystubs, Paystub, PageInfo } from 'lib/fetchEmployeePaystubs';
import { Spinner } from 'components/Spinner/spinner';
import { Pagination } from 'components/Pagination/pagination';
import { DateSelector } from 'components/DateSelector/dateSelector';
import { DateSelectionContainer, DateContainer } from './PaystubsTable.styles';
import { DEFAULT_PAGE_SIZE } from '@src/constants/constants';

type PaystubsTableProps = {
  employeeId: string;
};

export const PaystubsTable = ({ employeeId }: PaystubsTableProps) => {
  const [paystubs, setPaystubs] = useState<Paystub[]>([]);
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);

  const loadMore = () => {
    if (pageInfo?.hasNextPage) {
      setEndCursor(pageInfo.endCursor);
    }
  };

  const onPrev = () => {
    if (pageInfo?.hasPreviousPage) {
      setEndCursor(pageInfo.startCursor);
    }
  };

  const onNext = () => {
    if (pageInfo?.hasNextPage) {
      loadMore();
    }
  };

  const formattedStartDate = startDate ? startDate.format('YYYY-MM-DD') : undefined;
  const formattedEndDate = endDate ? endDate.format('YYYY-MM-DD') : undefined;

  useEffect(() => {
    fetchEmployeePaystubs(
      employeeId,
      DEFAULT_PAGE_SIZE,
      endCursor,
      formattedStartDate,
      formattedEndDate
    ).then(({ paystubs: newPaystubs, pageInfo: newPageInfo }) => {
      setPaystubs(newPaystubs);
      setPageInfo(newPageInfo);
      setLoading(false);
    });
  }, [employeeId, endCursor, formattedStartDate, formattedEndDate]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <DateSelectionContainer>
        <DateContainer>
          <DateSelector placeholder="Start Date" onDateChange={setStartDate} />
        </DateContainer>
        <DateContainer>
          <DateSelector placeholder="End Date" onDateChange={setEndDate} />
        </DateContainer>
      </DateSelectionContainer>

      {pageInfo && (pageInfo.hasPreviousPage || pageInfo.hasNextPage) && (
        <Pagination
          onPrev={onPrev}
          onNext={onNext}
          disablePrev={!pageInfo.hasPreviousPage}
          disableNext={!pageInfo.hasNextPage}
        />
      )}
      <Table paystubs={paystubs} />
    </>
  );
};
