import { TabContainer } from '../../tabMenu.styles';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { DocumentsTable } from 'components/Table/DocumentsTable/DocumentsTable';
import { EmployeeContext } from 'features/user/types';

type DocumentsProps = {
  employee: EmployeeContext;
};

export const Documents = ({ employee }: DocumentsProps) => {
  return (
    <ContentContainer>
      <TabContainer>
        <DocumentsTable employee={employee} />
      </TabContainer>
    </ContentContainer>
  );
};
