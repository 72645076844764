const tokenKey = 'accessToken';
const refreshTokenKey = 'refreshToken';

export const getAccessToken = () => localStorage.getItem(tokenKey) || undefined;
export const removeAccessToken = () => localStorage.removeItem(tokenKey);
export const setAccessToken = (token: string) => localStorage.setItem(tokenKey, token);

export const getRefreshToken = () => localStorage.getItem(refreshTokenKey) || undefined;
export const removeRefreshToken = () => localStorage.removeItem(refreshTokenKey);
export const setRefreshToken = (token: string) => localStorage.setItem(refreshTokenKey, token);
