import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

type EmployeeSsnResponse = {
  data: {
    employeeSsnComponent: {
      url: string;
    };
  };
};

export const fetchEmployeeSsnComponent = (employeeId: string) => {
  const query = `
    query EmployeeSsnComponent($employeeInput: EmployeeInput!) { 
      employeeSsnComponent(employeeInput: $employeeInput) { 
        url 
      } 
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<EmployeeSsnResponse>>('graphql', {
      query,
      variables: {
        employeeInput: {
          id: employeeId,
        },
      },
    })
    .then((response: AxiosResponse<EmployeeSsnResponse>) => {
      return response.data.data.employeeSsnComponent.url;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
