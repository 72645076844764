import { createTheme } from 'styled-breakpoints';

const breakpointsTheme = createTheme({
  tablet: '768px',
  desktop: '1024px',
  wideDesktop: '1280px',
});

const primaryTheme = {
  colors: {
    text: '#666666',
    white: '#ffffff',
    offWhite: '#dddddd',
    black: '#000',
    black0: '#333333',
    black1: '#3B3A39',
    black2: '#999999',
    gray0: '#cccccc',
    gray1: '#d2d0ce',
    gray2: '#f9f9f9',
    gray3: '#C8C6C4',
    gray4: '#fbfcfe',
    gray5: '#f4f4f4',
    gray6: '#f3f3f3',
    gray7: '#82888a',
    blue1: '#30bdef',
    blue2: '#d3f6fe',
    border: '#eeeeee',
    border1: '#cccccc',
    border2: '#8a8886',
    borderTable: '#e5e7eb',
    buttonContainer: '#edebe9',
    disabled: '#f2f2f2',
    homeStart: '#09bede',
    homeEnd: '#4ebe9e',
    blockingStatus: '#fae6e5',
    attentionStatus: '#ffffcc',
    completedStatus: '#e6faf0',
    draft: '#CAE9F5',
    checkbox: '#09bed2',
    active: '#019FD4',
    error: '#cc0000',
    warning: '#FEBE10',
    success: '#58BA50',
    delete: '#fae6e5',
    primary: '#007faa',
    primaryDark: '#016080',
    accent: '#00C1F2',
  },
  fontSizes: {
    small: '12px',
    normal: '14px',
    tableHeading: '14px',
    formGroupLabel: '18px',
    subHeading: '20px',
    heading: '24px',
    largeHeading: '32px',
  },
  fontFamily: {
    default: 'GothamRounded, Helvetica, Arial, sans-serif',
  },
  zIndex: {
    tooltip: 100,
    modal: 11,
    topbar: 10,
    sidebar: 9,
  },
  padding: {
    sm: '6px',
    md: '16px',
    lg: '25px',
  },
  margin: {
    sm: '6px',
    md: '16px',
    lg: '25px',
  },
  dimensions: {
    headerHeight: '72px',
    layoutWidth: '980px',
    asideWidth: '214px',
    asideWidthTablet: '48px',
    containerOffset: '105px',
  },
  iconSizes: {
    small: '14px',
    medium: '16px',
    large: '20px',
    xl: '30px',
  },
  tooltip: {
    textColor: 'white',
    backgroundColor: '#3B3A39',
    margin: '10px',
    arrowSize: '7px',
  },
};

export const theme = {
  ...primaryTheme,
  ...breakpointsTheme,
};
