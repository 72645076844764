import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

// Deduction Types
export type ChildSupportDeduction = {
  agency: string;
  amount: string;
  externalId: string;
  fipsCode: string;
  issueDate: string;
  maxPercent: number;
};

export type MiscellaneousDeduction = {
  amount: string;
  annualLimit: string;
  percent: number;
  totalAmount: string;
};

export type ConfigurationObject = ChildSupportDeduction | MiscellaneousDeduction;

export enum PostTaxDeductionTypes {
  Miscellaneous = 'miscellaneous',
  ChildSupport = 'child_support',
}

export type DeductionNode = {
  configurationObject: ConfigurationObject;
  description: string;
  effectiveEnd: string;
  effectiveStart: string;
  providerId: string;
  employeeProviderId: string;
  type: PostTaxDeductionTypes;
};

export type PageInfo = {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
};

type PostTaxDeductionsResponse = {
  data: {
    employeePostTaxDeductions: {
      nodes: DeductionNode[];
      pageInfo: PageInfo;
    };
  };
};

export const fetchEmployeePostTaxDeductions = (
  employeeId: string,
  first: number,
  endCursor?: string
): Promise<{ deductions: DeductionNode[]; pageInfo: PageInfo | null }> => {
  const after = endCursor ? `, after: "${endCursor}"` : '';

  const query = `
    query PostTaxDeductions($employeeInputVar: EmployeeInput!, $firstValue: Int!) {
      employeePostTaxDeductions(employeeInput: $employeeInputVar, first: $firstValue${after}) {
        nodes {
            configurationObject {
                ... on ChildSupportDeduction {
                  agency
                  amount
                  externalId
                  fipsCode
                  issueDate
                  maxPercent
                }
                ... on MiscellaneousDeduction {
                  amount
                  annualLimit
                  percent
                  totalAmount
                }
              }
          description
          effectiveEnd
          effectiveStart
          providerId
          employeeProviderId
          type
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<PostTaxDeductionsResponse>>('graphql', {
      query,
      variables: {
        employeeInputVar: { id: employeeId },
        firstValue: first,
      },
    })
    .then((response: AxiosResponse<PostTaxDeductionsResponse>) => {
      const deductions = response.data.data.employeePostTaxDeductions.nodes;
      const pageInfo = response.data.data.employeePostTaxDeductions.pageInfo;
      return { deductions, pageInfo };
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return { deductions: [], pageInfo: null };
    });
};
