import { useEffect, useState } from 'react';
import { ActionButton } from 'components/ActionButton/actionButton';
import { Spinner } from 'components/Spinner/spinner';
import { CheckComponent } from 'components/CheckComponent/checkComponent';
import { useAppSelector } from 'hooks/redux';
import { fetchCompanyBankAccounts, Bank } from 'lib/fetchCompanyBankAccounts';
import { fetchCompanyPaymentSetup } from 'lib/Check/fetchCompanyPaymentSetupComponent';
import { fetchCompanyTaxSetup } from 'lib/Check/fetchCompanyTaxSetupComponent';
import { TabContainer, TabHeading, TabContent, SectionHeader } from '../../../tabMenu.styles';
import {
  BankAccountItem,
  ItemLabel,
  ItemValue,
  BankAccountModal,
  BankAccountContainer,
  CloseButton,
} from './financials.styles';
import { selectCurrentUser } from 'features/user/slice';
import Tooltip from 'components/Tooltip/tooltip';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';

export const Financials = () => {
  const [bankAccounts, setBankAccounts] = useState<Bank[]>([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [taxModalIsOpen, setTaxModalIsOpen] = useState(false);
  const [componentURL, setComponentURL] = useState('');
  const [taxComponentURL, setTaxComponentURL] = useState('');
  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    fetchCompanyBankAccounts()
      .then((accounts) => {
        setBankAccounts(accounts);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  const handleOpenModal = async () => {
    const signatoryId = currentUser?.signatory?.providerIdentifier ?? '';
    if (signatoryId) {
      const url = await fetchCompanyPaymentSetup(signatoryId);
      if (url) {
        setComponentURL(url);
      }
    }
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    fetchCompanyBankAccounts()
      .then((accounts) => {
        setBankAccounts(accounts);
      })
      .catch(() => setLoading(false));
  };

  const handleOpenTaxModal = async () => {
    const signatoryId = currentUser?.signatory?.providerIdentifier ?? '';
    if (signatoryId) {
      const url = await fetchCompanyTaxSetup(signatoryId);
      if (url) {
        setTaxComponentURL(url);
      }
    }
    setTaxModalIsOpen(true);
  };

  const handleCloseTaxModal = () => {
    setTaxModalIsOpen(false);
  };

  const renderActionButtonWithTooltip = (onClick: () => Promise<void>, title: string) => {
    const button = (
      <ActionButton
        onClick={() => onClick()}
        size="medium"
        title={title}
        variant="text"
        hidden={false}
        disabled={!currentUser?.signatory}
      />
    );

    return currentUser?.signatory ? (
      button
    ) : (
      <Tooltip
        content="You do not have permission to update. Please contact your admin."
        direction="right"
      >
        {button}
      </Tooltip>
    );
  };

  const renderBankAccount = (bankAccount: Bank) => {
    let status: string;
    if ('verified' in bankAccount.account) {
      status = bankAccount.account.verified ? 'Verified' : 'Not Verified';
    } else {
      status = 'Unknown';
    }

    return (
      <>
        <BankAccountItem>
          <ItemLabel>Institution</ItemLabel>
          <ItemValue>{bankAccount.account.institutionName}</ItemValue>
        </BankAccountItem>
        <BankAccountItem>
          <ItemLabel>Account Type</ItemLabel>
          <ItemValue>{bankAccount.account.subtype}</ItemValue>
        </BankAccountItem>
        <BankAccountItem>
          <ItemLabel>Status</ItemLabel>
          <ItemValue>{status}</ItemValue>
        </BankAccountItem>
      </>
    );
  };

  return (
    <TabContainer>
      <ContentContainer>
        <SectionHeader>
          <TabHeading>Bank Account</TabHeading>
          {renderActionButtonWithTooltip(handleOpenModal, 'Update Payment Method')}
        </SectionHeader>
        <BankAccountModal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          style={{
            overlay: {
              position: 'fixed',
              inset: '0px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 11,
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              transform: 'translate(-30%, -50%)',
              borderRadius: '16px',
              overflow: 'visible',
              width: '440px',
            },
          }}
        >
          <BankAccountContainer>
            {modalIsOpen && <CheckComponent id="payment-setup" componentURL={componentURL} />}
            <CloseButton icon={<CloseIcon />} onClick={handleCloseModal} />
          </BankAccountContainer>
        </BankAccountModal>
        <TabContent>
          {loading ? (
            <Spinner />
          ) : bankAccounts.length > 0 ? (
            renderBankAccount(bankAccounts[0])
          ) : (
            <div>No bank account available</div>
          )}
        </TabContent>
        <SectionHeader>
          <TabHeading>Tax Setup</TabHeading>
          {renderActionButtonWithTooltip(handleOpenTaxModal, 'Update Tax Setup')}
        </SectionHeader>
        <BankAccountModal
          isOpen={taxModalIsOpen}
          onRequestClose={handleCloseTaxModal}
          style={{
            overlay: {
              position: 'fixed',
              inset: '0px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 11,
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              transform: 'translate(-30%, -50%)',
              borderRadius: '16px',
              overflow: 'visible',
              width: '440px',
            },
          }}
        >
          <BankAccountContainer>
            {taxModalIsOpen && <CheckComponent id="tax-setup" componentURL={taxComponentURL} />}
            <CloseButton icon={<CloseIcon />} onClick={handleCloseTaxModal} />
          </BankAccountContainer>
        </BankAccountModal>
      </ContentContainer>
    </TabContainer>
  );
};
