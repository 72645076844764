import ReactModal, { Props as ModalProps } from 'react-modal';

ReactModal.setAppElement('#root');

export const Modal = (props: ModalProps) => {
  return (
    <ReactModal
      style={{
        overlay: {
          position: 'fixed',
          inset: '0px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 11,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          borderRadius: '16px',
          overflow: 'visible',
        },
        ...props.style,
      }}
      {...props}
    />
  );
};
