import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  line-height: 1.5;
  font-size: 14px;
  position: relative;
  cursor: pointer;
`;

export const Input = styled.input`
  position: relative;
  top: 3px;
  box-sizing: content-box;
  width: 13px;
  height: 13px;
  margin: 0 5px 0 0;
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.border2};

  &:disabled {
    background-color: ${(props) => props.theme.colors.gray0};
    cursor: not-allowed;
  }

  &:checked {
    background-color: ${(props) => props.theme.colors.checkbox};
  }

  &:before {
    content: '';
    display: block;
  }

  &:checked:before {
    width: 2px;
    height: 6px;
    margin: 1px 5px;
    border-bottom: 2px solid ${(props) => props.theme.colors.black};
    border-right: 2px solid ${(props) => props.theme.colors.black};
    transform: rotate(45deg);
  }
`;

export const RadioInputContainer = styled.div`
  margin: 5px 0;
`;
