import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/redux';
import { selectCurrentCompany } from 'features/company/slice';
import { Company, Workplace } from 'features/company/types';
import {
  CreateWorkplaceContainer,
  WorkplaceHeadingContainer,
  WorkplaceHeading,
  WorkplaceContainer,
  WorkplaceSubHeadingContainer,
  WorkplaceSubHeading,
  WorkplaceMessage,
  WorkplaceCount,
  WorkplaceList,
  WorkplaceListItem,
  ButtonContainer,
  Address,
} from './CreateWorkplace.styles';
import { WorkplaceForm } from './WorkplaceForm/WorkplaceForm';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useNavigate } from 'react-router-dom';
import { getFullAddress } from 'lib/utils/getFullAddress';
import { fetchPlatformWorkplaces, PlatformWorkplace } from 'lib/fetchPlatformWorkplaces';
import { FormSelect } from 'components/form';
import { Spinner } from 'components/Spinner/spinner';

export const CreateWorkplace = () => {
  const navigate = useNavigate();
  const company: Company | null = useAppSelector(selectCurrentCompany);
  const [workplaceOptions, setWorkplaceOptions] = useState<{ value: string; label: string }[]>([]);
  const [selectedWorkplace, setSelectedWorkplace] = useState<PlatformWorkplace | null>(null);
  const [fetchedWorkplaces, setFetchedWorkplaces] = useState<PlatformWorkplace[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPlatformWorkplaces().then((workplaces) => {
      if (workplaces) {
        setFetchedWorkplaces(workplaces);
        const options = workplaces.map((workplace: PlatformWorkplace) => ({
          value: workplace.platformId,
          label: workplace.name,
        }));
        setWorkplaceOptions(options);
        if (workplaces.length > 0) {
          setSelectedWorkplace(workplaces[0]);
        }
        setLoading(false);
      }
    });
  }, []);

  const handleWorkplaceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPlatformId = event.target.value;
    const workplace = fetchedWorkplaces.find((wp) => wp.platformId === selectedPlatformId) || null;
    setSelectedWorkplace(workplace);
  };

  const renderWorkplaceList = () => {
    if (!company || !company.workplaces.length) return null;

    return (
      <>
        <WorkplaceCount>
          <strong>({company.workplaces.length})</strong> Workplaces have already been added for this
          company
        </WorkplaceCount>
        <WorkplaceList>
          {company.workplaces.map((workplace: Workplace) => (
            <WorkplaceListItem key={workplace.id}>
              {workplace.name}
              <br />
              {getFullAddress(workplace.providerAttributes.address).map((line, index) => (
                <Address key={index}>{line}</Address>
              ))}
            </WorkplaceListItem>
          ))}
        </WorkplaceList>
      </>
    );
  };

  return (
    <CreateWorkplaceContainer>
      <WorkplaceHeadingContainer>
        <WorkplaceHeading>Let&apos;s get started!</WorkplaceHeading>
      </WorkplaceHeadingContainer>
      <WorkplaceContainer>
        {company && company.workplaces.length > 0 ? (
          <>
            {renderWorkplaceList()}
            <ButtonContainer>
              <ActionButton
                onClick={() => navigate('/pay-schedule')}
                size="medium"
                title="Continue"
                hidden={false}
              />
            </ButtonContainer>
          </>
        ) : (
          <>
            <WorkplaceSubHeadingContainer>
              <WorkplaceSubHeading>Select your primary workplace?</WorkplaceSubHeading>
              <WorkplaceMessage>
                To accurately calculate taxes for employees, we require your company&apos;s primary
                physical work location. If you have multiple locations, you can add them later in
                your profile settings.
              </WorkplaceMessage>
            </WorkplaceSubHeadingContainer>

            {loading ? (
              <Spinner />
            ) : (
              <>
                {workplaceOptions.length > 0 && (
                  <FormSelect
                    label="Primary Workplace"
                    id="primary-workplace-select"
                    options={workplaceOptions}
                    defaultValue={workplaceOptions[0].value}
                    onChange={handleWorkplaceChange}
                  />
                )}

                <WorkplaceForm
                  defaultValues={
                    selectedWorkplace
                      ? {
                          name: selectedWorkplace.name,
                          address: {
                            line1: selectedWorkplace.addressLine1,
                            line2: selectedWorkplace.addressLine2 || '',
                            city: selectedWorkplace.city,
                            state: selectedWorkplace.state,
                            postalCode: selectedWorkplace.zip,
                            country: 'US',
                          },
                          platformId: selectedWorkplace.platformId,
                        }
                      : undefined
                  }
                />
              </>
            )}
          </>
        )}
      </WorkplaceContainer>
    </CreateWorkplaceContainer>
  );
};
