import styled from 'styled-components';
import moment from 'moment';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  flex-basis: 21%;
`;

const CardHeader = styled.div<{ bgColor?: string }>`
  width: 100%;
  background-color: ${(props) => props.bgColor || '#007faa'};
  color: #fff;
  padding: 8px;
  border-radius: 6px 6px 0 0;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: 12px;
`;

const DayText = styled.div`
  font-size: 28px;
  font-weight: bold;
`;

type CalendarCardProps = {
  title: string;
  date: Date;
  bgColor?: string;
  id?: string;
};

export const CalendarCard = ({ title, date, bgColor, id }: CalendarCardProps) => {
  const month = moment(date).format('MMMM');
  const day = moment(date).format('D');
  const year = moment(date).format('YYYY');

  return (
    <CardContainer id={id}>
      <CardHeader bgColor={bgColor}>{title}</CardHeader>
      <CardContent>
        <div>{month}</div>
        <DayText>{day}</DayText>
        <div>{year}</div>
      </CardContent>
    </CardContainer>
  );
};
