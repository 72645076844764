import styled from 'styled-components';
import { IconButton } from 'components/IconButton/IconButton';
import { Modal } from 'components/Modal/Modal';

export const CloseButton = styled(IconButton)`
  position: absolute;
  z-index: 10;
  top: 16px;
  right: 16px;
`;

export const EditButton = styled(IconButton)`
  display: inline-block;
  margin-left: 16px;
  vertical-align: top;
  svg {
    width: 16px;
    height: 16px;
    fill: ${(props) => props.theme.colors.primary};
  }
`;

type ProfileContainerProps = {
  divider?: boolean;
};

export const ProfileContainer = styled.div<ProfileContainerProps>`
  border-bottom: ${({ divider, theme }) => (divider ? `2px solid ${theme.colors.border}` : 'none')};
  margin-bottom: 16px;
  width: 100%;
  max-width: 1300px;
`;

export const ProfileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1300px;
`;

export const ProfileHeading = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: 18px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black0};
  margin: 0;
`;

export const ProfileContent = styled.div`
  padding: 8px 0;
`;

export const ItemContainer = styled.p``;

export const ItemLabel = styled.span`
  font-weight: 500;
  color: ${(props) => props.theme.colors.black};
  display: inline-block;
  width: 200px;
  vertical-align: top;
`;

export const ItemValue = styled.span`
  display: inline-block;
  line-height: 1.5;
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding: 12px 0;
`;

export const BankAccountModal = styled(Modal)`
  iframe {
    height: 70vh !important;
    width: 400px !important;
    max-height: 750px;
    border-radius: 16px;
  }
`;

export const BankAccountContainer = styled.div`
  background: white;
  border-radius: 16px;
`;

export const FormHeading = styled.h3`
  font-size: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
  margin: 0 10px;
  padding-bottom: 15px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray6};
`;
