import { PageContainer } from 'components/SharedComponents/Containers.styles';
import { SectionHeader } from 'components/SectionHeader/sectionHeader';
import { EmployeeListing } from 'components/EmployeeListing/employeeListing';

export const People = () => {
  return (
    <PageContainer>
      <SectionHeader title="People" />
      <EmployeeListing />
    </PageContainer>
  );
};
