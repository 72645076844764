import { object, string } from 'yup';

export const loginSchema = object({
  email: string().email().required(),
  password: string().required(),
}).required();

export const signupSchema = object({
  name: string().required(),
  email: string().email().required(),
  password: string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
}).required();
