import { Container, Heading, Description, Content, StyledLink } from './NavCard.styles';

type NavCardProps = {
  heading: string;
  description?: string;
  link: string;
  title: string;
  customLinkText?: string;
};

export const NavCard = ({ heading, description, link, title, customLinkText }: NavCardProps) => {
  return (
    <Container>
      <Content>
        <Heading>{heading}</Heading>
        <Description>{description}</Description>
      </Content>
      <StyledLink to={link}>{customLinkText ? customLinkText : `View ${title}`}</StyledLink>
    </Container>
  );
};
