import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

type PaymentSetupResponse = {
  data: {
    companyPaymentSetupComponent: {
      url: string;
    };
  };
};

export const fetchCompanyPaymentSetup = (signatoryId: string) => {
  const query = `
    query CompanyPaymentSetupComponent($companyInput: CompanyOnboardInput!) { 
      companyPaymentSetupComponent(companyInput: $companyInput) { 
        url 
      } 
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<PaymentSetupResponse>>('graphql', {
      query,
      variables: {
        companyInput: {
          signatory: signatoryId,
        },
      },
    })
    .then((response: AxiosResponse<PaymentSetupResponse>) => {
      return response.data.data.companyPaymentSetupComponent.url;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
