import { StyledTable, TableLink, Thead, Tr, Th, Td, Tbody, EmptyMessage } from '../Table.styles';
import { ImportErrorLog } from 'lib/fetchImportErrors';
import { formatTimestamp } from 'lib/utils/formatTimestamp';

export type TableProps = {
  importErrors: ImportErrorLog[];
};

export const Table = ({ importErrors }: TableProps) => {
  if (importErrors.length === 0) {
    return <EmptyMessage>No import errors found. Please check back later.</EmptyMessage>;
  }

  return (
    <StyledTable>
      <Thead>
        <Tr>
          <Th>ID</Th>
          <Th>Sync Type</Th>
          <Th>Failed At</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {importErrors.map((errorLog) => (
          <Tr key={errorLog.id}>
            <Td>{errorLog.id}</Td>
            <Td>{errorLog.import.type}</Td>
            <Td>{formatTimestamp(errorLog.failedAt)}</Td>
            <Td>
              {/* Link will go to Procare Online (Will use staging for now) */}
              <TableLink href="https://schools.staging-procareconnect.com/" target="_blank">
                Navigate to Online
              </TableLink>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </StyledTable>
  );
};
