import { type Asserts } from 'yup';
import { payScheduleSchema } from './validator';

export type PayScheduleFormData = Asserts<typeof payScheduleSchema>;

export enum PayFrequencyType {
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
  SEMIMONTHLY = 'semimonthly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUALLY = 'annually',
}
