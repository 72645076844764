import { PageContainer } from 'components/SharedComponents/Containers.styles';
import { SectionHeader } from 'components/SectionHeader/sectionHeader';
import { TabMenu } from 'components/TabMenu/tabMenu';
import { SyncLog } from 'components/TabMenu/Tabs/SyncsMenu/SyncLog';
import { Errors } from 'components/TabMenu/Tabs/SyncsMenu/Errors';

export const Syncs = () => {
  const MENU_ITEMS = {
    'Sync Log': <SyncLog />,
    Errors: <Errors />,
  };
  return (
    <PageContainer>
      <SectionHeader title="Syncs" />
      <TabMenu menuItems={MENU_ITEMS} defaultActiveItem="Sync Log" />
    </PageContainer>
  );
};
