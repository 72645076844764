import styled, { css } from 'styled-components';
import { IconContainer } from 'components/Icon/icon.styles';
import { ActionButtonSize, ActionButtonType } from './actionButton';

const ActionButtonSquare = css`
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.offWhite};

  & + & {
    padding-left: 0;
  }
`;

const ActionButtonCircle = css`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.blue1};
  border-radius: 50%;
  border: 0;

  ${IconContainer} {
    display: inline-block;
    vertical-align: middle;

    svg {
      fill: ${(props) => props.theme.colors.blue2};
    }
  }
`;

const ActionButtonFullWidth = css`
  width: 100%;
`;

export const ActionButtonMedium = css`
  font-size: 14px;
  padding: 3px 18px;
`;

export const ActionButtonLarge = css`
  font-size: 18px;
  padding: 6px 24px;
`;

export const OutlineButton = css`
  color: ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.primary};

  &:hover {
    color: ${(props) => props.theme.colors.primaryDark};
    background-color: ${(props) => props.theme.colors.white};
    border: 2px solid ${(props) => props.theme.colors.primaryDark};
  }
`;

export const TextButton = css`
  color: ${(props) => props.theme.colors.primary};
  background: none;
  border: none;

  &:hover {
    color: ${(props) => props.theme.colors.primaryDark};
    background: none;
    border: none;
  }
`;

export const ActionButtonBase = styled.button.attrs(
  (props: { shape: string; size: ActionButtonSize; variant: ActionButtonType }) => props
)`
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  transition: 100ms ease-in-out;
  font-size: 12px;
  font-family: ${(props) => props.theme.fontFamily.default};
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 20px;
  border: 2px solid ${(props) => props.theme.colors.primary};
  outline: none;
  ${(props) => props.shape === 'fullWidth' && ActionButtonFullWidth}

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDark};
    border: 2px solid ${(props) => props.theme.colors.primaryDark};
  }

  ${(props) => props.size === 'medium' && ActionButtonMedium}
  ${(props) => props.size === 'large' && ActionButtonLarge}

  ${(props) => props.variant === 'outline' && OutlineButton}
  ${(props) => props.variant === 'text' && TextButton}

	&:active {
    opacity: 0.7;
  }

  & + & {
    margin-left: ${(props) => props.theme.margin.md};
    padding-left: ${(props) => props.theme.padding.md};
    border-left: 1px ${(props) => props.theme.colors.gray0} solid;
  }

  &:disabled {
    opacity: 0.5 !important;
    cursor: default;

    &:active {
      opacity: 1;
    }
  }

  ${IconContainer} {
    display: inline-block;
    vertical-align: middle;

    svg {
      fill: ${(props) => props.theme.colors.text};
    }
  }

  ${(props) => props.shape === 'square' && ActionButtonSquare}
  ${(props) => props.shape === 'circle' && ActionButtonCircle}
`;

export const ActionButtonTitle = styled.span`
  white-space: nowrap;
  vertical-align: middle;
`;
