import {
  configureStore,
  ThunkAction,
  Action,
  createAsyncThunk,
  combineReducers,
} from '@reduxjs/toolkit';
import userSlice from './features/user/slice';
import companySlice from './features/company/slice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { logger } from 'redux-logger';

const persistConfig = {
  key: 'root',
  storage,
};

export const rootReducer = combineReducers({
  user: userSlice,
  company: companySlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV == 'production')
      return getDefaultMiddleware({
        serializableCheck: false,
      });

    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger);
  },
});
export const persistor = persistStore(store);

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
  extra: { s: string; n: number };
}>();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
