import {
  ActionNotificationContainer,
  Container,
  Heading,
  Count,
  Link,
} from './ActionItemsNotification.styles';
import { useNavigate } from 'react-router-dom';

type ActionItemsNotificationProps = {
  count: number;
};

export const ActionItemsNotification = ({ count }: ActionItemsNotificationProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/people', { state: { triggerImport: true } });
  };

  return (
    <ActionNotificationContainer>
      <Heading>Action Items</Heading>
      <Container>
        <Count>{count}</Count>
        <Link onClick={handleClick}>EMPLOYEES MISSING INFORMATION</Link>
      </Container>
    </ActionNotificationContainer>
  );
};
