import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Link } from 'react-router-dom';
import { ReactComponent as ProcareLogo } from '../../assets/images/logo.svg';

export const Header = styled.header`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.gray5};
  margin-bottom: 24px;
`;

export const HeaderLogoLink = styled(Link)`
  display: flex;
`;

export const HeaderText = styled.h2`
  color: ${(props) => props.theme.colors.black0};
  font-size: 30px;
  font-weight: 400;
  margin: 16px 0;
`;

export const HeaderLogo = styled(ProcareLogo)`
  width: 160px;
  min-width: 160px;
  height: 72px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${down('desktop')} {
    width: ${(props) => props.theme.dimensions.asideWidthTablet} - 2 * 10px;
    background-size: 100px;
    background-position: left center;
    padding: 0;
    margin: 0 10px;
    min-width: 0;
  }
`;
