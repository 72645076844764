import {
  StatusContainer,
  StatusLabel,
  StatusValue,
  BlockingStatus,
  CompletedStatus,
  AttentionStatus,
} from './status.styles';
import { OnboardingStatus } from '@src/constants/onboardingStatus';

type StatusProps = {
  label: string;
  value: string;
  statusType?: OnboardingStatus;
};

export const Status = ({ label, value, statusType }: StatusProps) => {
  return (
    <StatusContainer>
      <StatusLabel>{label}</StatusLabel>
      {statusType ? (
        <>
          {statusType === OnboardingStatus.Blocking && <BlockingStatus>{value}</BlockingStatus>}
          {statusType === OnboardingStatus.Completed && <CompletedStatus>{value}</CompletedStatus>}
          {statusType === OnboardingStatus.NeedsAttention && (
            <AttentionStatus>{value.replace('_', ' ')}</AttentionStatus>
          )}
        </>
      ) : (
        <StatusValue>{value}</StatusValue>
      )}
    </StatusContainer>
  );
};
