import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { CompanySelection } from 'components/CompanySelection/CompanySelection';

export const SelectCompany = () => {
  return (
    <ContentContainer>
      <CompanySelection />
    </ContentContainer>
  );
};
