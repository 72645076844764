import { useEffect, useState } from 'react';
import { Table } from './Table';
import { ControlBar } from '../Table.styles';
import { fetchImportLogs, ImportLog, PageInfo, ImportType } from 'lib/fetchImportLogs';
import { Spinner } from 'components/Spinner/spinner';
import { Pagination } from 'components/Pagination/pagination';
import { Filter } from 'components/Filter/Filter';
import { SyncFilters } from 'components/Filter/Filters/SyncFilters/SyncFilters';

export const SyncsLogTable = () => {
  const [syncLogs, setSyncLogs] = useState<ImportLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);
  const [importType, setImportType] = useState<ImportType | undefined>(undefined);

  const loadMore = () => {
    if (pageInfo && pageInfo.hasNextPage) {
      setEndCursor(pageInfo.endCursor);
    }
  };

  const onPrev = () => {
    if (pageInfo && pageInfo.hasPreviousPage) {
      setEndCursor(pageInfo.startCursor);
    }
  };

  const onNext = () => {
    if (pageInfo && pageInfo.hasNextPage) {
      loadMore();
    }
  };

  const applyFilter = (newImportType: ImportType | undefined) => {
    setImportType(newImportType);
  };

  useEffect(() => {
    fetchImportLogs(endCursor, importType).then(
      ({ importLogs: newSyncLogs, pageInfo: newPageInfo }) => {
        setSyncLogs(newSyncLogs);
        setPageInfo(newPageInfo);
        setLoading(false);
      }
    );
  }, [endCursor, importType]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <ControlBar>
        <Filter tooltipContent={<SyncFilters onApplyFilter={applyFilter} />} />
        {pageInfo && (pageInfo.hasPreviousPage || pageInfo.hasNextPage) && (
          <Pagination
            onPrev={onPrev}
            onNext={onNext}
            disablePrev={!pageInfo.hasPreviousPage}
            disableNext={!pageInfo.hasNextPage}
          />
        )}
      </ControlBar>

      <Table syncLogs={syncLogs} />
    </>
  );
};
