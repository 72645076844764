import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from 'lib/sendErrorMessage';

export const uploadCompanyDocument = (
  docType: string,
  file: File
): Promise<AxiosResponse<any> | void> => {
  const formData = new FormData();
  formData.append('document[file]', file);
  formData.append('document[provided_document_type]', docType);

  return procareApi
    .post(`/api/v1/companies/documents`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
