import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from './sendErrorMessage';

type Company = {
  id: string;
  name: string;
  onboarded: boolean;
  platformIdentifier: string;
  providerIdentifier: string;
};

type SetCurrentCompanyPayload = {
  company: Company;
};

export const setCurrentCompany = (companyId: string): Promise<Company | void> => {
  const mutation = `
          mutation($companyId: ID!) {
            setCurrentCompany(companyId: $companyId) {
              company {
                id
                name
                onboarded
                platformIdentifier
                providerIdentifier
              }
            }
          }
        `;

  const variables = {
    companyId,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { setCurrentCompany: SetCurrentCompanyPayload } }>>(
      'graphql',
      {
        query: mutation,
        variables,
      }
    )
    .then((response: AxiosResponse<{ data: { setCurrentCompany: SetCurrentCompanyPayload } }>) => {
      return response.data.data.setCurrentCompany.company;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
