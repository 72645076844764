import styled from 'styled-components';

export const AccountCreationMessage = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.fontSizes.heading};
  font-weight: 700;
`;

export const InstructionMessage = styled.div`
  font-size: ${(props) => props.theme.fontSizes.normal};
`;
