import styled from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid ${(props) => props.theme.colors.borderTable};
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;
`;

export const DateSelectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
`;

export const DateContainer = styled.div`
  width: 48%;
`;
