import styled from 'styled-components';
import { FormInputContainer, Input } from 'components/form/Input/input.styles';
import { FormInput } from 'components/form';
import { hexToRgba } from 'styles/mixins';

export const WorkplaceFormContainer = styled.div``;

export const Form = styled.form`
  margin: 20px auto 5px;
  padding-bottom: 10px;
  border-bottom: 2px solid ${(props) => hexToRgba(props.theme.colors.offWhite, 0.75)};
`;

export const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;

  ${FormInputContainer} {
    min-width: 32%;
  }

  .state {
    min-width: 18%;
  }

  .city {
    min-width: 40%;
  }

  .postal {
    min-width: 40%;
  }
`;

export const StateInput = styled(FormInput)`
  width: 50px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px;
`;
