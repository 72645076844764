import { HeadingContainer, Heading, HeadingMessage, SubHeading } from './sectionHeader.styles';

type SectionHeaderProps = {
  title: string;
  subHeading?: string;
  message?: string;
  divider?: boolean;
  detailItem?: string;
};

export const SectionHeader = ({
  title,
  subHeading,
  message,
  divider = false,
  detailItem,
}: SectionHeaderProps) => {
  return (
    <HeadingContainer divider={divider}>
      <Heading>
        {title}{' '}
        {detailItem && (
          <span>
            {' > '}
            {detailItem}
          </span>
        )}
      </Heading>
      {subHeading && <SubHeading>{subHeading}</SubHeading>}
      {message && <HeadingMessage>{message}</HeadingMessage>}
    </HeadingContainer>
  );
};
