import styled from 'styled-components';

export const Heading = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.fontSizes.subHeading};
  font-weight: 500;
  margin: 0 0 10px;
`;

export const Date = styled.span`
  font-weight: 400;
`;
