import styled from 'styled-components';
import { ActionButtonSize } from 'components/ActionButton/actionButton';

type IconContainerProps = {
  color?: string;
  size?: ActionButtonSize;
  customSize?: string;
};

export const IconContainer = styled.div.attrs((props: IconContainerProps) => props)`
  margin: 0 10px;
  svg {
    width: ${({ theme, size, customSize }) => customSize || theme.iconSizes[size || 'small']};
    min-width: ${({ theme, size, customSize }) => customSize || theme.iconSizes[size || 'small']};
    height: ${({ theme, size, customSize }) => customSize || theme.iconSizes[size || 'small']};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    fill: ${({ theme, color }) => color || theme.colors.text};
  }
`;
