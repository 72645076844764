import styled from 'styled-components';

export const StepsContainer = styled.div``;

export const StepContentContainer = styled.div`
  margin: 20px 0;
`;

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StepIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 40px;
`;

export const StepConnector = styled.div`
  flex-grow: 1;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.gray0};
  margin: 0 20px;
  transform: translateY(-10px);
`;
