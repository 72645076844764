import { PermissionAccessItem } from './PermissionAccessItem';
import Tooltip from 'components/Tooltip/tooltip';
import { QuestionMark } from 'components/Tooltip/Elements/questionMark.styles';
import { formatName } from 'lib/utils/formatName';
import { Thead, Tr, Tbody, Th, Td } from '../Table.styles';
import { Access, StyledTable } from './PermissionsListTable.styles';

type Permission = {
  allowedFields: string[];
  id: string;
  operation: string;
  resource: string;
};

type TableProps = {
  permissions: Permission[];
  roleName: string;
};

enum TooltipPermissions {
  COMPANY_DOCUMENT = 'company_document',
  COMPANY = 'company',
}

export const Table = ({ permissions, roleName }: TableProps) => {
  const getPermissionForOperation = (resource: string, operation: string) => {
    return permissions.some((perm) => perm.resource === resource && perm.operation === operation);
  };

  const resources = Array.from(new Set(permissions.map((perm) => perm.resource)));

  return (
    <StyledTable>
      <Thead>
        <Tr>
          <Th>{formatName(roleName)} Permissions</Th>
          <Th>Access</Th>
        </Tr>
      </Thead>
      <Tbody>
        {resources.map((resource) => (
          <Tr key={resource}>
            <Td>
              {formatName(resource)}
              {/* Tooltips for specific permissions */}
              {resource === TooltipPermissions.COMPANY_DOCUMENT && (
                <Tooltip
                  content="This is where the company’s authorization and tax forms are saved."
                  direction="right"
                >
                  <QuestionMark>?</QuestionMark>
                </Tooltip>
              )}
              {resource === TooltipPermissions.COMPANY && roleName !== 'employee' && (
                <Tooltip
                  content="This is the company’s general information, e.g. legal name, address, phone number"
                  direction="right"
                >
                  <QuestionMark>?</QuestionMark>
                </Tooltip>
              )}
            </Td>
            <Td>
              <Access>
                <PermissionAccessItem hasPermission={getPermissionForOperation(resource, 'create')}>
                  CREATE
                </PermissionAccessItem>
                <PermissionAccessItem hasPermission={getPermissionForOperation(resource, 'update')}>
                  UPDATE
                </PermissionAccessItem>
                <PermissionAccessItem hasPermission={getPermissionForOperation(resource, 'read')}>
                  VIEW
                </PermissionAccessItem>
              </Access>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </StyledTable>
  );
};
