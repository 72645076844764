import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SyncLogContainer = styled.div`
  margin-bottom: 16px;
`;

export const Instructions = styled.p`
  font-size: ${(props) => props.theme.fontSizes.normal};
  margin-top: 25px;
`;

export const ReviewContainer = styled.div`
  margin: 25px 0;

  p {
    margin: 5px 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: start;
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
`;
