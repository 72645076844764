import styled from 'styled-components';

export const GroupContainer = styled.div`
  padding: 5px 0;
`;

export const GroupLabel = styled.h2`
  font-size: ${(props) => props.theme.fontSizes.formGroupLabel};
  font-weight: 400;
  margin: 0 0 10px;
`;
