import { useState, useEffect } from 'react';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useNavigate } from 'react-router-dom';
import { NotificationContainer, Container, Message, ButtonContainer } from '../notification.styles';
import { Heading, Date } from './viewPaystubs.styles';
import { fetchPaySchedule, Payday } from 'lib/fetchPaySchedule';
import moment from 'moment';

export const ViewPaystubsNotification = () => {
  const navigate = useNavigate();

  const [nextPayday, setNextPayday] = useState<Payday | null>(null);

  useEffect(() => {
    fetchPaySchedule().then((data) => {
      if (data) {
        setNextPayday(data.nextPayday);
      }
    });
  }, []);

  return (
    <NotificationContainer>
      <Container>
        <Heading>
          Upcoming Payday:{' '}
          {nextPayday && <Date>{moment(nextPayday.payday).format('dddd, MMMM D')}</Date>}
        </Heading>
        <Message>
          After every payday we store a paystub. You can access all of your previous paystubs here
          at anytime.
        </Message>
      </Container>
      <ButtonContainer>
        <ActionButton
          onClick={() => navigate('/paystubs')}
          size="medium"
          title="View paystubs"
          hidden={false}
        />
      </ButtonContainer>
    </NotificationContainer>
  );
};
