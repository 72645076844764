import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'hooks/redux';
import { onboard } from 'features/user/slice';
import { FormData, OnboardParams } from 'features/user/types';
import { loginSchema } from '../validator';
import { FormInput, FormButton } from 'components/form';
import {
  Form,
  AuthFormContainer,
  AuthFormHeader,
  AuthForm,
  ButtonContainer,
  Wrapper,
  AuthSubHeader,
  PasswordWrapper,
  ToggleButton,
} from '../authForm.styles';
import { toast } from 'react-toastify';
import { fetchUserEmail } from 'lib/fetchUserEmail';
import Tooltip from 'components/Tooltip/tooltip';
import { QuestionMark } from 'components/Tooltip/Elements/questionMark.styles';
import { EmailInputWrapper, TooltipWrapper } from './onboardingAccountForm.styles';
import { ReactComponent as ViewIcon } from '@src/assets/icons/view.svg';
import { ReactComponent as HideIcon } from '@src/assets/icons/hide.svg';
import { createFormKeyPressHandler } from 'lib/utils/formUtils';

export const OnboardAccountForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { confirmToken } = useParams<{ confirmToken: string }>();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    if (confirmToken) {
      fetchUserEmail(confirmToken, navigate)
        .then((email) => {
          if (email) {
            setValue('email', email);
          }
        })
        .catch((err) => {
          toast.error('Something went wrong. Try again later.');
        });
    }
  }, [confirmToken, setValue]);

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (Object.keys(errors).length === 0) {
      if (confirmToken) {
        dispatch(onboard({ password: data.password, confirmToken } as OnboardParams));
      } else {
        toast.error('Confirmation Token not provided');
      }
    }
  };
  const formKeyPressHandler = createFormKeyPressHandler(handleSubmit, onSubmit);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const isButtonDisabled = (): boolean => !watch('email') || !watch('password');

  return (
    <AuthForm>
      <AuthFormHeader>Welcome to Procare Payroll!</AuthFormHeader>
      <AuthSubHeader>Setup my payroll</AuthSubHeader>
      <AuthFormContainer>
        <Form onKeyPress={formKeyPressHandler} onSubmit={handleSubmit(onSubmit)}>
          <EmailInputWrapper>
            <FormInput
              id="email"
              placeholder="Email"
              type="email"
              required
              {...register('email')}
              disabled
            />
            <TooltipWrapper>
              <Tooltip
                content="Your email address cannot be edited here. You'll be able to update it in your employee profile once onboarded."
                direction="right"
              >
                <QuestionMark>?</QuestionMark>
              </Tooltip>
            </TooltipWrapper>
          </EmailInputWrapper>

          <PasswordWrapper>
            <FormInput
              id="password"
              placeholder="Password"
              type={passwordVisible ? 'text' : 'password'}
              autoComplete="on"
              required
              {...register('password')}
              error={errors.password?.message}
            />
            <ToggleButton
              type="button"
              icon={passwordVisible ? <HideIcon /> : <ViewIcon />}
              onClick={togglePasswordVisibility}
            />
          </PasswordWrapper>
          <ButtonContainer>
            <Wrapper>
              <FormButton
                onSubmit={handleSubmit(onSubmit)}
                disabled={isButtonDisabled()}
                label="Sign Up"
              />
            </Wrapper>
          </ButtonContainer>
        </Form>
      </AuthFormContainer>
    </AuthForm>
  );
};
