import { AxiosResponse, AxiosError } from 'axios';
import { sendAuthErrorMessage } from './sendAuthErrorMessage';
import { procareApi, ErrorResponse } from './HttpClient/httpClient';
import { ForgotPasswordInput } from 'components/AuthForm/ForgotPasswordForm/types';

type ForgotPasswordResponse = {
  message: string;
};

export const forgotPassword = (data: ForgotPasswordInput): Promise<ForgotPasswordResponse | void> =>
  procareApi
    .post<ForgotPasswordInput, AxiosResponse<ForgotPasswordResponse>>('/api/v1/auth/forgot', data)
    .then((response: AxiosResponse<ForgotPasswordResponse>) => {
      return {
        message: response.data.message,
      };
    })
    .catch((error: AxiosError<ErrorResponse>) => {
      sendAuthErrorMessage(error);
    });
