import {
  StyledTable,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Button,
  ButtonContainer,
  EmptyMessage,
} from '../Table.styles';
import {
  DeductionNode,
  MiscellaneousDeduction,
  ChildSupportDeduction,
} from 'lib/fetchEmployeePostTaxDeductions';
import { formatValue } from 'lib/utils/formatValue';

type TableProps = {
  deductions: DeductionNode[];
  onDelete: (providerId: string) => void;
  onUpdate: (deduction: DeductionNode) => void;
};

export const Table = ({ deductions, onDelete, onUpdate }: TableProps) => {
  const handleDeleteDeduction = (providerId: string) => {
    onDelete(providerId);
  };

  if (!deductions || deductions.length === 0) {
    return <EmptyMessage>No deductions found.</EmptyMessage>;
  }

  const extractAmount = (
    configObj: MiscellaneousDeduction | ChildSupportDeduction
  ): string | null => {
    if ('amount' in configObj && configObj.amount) {
      return configObj.amount;
    } else if ('percent' in configObj && (configObj as MiscellaneousDeduction).percent !== null) {
      return `${(configObj as MiscellaneousDeduction).percent}% of pay`;
    }
    return null;
  };

  return (
    <StyledTable>
      <Thead>
        <Tr>
          <Th>Type</Th>
          <Th>Description</Th>
          <Th>Effective Start</Th>
          <Th>Effective End</Th>
          <Th>Amount</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {deductions.map((deduction) => (
          <Tr key={deduction.providerId}>
            <Td>{formatValue(deduction.type, true)}</Td>
            <Td>{deduction.description}</Td>
            <Td>{deduction.effectiveStart}</Td>
            <Td>{deduction.effectiveEnd}</Td>
            <Td>{extractAmount(deduction.configurationObject)}</Td>
            <Td>
              <ButtonContainer>
                <Button onClick={() => onUpdate(deduction)}>Update</Button>
                <Button onClick={() => handleDeleteDeduction(deduction.providerId)}>Delete</Button>
              </ButtonContainer>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </StyledTable>
  );
};
