import styled, { css } from 'styled-components';
import { IconContainer } from 'components/Icon/icon.styles';

export const Td = styled.td`
  padding: 20px;
  border-top: 1px solid #ddd;
  text-transform: capitalize;
`;

const Status = css`
  text-transform: capitalize;
  border-radius: 15px;
  padding: 8px 16px;
`;

export const DraftStatus = styled.span`
  background-color: ${(props) => props.theme.colors.draft};
  ${Status}
`;

export const PendingStatus = styled.span`
  background-color: #ffffcc;
  ${Status}
`;

export const ProcessingStatus = styled.span`
  background-color: #ffffcc;
  ${Status}
`;

export const FailedStatus = styled.span`
  background-color: ${(props) => props.theme.colors.blockingStatus};
  ${Status}
`;

export const PartiallyPaidStatus = styled.span`
  background-color: #ffffcc;
  ${Status}
`;

export const PaidStatus = styled.span`
  background-color: ${(props) => props.theme.colors.completedStatus};
  ${Status}
`;

export const TooltipDiv = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.gray0};
  box-shadow: 0 7px 40px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  color: ${(props) => props.theme.colors.black0};
  padding: 10px;
  margin-top: 8px;
  z-index: 10;
  width: 150px;
  right: 25px;

  // Tooltip arrow
  &::before {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    display: block;
    right: 24px;
    top: -7px;
    border-top: 1px solid ${(props) => props.theme.colors.gray0};
    border-left: 1px solid ${(props) => props.theme.colors.gray0};
    background-color: white;
  }
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  button {
    width: 100%;
    text-align: left;
    margin-bottom: 8px;
  }

  button:last-child {
    margin-bottom: 0;
  }

  button + button {
    border-top: 1px solid ${(props) => props.theme.colors.gray0};
    padding-top: 8px;
  }
`;

export const ManageIcon = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px 8px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${IconContainer} {
    width: 100%;
    height: 100%;

    svg {
      padding: 8px;
      width: 24px;
      min-width: 24px;
      height: 24px;
    }
  }
`;
