import styled from 'styled-components';

export const QuestionMark = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.theme.colors.warning};
  border-radius: 50%;
  color: white;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
`;
