import React from 'react';
import InfoPlaceholder from './Info';
import Feature from './Feature';
import { IconType } from '../../assets/icons/index';
import { StepPlaceholderContainer } from './Placeholder.styles';

type Step = {
  iconName: IconType;
  color?: string;
  text: string;
};

type EmptyStatePlaceholderProps = {
  title: string;
  description: React.ReactNode;
  steps: Step[];
  className?: string;
};

const EmptyStatePlaceholder = ({ title, description, steps }: EmptyStatePlaceholderProps) => {
  return (
    <InfoPlaceholder title={title} image={false}>
      {description}
      <StepPlaceholderContainer>
        {steps.map((step, index) => (
          <Feature key={index} iconName={step.iconName} color={step.color}>
            {step.text}
          </Feature>
        ))}
      </StepPlaceholderContainer>
    </InfoPlaceholder>
  );
};

export default EmptyStatePlaceholder;
