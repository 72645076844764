import { useCallback } from 'react';
import { StyledTable, Thead, Tr, Th, Td, Tbody, EmptyMessage } from '../Table.styles';
import { DownloadLink } from 'components/DownloadLink/DownloadLink';
import { EmployeeDocument } from 'features/user/types';
import { downloadEmployeeDocument } from 'lib/downloadEmployeeDocument';

export type TableProps = {
  documents: EmployeeDocument[];
};

export const Table = ({ documents }: TableProps) => {
  const handleDownload = useCallback(async (docId: string) => {
    const documentData = await downloadEmployeeDocument(docId);
    if (documentData) {
      const url = window.URL.createObjectURL(new Blob([documentData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `document_${docId}.pdf`);
      document.body.appendChild(link);
      link.click();
    }
  }, []);

  if (!documents || documents.length === 0) {
    return <EmptyMessage>No documents found.</EmptyMessage>;
  }

  return (
    <StyledTable>
      <Thead>
        <Tr>
          <Th>Document</Th>
          <Th>Signed Date</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {documents.map((doc) => (
          <Tr key={doc.id}>
            <Td>{doc.label}</Td>
            <Td>{doc.filedOn}</Td>
            <Td>
              <DownloadLink onClick={() => handleDownload(doc.id)}>Download</DownloadLink>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </StyledTable>
  );
};
