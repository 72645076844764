import { ReactNode } from 'react';
import { StyledButton } from './IconButton.styles';

type IconButtonProps = {
  icon: ReactNode;
  onClick?: () => void;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
};

export const IconButton = ({ icon, onClick, className, type = 'button' }: IconButtonProps) => {
  return (
    <StyledButton type={type} className={className} onClick={onClick}>
      {icon}
    </StyledButton>
  );
};
