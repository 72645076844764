import { NotificationContainer, Container, Heading, Message } from '../notification.styles';
import { ButtonContainer } from './companyOnboardingNotification.styles';
import { ActionButton } from 'components/ActionButton/actionButton';

type OnboardingNotificationProps = {
  openModal: () => void;
};

export const CompanyOnboardingNotification = ({ openModal }: OnboardingNotificationProps) => {
  return (
    <NotificationContainer>
      <Container>
        <Heading>Complete Onboarding</Heading>
        <Message>
          Let&apos;s get your company onboarded so that you can start running payroll!
        </Message>
      </Container>
      <ButtonContainer>
        <ActionButton
          onClick={openModal}
          size="medium"
          title="Continue Onboarding"
          hidden={false}
        />
      </ButtonContainer>
    </NotificationContainer>
  );
};
