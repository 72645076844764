import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendAuthErrorMessage } from './sendAuthErrorMessage';
import { toast } from 'react-toastify';

export interface UserResponse {
  id: string;
  email: string;
}

export const fetchUserEmail = (
  userId: string,
  navigate: (to: string) => void
): Promise<string | void> => {
  return procareApi
    .get<UserResponse>(`/api/v1/auth/employees/${userId}`)
    .then((response: AxiosResponse<UserResponse>) => {
      return response.data.email;
    })
    .catch((error: AxiosError<ErrorResponse>) => {
      if (error.response?.status === 404) {
        navigate('/login');
        toast.warning('Account already confirmed. Please login with your user credentials.');
      } else {
        sendAuthErrorMessage(error);
      }
    });
};
