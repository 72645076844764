import { useState, useEffect, useRef, PropsWithRef, forwardRef } from 'react';
import { Select, FormSelectContainer, FormLabel, ArrowIcon, SelectWrapper } from './select.styles';

export interface SelectProps extends PropsWithRef<JSX.IntrinsicElements['select']> {
  label?: string;
  className?: string;
  id: string;
  defaultValue?: string | number;
  options: { value: string | number; label: string }[];
}

export const FormSelect = forwardRef<HTMLSelectElement, SelectProps>(function SelectField(
  { label, className, id, options, defaultValue, ...rest },
  ref
) {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsSelectOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleSelect = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  return (
    <FormSelectContainer ref={selectRef}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <SelectWrapper>
        <Select
          id={id}
          ref={ref}
          onClick={toggleSelect}
          className={className ? `${className}` : ''}
          defaultValue={defaultValue}
          {...rest}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>

        <ArrowIcon isOpen={isSelectOpen} />
      </SelectWrapper>
    </FormSelectContainer>
  );
});
