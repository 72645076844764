import { useState, ChangeEvent } from 'react';
import { FormSelect } from 'components/form';
import { ImportType } from 'lib/fetchImportLogs';
import { ActionButton } from 'components/ActionButton/actionButton';
import { FilterForm, ButtonContainer } from 'components/Filter/Filter.styles';

type SyncFiltersProps = {
  onApplyFilter: (importType: ImportType | undefined) => void;
};

export const SyncFilters = ({ onApplyFilter }: SyncFiltersProps) => {
  const [localImportType, setLocalImportType] = useState<ImportType | undefined>(undefined);

  const handleApply = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onApplyFilter(localImportType);
  };

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value === '' ? undefined : (e.target.value as ImportType);
    setLocalImportType(value);
  };

  return (
    <FilterForm>
      <FormSelect
        id="importType"
        label="Sync Type"
        options={[
          { value: '', label: 'All Types' },
          { value: ImportType.Workplace, label: 'Workplace' },
          { value: ImportType.Staff, label: 'Staff' },
        ]}
        value={localImportType ?? ''}
        onChange={handleChange}
      />
      <ButtonContainer>
        <ActionButton onClick={handleApply} size="medium" title="Apply" hidden={false} />
      </ButtonContainer>
    </FilterForm>
  );
};
