import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from 'lib/sendErrorMessage';

export type Payday = {
  approvalDeadline: string;
  impactedByWeekendOrHoliday: boolean;
  payday: string;
  periodEnd: string;
  periodStart: string;
};

export type PaySchedule = {
  id: string;
  name: string;
  providerIdentifier: string;
  payFrequency: string;
  nextPayday: Payday;
};

type PayScheduleResponse = {
  data: {
    getPaySchedule: PaySchedule;
  };
};

export const fetchPaySchedule = (): Promise<PaySchedule | null> => {
  const query = `
    query {
      getPaySchedule {
        id
        name
        providerIdentifier
        payFrequency
        nextPayday {
          approvalDeadline
          impactedByWeekendOrHoliday
          payday
          periodEnd
          periodStart
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<PayScheduleResponse>>('graphql', {
      query,
    })
    .then((response: AxiosResponse<PayScheduleResponse>) => {
      return response.data.data.getPaySchedule;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return null;
    });
};
