import { useAppSelector } from '../../../hooks/redux';
import { AuthorizeCheckContainer, AuthorizeCheckTerms } from './AuthorizeCheck.styles';
import { Checkbox, CheckboxProps } from 'components/Checkbox/Checkbox';

export const AuthorizeCheck = ({ id, isChecked, toggleCheckbox }: CheckboxProps) => {
  const { currentUser } = useAppSelector((state) => state.user);

  return (
    <AuthorizeCheckContainer>
      <Checkbox id={id} isChecked={isChecked} toggleCheckbox={toggleCheckbox} />
      <AuthorizeCheckTerms>
        I, {currentUser?.user.name}, have read and agree to{' '}
        <strong>Check Payroll User Service Terms</strong>. I certify that I am authorized to agree
        on behalf of the company.
      </AuthorizeCheckTerms>
    </AuthorizeCheckContainer>
  );
};
