import { ContentContainer, PageContainer } from 'components/SharedComponents/Containers.styles';
import { SectionHeader } from 'components/SectionHeader/sectionHeader';
import { PayrollSummary } from 'components/Reports/PayrollSummary/payrollSummary';
import { PayrollJournal } from 'components/Reports/PayrollJournal/payrollJournal';

export const Reports = () => {
  return (
    <PageContainer>
      <SectionHeader title="Reports" />
      <ContentContainer>
        <PayrollSummary />
        <PayrollJournal />
      </ContentContainer>
    </PageContainer>
  );
};
