import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

type RunPayrollComponentResponse = {
  data: {
    companyRunPayrollComponent: {
      url: string;
    };
  };
};

export const fetchRunPayrollComponent = (): Promise<string | void> => {
  const query = `
    query runPayrollComponent {
      companyRunPayrollComponent {
        url
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<RunPayrollComponentResponse>>('graphql', { query })
    .then((response: AxiosResponse<RunPayrollComponentResponse>) => {
      return response.data.data.companyRunPayrollComponent.url;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
