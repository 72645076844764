import { Container, NavCardsContainer, Heading } from './NavCardList.styles';
import { NavItem } from '@src/constants/navData';
import { NavCard } from './NavCard/NavCard';

type NavCardListProps = {
  navData: NavItem[];
};

export const NavCardList = ({ navData }: NavCardListProps) => {
  return (
    <Container>
      <Heading>Quick Navigation</Heading>
      <NavCardsContainer>
        {navData.map((item: NavItem) => {
          if (item.description) {
            return (
              <NavCard
                key={item.url}
                heading={item.customText ? item.customText : item.title}
                description={item.description}
                link={item.url}
                title={item.title}
                customLinkText={item.customLinkText}
              />
            );
          }
          return null;
        })}
      </NavCardsContainer>
    </Container>
  );
};
