import styled from 'styled-components';

export const ActionNotificationContainer = styled.div`
  padding: 8px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  padding: 20px;
`;

export const Heading = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.fontSizes.subHeading};
  font-weight: 500;
  margin: 0 0 10px;
`;

export const Count = styled.p`
  font-size: 20px;
  color: #333;
  margin-bottom: 8px;
  font-weight: 500;
`;

export const Link = styled.a`
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
`;
