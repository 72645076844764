import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

type CompanyOnboardingStatusResponse = {
  data: {
    companyOnboardingStatus: {
      result: boolean;
    };
  };
};

export const fetchCompanyOnboardingStatus = (): Promise<boolean> => {
  const query = `
        query GetCompanyOnboardingStatus {
          companyOnboardingStatus {
            result
          }
        }
      `;
  return procareApi
    .post<unknown, AxiosResponse<CompanyOnboardingStatusResponse>>('graphql', {
      query,
    })
    .then((response: AxiosResponse<CompanyOnboardingStatusResponse>) => {
      return response.data.data.companyOnboardingStatus.result;
    })
    .catch((error: AxiosError<APIError>) => {
      return false;
    });
};
