import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

type EmployeeOnboardingStatusResponse = {
  data: {
    employeeOnboardingStatus: {
      result: boolean;
    };
  };
};

export const fetchEmployeeOnboardingStatus = (): Promise<boolean> => {
  const query = `
        query GetEmployeeOnboardingStatus {
          employeeOnboardingStatus {
            result
          }
        }
      `;
  return procareApi
    .post<unknown, AxiosResponse<EmployeeOnboardingStatusResponse>>('graphql', {
      query,
    })
    .then((response: AxiosResponse<EmployeeOnboardingStatusResponse>) => {
      return response.data.data.employeeOnboardingStatus.result;
    })
    .catch((error: AxiosError<APIError>) => {
      return false;
    });
};
