import { ContentContainer, PageContainer } from 'components/SharedComponents/Containers.styles';
import { SectionHeader } from 'components/SectionHeader/sectionHeader';
import { UserProfile } from 'components/UserProfile/UserProfile';

export const Profile = () => {
  return (
    <PageContainer>
      <SectionHeader title="My Information" />
      <ContentContainer>
        <UserProfile />
      </ContentContainer>
    </PageContainer>
  );
};
