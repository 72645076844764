import {
  OnboaringInfoContainer,
  OnboaringInfoHeadingContainer,
  OnboaringInfoHeading,
  InfoContainer,
  InfoInstruction,
  InfoList,
  InfoListItem,
  ButtonContainer,
} from './OnboardingInfo.styles';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useNavigate } from 'react-router-dom';

export const OnboardingInfo = () => {
  const navigate = useNavigate();
  return (
    <OnboaringInfoContainer>
      <OnboaringInfoHeadingContainer>
        <OnboaringInfoHeading>Before you begin Onboarding</OnboaringInfoHeading>
      </OnboaringInfoHeadingContainer>
      <InfoContainer>
        <InfoInstruction>Make sure you have the following information:</InfoInstruction>
        <InfoList>
          <InfoListItem>Industry</InfoListItem>
          <InfoListItem>Company type</InfoListItem>
          <InfoListItem>Company legal name and or trade name (DBA)</InfoListItem>
          <InfoListItem>EIN Number</InfoListItem>
          <InfoListItem>Company phone number</InfoListItem>
          <InfoListItem>Company legal address</InfoListItem>
          <InfoListItem>Workplace address</InfoListItem>
          <InfoListItem>Banking information</InfoListItem>
          <InfoListItem>Pay schedule</InfoListItem>
        </InfoList>
      </InfoContainer>
      <ButtonContainer>
        <ActionButton
          onClick={() => navigate('/workplace')}
          size="medium"
          title="Begin Onboarding"
          hidden={false}
        />
      </ButtonContainer>
    </OnboaringInfoContainer>
  );
};
