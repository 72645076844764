import { CheckboxContainer, CheckboxLabel, CheckboxInput } from './Checkbox.styles';
import { ReactNode } from 'react';

export type CheckboxProps = {
  id: string;
  isChecked: boolean;
  toggleCheckbox: () => void;
  label?: string | ReactNode;
  disabled?: boolean;
};

export const Checkbox = ({
  id,
  isChecked,
  toggleCheckbox,
  label,
  disabled = false,
}: CheckboxProps) => {
  return (
    <CheckboxContainer>
      <CheckboxInput
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={toggleCheckbox}
        className={isChecked ? 'checked' : ''}
        disabled={disabled}
      />
      {label && (
        <CheckboxLabel htmlFor={id} disabled={disabled}>
          {label}
        </CheckboxLabel>
      )}
    </CheckboxContainer>
  );
};
