import styled from 'styled-components';
import { IconButton } from 'components/IconButton/IconButton';

export const AuthFormHeader = styled.div`
  font-size: 2em;
  font-weight: 400;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
  line-height: 1.2em;
`;

export const AuthSubHeader = styled.div`
  font-size: 1.5em;
  color: #605e5c;
  text-align: center;
  margin-bottom: 24px;
  line-height: 1.2em;
`;

export const AuthFormContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
`;

export const Form = styled.form`
  width: 360px;
  margin: auto;

  &-label {
    font-size: 0.9em;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black2};
    margin: 24px 0 14px;
    text-transform: uppercase;
  }
`;

export const AuthAction = styled.div`
  font-size: 0.85em;
  line-height: 1.3;
  margin-bottom: 12px;
`;

export const AuthForm = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  min-width: 720px;
  padding: 40px 8px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PasswordReset = styled.div`
  display: block;
  margin-bottom: 8px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PasswordWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ToggleButton = styled(IconButton)`
  position: absolute;
  right: 0px;
  top: 12px;
  transform: translate(-50%);

  svg {
    width: 24px;
    height: 24px;
    fill: ${(props) => props.theme.colors.gray7};
  }
`;
