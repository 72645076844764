import { StyledTable, Thead, Tr, Th, Td, Tbody, EmptyMessage } from '../../Table.styles';
import { PayrollItem } from 'lib/fetchProviderPayroll';
import { AggregateContainer, Aggregate, Total, SubHeading } from './ReviewStep.styles';
import { currencyFormatter } from 'lib/utils/currencyFormatter';
import { formatName } from 'lib/utils/formatName';

type EmployeePay = {
  name: string;
  regular: number;
  overtime: number;
  additionalEarning: number;
  reimbursements: number;
  netPay: number;
  paymentMethod: string;
};

const calculateEmployeePay = (items: PayrollItem[]): EmployeePay[] => {
  return items.map((item) => {
    const regular = item.earnings
      .filter((earning) => earning.type === 'hourly')
      .reduce((acc, curr) => acc + Number(curr.amount), 0);
    const overtime = item.earnings
      .filter((earning) => earning.type === 'overtime')
      .reduce((acc, curr) => acc + Number(curr.amount), 0);
    const additionalEarning = item.earnings
      .filter((earning) => earning.type === 'bonus' || earning.type === 'commission')
      .reduce((acc, curr) => acc + Number(curr.amount), 0);
    const reimbursements = item.reimbursements.reduce((acc, curr) => acc + Number(curr.amount), 0);

    return {
      name: item.employee.name,
      regular,
      overtime,
      additionalEarning,
      reimbursements,
      netPay: Number(item.netPay),
      paymentMethod: item.paymentMethod,
    };
  });
};

const calculateAggregatePay = (employeePay: EmployeePay[]) => {
  return employeePay.reduce(
    (acc, curr) => {
      acc.regular += curr.regular;
      acc.overtime += curr.overtime;
      acc.additionalEarning += curr.additionalEarning;
      acc.reimbursements += curr.reimbursements;
      acc.netPay += curr.netPay;
      return acc;
    },
    {
      regular: 0,
      overtime: 0,
      additionalEarning: 0,
      reimbursements: 0,
      netPay: 0,
    }
  );
};

export const EmployeePayBreakdown = ({ items }: { items: PayrollItem[] }) => {
  const employeePay = calculateEmployeePay(items);
  const aggregatePay = calculateAggregatePay(employeePay);

  if (employeePay.length === 0) {
    return (
      <EmptyMessage>No employees found. Please add employees in order to run payroll.</EmptyMessage>
    );
  }

  return (
    <>
      <SubHeading>Employee Pay Breakdown</SubHeading>
      <AggregateContainer>
        <Aggregate>
          <div>Total Regular</div>
          <Total>{currencyFormatter(aggregatePay.regular)}</Total>
        </Aggregate>
        <Aggregate>
          <div>Total Overtime</div>
          <Total>{currencyFormatter(aggregatePay.overtime)}</Total>
        </Aggregate>
        <Aggregate>
          <div>Total Additional Earnings</div>
          <Total>{currencyFormatter(aggregatePay.additionalEarning)}</Total>
        </Aggregate>
        <Aggregate>
          <div>Total Reimbursements</div>
          <Total>{currencyFormatter(aggregatePay.reimbursements)}</Total>
        </Aggregate>
        <Aggregate>
          <div>Total Net Pay</div>
          <Total>{currencyFormatter(aggregatePay.netPay)}</Total>
        </Aggregate>
      </AggregateContainer>
      <StyledTable>
        <Thead>
          <Tr>
            <Th>Employee</Th>
            <Th>Regular</Th>
            <Th>Overtime</Th>
            <Th>Additional Earnings</Th>
            <Th>Reimbursements</Th>
            <Th>Net Pay</Th>
            <Th>Payment Type</Th>
          </Tr>
        </Thead>
        <Tbody>
          {employeePay.map((employee, index) => (
            <Tr key={index}>
              <Td>{employee.name}</Td>
              <Td>{currencyFormatter(employee.regular)}</Td>
              <Td>{currencyFormatter(employee.overtime)}</Td>
              <Td>{currencyFormatter(employee.additionalEarning)}</Td>
              <Td>{currencyFormatter(employee.reimbursements)}</Td>
              <Td>{currencyFormatter(employee.netPay)}</Td>
              <Td>{formatName(employee.paymentMethod)}</Td>
            </Tr>
          ))}
        </Tbody>
      </StyledTable>
    </>
  );
};
