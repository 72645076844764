import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/redux';
import { Table } from './Table';
import { fetchEmployeeDocuments } from 'lib/fetchEmployeeDocuments';
import { EmployeeDocument, CurrentUserContext, EmployeeContext } from 'features/user/types';
import { Spinner } from 'components/Spinner/spinner';

type UserState = {
  user: {
    currentUser: CurrentUserContext | null;
  };
};

type DocumentsTableProps = {
  employee?: EmployeeContext;
};

export const DocumentsTable = ({ employee }: DocumentsTableProps) => {
  const { currentUser } = useAppSelector((state: UserState) => state.user);
  const [documents, setDocuments] = useState<EmployeeDocument[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (employee) {
      fetchEmployeeDocuments(employee.id).then((fetchedDocuments) => {
        setDocuments(fetchedDocuments);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [currentUser]);

  return loading ? <Spinner /> : <Table documents={documents} />;
};
