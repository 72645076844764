import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from './sendErrorMessage';

type DeletePayrollInput = {
  providerId: string;
};

type DeletePayrollPayload = {
  result: boolean;
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

export const deletePayroll = (payrollInput: DeletePayrollInput): Promise<boolean | void> => {
  const mutation = `
        mutation($payrollInput: PayrollInput!) {
          deletePayroll(payrollInput: $payrollInput) {
            result
            error {
              message
              type
              inputErrors {
                field
                fieldPath
                message
              }
            }
          }
        }
      `;

  const variables = {
    payrollInput,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { deletePayroll: DeletePayrollPayload } }>>('graphql', {
      query: mutation,
      variables,
    })
    .then((response: AxiosResponse<{ data: { deletePayroll: DeletePayrollPayload } }>) => {
      if (response.data.data.deletePayroll.error) {
        sendErrorMessage(response.data.data.deletePayroll.error);
        return;
      }
      return response.data.data.deletePayroll.result;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
