import { Table } from './Table';
import { Role } from 'lib/fetchEmployeeRoles';

export type PermissionsListTableProps = {
  roles: Role[];
};

export const PermissionsListTable = ({ roles }: PermissionsListTableProps) => {
  return (
    <>
      {roles.map((role) => (
        <div key={role.id}>
          <Table roleName={role.name} permissions={role.permissions} />
        </div>
      ))}
    </>
  );
};
