import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'hooks/redux';
import { useNavigate } from 'react-router-dom';
import { login } from 'features/user/slice';
import { FormData } from 'features/user/types';
import { loginSchema } from '../validator';
import { FormInput, FormButton } from 'components/form';
import { ActionButton } from 'components/ActionButton/actionButton';
import { toast } from 'react-toastify';
import {
  Form,
  AuthFormContainer,
  AuthForm,
  AuthFormHeader,
  AuthSubHeader,
  ButtonContainer,
  PasswordReset,
  Wrapper,
  PasswordWrapper,
  ToggleButton,
} from '../authForm.styles';
import { ReactComponent as ViewIcon } from '@src/assets/icons/view.svg';
import { ReactComponent as HideIcon } from '@src/assets/icons/hide.svg';
import { createFormKeyPressHandler } from 'lib/utils/formUtils';

export const LoginForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(loginSchema),
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    if (Object.keys(errors).length === 0) {
      try {
        const resultAction = await dispatch(login(data));
        if (login.fulfilled.match(resultAction)) {
          navigate('/select-company');
        }
      } catch (err) {
        toast.error('Login Failure.');
      }
    }
  };
  const formKeyPressHandler = createFormKeyPressHandler(handleSubmit, onSubmit);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const isButtonDisabled = (): boolean => !watch('email');

  return (
    <AuthForm>
      <AuthFormHeader>Welcome to Procare Payroll!</AuthFormHeader>
      <AuthSubHeader>Log In to your Account</AuthSubHeader>
      <AuthFormContainer>
        <Form onKeyPress={formKeyPressHandler}>
          <FormInput
            id="email"
            placeholder="Email"
            type="email"
            required
            {...register('email')}
            error={errors.email?.message}
          />
          <PasswordWrapper>
            <FormInput
              id="password"
              placeholder="Password"
              type={passwordVisible ? 'text' : 'password'}
              autoComplete="on"
              required
              {...register('password')}
              error={errors.password?.message}
            />
            <ToggleButton
              type="button"
              icon={passwordVisible ? <HideIcon /> : <ViewIcon />}
              onClick={togglePasswordVisibility}
            />
          </PasswordWrapper>
          <ButtonContainer>
            <Wrapper>
              <PasswordReset>
                Forgot Password?
                <ActionButton
                  onClick={() => navigate('/forgot-password')}
                  size="small"
                  variant="text"
                  title="Reset"
                  hidden={false}
                />
              </PasswordReset>
              <FormButton
                onSubmit={handleSubmit(onSubmit)}
                disabled={isButtonDisabled()}
                label="Login"
              />
            </Wrapper>
          </ButtonContainer>
        </Form>
      </AuthFormContainer>
    </AuthForm>
  );
};
