import styled from 'styled-components';
import { Modal } from 'components/Modal/Modal';
import { IconButton } from 'components/IconButton/IconButton';

export const CloseButton = styled(IconButton)`
  position: absolute;
  z-index: 10;
  top: 16px;
  right: 16px;
`;

export const BankAccountModal = styled(Modal)`
  iframe {
    height: 70vh !important;
    width: 400px !important;
    max-height: 750px;
    border-radius: 16px;
  }
`;

export const BankAccountItem = styled.p``;

export const ItemLabel = styled.span`
  font-weight: 500;
  color: ${(props) => props.theme.colors.black};
  display: inline-block;
  width: 150px;
`;

export const ItemValue = styled.span`
  display: inline-block;
  text-transform: capitalize;
`;

export const BankAccountContainer = styled.div`
  background: white;
  border-radius: 16px;
`;
