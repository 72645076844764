import { PropsWithRef, forwardRef } from 'react';
import { Input, FormInputContainer, FormLabel, InputError } from './input.styles';

export interface InputProps extends PropsWithRef<JSX.IntrinsicElements['input']> {
  label?: string;
  className?: string;
  id: string;
  column?: 2 | 3;
  error?: string | null;
}

export const FormInput = forwardRef<HTMLInputElement, InputProps>(function InputField(
  { label, className, id, column, error, ...rest },
  ref
) {
  return (
    <FormInputContainer className={className ? `${className}` : ''} column={column}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <Input id={id} ref={ref} {...rest} />
      {error && <InputError>{error}</InputError>}
    </FormInputContainer>
  );
});
