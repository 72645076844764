import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { CheckComponent } from 'components/CheckComponent/checkComponent';
import { useEffect, useState } from 'react';
import { fetchEmployeeOnboarding } from 'lib/Check/fetchEmployeeOnboardingComponent';
import { Spinner } from 'components/Spinner/spinner';
import { EmployeeOnboardingModal } from './employeeOnboarding.styles';
import { EmployeeOnboardingNotification } from 'components/DashboardNotifications/EmployeeOnboardingNotification/employeeOnboardingNoitification';
import { useAppDispatch } from 'hooks/redux';
import { setEmployeeOnboarded } from 'features/user/slice';
import { fetchEmployeeOnboardingStatus } from 'lib/fetchEmployeeOnboardingStatus';
import { toast } from 'react-toastify';

export const EmployeeOnboarding = () => {
  const dispatch = useAppDispatch();
  const [componentURL, setComponentURL] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(true);

  useEffect(() => {
    const fetchComponentURL = async () => {
      const url = await fetchEmployeeOnboarding();
      if (url) {
        setComponentURL(url);
      }

      setIsLoading(false);
    };
    fetchComponentURL();
  }, []);

  const completeModal = () => {
    setModalIsOpen(false);

    fetchEmployeeOnboardingStatus()
      .then((status) => {
        if (status) {
          toast.success('You have been successfully onboarded!');
        }
        dispatch(setEmployeeOnboarded(status));
      })
      .catch(() => {
        dispatch(setEmployeeOnboarded(false));
      });
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  return (
    <ContentContainer contentAlign="left" onboarding={true}>
      <EmployeeOnboardingNotification openModal={openModal} />
      {isLoading ? (
        <Spinner />
      ) : (
        <EmployeeOnboardingModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              position: 'fixed',
              inset: '0px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 11,
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              transform: 'translate(-50%, -50%)',
              borderRadius: '16px',
              overflow: 'visible',
              width: '65vw',
            },
          }}
        >
          <CheckComponent
            id="employee-onboarding"
            componentURL={componentURL}
            onComplete={completeModal}
            onClose={closeModal}
          />
        </EmployeeOnboardingModal>
      )}
    </ContentContainer>
  );
};
