import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.gray6};
  color: ${(props) => props.theme.colors.black};
  font-size: 20px;
  padding: 20px;
  text-align: center;
  min-height: 400px;
`;

export const Loading = styled.img`
  width: 120px;
`;
