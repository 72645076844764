import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { sendErrorMessage, APIError } from './sendErrorMessage';
import { Employee, Address } from 'features/user/types';

type ProfileUpdateInput = {
  address: Address;
};

type UpdateProfilePayload = {
  employee: Employee;
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

export const updateProfile = (profileParams: ProfileUpdateInput): Promise<Employee | void> => {
  const mutation = `
    mutation($profileParams: UpdateProfileInput!) {
      updateProfile(profileParams: $profileParams) {
        employee {
          id
          name
          providerIdentifier
          providerAttributes {
            dateOfBirth
            email
            providerIdentifier
            ssnLastFour
            address {
              city
              country
              line1
              line2
              postalCode
              state
            }
          }
        }
        error {
          message
          type
          inputErrors {
            field
            fieldPath
            message
          }
        }
      }
    }
  `;

  const variables = {
    profileParams,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { updateProfile: UpdateProfilePayload } }>>('graphql', {
      query: mutation,
      variables,
    })
    .then((response: AxiosResponse<{ data: { updateProfile: UpdateProfilePayload } }>) => {
      if (response.data.data.updateProfile.error) {
        sendErrorMessage(response.data.data.updateProfile.error);
      }
      return response.data.data.updateProfile.employee;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
