import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

type EmployeeOnboardResponse = {
  data: {
    employeeOnboardComponent: {
      url: string;
    };
  };
};

export const fetchEmployeeOnboarding = () => {
  const query = `
    query {
      employeeOnboardComponent {
        url
      }
    }
  `;
  return procareApi
    .post<unknown, AxiosResponse<EmployeeOnboardResponse>>('graphql', { query })
    .then((response: AxiosResponse<EmployeeOnboardResponse>) => {
      return response.data.data.employeeOnboardComponent.url;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
