import { withRouter } from 'lib/withRouter';

import { Header, HeaderLogo, HeaderLogoLink, HeaderText } from './authHeader.styles';

export const AuthHeader = withRouter(() => {
  return (
    <Header>
      <HeaderLogoLink to="/">
        <HeaderLogo />
        <HeaderText>Payroll</HeaderText>
      </HeaderLogoLink>
    </Header>
  );
});
