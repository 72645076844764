import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';
import { EmployeeDocument } from 'features/user/types';

type EmployeeDocumentsResponse = {
  data: {
    employeeTaxDocuments: EmployeeDocument[];
  };
};

export const fetchEmployeeDocuments = (employeeId: string): Promise<EmployeeDocument[]> => {
  const query = `
      query EmployeeDocuments($employeeInput: EmployeeInput!) {
        employeeTaxDocuments(employeeInput: $employeeInput) {
          id
          filedOn
          label
        }
      }
    `;
  return procareApi
    .post<unknown, AxiosResponse<EmployeeDocumentsResponse>>('graphql', {
      query,
      variables: { employeeInput: { id: employeeId } },
    })
    .then((response: AxiosResponse<EmployeeDocumentsResponse>) => {
      return response.data.data.employeeTaxDocuments;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return [];
    });
};
