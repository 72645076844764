import styled from 'styled-components';

export const NotificationContainer = styled.div`
  padding: 8px;
  border-radius: 10px;
  min-width: 800px;
`;

export const Container = styled.div``;

export const Heading = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.fontSizes.subHeading};
  font-weight: 500;
  margin: 0 0 10px;
`;

export const Message = styled.div`
  font-size: ${(props) => props.theme.fontSizes.normal};
`;

export const ButtonContainer = styled.div`
  padding: 8px 0;
  display: flex;
  justify-content: flex-end;
`;
