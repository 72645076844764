import styled from 'styled-components';
import { CheckboxContainer } from 'components/Checkbox/Checkbox.styles';

export const AuthorizeCheckContainer = styled.div`
  display: flex;
  padding: 8px 0;

  ${CheckboxContainer} {
    margin-top: 10px;
  }
`;

export const AuthorizeCheckTerms = styled.p`
  padding: 8px;
  margin: 0;
`;
