import React from 'react';
import { Icon } from 'components/Icon/icon';
import { IconType } from '../../assets/icons/index';
import { FeaturePlaceholder, FeaturePlaceholderDesc } from './Placeholder.styles';
import { theme } from 'styles/theme';

type FeatureProps = {
  iconName: IconType;
  color?: string;
  children: React.ReactNode;
};

const Feature = ({ iconName, color, children }: FeatureProps) => {
  return (
    <FeaturePlaceholder>
      <Icon name={iconName} customSize="60px" color={color || theme.colors.primary} />
      <FeaturePlaceholderDesc>{children}</FeaturePlaceholderDesc>
    </FeaturePlaceholder>
  );
};

export default Feature;
