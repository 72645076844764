import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from './sendErrorMessage';
import { PostTaxDeductionTypes } from './fetchEmployeePostTaxDeductions';

type ChildSupportDeduction = {
  agency: string;
  amount: string;
  externalId: string;
  fipsCode: string;
  issueDate: string;
  maxPercent: number;
};

type MiscellaneousDeduction = {
  amount: string;
  annualLimit: string;
  percent: number;
  totalAmount?: string;
};

type ConfigurationObject = ChildSupportDeduction | MiscellaneousDeduction;

type PostTaxDeduction = {
  description: string;
  effectiveEnd?: string;
  effectiveStart: string;
  employeeProviderId: string;
  type: string;
  configurationObject: ConfigurationObject;
};

export type PostTaxDeductionInput = {
  employeeId: string;
  type: PostTaxDeductionTypes;
  description: string;
  effectiveStart: string;
  effectiveEnd?: string;
  configurationObject: {
    miscellaneous?: {
      amount?: string;
      annualLimit?: string;
      percent?: number;
      totalAmount?: string;
    };
    childSupport?: {
      agency: string;
      amount: string;
      externalId: string;
      issueDate: string;
      maxPercent: number;
    };
  };
};

type CreatePostTaxDeductionPayload = {
  postTaxDeduction: PostTaxDeduction;
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

export const createPostTaxDeduction = (
  postTaxDeductionParams: PostTaxDeductionInput
): Promise<PostTaxDeduction | void> => {
  const mutation = `
      mutation CreatePostTaxDeduction($employeeId: String!, $postTaxDeductionInput: CreatePostTaxDeductionInput!) {
        createPostTaxDeduction(
          employeeInput: {id: $employeeId},
          postTaxDeductionInput: $postTaxDeductionInput
        ) {
          postTaxDeduction {
            description
            effectiveEnd
            effectiveStart
            employeeProviderId
            type
            configurationObject {
              ... on MiscellaneousDeduction {
                amount
                annualLimit
                percent
                totalAmount
              }
              ... on ChildSupportDeduction {
                agency
                amount
                externalId
                issueDate
                maxPercent
              }
            }
          }
          error {
            message
            type
            inputErrors {
              field
              fieldPath
              message
            }
          }
        }
      }
    `;

  const variables = {
    employeeId: postTaxDeductionParams.employeeId,
    postTaxDeductionInput: {
      type: postTaxDeductionParams.type,
      description: postTaxDeductionParams.description,
      effectiveStart: postTaxDeductionParams.effectiveStart,
      effectiveEnd: postTaxDeductionParams.effectiveEnd,
      configurationObject: postTaxDeductionParams.configurationObject,
    },
  };

  return procareApi
    .post<
      unknown,
      AxiosResponse<{ data: { createPostTaxDeduction: CreatePostTaxDeductionPayload } }>
    >('graphql', {
      query: mutation,
      variables,
    })
    .then(
      (
        response: AxiosResponse<{ data: { createPostTaxDeduction: CreatePostTaxDeductionPayload } }>
      ) => {
        if (response.data.data.createPostTaxDeduction.error) {
          sendErrorMessage(response.data.data.createPostTaxDeduction.error);
        }
        return response.data.data.createPostTaxDeduction.postTaxDeduction;
      }
    )
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
