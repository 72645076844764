import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { UserState, FormData, OnboardParams, CurrentUserContext, Role } from './types';
import { RootState } from '@src/store';
import { fetchUserContext, logoutUser, loginUser, confirmUser, onboardUser } from './service';
import { AxiosError } from 'axios';
import { redirect } from 'react-router-dom';
import { authorizeSignatory } from 'lib/authorizeSignatory';
import { setCurrentRole } from 'lib/setCurrentRole';
import { SignatoryInput } from 'components/AuthorizeSignatory/SignatoryForm/types';

const initialState: UserState = {
  currentUser: null,
  loading: false,
  employeeOnboarded: false,
};

export const userSelector = (state: RootState): UserState => state.user;
export const selectCurrentUser = createSelector(userSelector, (user) => user.currentUser);
export const selectEmployeeOnboarded = createSelector(
  userSelector,
  (user) => user.employeeOnboarded
);

export const getCurrentUser = createAsyncThunk<CurrentUserContext>(
  'user/getCurrentUser',
  async () => {
    const user = await fetchUserContext();
    return user;
  }
);

export const login = createAsyncThunk<CurrentUserContext, FormData>(
  'user/login',
  async (loginParams: FormData) => {
    const auth = await loginUser(loginParams);
    if (typeof auth === 'object' && auth.access_token) {
      const user = await fetchUserContext();
      return user;
    } else {
      throw new AxiosError('User is not authenticated.', '401');
    }
  }
);

export const signup = createAsyncThunk<CurrentUserContext, string>('user/signup', async (token) => {
  const auth = await confirmUser(token);
  if (typeof auth === 'object' && auth.access_token) {
    const user = await fetchUserContext();
    redirect('/dashboard');
    return user;
  } else {
    throw new AxiosError('User is not authenticated.', '401');
  }
});

export const logout = createAsyncThunk('user/logout', async () => {
  return logoutUser();
});

// Employee User Action
export const onboard = createAsyncThunk<CurrentUserContext, OnboardParams>(
  'user/onboard',
  async (onboardParams: OnboardParams) => {
    const auth = await onboardUser(onboardParams);
    if (typeof auth === 'object' && auth.access_token) {
      const user = await fetchUserContext();
      return user;
    } else {
      throw new AxiosError('User is not authenticated.', '401');
    }
  }
);

export const signatory = createAsyncThunk('user/addSignatory', async (data: SignatoryInput) => {
  return authorizeSignatory(data);
});

export const setRole = createAsyncThunk<Role, string>('user/setRole', async (roleId: string) => {
  const newRole = await setCurrentRole(roleId);
  if (!newRole) {
    throw new Error('Failed to set role');
  }
  return newRole;
});

export const setEmployeeOnboarded = createAsyncThunk<boolean, boolean>(
  'user/setEmployeeOnboarded',
  async (status: boolean) => {
    return status;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.loading = false;
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.currentUser = { ...payload };
      })
      .addCase(login.rejected, () => initialState)
      .addCase(onboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(onboard.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.currentUser = { ...payload };
      })
      .addCase(onboard.rejected, () => initialState)
      .addCase(signatory.pending, (state) => {
        state.loading = true;
      })
      .addCase(signatory.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload && state.currentUser) {
          state.currentUser.signatory = payload;
        }
      })
      .addCase(signatory.rejected, (state) => {
        state.loading = false;
      })
      .addCase(setRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(setRole.fulfilled, (state, { payload: newRole }) => {
        state.loading = false;
        if (state.currentUser) {
          state.currentUser.role = newRole;
        }
      })
      .addCase(setRole.rejected, (state) => {
        state.loading = false;
      })
      .addCase(setEmployeeOnboarded.fulfilled, (state, { payload }) => {
        state.employeeOnboarded = payload;
      })
      .addCase(logout.fulfilled, () => initialState)
      .addCase(signup.pending, (state) => {
        state.loading = true;
      })
      .addCase(signup.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.currentUser = { ...payload };
      })
      .addCase(signup.rejected, () => initialState);
  },
});

export default userSlice.reducer;
