import { type Asserts } from 'yup';
import { uploadFileSchema } from './validator';

export type UploadFormData = Asserts<typeof uploadFileSchema>;

export enum CheckDocumentType {
  FORM_940 = 'form_940',
  FORM_941 = 'form_941',
  FORM_943 = 'form_943',
  FORM_944 = 'form_944',
  FORM_945 = 'form_945',
  FORM_SS4 = 'form_ss4',
  FORM_CP_575 = 'form_cp_575',
  FORM_147_C = 'form_147_c',
  SIGNATORY_PHOTO_ID = 'signatory_photo_id',
  VOIDED_CHECK = 'voided_check',
  BANK_STATEMENT = 'bank_statement',
  BANK_LETTER = 'bank_letter',
  PROFIT_AND_LOSS = 'profit_and_loss',
  CASH_FLOW_STATEMENT = 'cash_flow_statement',
  BALANCE_SHEET = 'balance_sheet',
  ARTICLES_OF_INCORPORATION = 'articles_of_incorporation',
  ARTICLES_OF_INCORPORATION_SIGNATORY_AMENDMENT = 'articles_of_incorporation_signatory_amendment',
  STATE_REGISTRATION = 'state_registration',
}
