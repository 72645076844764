import styled from 'styled-components';

export const TabMenuContainer = styled.div`
  min-width: 100%;
`;

export const StyledTabMenu = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const TabMenuItem = styled.div<{ active: boolean }>`
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid ${(props) => props.theme.colors.border2};
  cursor: pointer;
  color: ${(props) => (props.active ? props.theme.colors.black : props.theme.colors.primary)};
  font-size: 16px;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};

  &:last-child {
    border-right: none;
  }
`;

type TabContainerProps = {
  divider?: boolean;
};

export const TabContainer = styled.div<TabContainerProps>`
  padding: 8px 0;
  border-bottom: ${({ divider, theme }) => (divider ? `2px solid ${theme.colors.border}` : 'none')};
  max-width: 1300px;
`;

type TabHeaderProps = {
  type?: 'company' | 'default';
};

export const TabHeader = styled.div<TabHeaderProps>`
  display: flex;
  justify-content: flex-end;
`;

export const TabHeading = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black0};
  margin: 8px 0;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabInstruction = styled.p`
  color: ${(props) => props.theme.colors.black0};
  margin: 12px 0;
`;

export const TabContent = styled.div``;

export const ItemContainer = styled.p``;

export const ItemLabel = styled.span`
  font-weight: 500;
  color: ${(props) => props.theme.colors.black};
  display: inline-block;
  width: 200px;
  vertical-align: top;
`;

export const ItemValue = styled.span`
  display: inline-block;
  line-height: 1.5;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
