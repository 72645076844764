import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionButton } from 'components/ActionButton/actionButton';
import { Employee } from 'features/user/types';
import { CompensationType } from '@src/constants/compensationType';
import { FormInput, FormSelect } from 'components/form';
import { updateEmployee, UpdateEmployeeInput } from 'lib/updateEmployee';
import { updateCompensationSchema } from '../validator';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  UpdateEmployeeFormContainer,
  UpdateForm,
  UpdateButtonContainer,
} from '../UpdateEmployeeForm/updateEmployeeForm.styles';

type UpdateCompensationFormProps = {
  id: string;
  employee: Employee;
  handleFormSubmit: (updatedEmployee: Employee) => void;
};

export const UpdateCompensationForm = ({
  employee,
  handleFormSubmit,
}: UpdateCompensationFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateEmployeeInput>({
    resolver: yupResolver(updateCompensationSchema),
    defaultValues: {
      compensation: {
        type: employee.compensation.type,
        amount: employee.compensation.amount,
      },
    },
  });

  const onSubmit: SubmitHandler<UpdateEmployeeInput> = async (data: UpdateEmployeeInput) => {
    const updatedEmployeeData: UpdateEmployeeInput = {
      firstName: employee.providerAttributes.firstName,
      lastName: employee.providerAttributes.lastName,
      email: employee.providerAttributes.email,
      dateOfBirth: moment(employee.providerAttributes.dateOfBirth).format('YYYY-MM-DD'),
      startDate: employee.providerAttributes.startDate,
      compensation: {
        type: data.compensation.type,
        amount: data.compensation.amount,
      },
      address: {
        line1: employee.providerAttributes.address.line1,
        city: employee.providerAttributes.address.city,
        state: employee.providerAttributes.address.state,
        postalCode: employee.providerAttributes.address.postalCode,
        country: 'US',
      },
      workplaceId: employee.workplaces[0].id,
      providerIdentifier: employee.providerIdentifier,
      active: employee.active,
    };

    const response = await updateEmployee(updatedEmployeeData);
    if (response) {
      toast.success('The compensation has been successfully updated.');
      handleFormSubmit(response);
    }
  };

  return (
    <UpdateEmployeeFormContainer>
      <UpdateForm>
        <FormSelect
          id="type"
          label="Compensation Type"
          required
          options={Object.values(CompensationType).map((type) => ({
            value: type,
            label: type,
          }))}
          {...register('compensation.type')}
        />
        <FormInput
          id="amount"
          placeholder="Amount"
          label="Amount"
          type="text"
          required
          {...register('compensation.amount')}
          error={errors.compensation?.amount?.message}
        />
        <UpdateButtonContainer>
          <ActionButton
            size="medium"
            title="Save"
            hidden={false}
            onClick={handleSubmit(onSubmit)}
            shape="fullWidth"
          />
        </UpdateButtonContainer>
      </UpdateForm>
    </UpdateEmployeeFormContainer>
  );
};
